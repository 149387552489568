import { Checkbox, ColorPicker } from 'antd'
import React, { useState } from 'react'
import { addElements, editCategory } from '../../utils/elementsUtils'
import { setNotes } from '../../../../../store/scriptSlice'
import Dropdown from '../../../../Common/DropDown'
import { MdExpandMore } from 'react-icons/md'

const EditCategoryModal = ({  editor, category, sceneIndex, setUpdateElements, close }) => {
  const [name, setName] = useState(category.name)
  const [color, setColor] = useState(category.color)
  const [isDefault, setIsDefault] = useState(category.isDefault)

  const handleSave = ()=>{
    const categoryData = {name,color,isDefault}
    if(category.scene || category.scene === 0)
      editCategory(editor,categoryData,category.name,category.scene-1)
    else
      editCategory(editor,categoryData,category.name,sceneIndex)
    setUpdateElements(prev=>!prev)
    close()
  }

  return (
    <div className='bg-[#00000030] z-[80] fixed top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <div className='w-[400px] bg-white rounded-lg p-4'>
        <h2 className='w-full font-bold text-center text-[#868686]'>Edit category</h2>
        <p className='text-[#868686] text-[12px] mt-8 mb-2'>Category name</p>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className='border text-[12px] w-full p-2 rounded-lg' placeholder='Category name' />

        <div className='flex gap-4 mt-4'>
          <div className='flex gap-2 items-center'>
            <ColorPicker size={'small'} defaultValue={color} onChange={(val, hex) => { setColor(hex) }} />
            <p className='text-[#868686] text-[12px]'>Category color</p>
          </div>

          <div className='flex gap-2 items-center'>
            <Checkbox  className='flex items-center text-[12px]' checked={isDefault} onChange={(e) => { setIsDefault(e.target.checked) }} />
            <p className='text-[#868686] text-[12px]'>Default category</p>
          </div>
        </div>


        <div className='flex justify-between mt-4'>
          <button onClick={() => {close() }} className='text-[12px] px-3 py-2 border rounded-lg'>Cancel</button>
          <button onClick={() => {handleSave()}} className='text-[12px] px-3 py-2 bg-[#0099ff] rounded-lg text-white'>Save</button>
        </div>
      </div>
    </div>
  )
}

export default EditCategoryModal