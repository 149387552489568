import { useEffect, useState } from "react"
import BackButton from "../BackButton"
import EmptyPage from "../EmptyPage"
import { useSelector } from "react-redux"
import CharactrDetails from "./CharacterDetails"
import Dropdown from "../../../../Common/DropDown.js"
import { MdAdd, MdExpandMore } from "react-icons/md"
import AddCharacterModal from "./AddCharacterModal.js"
import { addExtrasCharacter, addNonSpeakingCharacter, deleteExtrasCharacter, deleteNonSpeakingCharacter } from "../../utils/characterUtils.js"
import CharacterButton from "./CharacterButton.js"
import { getAccessToken } from "../../../../../services/authService.js"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import LoaderSpin from "../../../../Common/LoaderSpin.js"
import AddNonSpeakingModal from "./AddNonSpeakingModal.js"
import { goToScene } from "../../utils/sceneutils.js"

const CharacterPanel = ({ setPanel, editor, setUpdateCharacters, permissionToEdit }) => {

    const sceneCharacters = useSelector(state => state.script.characters.sceneCharacters)
    const [sceneIndex, setSceneIndex] = useState(0)
    const [openNonSpeakingModal, setOpenNonSpeakingModal] = useState(false)
    const [openExtrasModal, setOpenExtrasModal] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [openedCharacter, setOpenedCharacter] = useState(null)
    const { id } = useParams()
    const [characterList, setCharacterList] = useState([])
    const [updateList,setUpdateList] = useState(false)

    const fetchCharacterList = async () => {
        setLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/character?documentId=${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })

            const body = await res.json()
            if (!res.ok) {
                throw new Error('Error getting response', body)
            }
            const data = body.data
            setCharacterList(data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error)
            toast.error('Error fetching characters')
        }
    }

    useEffect(()=>{
        fetchCharacterList()
    },[updateList])

    const getCharacterFromList=(character)=>{
        return characterList.find(char=>char.name === character)
     }


    const handleCharacterClick = (name) => {
        setOpenedCharacter(name)
    }
    const handleNonSpeakSave = (name) => {
        if (!sceneCharacters[sceneIndex].nonSpeaking.find(char => char.name === name)) {
            addNonSpeakingCharacter(editor, name, sceneIndex)
        }
        setOpenNonSpeakingModal(false)
    }

    const handleExtrasSave = (name) => {
        if (!sceneCharacters[sceneIndex].extra.find(char => char.name === name)) {
            addExtrasCharacter(editor, name, sceneIndex)
        }
        setOpenExtrasModal(false)
    }

    useEffect(()=>{
        if(editor){
            goToScene(sceneIndex,editor)
        }
    },[sceneIndex,editor])

    return <>
        <div className='w-full h-full '>
            {openNonSpeakingModal && <AddNonSpeakingModal onSave={handleNonSpeakSave} onCancel={() => { setOpenNonSpeakingModal(false) }} title={'Add Non-Speaking Character'} />}
            {openExtrasModal && <AddCharacterModal  onSave={handleExtrasSave} onCancel={() => { setOpenExtrasModal(false) }} title={'Add Extra Character'} />}
            {openedCharacter !== null ?
                <CharactrDetails
                    characterList={characterList}
                    setUpdateCharacters={setUpdateCharacters}
                    setOpenedCharacter={setOpenedCharacter}
                    editor={editor}
                    activeCharacterName ={openedCharacter}
                    setUpdateList={setUpdateList}
                    permissionToEdit={permissionToEdit}
                />
                :
                <>
                    <BackButton setPanel={setPanel} />
                    {isLoading? 
                    <div className="flex justify-center items-center py-8"><LoaderSpin/></div>
                    :
                    <div className='w-full h-[calc(100%-65px)] mt-4 shadow-sm overflow-hidden'>
                        {Object.keys(sceneCharacters)?.length===0 &&
                            <EmptyPage title={'You dont have any characters'}
                                subtitle={'Start typing characters to see here'} />}
                        {Object.keys(sceneCharacters)?.length!==0 &&
                            <div className=" bg-[#F3F4F6] h-full rounded flex flex-col gap-4 pt-2 pb-1">
                                <div className="flex justify-between items-center px-4 ">
                                    <h3 className="font-bold text-[13px] ">Characters</h3>
                                    <Dropdown close={sceneIndex} trigger={
                                        <button className="bg-white hover:bg-[#efefef] text-[12px] text-start rounded-lg flex items-center justify-between border text-[#868686] px-2 w-[100px] py-2">
                                            Scene {sceneIndex + 1}
                                            <MdExpandMore size={18} color="#CACACA" />
                                        </button>}>
                                        <div className=' flex flex-col w-[100px] max-h-[250px] overflow-y-auto'>
                                            {Object.keys(sceneCharacters).map((_, index) =>
                                                <button
                                                key={index}
                                                    style={{ color: index === sceneIndex ? '#0099ff' : '', background: index === sceneIndex ? '#F0F9FF' : '' }}
                                                    className='border-b py-2 text-start text-[#868686] text-[13px] px-2 hover:bg-[#e2e2e2]'
                                                    onClick={() => {
                                                        setSceneIndex(index)
                                                    }}>
                                                    Scene {1 + index}
                                                </button>
                                            )}
                                        </div>

                                    </Dropdown>
                                </div>
                                <hr className="-mt-2" />
                                <h3 className="font-medium text-[12px] px-4 text-[#868686]">Dialogue</h3>
                                {sceneCharacters[sceneIndex]?.dialogue?.length > 0 && <div className="-mt-4 py-4 px-4 overflow-y-auto gap-[1px] flex flex-col">
                                    {sceneCharacters[sceneIndex]?.dialogue?.map((character, index) =>{
                                        if(character && character.name!=="")
                                        return <CharacterButton
                                        key={index}
                                        onClick={() => { handleCharacterClick(character) }}
                                        color={getCharacterFromList(character)?.color ||'#d9dee3'}
                                        name={character}
                                    />
                                    }
                                        )}
                                </div>}

                                <hr />
                                <div className="flex justify-between px-4 items-center">
                                    <h3 className="font-medium text-[12px] text-[#868686]">Non Speaking</h3>
                                    {permissionToEdit && <button onClick={() => { setOpenNonSpeakingModal(true) }} className="text-[#CACACA] transition-colors hover:text-[#292929]">
                                        <MdAdd size={20} />
                                    </button>}

                                </div>
                                {sceneCharacters[sceneIndex]?.nonSpeaking?.length > 0 && <div className="-mt-4 py-4 px-4 overflow-y-auto gap-[1px] flex flex-col">
                                    {sceneCharacters[sceneIndex]?.nonSpeaking?.map((character, index) =>
                                        <CharacterButton
                                            key={index}
                                            onClick={() => { handleCharacterClick(character) }}
                                            color={getCharacterFromList(character)?.color ||'#d9dee3'}
                                            name={character}
                                            isDelete={permissionToEdit}
                                            onDelete={() => {
                                                deleteNonSpeakingCharacter(editor, character, sceneIndex)
                                                setUpdateCharacters(prev => !prev)
                                            }}
                                        />)}
                                </div>}

                                <hr />

                                <h3 className="font-medium text-[12px] px-4 text-[#868686]">Voice Over</h3>
                                {sceneCharacters[sceneIndex]?.voice?.length > 0 && <div className="-mt-4 py-4 px-4 overflow-y-auto gap-[1px] flex flex-col">
                                    {sceneCharacters[sceneIndex]?.voice?.map((character, index) =>
                                        <CharacterButton
                                            key={index}
                                            onClick={() => { handleCharacterClick(character) }}
                                            color={characterList.find((char)=> char.name === character)?.color ||'#d9dee3'}
                                            name={character.replace('(V.O)', '')}
                                        />)}
                                </div>}
                                <hr />
                                <div className="flex justify-between px-4 items-center">
                                    <h3 className="font-medium text-[12px] text-[#868686]">Extras</h3>
                                    {permissionToEdit && <button onClick={() => { setOpenExtrasModal(true) }} className="text-[#CACACA] transition-colors hover:text-[#292929]">
                                        <MdAdd size={20} />
                                    </button>}
                                </div>
                                {sceneCharacters[sceneIndex]?.extra?.length > 0 && <div className="-mt-4 py-4 px-4 overflow-y-auto gap-[1px] flex flex-col">
                                    {sceneCharacters[sceneIndex]?.extra?.map((character, index) =>
                                        <CharacterButton
                                            key={index}
                                            onClick={() => { handleCharacterClick(character) }}
                                            color={characterList.find((char)=> char.name === character)?.color ||'#d9dee3'}
                                            name={character}
                                            isDelete={permissionToEdit}
                                            onDelete={() => {
                                                deleteExtrasCharacter(editor, character, sceneIndex)
                                                setUpdateCharacters(prev => !prev)
                                            }}
                                        />)}
                                </div>}
                            </div>}
                    </div>
                    }
                </>
            }
        </div>
    </>
}

export default CharacterPanel