import React, { useState, useEffect } from "react";
import { MdArrowBackIos, MdDelete, MdPersonAddAlt1 } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import InviteBanner from "../../../assets/invite-banner.png";
import LoaderSpin from "../../Common/LoaderSpin";
import Checkbox from "antd/es/checkbox/Checkbox";
import ManagePermissions from "./ManagePermissions";
import AddPeoplePopUp from "./AddPeoplePopUp";
import { FloatButton, Modal } from "antd";
import { useParams } from "react-router-dom";
import AcceptInvitePopup from "./AcceptInvitePopUp";
import { getAccessToken } from "../../../services/authService";
import { deleteUser } from "../Editor/utils/inviteUtils";

const Invite = () => {
  const [isPeopleTabActive, setIsPeopleTabActive] = useState(true);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [updateCollaborators, setUpdateCollaborators] = useState(false);
  const [isPermissionsPopupOpen, setIsPermissionsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [deleteUserModal, setDeleteUserModal] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleDeleteUser = async (userId) => {
    if (!isUserAdmin) return
    setDeleteUserModal(null)
    setLoading(true)
    await deleteUser(id, userId)
    setLoading(false)
    setUpdateCollaborators(prev => !prev)
  }
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPopUpOpen)
      fetchCollaborator();
  }, [isPopUpOpen, updateCollaborators]);



  const fetchCollaborator = async () => {
    setIsLoading(true);
    const token = getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/documents/${id}/collaborators/list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUsers(data.data);
      setIsUserAdmin(!data.data.find(user => user.email === userData.email));
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div className="">
      <div className=" bg-white flex flex-col">
        <header className="p-4 flex border-b border-[#0000001A] justify-between items-baseline">
          <div className="flex gap-1">
            <button
              className="h-fit pt-1"
              onClick={() => {
                navigate(`/scripts/${id}`);
              }}
            >
              <MdArrowBackIos size={22} color="#292929" />
            </button>
            <div className="">
              <h1 className="text-[#292929] font-bold text-[20px]">Menu</h1>
              {/* <h2 className='text-[#868686] text-[15px]'>{scriptData?.title}</h2> */}
            </div>
          </div>

        </header>
        <div>
          <div className="lg:hidden mt-5 flex p-1 gap-1 rounded-[9px] bg-[#EFEFEF] m-[10px]">
            <button
              className={` grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${isPeopleTabActive && "bg-white shadow-md"
                }`}
              onClick={() => {
                setIsPeopleTabActive(true);
              }}
            >
              People
            </button>
            <button
              className={`grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${!isPeopleTabActive && "bg-white shadow-md"
                } `}
              onClick={() => {
                setIsPeopleTabActive(false);
              }}
              disabled={!isUserAdmin}
            >
              Permissions
            </button>
          </div>
        </div>
        <div>
          {isLoading || loading ? (
            <div className="flex w-full justify-center items-center h-[80vh]">
              <LoaderSpin size={36} />
            </div>
          ) : users.length == 0 ? (
            <NoDataView setIsPopUpOpen={setIsPopUpOpen} />
          ) : (
            <>
              <div className="text-[13px] text-[#3C3C4399] roboto font-light text-left w-full py-2 px-[10px] bg-[#F6F6F6]">
                RECENT
              </div>
              <div className="bg-[#F6F6F6] w-full overflow-auto ">
                {isPeopleTabActive
                  ? users.map((user) => (
                    <div className="flex justify-between w-full h-[76px]  items-center px-[10px] bg-[white] my-[1px]">
                      <div className="flex items-center gap-3 justify-between">
                        <div className="rounded-[50%] uppercase flex justify-center items-center h-10 w-10 bg-red-200 text-gray-600">
                          {user.name.charAt(0)}
                        </div>
                        <div className="flex flex-col">
                          <p className="text-[15px] font-[500]">
                            {user.name}
                          </p>
                          <p className="text-[#868686] text-[13px]">
                            {user.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <p className="text-[green] text-[13px] font-[500]">
                          Accepted
                        </p>
                        {isUserAdmin && <button
                          onClick={() => { setDeleteUserModal(user.userId) }}
                          className=" flex  items-center gap-1 text-[13px] border text-gray-500 font-medium px-2 py-1 rounded-full">

                          Delete
                        </button>}
                      </div>

                    </div>
                  ))
                  : users.reverse().map((user) => (
                    <div className="flex justify-between w-full h-[76px]  items-center px-[10px] bg-[white] my-[1px]">
                      <div className="flex items-center gap-3 justify-between">
                        <div className="rounded-[50%] uppercase flex justify-center items-center h-10 w-10 bg-red-200 text-gray-600">
                          {user.name.charAt(0)}
                        </div>
                        <div className="flex flex-col">
                          <p className="text-[15px] font-[500]">
                            {user.name}
                          </p>
                          <p className="text-[#868686] text-[13px]">
                            {user.email}
                          </p>
                        </div>
                      </div>
                      <p
                        className="text-[#0099FF] text-[13px] font-[500] Eudoxus"
                        onClick={() => setIsPermissionsPopupOpen(user)}
                      >
                        Details
                      </p>
                    </div>
                  ))}
              </div>{" "}
            </>
          )}
        </div>
      </div>
      {((users.length !== 0 && (!isPermissionsPopupOpen && !isPopUpOpen)) && isUserAdmin) && (
        <FloatButton
          onClick={() => setIsPopUpOpen(true)}
          shape="circle"
          type="primary"
          className="h-[50px] flex items-center justify-center w-[50px] bottom-[100px] bg-[#0099FF]"
          style={{ insetInlineEnd: 24 }}
          icon={
            <MdPersonAddAlt1 size={22} />
          }
        />
      )}

      {isPopUpOpen && (
        <AddPeoplePopUp
          setUpdateCollaborators={setUpdateCollaborators}
          isOpen={isPopUpOpen}
          setIsOpen={setIsPopUpOpen} />
      )}
      {isPermissionsPopupOpen && (
        <ManagePermissions
          setUpdateCollaborators={setUpdateCollaborators}
          activeUser={isPermissionsPopupOpen}
          setActiveUser={setIsPermissionsPopupOpen}
        />
      )}
      <Modal title={'Delete user'} open={deleteUserModal}
        onCancel={() => { setDeleteUserModal(null) }}
        onOk={() => { handleDeleteUser(deleteUserModal) }}>
        <p>Are you sure you want to remove the invited user</p>
      </Modal>

      {/* <AcceptInvitePopup isOpen={true} setIsOpen={setIsPermissionsPopupOpen} /> */}
    </div>
  );
};

export default Invite;

const NoDataView = ({ setIsPopUpOpen }) => {
  return (
    <>
      <div>
        <img
          src={InviteBanner}
          alt="invite-banner"
          className="w-[239px] mx-auto mt-4"
        />
      </div>
      <div className="mx-[31px]">
        <h2 className="mt-[47px] text-center text-[#404040] font-bold text-[20px] text-center]">
          Add Team member to this Script
        </h2>
        <h3 className="mt-3 text-[#768394] text-[15px] text-center">
          Add your team members to collaborate together on this script.
        </h3>
        {false ? (
          <div className="flex items-center h-[250px]">
            <LoaderSpin />
          </div>
        ) : (
          <>
            <button
              className="mt-6 bg-[#f5fbff] w-full p-4 rounded-full font-bold text-[#0099ff]"
              onClick={() => setIsPopUpOpen(true)}
            >
              Invite
            </button>
          </>
        )}
      </div>
    </>
  );
};
