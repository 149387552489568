import { Spin } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { LoadingOutlined } from "@ant-design/icons";

const ScrollPagination = ({ lastId, setFetch, totalItems, length, loading }) => {

  const loader = useRef(null)

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  },[lastId]);

  const handleObserver = (entities) => {
    const target = entities[0];

    if (target.isIntersecting && length<totalItems) {
     setFetch(prev => !prev)
    }
  };

  return (
    <div className='h-[96px] flex justify-center items-center p-8'> 
      {loading === 1 && 
        <Spin size='large' indicator={
        <LoadingOutlined spin />
      } />
      }
      <div ref={loader} />
    </div>
  );
};

export default ScrollPagination;