import React, { useEffect } from 'react'
import { useMediaQuery, Navigate, Outlet, useNavigate } from 'react-router-dom';

const ResponsiveReRoute = ({ isLargeRoute }) => {

    const isSmallScreen = () => {
        return window.innerWidth < 1024
    }
    const navigate = useNavigate()

    const handleResize = () => {
        if (!isSmallScreen()  ) {
            navigate('/')
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return ()=> window.removeEventListener('resize',handleResize)
    }, [])

    if (isLargeRoute) {
        return isSmallScreen() ? <Navigate to={'/'} /> : <Outlet />
    }
    else {
        return isSmallScreen() ? <Outlet /> : <Navigate to={'/'} />
    }
}

export default ResponsiveReRoute