import { createAsyncThunk } from "@reduxjs/toolkit";
import { logOutFromLocal } from "../services/authService";
import { logout } from "./authSlice";

export const logOutAsync = createAsyncThunk("auth/logoutAsync", async (_, { dispatch }) => {
  try {
    await logOutFromLocal();  
  } catch (error) {
    console.error("Logout failed:", error);
  }
});
