import { useEffect, useState } from "react";
import Invite from "../../../Invite"
import BackButton from "../BackButton"
import EmptyPage from "../EmptyPage"
import { MdArrowBackIos, MdDelete, MdPersonAddAlt1 } from "react-icons/md";
import LoaderSpin from "../../../../Common/LoaderSpin";
import InviteBanner from "../../../../../assets/invite-banner.png";
import ManagePermissionsModal from "./ManagePermissionsModal";
import AddPeopleModal from "./AddPeopleModal";
import { FloatButton, Modal } from "antd";
import { useParams } from "react-router-dom";
import { getAccessToken } from "../../../../../services/authService";
import { deleteUser } from "../../utils/inviteUtils";

const InvitePanel = ({ setPanel, isScriptAdmin, users, setUsers, isLoading, setIsLoading, isPopUpOpen, setIsPopUpOpen, updateCollaborators, setUpdateCollaborators }) => {
    const [isPeopleTabActive, setIsPeopleTabActive] = useState(true);
    const [isPermissionsActiveUser, setPermissionActiveUser] = useState(false);
    const [deleteUserModal, setDeleteUserModal] = useState(null)
    const [loading, setLoading] = useState(false)
    const { id } = useParams()

    const handleDeleteUser = async (userId) => {
        if (!isScriptAdmin) return
        setDeleteUserModal(null)
        setLoading(true)
        await deleteUser(id, userId)
        setLoading(false)
        setUpdateCollaborators(prev => !prev)
    }

    return <div className='w-full h-full relative '>
        <BackButton setPanel={setPanel} />
        <div className='w-full shadow-sm h-[calc(100%-40px)]'>
            {/* <EmptyPage title={'You have not invited anyone'} subtitle={'Invite somone to see here'} /> */}
            <div className=" h-full bg-white">
                <div className=" flex flex-col h-full">
                    <div>
                        <h1 className="font-semibold my-2 mt-4">Invites</h1>
                        <div className=" flex p-1 gap-1 rounded-[9px] bg-[#EFEFEF] m-[10px]">
                            <button
                                className={` grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${isPeopleTabActive && "bg-white shadow-md"
                                    }`}
                                onClick={() => {
                                    setIsPeopleTabActive(true);
                                }}
                            >
                                People
                            </button>
                            <button
                                className={`grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${!isPeopleTabActive && "bg-white shadow-md"
                                    } ${users.length === 0 || !isScriptAdmin ? "opacity-50 cursor-not-allowed" : ""}`}
                                onClick={() => {
                                    setIsPeopleTabActive(false);
                                }}
                                disabled={users.length === 0 || !isScriptAdmin}
                            >
                                Permissions
                            </button>
                        </div>
                    </div>
                    <div className="max-h-[calc(100%-90px)]">
                        {isLoading || loading ? (
                            <div className="flex w-full justify-center items-center h-[80vh]">
                                <LoaderSpin size={36} />
                            </div>
                        ) : users.length == 0 ? (
                            <NoDataView setIsPopUpOpen={setIsPopUpOpen} isScriptAdmin={isScriptAdmin} />
                        ) : (

                            <div className=" w-full overflow-y-auto py-2 h-full">
                                {isPeopleTabActive
                                    ? users.map((user) => (
                                        <>
                                            <div className="flex group relative justify-between w-full h-[50px] border-b items-center px-[10px] bg-[white] my-[1px]">
                                                <div className="flex items-center max-w-[80%] gap-2">
                                                    <div className="rounded-[50%] min-w-8 flex text-[14px] justify-center uppercase items-center h-8 w-8 bg-red-200 text-gray-600">
                                                        {user.name.charAt(0)}
                                                    </div>
                                                    <div className="flex flex-col max-w-full ">
                                                        <p className="text-[14px] font-[500]">
                                                            {user.name}
                                                        </p>
                                                        <p className="text-[#868686] text-[12px]  overflow-hidden text-ellipsis whitespace-nowrap">
                                                            {user.email}

                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col gap-1 items-center">
                                                    <p className="text-[green] w-fit text-[12px] font-[500]">
                                                        Accepted
                                                    </p>
                                                    {isScriptAdmin && <button
                                                        // disabled={}
                                                        onClick={() => { setDeleteUserModal(user.userId) }}
                                                        className='bg-white rounded-full text-[12px] transition-all border-red-400 text-red-400 px-2  border flex justify-center items-center hover:bg-red-400 hover:text-white'>
                                                        Remove
                                                    </button>}
                                                </div>


                                            </div>


                                        </>
                                    ))
                                    : users.reverse().map((user) => (
                                        <div className="flex group relative justify-between w-full h-[50px] border-b items-center px-[10px] bg-[white] my-[1px]">
                                            <div className=" flex items-center max-w-[80%] gap-2">
                                                <div className="rounded-[50%] min-w-8 flex text-[14px] justify-center uppercase items-center h-8 w-8 bg-red-200 text-gray-600">
                                                    {user.name.charAt(0)}
                                                </div>
                                                <div className="flex flex-col max-w-full">
                                                    <p className="text-[14px] font-[500]">
                                                        {user.name}
                                                    </p>
                                                    <p className="text-[#868686] text-[12px]  overflow-hidden text-ellipsis whitespace-nowrap">
                                                        {user.email}
                                                    </p>
                                                </div>
                                            </div>
                                            <p
                                                className="text-[#0099FF] cursor-pointer text-[13px] font-[500] Eudoxus"
                                                onClick={() => setPermissionActiveUser(user)
                                                }
                                            >
                                                Details
                                            </p>
                                        </div>
                                    ))}
                            </div>

                        )}
                    </div>
                </div>
                {isScriptAdmin && (users.length !== 0 && (!isPermissionsActiveUser && !isPopUpOpen)) && (
                    <button
                        className="h-[50px] flex justify-center items-center absolute w-[50px] rounded-full bottom-[10px] right-[10px] bg-[#0099FF]"

                        onClick={() => setIsPopUpOpen(true)}>
                        <MdPersonAddAlt1 color="white" size={24} />
                    </button>

                )}

                {isPopUpOpen && (
                    <AddPeopleModal
                        setUpdateCollaborators={setUpdateCollaborators}
                        isOpen={isPopUpOpen}
                        setIsOpen={setIsPopUpOpen} />
                )}
                {isPermissionsActiveUser && (
                    <ManagePermissionsModal
                        setUpdateCollaborators={setUpdateCollaborators}
                        activeUser={isPermissionsActiveUser}
                        setActiveUser={setPermissionActiveUser}
                    />
                )}

                {/* <AcceptInvitePopup isOpen={true} setIsOpen={setIsPermissionsPopupOpen} /> */}
            </div>
            <Modal title={'Remove User'} open={deleteUserModal}
                onCancel={() => { setDeleteUserModal(null) }}
                onOk={() => { handleDeleteUser(deleteUserModal) }}>
                <p>Are you sure you want to remove the invited user</p>
            </Modal>
        </div>
    </div>
}
const NoDataView = ({ setIsPopUpOpen, isScriptAdmin }) => {
    return (
        <div className="overflow-y-auto max-h-full">
            <div>
                <img
                    src={InviteBanner}
                    alt="invite-banner"
                    className="w-[239px] mx-auto mt-4"
                />
            </div>
            <div className="mx-[31px]">
                <h2 className="mt-[47px] text-center text-[#404040] font-bold text-[20px]  ">
                    Add Team member to this Script
                </h2>
                <h3 className="mt-3 text-[#768394] text-[15px] text-center">
                    Add your team members to collaborate together on this script.
                </h3>
                {false ? (
                    <div className="flex items-center h-[250px]">
                        <LoaderSpin />
                    </div>
                ) : (
                    <>
                        <button
                            disabled={!isScriptAdmin}
                            className="mt-6 mb-6 bg-[#f5fbff] w-full p-4 rounded-full font-bold text-[#0099ff] disabled:cursor-not-allowed"
                            onClick={() => setIsPopUpOpen(true)}
                        >
                            Invite
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};
export default InvitePanel