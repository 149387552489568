import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="825253309448-4do6725pil5leni80cg2l08nhfftp4no.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          id="myContainer"
          stacked
          autoClose={3000}
        />
      </Provider>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);
