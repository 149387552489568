import { useEffect, useRef, useState } from "react"
import { getTitle, timeColors } from "../Editor/utils/sceneutils";
import { serialize } from "parse5";
import { getAccessToken } from "../../../services/authService";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import LoaderSpin from "../../Common/LoaderSpin";
import SuggestionDropDown from "../Editor/sidepanel/SuggestionDropDown";
import { MdExpandMore } from "react-icons/md";
import Dropdown from "../../Common/DropDown";
import { useParams } from "react-router-dom";



const AddElement = ({categoryList,elementList, sceneIndex: index, pNodes, setIsOpen, setUpdateData }) => {
    const [category, setCategory] = useState(categoryList.find(category => category.isDefault) || categoryList[0] || {name:'', color:'#d9dee3'}) 
    const [name, setName] = useState('')
    const [closeDropDown, setCloseDropDown] = useState(false)
    const [notes, setNotes] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const close = ()=>{
      setIsOpen(false)
    }
  
    const { id } = useParams()
    const handleSave = () => {
        if (name == "" || elementList.find(elem => elem.name === name)) {
            toast.error("Category Already exists")
            return
        }
        const newElementList = [...elementList]

        newElementList.push({
            name, category:category.name, notes
        })

        const scenePnodes = pNodes.filter(node => node.attrs.find(attr => attr.name === 'class')?.value === 'sceneHeadings')
        const currentScene = scenePnodes[index]
    
        if (currentScene.attrs.find(({ name }) => name === 'data-element-list')?.value)
          pNodes.find(node => node === currentScene).attrs.find(({ name }) => name === 'data-element-list').value = JSON.stringify(newElementList)
        else pNodes.find(node => node === currentScene).attrs.push({ name: 'data-element-list', value: JSON.stringify(newElementList) })

        const content = serialize({ childNodes: pNodes })
        updateContent(content)
        // setUpdateElements(prev=>!prev)

    }
    const updateContent = async (content) => {
        setIsLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }

            const data = {
                content
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'PUT',
                body: JSON.stringify(data)

            })
            if (!res.ok) {
                throw new Error('Error getting response')
            }
            const body = await res.json()
            toast.success('Element Updated')
            setIsLoading(false)
            setUpdateData(prev => !prev)
            close()
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            toast.error('Element Update Failed')
        }
    }
  
    return (
      <div className='bg-[#00000030] text-[12px] z-[80] fixed top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
        <div className='w-[400px] max-w-[95%] bg-white rounded-lg p-4'>
          <h2 className='w-full font-bold text-center text-[#868686] text-[16px]'>Add element</h2>
  
  
          <Dropdown
            className={'w-full'}
            close={closeDropDown}
            disabled={categoryList.length === 0}
            alignment="left"
            trigger={
            <button className='border-2 flex justify-between w-full text-start rounded mt-8 px-3 py-2'>
              <div style={{borderColor:category.color} } className='border-s-2 ps-2 -ms-2'>
              {category.name || 'No categories'}
              </div>
               
               <MdExpandMore color='#CACACA' size={20}/>
               </button>}>
            <div className='flex flex-col w-[300px] max-h-[250px] overflow-y-auto' >
              {categoryList.map(cat =>
                <>
                  <button
                    onClick={() => { setCategory(cat)
                      setCloseDropDown(prev=>!prev)
                     }}
                    style={{ borderColor: cat.color }}
                    className={`text-start hover:bg-[#e2e2e2] px-3 py-3 border-s-2`}>
                    {cat.name}
                  </button>
                  <hr/>
                </>
              )}
  
  
            </div>
          </Dropdown>
  
  
          <p className='text-[#868686] text-[12px] mt-4 mb-2'>Element name</p>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className='border text-[12px] w-full p-2 rounded-lg'
            placeholder='Element name' />
  
          <p className='text-[#868686] text-[12px] mt-4 mb-2'>Item notes</p>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className='border h-40 text-[12px] w-full p-2 rounded-lg'
            placeholder='Add notes' />
  
  
  
          <div className='flex justify-between mt-4'>
            <button onClick={() => { close() }} disabled={isLoading} className='text-[12px] px-3 py-2 border rounded-lg'>Cancel</button>
            <button onClick={() => { handleSave() }} disabled={isLoading} className='text-[12px] w-[52px] px-3 py-2 bg-[#0099ff] rounded-lg text-white'>
            {isLoading ? <LoaderSpin color={'white'} /> : "Save"}
              </button>
          </div>
        </div>
      </div>
    )
  }

export default AddElement