import { useEffect, useRef, useState } from "react"
import { MdUploadFile } from "react-icons/md";
import { toast } from "react-toastify";
import { getAccessToken } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import LoaderSpin from "../../Common/LoaderSpin";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { useDispatch } from "react-redux";
import SaveAsModal from "../modals/SaveAsModal";

const SaveMenuPopUp = ({ isSaveOpen, setIsSaveOpen, id, scriptData }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [isSaveAsModalOpen, setIsSaveAsModalOpen] = useState(false)
    const menuRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const close = () => {
        setIsVisible(false)
        setTimeout(() => {
            setIsSaveOpen(false)
        }, 300);
    }

    const saveScript = async (status) => {
        setLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        const { title, content, episodeTitle, episodeNumber, author, basedOn, byLine, copyright, phoneNumber, type } = scriptData
        const body = { content }
        
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'PUT',
                body: JSON.stringify({...body,status})
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const data = await res.json()
            navigate('/scripts')

            setLoading(false)
        } catch (error) {
            if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
                dispatch(setConnectionErrorOpen(true))
              }
            setLoading(false)
            toast.error('Failed to update the script')
            console.error(error)
        }
    }

    useEffect(() => {
        setIsVisible(isSaveOpen)
    }, [isSaveOpen])

    useEffect(()=>{
      if(isSaveAsModalOpen) {
        setIsVisible(false)
      }
      else{
        setIsVisible(true)
      }
    },[isSaveAsModalOpen])

    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} transition-all duration-200 bottom-0 flex flex-col justify-center gap-2 items-center z-50 fixed top-0 left-0 w-[100vw] h-[100vh] `}>
             {isSaveAsModalOpen && <SaveAsModal scripData={scriptData} isSaveAsModalOpen={isSaveAsModalOpen} setIsSaveAsModalOpen={setIsSaveAsModalOpen}/>}
            <div ref={menuRef}
                className={`${isVisible ? '' : 'opacity-0'} transition-all duration-300 w-[80%] max-w-[400px] mx-auto rounded-2xl bg-[#f2f2f2] shadow flex flex-col items-center`}>
                <h2 className="py-4 font-bold text-[#3C3C4399] text-[13px]">Script Menu</h2>
                <hr className="w-full border-[#3C3C4399]" />
                {!loading?<div className="w-full">
                    <button className="py-4 text-center text-[17px] text-[#007AFF] w-full" onClick={() => { saveScript() }}>Save</button>
                    <hr className="w-full border-[#3C3C4399]" />
                    <button className="py-4 text-center text-[17px] text-[#007AFF] w-full" onClick={() => { setIsSaveAsModalOpen(true)
                     }}>Save a copy</button>
                    <hr className="w-full border-[#3C3C4399]" />
                    <button className="py-4 text-center text-[17px] text-[#007AFF] w-full" onClick={() => { saveScript('INCOMPLETE') }}>In Progress</button>
                    <hr className="w-full border-[#3C3C4399]" />
                    <button className="py-4 text-center text-[17px] text-[#007AFF] w-full" onClick={() => { saveScript('INREVIEW') }}>In Review</button>
                    <hr className="w-full border-[#3C3C4399]" />
                    <button className="py-4 text-center text-[17px] text-[#007AFF] w-full" onClick={() => { saveScript('COMPLETE') }}>Complete</button>
                </div>:
                <div className="h-[250px] flex items-center"><LoaderSpin size={25}/></div>}
            </div>
            <button onClick={close} className={`${isVisible ? '' : 'opacity-0'} transition-opacity duration-300 rounded-2xl active:bg-[#f4f4f4] bg-white text-[#007AFF] text-[20px] font-bold w-[80%] max-w-[400px] py-4`}>Cancel</button>
        </div>)
}

export default SaveMenuPopUp