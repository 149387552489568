import { toast } from "react-toastify";

export const getSceneCards = (editor) => {
    if (editor) {

        const content = editor.getContent({ format: 'raw' });
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');

        const allSceneCards = [];
        const selectedNode = editor.selection?.getNode();
        let lastSceneIndex = -1;

        const traverseNodes = (node, index = 0) => {
            if (node.nodeType === 1) {
                if (node.classList.contains('sceneHeadings')) {
                    allSceneCards.push({
                        title: node.textContent,
                        color: node.getAttribute('data-color'),
                        action: ''
                    })
                }

                if (node.classList.contains('action') && allSceneCards[allSceneCards.length - 1]) {
                    allSceneCards[allSceneCards.length - 1].action += node.textContent + '\n'
                }

            }


            if (node.isEqualNode(selectedNode)) {
                lastSceneIndex = allSceneCards.length - 1;
            }
            node.childNodes.forEach((childNode, childIndex) =>
                traverseNodes(childNode, index + childIndex + 1)
            );
        };

        traverseNodes(doc.body);

        if (lastSceneIndex >= 0) {
            return { index: lastSceneIndex + 1, allScenes: allSceneCards };
        } else {
            return { index: null, allScenes: allSceneCards }
        }
    }
}

export const migrateHeadings = (editor, sceneCardList) => {
    const body = editor.getBody()
    if (editor) {
        sceneCardList.forEach((sceneCard, index) => {
            const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]');
            if (selectedNodes.length > 0) {
                const selectedNode = selectedNodes[0]
                editor.dom.setHTML(selectedNode, sceneCard.title)
            }
            else {
                var sceneNode = editor.dom.create('p', { class: 'sceneHeadings' }, sceneCard.title);
                sceneNode.setAttribute('data-scene-index', index + 1)

                const lastNode = editor.dom.select('body *:last-child')[0];
                if(lastNode && lastNode.classList.length!==0){
                    editor.dom.insertAfter(sceneNode, lastNode);
                }
                else{
                    body.appendChild(sceneNode)
                }
                
            }
        })
    }
    else {
        toast.error('Editor not found')
    }

    toast.success('Headings migrated')
}

export const migrateAll = (editor, sceneCardList) => {
    if (editor) {
        const body = editor.getBody();
        sceneCardList.forEach((sceneCard, index) => {
            const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]');
            let lastNode = null
            if (selectedNodes.length > 0) {
                const selectedNode = selectedNodes[0]
                editor.dom.setHTML(selectedNode, sceneCard.title)

                lastNode = selectedNode
                let currentNode = selectedNode.nextSibling

                //remove previous action nodes from the scene
                while (currentNode && currentNode.classList[0] !== "sceneHeadings") {
                    const tempNode = currentNode
                    currentNode = currentNode.nextSibling
                    if (tempNode.classList[0] === "action") {
                        editor.dom.remove(tempNode)
                    }
                }

            }
            else {
                var sceneNode = editor.dom.create('p', { class: 'sceneHeadings' }, sceneCard.title);
                sceneNode.setAttribute('data-scene-index', index + 1)

                lastNode = editor.dom.select('body *:last-child')[0];
                if(lastNode && lastNode.classList.length!==0){
                    editor.dom.insertAfter(sceneNode, lastNode);
                }
                else{
                    body.appendChild(sceneNode)
                }
                

                lastNode = sceneNode

            }

            const actionsList = sceneCard.action.split('\n')

            if (actionsList) {
                actionsList.forEach(action => {
                    if (action !== "") {
                        const newActionNode = editor.dom.create('p', { class: 'action' }, action);
                        editor.dom.insertAfter(newActionNode, lastNode)
                        lastNode = newActionNode
                    }
                })
            }
            else if(sceneCard.action !== "") {
                const newActionNode = editor.dom.create('p', { class: 'action' }, sceneCard.action);
                editor.dom.insertAfter(newActionNode, lastNode)
            }
        })
    }
    else {
        toast.error('Editor not found')
    }

    toast.success('Scene card migrated')
}