import React, { useEffect, useRef, useState } from 'react'
import { MdArrowBackIos, MdClose, MdExpandMore, MdSearch } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from '../../../services/authService'
import LoaderSpin from '../../Common/LoaderSpin'
import ScriptTab from './ScriptTab'
import ScenesTab from './ScenesTab'
import { parseToBody } from '../Editor/utils/scriptUtils'
import Dropdown from '../../Common/DropDown'

const Scenes = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isScriptTabActive, setIsScriptTabActive] = useState(true)
    const [searchVal, SetSearchVal] = useState('')
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [activeScene, setActiveScene] = useState(1)

    const [loading, setLoading] = useState(1)
    const [scriptData, setScriptData] = useState({})
    const [updateData, setUpdateData] = useState(false)
    const searchRef = useRef(null)



    const fetchScript = async () => {
        setLoading(1)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const body = await res.json()
            setScriptData(body.data)

            setLoading(0)
        } catch (error) {
            setLoading(2)
            console.error(error)
        }
    }

    const { content } = scriptData
    const body = parseToBody(content)

    const scenes = []

    body.childNodes.forEach((node, index) => {
        if (node.nodeType === 1) {
            if (node.classList[0] === 'sceneHeadings') {
                scenes.push({
                    color: node.getAttribute('data-color'),
                    summary: node.getAttribute('data-summary'),
                    title: node.textContent,
                    notes: 0
                })
            }
        }
    })

    useEffect(() => {
        searchRef.current?.focus()
    }, [searchRef.current, isSearchOpen])

    useEffect(() => {
        fetchScript()
    }, [updateData])
    return (
        <div className=" bg-white flex flex-col h-full overflow-y-hidden">
            <nav className=' p-4 gap-4 min-h-[72px] max-h-[72px] flex border-b border-[#0000001A] justify-between items-center'>
                {isSearchOpen && !isScriptTabActive ?
                    <>
                        <div className='bg-white border grow border-[#CACACA] gap-2 rounded-lg p-3 flex items-center'>
                            <MdSearch size={24} color='#AAAAAA' />
                            <input ref={searchRef} placeholder={`Search scenes`}
                                className='outline-none grow focus:border-none'
                                value={searchVal}
                                onChange={(e) => { SetSearchVal(e.target.value) }} />

                        </div>
                        <MdClose size={28} color='#868686' onClick={() => {
                            setIsSearchOpen(false)
                            SetSearchVal('')
                        }} />
                    </>
                    :
                    <><div className='flex gap-1'>
                        <button className='h-fit pt-1' onClick={() => { navigate(`/scripts/${id}`) }}>
                            <MdArrowBackIos size={22} color='#292929' />
                        </button>
                        <div className=''>
                            <h1 className='text-[#292929] font-bold text-[20px]'>Scenes</h1>
                        </div>
                    </div>
                        <div>
                        </div>
                        {!isScriptTabActive ? <button className='pt-1'>
                            <MdSearch size={28} color='#AAAAAA' onClick={() => {
                                setIsSearchOpen(true)
                            }} />
                        </button> :

                            <Dropdown close={activeScene} alignment='right' trigger={
                                <button className="bg-white hover:bg-[#efefef] text-[14px] text-start rounded-lg flex items-center justify-between border text-[#868686] p-3 py-2 w-[130px] ">
                                    Scene {activeScene}
                                    <MdExpandMore size={18} color="#CACACA" />
                                </button>}>
                                <div className=' flex flex-col w-[130px] max-h-[250px] z-[90] overflow-y-auto'>
                                    {scenes.map((_, index) =>
                                        <button
                                            style={{ color: index === activeScene - 1 ? '#0099ff' : '', background: index === activeScene - 1 ? '#F0F9FF' : '' }}
                                            className='border-b py-2 text-start text-[#868686] text-[13px] px-2 hover:bg-[#e2e2e2]'
                                            onClick={() => {
                                                setActiveScene(index + 1)
                                            }}>
                                            Scene {1 + index}
                                        </button>
                                    )}
                                </div>
                            </Dropdown>}
                    </>}
            </nav>

            <div className="overflow-y-hidden mt-5 mx-4 flex p-1 mb-2 gap-1 rounded-[9px] bg-[#EFEFEF]">
                <button
                    className={` grow text-[13px] flex items-center justify-center p-2 text-[#292929] text-center rounded-[9px] ${isScriptTabActive && "bg-white shadow-md"
                        }`}
                    onClick={() => {
                        setIsScriptTabActive(true);
                    }}
                >
                    Script
                </button>
                <button
                    className={`grow text-[13px] flex items-center justify-center p-2 text-[#292929] text-center rounded-[9px] ${!isScriptTabActive && "bg-white shadow-md"
                        }`}
                    onClick={() => {
                        setIsScriptTabActive(false);
                    }}
                >
                    Scenes
                </button>
            </div>

            <div className='h-full overflow-hidden'>
                {loading === 1 && <div className='h-full flex items-center justify-center'><LoaderSpin size={40} /></div>}
                {loading === 0 && <div className='overflow-y-hidden'>
                    
                    <div className='overflow-y-scroll h-[calc(100svh-100px)] pb-10 mt-2'>
                        {isScriptTabActive ? <ScriptTab content={scriptData.content} sceneIndex={activeScene} /> :
                            <ScenesTab
                                searchVal={searchVal}
                                scriptData={scriptData}
                                body={body}
                                setUpdateData={setUpdateData} />}
                    </div>
                </div>}
            </div>

        </div>
    )
}

export default Scenes