import { useEffect, useRef, useState } from "react"
import { MdUploadFile } from "react-icons/md";
import { toast } from "react-toastify";
import { getAccessToken } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import LoaderSpin from "../../Common/LoaderSpin";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { useDispatch } from "react-redux";

const AddMenu = ({  isOpen,   setIsOpen,  setOpenAddCategory, setOpenAddElement }) => {
    const [isVisible, setIsVisible] = useState(false)
    const menuRef = useRef(null)
    const [loading, setLoading] = useState(false)

    const close = () => {
        setIsVisible(false)
        setTimeout(() => {
            setIsOpen(false)
        }, 300);
    }

    useEffect(() => {
        setIsVisible(isOpen)
    }, [isOpen])

    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} transition-all duration-200 bottom-0 flex flex-col justify-center gap-2 items-center z-50 fixed top-0 left-0 w-[100vw] h-[100vh] `}>
            <div ref={menuRef}
                className={`${isVisible ? '' : 'opacity-0'} transition-all duration-300 w-[80%] max-w-[400px] mx-auto rounded-2xl bg-[#f2f2f2] shadow flex flex-col items-center`}>
                <h2 className="py-4 font-bold text-[#3C3C4399] text-[13px]">Add Element</h2>
                <hr className="w-full border-[#3C3C4399]" />
                {!loading?<div className="w-full">
                    <button className="py-4 text-center text-[17px] text-[#007AFF] w-full" onClick={() => { setOpenAddCategory(true)
                        close()
                     }}> Add Category</button>
                    <hr className="w-full border-[#3C3C4399]" />
                    <button className="py-4 text-center text-[17px] text-[#007AFF] w-full" onClick={() => { setOpenAddElement(true)
                        close()
                     }}> Add Element</button>
                </div>:
                <div className="h-[250px] flex items-center"><LoaderSpin size={25}/></div>}
            </div>
            <button onClick={close} className={`${isVisible ? '' : 'opacity-0'} transition-opacity duration-300 rounded-2xl active:bg-[#f4f4f4] bg-white text-[#007AFF] text-[20px] font-bold w-[80%] max-w-[400px] py-4`}>Cancel</button>
        </div>)
}

export default AddMenu