import React, { useState, useRef, useEffect } from "react";

const Dropdown = ({ className, trigger, close, children, onOpen=()=>{}, onClose=()=>{}, alignment = 'center' ,showArrow = false ,triggerAction = 'click',disabled=false}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if(disabled) return
    setIsOpen(!isOpen);
  };

  let timeout = null
  const handleMouseOver = () =>{
    if(triggerAction==='hover'){
      clearTimeout(timeout)
      setIsOpen(true)
    }
  }
  const handleMouseLeave = () =>{
    if(triggerAction==='hover'){
      timeout = setTimeout(()=>{
        setIsOpen(false)
      },[100])
      
    }
  }

  useEffect(()=>{
    if(isOpen){
      onOpen()
    }
    else{
      onClose()
    }
  },[isOpen])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(()=>{
    setIsOpen(false)
  },[close])


  return (
    <div className={`inline-block ${isOpen && 'z-[1000]'} ${className}`} ref={dropdownRef} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <div className="cursor-pointer" onClick={toggleDropdown}>{trigger}</div>

      {isOpen && (
        <div className="relative">
          <div
          style={{boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'}}
            className={`absolute ${alignment === 'center' && "left-[50%] translate-x-[-50%]"}
             ${alignment === 'left' && "left-0"}
             ${alignment === 'right' && "right-0"}
             bg-white border w-max h-max rounded-md mt-4`}>
            {showArrow && (
              <div className={`absolute -top-2 -translate-x-1/2 ${alignment === 'left' && 'left-5'} ${alignment === 'right' && 'right-2'}`}>
                <div className="w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45"/>
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
