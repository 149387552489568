import React from 'react'
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from 'antd';

const LoaderSpin = ({size,color}) => {
  return (
    <Spin indicator={
        <LoadingOutlined style={{fontSize: `${size}px`, color}} />
      } />
  )
}

export default LoaderSpin