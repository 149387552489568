import React from "react"
import { Drawer } from "antd"
import AlexAiModal from "./AlexAiModal copy"
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";

export default function AlexAI({ open, onClose, }) {
  const { id } = useParams();
  const navigate = useNavigate();

  return <div className=" bg-white flex flex-col">

    <AlexAiModal open={open} setOpen={onClose} />
  </div>

}