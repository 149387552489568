import { useEffect, useRef, useState } from "react"
import { MdUploadFile } from "react-icons/md";
import typeWriterImage from '../../../../assets/backgrounds/type_writer.png'
import sceneYellow from '../../../../assets/backgrounds/scene_yellow.png'
import sceneBlue from '../../../../assets/backgrounds/scene_blue.png'
import { getAccessToken } from "../../../../services/authService";
import { setConnectionErrorOpen } from "../../../../store/navSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import LoaderSpin from "../../../Common/LoaderSpin";
import { handleFileInput } from "../utils/scriptUtils";
import { autoFormatScript } from "../utils/formatUtils";

const ScreenWritingPopUp = ({ isOpen, setIsOpen, editor,documentType }) => {
    const [isVisible, setIsVisible] = useState(false)
    const menuRef = useRef(null)
    const [isSecondScreen, setIsSecondScreen] = useState(false)
    const pdfInputRef = useRef(null)
    const fdxInputRef = useRef(null)
    const [isLoading, setisLoading] = useState(null)

    const handleInput = async (e) => {
        setisLoading(true)
        const body = await handleFileInput(e)
        setisLoading(false)
        const formattedContent = autoFormatScript(body.text, documentType)

        if (editor) {
            editor.setContent(formattedContent.toString())
            closeAfterNavigate()
        }
        else {
            toast.error('editor not loaded')
        }

    }

    const close = () => {
        setIsVisible(false)
        setTimeout(() => {
            setIsOpen(false)
        }, 300);
    }

    const closeAfterNavigate = () => {
        setIsVisible(false)
        setIsOpen(false)
    }

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            close()
        }
    };



    useEffect(() => {
        setIsVisible(isOpen)
    }, [isOpen])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} lg:h-[93vh] lg:justify-center lg:items-center lg:fixed lg:top-[7vh] lg:left-0 transition-all duration-200 bottom-0 flex items-end z-[80] fixed top-0 left-0  w-[100vw] h-[100svh] `}>
            <div ref={menuRef}
                className={`${isVisible ? 'translate-y-0' : 'translate-y-full'} lg:max-w-[500px] lg:max-h-[90vh] lg:rounded-lg lg:overflow-y-auto transition-all duration-300 px-7 w-full mx-auto rounded-t-2xl bg-white shadow flex flex-col items-center`}>
                {!isSecondScreen ? <>
                    <div className="bg-[#E4E4E4] w-[56px] mx-auto mt-2 rounded-full h-[6px]" />
                    <div className="mt-7">
                        <img src={typeWriterImage} className="mx-auto size-[180px]" />
                    </div>
                    <h2 className="mt-[47px] text-[#404040] font-bold text-[20px] text-center">Welcome to Screenwriting</h2>
                    <h3 className="mt-3 text-[#768394] text-[15px] text-center">Let's create your script. You can work together with your team.</h3>
                    {isLoading ?
                        <div className="flex items-center h-[250px]">
                            <LoaderSpin />
                        </div>
                        : <>
                            <button className="mt-6 bg-[#f5fbff] w-full p-4 rounded-full font-bold text-[#0099ff]" onClick={close}>Continue</button>
                            <button onClick={() => { pdfInputRef.current.click() }} className="mt-4 bg-[#F6F6F6] w-full p-4 rounded-full font-bold text-[#AAAAAA] flex gap-2 justify-center items-center"><MdUploadFile size={24} color="#AAAAAA" />Import from PDF</button>
                            <button onClick={() => { fdxInputRef.current.click() }} className="my-4 bg-[#F6F6F6] w-full p-4 rounded-full font-bold text-[#AAAAAA] flex gap-2 justify-center items-center"><MdUploadFile size={24} color="#AAAAAA" />Import from FDX</button>
                        </>}
                    <input onInput={handleInput} ref={pdfInputRef} type="file" accept="application/pdf" className="hidden" />
                    <input onInput={handleInput} ref={fdxInputRef} type="file" accept=".fdx" className="hidden" />
                </>
                    :
                    <>
                        <div className="bg-[#E4E4E4] w-[56px] mx-auto mt-2 rounded-full h-[6px]" />
                        <div className="relative mt-[32px]">
                            <img src={sceneYellow} className="absolute top-[53px]" />
                            <img src={sceneBlue} />
                        </div>
                        <h2 className="mt-[91px] text-[#404040] font-bold text-[20px] text-center">Important for Scene</h2>
                        <h3 className="mt-3 text-[#768394] text-[15px] text-center">For best results and allowing  the formatting to take affect correctly the scene location should be as follows: EXT,INT, I/E space location - sub-location - time of day</h3>
                        <button className="mt-6 bg-[#f5fbff] w-full p-4 rounded-full font-bold text-[#0099ff]" onClick={close}>Got it</button>
                        <button className="mt-4 mb-6 w-full p-4 rounded-full font-bold text-[#AAAAAA] text-center" onClick={close}>Don't show me again</button>
                    </>}
            </div>
        </div>)
}

export default ScreenWritingPopUp