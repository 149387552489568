import { useEffect, useRef, useState } from "react"
import { MdMovie, MdArrowBackIos, MdAssistant, MdMicrowave } from "react-icons/md";
import { Link } from "react-router-dom";

const CreateScriptMenu = ({ isCreateOpen, setIsCreateOpen }) => {

    const [isVisible, setIsVisible] = useState(false)
    const menuRef = useRef(null)

    const close = () => {
        setIsVisible(false)
        setTimeout(() => {
            setIsCreateOpen(false)
        }, 300);
    }

    const closeAfterNavigate = ()=>{
        setIsVisible(false)
        setIsCreateOpen(false)
    }

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            close()
        }
    };



    useEffect(() => {
        setIsVisible(isCreateOpen)
    }, [isCreateOpen])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} transition-all duration-200 lg:hidden bottom-0 flex items-end z-20 absolute  w-[100vw] h-[100vh] `}>
            <div ref={menuRef}
                className={`${isVisible ? 'translate-y-0' : 'translate-y-full'} transition-all duration-300 w-full mx-auto rounded-t-2xl bg-white shadow pb-2`}>
                <div className="bg-[#E4E4E4] w-10 mx-auto mt-2 rounded-full h-1" />
                <h2 className="mt-6 text-center text-[20px] text-[#292929] font-bold">Create new Script</h2>
                <Link to={'/scripts/create/treatment'} className="w-full text-[#292929] mt-4 p-4 flex items-center gap-[12px] text-start" onClick={() => { closeAfterNavigate() }}><MdAssistant size={24} color="#AAAAAA" /> Treatment </Link>
                <hr />
                <Link to={'/scripts/create/film'} className="w-full text-[#292929]  p-4 flex items-center gap-[12px] text-start" onClick={() => { closeAfterNavigate() }}><MdMovie size={24} color="#AAAAAA" /> Film Script </Link>
                <hr />
                <Link to={'/scripts/create/tv'} className="w-full text-[#292929] p-4 flex items-center gap-[12px] text-start" onClick={() => { closeAfterNavigate() }}><MdMicrowave size={24} color="#AAAAAA" /> TV Script </Link>
            </div>
        </div>)
}

export default CreateScriptMenu