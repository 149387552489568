import { useEffect, useRef, useState } from "react";
import { getAccessToken } from "../../../../../services/authService";
import { useParams } from "react-router-dom";
import { setConnectionErrorOpen } from "../../../../../store/navSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderSpin from "../../../../Common/LoaderSpin";
import { Card } from "antd";
import { setRevisions } from "../../../../../store/scriptSlice";
import { Button } from "antd";
import { toast } from "react-toastify";
import EmptyPage from "../EmptyPage";

export default function RevisionTab({ scriptData, setRevisionData, setPanel }) {

  const [loading, setLoading] = useState(1);
  const { id } = useParams();
  const [revisionList, setRevisionList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0)
  // const [revisionId, setRevisionId] = useState(null);
  // const [savingRevision, setSavingRevision] = useState(null);
  const dispatch = useDispatch();
  const editor = useSelector((state) => state.script.editorRef);
  const currentContentRef = useRef(editor.getContent())
  const [isRestore, setIsRestore] = useState(false)

  const getColorForUser = (userId) => {
    // Fixed color palette - same colors will be used consistently
    const colors = [
      '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD',
      '#D4A5A5', '#9B59B6', '#3498DB', '#E67E22', '#1ABC9C',
      '#CD5C5C', '#40E0D0', '#FFB6C1', '#87CEEB', '#DDA0DD'
    ];
    
    // This hash function ensures same userId always gets same color
    const colorIndex = Math.abs(userId.split('').reduce((acc, char) => {
      return acc + char.charCodeAt(0);
    }, 0) % colors.length);
    
    return colors[colorIndex];
  };

  useEffect(() => {
    fetchRevisions();
  }, []);

  const fetchRevisions = async () => {
    try {
      setLoading(1);

      const token = getAccessToken();
      const url = `${process.env.REACT_APP_DEV_API}/document/${id}/revision`;
      if (!token) {
        throw new Error("A valid session not found");
      }
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        throw new Error("Error getting response", res);
      }
      const body = await res.json();

      setRevisionList(body.data)
      setRevisionData(body.data)
      dispatch(setRevisions(body.data))
      setLoading(0);

    } catch (error) {
      if (error.message === "Failed to fetch" || error.name === "TypeError") {
        dispatch(setConnectionErrorOpen(true));
      }
      setLoading(2);
      console.error(error);
    }
  };

  const handleChangeRevision = (revision,index) => {
    setActiveIndex(index)
    const body = editor.getBody()
    body.innerHTML = revision.content

    // editor.execCommand("mceSetContent", false, revision.content);
  };

  const restoreRevision = ()=>{
    currentContentRef.current=editor.getContent()
    setPanel('')
    toast.success('Revision restored successfully')
  }

  useEffect(()=>{
 
    return ()=>{
      if(!isRestore){
        console.log('shouldnotrun',isRestore)
        editor.execCommand("mceSetContent", false, currentContentRef.current);
      }
    }
  },[isRestore])

  // const handleSaveRevision = async () => {
  //   try {
  //     setSavingRevision(true);
  //     const revisionId = await fetchScript();
  //     if (!revisionId) {
  //       toast.error("Please modify the script to create a new revision")
  //       setSavingRevision(false)
  //       return
  //     }
  //     const token = getAccessToken();
  //     const url = `${process.env.REACT_APP_DEV_API}/document/${id}/revision/${revisionId}`;
  //     if (!token) {
  //       throw new Error("A valid session not found");
  //     }
  //     const res = await fetch(url, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       method: "POST",
  //     });
  //     if (!res.ok) {
  //       throw new Error("Error getting response", res);
  //     }
  //     const body = await res.json();

  //     toast.success("New revision saved")

  //     setSavingRevision(false);
  //   } catch (error) {
  //     if (error.message === "Failed to fetch" || error.name === "TypeError") {
  //       dispatch(setConnectionErrorOpen(true));
  //     }
  //     setLoading(2);
  //     console.error(error);
  //     setSavingRevision(false);
  //   }
  // };

  return (
    <div className='w-full h-[calc(100%-30px)] pt-4 shadow-sm'>
      {
        revisionList.length === 0 && loading === 0 ?
          <EmptyPage title={'You do not have any revisions'} subtitle={'Revisions will be visible here'} />
          :
          <div className="h-full">
            {loading === 1 ? (
              <div className="flex h-full items-center justify-center">
                <LoaderSpin size={30} />
              </div>

            ) : loading === 2 ? (
              <div>Failed to fetch</div>
            ) : loading === 0 ? (
              <div className="h-full">
                <>
                <div className="flex items-center justify-between">
                <h1 className="font-semibold mb-2">Revisions</h1>
                  <button onClick={restoreRevision} disabled={activeIndex===0} className={`text-[12px] bg-[#0099ff] text-white border disabled:text-gray-500 disabled:bg-white px-2 py-1 rounded-lg`}>
                    Restore this version
                  </button>
                </div>
                  

                  {/* <Button
              className="my-4 w-full"
              type="primary"
              onClick={handleSaveRevision}
              loading={savingRevision}
            >
              Save Revision
            </Button> */}

                  <div className="flex flex-col gap-1 pb-4 h-[calc(100%-45px)] overflow-auto">
                    {revisionList.slice().sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                      .map((revision, index) => {
                        return (
                          <button
                            key={index}
                            className={`mt-2 ${index===activeIndex && "border-[#0099ff]"} w-full focus:shadow-lg text-start h-fit group cursor-pointer relative border shadow-sm p-2 py-[2px] text-[12px] rounded-lg`}
                            onClick={() => {
                              handleChangeRevision(revision, index)
                            }}
                          >

                            <div className=" flex justify-between items-center w-full">
                              <div className="text-[#292929] flex items-center justify-between w-full">
                                <p className="font-medium text-gray-600">
                                  {new Date(revision.updatedAt).toLocaleDateString('en-US', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: '2-digit',
                                    hour: 'numeric',
                                    minute: '2-digit'
                                  })}
                                </p>
                                {index === 0 && <p className="text-gray-400">Latest</p>}
                              </div>
                            </div>
                            <div className="flex justify-between text-[12px] text-[#868686] items-center">
                              <div className="flex gap-1 items-center">
                              <div style={{background:getColorForUser(revision.updatedBy)}} className="size-[6px] -mb-[2px] rounded-full"/>
                              <p>{revision.userName || 'No user'}</p>
                              </div>
                              
                              {revision.updatedBy === scriptData.userId && <p className="text-gray-400">Admin</p>}
                            </div>
                            {/* <div className="flex justify-end text-[9px] text-[#868686] items-center">
                      <p>{note.time}</p>
                    </div> */}

                            {/* <button
                      className="bg-white rounded-full text-red-400 transition-all size-7 shadow-lg border flex justify-center items-center absolute -bottom-2 -right-2 opacity-0 group-hover:opacity-100"
                      disabled={!permissionToEdit}
                      onClick={(e) => {
                        setSelectedNoteId(note.noteId);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <MdDelete size={14} />
                    </button> */}
                            {/* <button
                      className="bg-white rounded-full text-gray-400 transition-all size-7 shadow-lg border flex justify-center items-center absolute -top-2 -right-2 opacity-0 group-hover:opacity-100"
                      disabled={!permissionToEdit}
                      onClick={(e) => {
                        e.preventDefault()
                        setSelectedNoteId(note.noteId);
                        form.setFieldValue("notes", note.text)
                        setEditVisible(true)
                        setVisible(true)
                      }}
                    >
                      <MdEdit size={14} />
                    </button> */}


                          </button>
                        );
                      })}
                  </div>
                </>
              </div>
            ) : (
              <div>Failed to fetch</div>
            )}
          </div>
      }

    </div>

  );
}
