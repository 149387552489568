import { useEffect } from "react";
import { handleGoogleCallback } from "../services/authService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const GoogleCallback = () => {
    const navigate = useNavigate()
    useEffect(() => {
     
      const handleCallback = async () => {
        try {
          const userData = await handleGoogleCallback();
          
          console.log('User data:', userData);
          navigate('/login',{state:{googleUser:userData}})
          
          
        } catch (error) {
          toast.error("Error signing in")
          console.error('Error:', error);
          navigate('/login'); // Redirect back to login on error
        }
      };
  
      handleCallback();
    }, []);
  
    return <div>Loading...</div>;
  };

export default GoogleCallback