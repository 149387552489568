import React, { useEffect, useState } from 'react'
import { BsChatDotsFill } from 'react-icons/bs'
import { FaHighlighter } from 'react-icons/fa'
import { toast } from "react-toastify";
import { MdAmpStories, MdArrowBackIos, MdArrowForwardIos, MdChevronRight, MdGroups, MdLayers, MdMovie, MdOutlineArticle, MdOutlineSentimentSatisfied } from 'react-icons/md'
import ScenePanel from './ScenePanel/ScenePanel'
import BackButton from './BackButton'
import { setCharacters, setNotes, setScenes } from '../../../../store/scriptSlice'
import { getScenes } from '../utils/sceneutils'
import { useDispatch, useSelector } from 'react-redux'
import AiIconDisabled from '../../../../assets/icons/ai_icon_disabled.svg'
import AiIcon from '../../../../assets/icons/ai_icon.svg'

import EmptyPage from './EmptyPage'
import { getCharacter } from '../utils/characterUtils'
import CharacterPanel from './CharacterPanel/CharacterPanel'
import { Button } from 'antd'
import InvitePanel from './InvitePanel'
import ElementsPanel from './CategoriesAndElementsPanel'
import { getElements } from '../utils/elementsUtils'
import SceneCardPanel from './SceneCardsPanel'
import { getSceneCards } from '../utils/sceneCardsUtils'
import RevisionTab from '../../Revisions/RevisionTab.js'
import { useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from '../../../../services/authService'
import AlexAIPanel from './AlexAIPanel'
import NotesPanel from './NotesPanel';
import LoaderSpin from '../../../Common/LoaderSpin';
import SubscriptionModal from './SubscriptionModal';
import AlexAiAddOnModal from './AlexAiAddOnModal';
import RevisionsPanel from './RevisionPanel';

const SidePanel = ({ editor, scriptData }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const planFeatures = user?.planFeatures || {}
    const subscription = user?.subscription || {}
    const [panel, setPanel] = useState('')
    const [updateScenes, setUpdateScenes] = useState(false)
    const [updateCharacters, setUpdateCharacters] = useState(false)
    const [updateElements, setUpdateElements] = useState(false)
    const scenes = useSelector(state => state.script.scenes)
    const characters = useSelector(state => state.script.characters.allCharacters)
    const [revisions, setRevisions] = useState([])
    const notes = useSelector(state => state.script.notes)
    const [sceneElements, setSceneElements] = useState([])
    const [sceneCards, setSceneCards] = useState([])
    const { id } = useParams()

    const [users, setUsers] = useState([]);
    const [havePermissionToEdit, setHavePermissionToEdit] = useState(false);
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [updateCollaborators, setUpdateCollaborators] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const [isAlexAiAddOnModalOpen, setIsAlexAiAddOnModalOpen] = useState(false);

    const dispatch = useDispatch()

    const getAllNotes = () => {
        const body = editor.getBody();
        let sceneCount = 0
        const noteList = []

        body.childNodes.forEach((node) => {
            if (node.classList[0] === 'sceneHeadings') {
                sceneCount++;
            }
            if (node.getAttribute('data-note-list')) {
                const tempNoteList = JSON.parse(node.getAttribute('data-note-list') || "null") || []
                noteList.push(...tempNoteList.map(note => {
                    return {...note,scene:sceneCount}
                }));
            }
        })

        dispatch(setNotes(noteList));
        return noteList;
    };

    const handleContentChange = (e) => {
        if (!editor) {
            return
        }
        reloadScenes()
        reloadCharacters()
        reloadElements()
        reloadSceneCards()
        getAllNotes()
    }

    const reloadScenes = () => {
        const sceneObject = getScenes(editor)
        dispatch(setScenes(sceneObject))
    }

    const reloadCharacters = () => {
        const charObject = getCharacter(editor)
        dispatch(setCharacters(charObject))
    }

    const reloadElements = () => {
        setSceneElements(getElements(editor))
    }

    const reloadSceneCards = () => {
        setSceneCards(getSceneCards(editor))
    }

    useEffect(() => {
        if (editor) {
            handleContentChange()

            editor.on('change', handleContentChange)
            return () => {
                editor.off('change', handleContentChange)
            }
        }
    }, [editor])

    useEffect(() => {
        fetchRevisions()
    }, [])


    const fetchRevisions = async () => {

        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}/revision`
        try {
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const body = await res.json()

            setRevisions(body.data)

        } catch (error) {
            console.error(error)
        }
    }

    const getUsersPermission = () => {
        let permissionToEdit = false;
        if (scriptData?.userId === user?._id) {
            permissionToEdit = true;
        }
        else {
            const userInInviteList = scriptData?.invitedCollaborators?.find(u => u.userId._id === user?._id);
            if (userInInviteList) {
                permissionToEdit = userInInviteList?.permissions?.SCRIPT === "WRITE";
            }
        }
        return permissionToEdit;
    };

    useEffect(() => {
        if (!isPopUpOpen && planFeatures.canCollaborate) {
            fetchCollaborator();
            const permissionToEdit = getUsersPermission();
            setHavePermissionToEdit(permissionToEdit);
        } else {
            setIsLoading(false);
            if (scriptData?.userId === user?._id) {
                setHavePermissionToEdit(true);
            } else {
                const permissionToEdit = getUsersPermission();
                setHavePermissionToEdit(permissionToEdit);
            }
        }

    }, [isPopUpOpen, updateCollaborators]);

    const fetchCollaborator = async () => {
        setIsLoading(true);
        const token = getAccessToken();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DEV_API}/documents/${id}/collaborators/list`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsLoading(false);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setUsers(data.data);
        } catch (error) {
            setIsLoading(false)
            console.error("There was a problem with the fetch operation:", error);
            toast.error('Error loading collaborators')
        }
    };

    useEffect(() => {
        if (editor)
            reloadScenes()
    }, [updateScenes])

    useEffect(() => {
        if (editor)
            reloadCharacters()
    }, [updateCharacters])

    useEffect(() => {
        if (editor)
            reloadElements()
    }, [updateElements])

    useEffect(() => {
        if (editor)
            reloadSceneCards()
    }, [scenes])




    const openSubscriptionModal = () => {
        setIsSubscriptionModalOpen(true)
    }


    const PanelOption = ({ title, isDisabled, subtitle, Icon, color, background, onClick, isTreatMentDisabled }) => {


        return (
            <div onClick={isDisabled ? openSubscriptionModal : isTreatMentDisabled ? () => { } : onClick} className='w-full cursor-pointer border transition-all hover:shadow-lg p-3 py-2 h-fit rounded-lg flex justify-between items-center'>
                <div className='flex gap-4'>
                    <div className={`rounded-full flex items-center justify-center size-8`} style={{ background: isDisabled || isTreatMentDisabled ? '#F6F6F6' : background }}>
                        <Icon size={18} color={isDisabled || isTreatMentDisabled ? "#AAAAAA" : color} />
                    </div>
                    <div>
                        <p className='text-[13px] font-medium' style={{ color: isDisabled || isTreatMentDisabled ? '#868686' : '#292929' }}>{title}</p>
                        <p className='text-[11px] font-medium' style={{ color: isDisabled || isTreatMentDisabled ? '#AAAAAA' : '#757575' }}>{subtitle}</p>
                    </div>
                </div>
                <MdChevronRight size={24} color={'#CACACA'} />
            </div>
        )
    }

    const { invitedCollaborators } = scriptData;

    return (
        <div className='max-lg:hidden h-[calc(100%-12px)] bg-white p-4 mt-3 shadow-xl xl:w-[350px] w-[300px] border rounded-xl'>
            {isLoading ?
                <div className='flex justify-center mt-40'>
                    <LoaderSpin size={24} />
                </div>

                :
                <>
                    {panel === '' &&
                        <div className=' gap-2 flex flex-col  h-full overflow-x-auto'>

                            <PanelOption onClick={() => { setPanel('invite') }} title={'Invite'} subtitle={`${!users || users?.length === 0 ? 'No' : users.length} People`}
                                Icon={MdGroups} color={'#0099FF'} background={'#F0F9FF'} isDisabled={!planFeatures.canCollaborate} />

                            <PanelOption onClick={() => { setPanel('scene') }} title={'Scenes'} subtitle={`${!scenes?.allScenes ? 'No' : scenes?.allScenes?.length} Scenes`}
                                Icon={MdMovie} isTreatMentDisabled={scriptData.type === "TREATMENT"} color={'#CC8C14'} background={'#FDF8EE'} isDisabled={!planFeatures.canListScenes} />

                            <PanelOption onClick={() => { setPanel('character') }} title={'Characters'} subtitle={`${!characters ? 'No' : characters.length} Characters`}
                                Icon={MdOutlineSentimentSatisfied} isTreatMentDisabled={scriptData.type === "TREATMENT"} color={'#48A966'} background={'#F2FFF6'} isDisabled={!planFeatures.canUseCharacterBreakdown} />

                            <PanelOption onClick={() => { setPanel('sceneCards') }} title={'Scene Cards'} subtitle={'Organize Scenes'}
                                Icon={MdAmpStories} color={'#D41E79'} isTreatMentDisabled={scriptData.type === "TREATMENT"} background={'#FFF5FA'} isDisabled={!planFeatures.canUseSceneBreakdown} />


                            <PanelOption onClick={() => { setPanel('elements') }} title={'Categories & Elements'} subtitle={`${!sceneElements.allElements || sceneElements.allElements.length === 0 ? 'No' : sceneElements.allElements.length} Elements`}
                                Icon={MdLayers} color={'#CC8C14'} isTreatMentDisabled={scriptData.type === "TREATMENT"} background={'#FDF8EE'} isDisabled={!planFeatures.canUseCategoriesAndElements} />


                            <PanelOption onClick={() => { setPanel('revision') }} title={'Revisions'} subtitle={`${!revisions || revisions?.length === 0 ? 'No' : revisions.length} Revisions`}
                                Icon={FaHighlighter} color={'#D41E79'} background={'#FFF5FA'} isDisabled={!planFeatures.canApplyRevision} />


                            <PanelOption onClick={() => { setPanel('note') }} title={'Notes'} subtitle={notes.length === 0 ? 'No Notes' : notes.length + ' Notes'}
                                Icon={BsChatDotsFill} color={'#48A966'} background={'#F2FFF6'} isDisabled={!planFeatures.canApplyRevision} />


                            <div
                                onClick={() => {
                                    if (!havePermissionToEdit) return;
                                    if (!planFeatures.canUseAlexAI || planFeatures?.alexAIHitsLimit <= subscription?.alexAIUsage)
                                        setIsAlexAiAddOnModalOpen(true);
                                    else
                                        setPanel('alex')
                                }}
                                className='w-full cursor-pointer border transition-all hover:shadow-lg p-3 py-2 h-fit rounded-lg flex justify-between items-center'
                                isDisabled={!havePermissionToEdit}
                            >
                                <div className='flex gap-4'>
                                    <div className={`rounded-full flex items-center justify-center size-8`}>
                                        <img src={!planFeatures.canUseAlexAI || !havePermissionToEdit || planFeatures.alexAIHitsLimit <= subscription?.alexAIUsage ? AiIconDisabled : AiIcon} />
                                    </div>
                                    <div>
                                        <p className='text-[13px] font-medium' style={{ color: !planFeatures.canUseAlexAI || !havePermissionToEdit || planFeatures.alexAIHitsLimit <= subscription?.alexAIUsage ? '#868686' : '#dba612' }}>{'Alex AI'}</p>
                                        <p className='text-[11px] font-medium' style={{ color: !planFeatures.canUseAlexAI || !havePermissionToEdit || planFeatures.alexAIHitsLimit <= subscription?.alexAIUsage ? '#AAAAAA' : '#757575' }}>{'Your AI Co-Pilot'}</p>
                                    </div>
                                </div>
                                <MdChevronRight size={24} color={'#CACACA'} />
                            </div>
                        </div>
                    }
                    {panel === 'invite' && (
                        <InvitePanel
                            users={users}
                            setUsers={setUsers}
                            setPanel={setPanel}
                            isScriptAdmin={scriptData?.userId === user?._id}
                            isPopUpOpen={isPopUpOpen}
                            setIsPopUpOpen={setIsPopUpOpen}
                            updateCollaborators={updateCollaborators}
                            setUpdateCollaborators={setUpdateCollaborators}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    )}
                    {panel === 'scene' && <ScenePanel setUpdateScenes={setUpdateScenes} setPanel={setPanel} editor={editor} permissionToEdit={havePermissionToEdit} />}
                    {panel === 'character' && <CharacterPanel setPanel={setPanel} editor={editor} setUpdateCharacters={setUpdateCharacters} permissionToEdit={havePermissionToEdit} />}
                    {panel === 'revision' && <RevisionsPanel setRevisions={setRevisions} setPanel={setPanel} scriptData={scriptData} />}
                    {panel === 'elements' && <ElementsPanel editor={editor} setPanel={setPanel} sceneElements={sceneElements} setUpdateElements={setUpdateElements} permissionToEdit={havePermissionToEdit} />}
                    {panel === 'sceneCards' && <SceneCardPanel editor={editor} setPanel={setPanel} sceneCards={sceneCards} permissionToEdit={havePermissionToEdit} />}
                    {panel === 'note' && <NotesPanel setPanel={setPanel} getUsersPermission={getUsersPermission} notes={notes} />}
                    {panel === "alex" && <AlexAIPanel setPanel={setPanel} editor={editor} />}
                </>}

            <SubscriptionModal isSubscriptionModalOpen={isSubscriptionModalOpen} setIsSubscriptionModalOpen={setIsSubscriptionModalOpen} />
            <AlexAiAddOnModal isAlexAiAddOnModalOpen={isAlexAiAddOnModalOpen} setIsAlexAiAddOnModalOpen={setIsAlexAiAddOnModalOpen} />
        </div>

    )
}

export default SidePanel