import { PAGE_HEIGHT } from "./pageUtils";

export const timeColors = {
    '': '',
    'DAY': '#4AEF84',
    'NIGHT': '#800080',
    'MORNING': '#6EE15B',
    'AFTERNOON': '#078C33',
    'SUNRISE': '#ffa500',
    'SUNSET': '#ff0000',
    'DUSK': '#ffc0cb',
    'LATER': '#808080',
    'EVENING': '#2082e3'
}
export const getTitle = (input) => {
    const result = { intro: '', title: '', time: '' };
    
    if (!input) return result;
  
    // Find the first period and last hyphen
    const firstPeriodIndex = input.indexOf('.');
    const lastHyphenIndex = input.lastIndexOf('-');
  
    // Extract intro (everything before first period)
    result.intro = firstPeriodIndex !== -1 ? input.slice(0, firstPeriodIndex).trim().toUpperCase() : '';
  
    // Extract time (everything after last hyphen)
    result.time = lastHyphenIndex !== -1 ? input.slice(lastHyphenIndex + 1).trim().toUpperCase() : '';
  
    // Extract title (everything between first period and last hyphen, or whole string if neither exists)
    let titleStart = firstPeriodIndex !== -1 ? firstPeriodIndex + 1 : 0;
    let titleEnd = lastHyphenIndex !== -1 ? lastHyphenIndex : input.length;
    result.title = input.slice(titleStart, titleEnd).trim().toUpperCase();
  
    return result;
};

function toEighths(number) {
    // Get whole number part
    const wholeNumber = Math.floor(number);
    
    // Get decimal part and convert to eighths
    const decimal = number - wholeNumber;
    const eighths = Math.round(decimal * 8);
    
    // Handle special cases
    if (eighths === 0) {
        return wholeNumber.toString();
    }
    if (eighths === 8) {
        return (wholeNumber + 1).toString();
    }
    
    // Return mixed number if whole number exists, otherwise just fraction
    return wholeNumber ? `${wholeNumber}   ${eighths}/8` : `${eighths}/8`;
}

const getNodeHeight = (node) => {
    const styles = window.getComputedStyle(node);
    
    const marginTop = parseFloat(styles.marginTop);
    const marginBottom = parseFloat(styles.marginBottom);
    const height = parseFloat(styles.height);
    
    const totalHeight = height + marginTop + marginBottom;
    // console.log('scenegetNodeHeight',node.classList[0],totalHeight)

    return totalHeight;
  }

export const getScenes = (editor) => {
    if (editor) {

        const body = editor.getBody()

        const allScenes = [];
        const selectedNode = editor.selection?.getNode();
        let sceneIndex = 0
        let lastSceneIndex = -1;
        var sceneHeight = 0

        body.childNodes.forEach((node,index)=>{
            if(node.classList[0] !== "custom-pagebreak"){
                sceneHeight += getNodeHeight(node)
            }

            if(sceneIndex>0 && index === body.childNodes.length-1){
                allScenes[sceneIndex-1].sceneHeight = toEighths(sceneHeight/PAGE_HEIGHT)
            }

            
           
            
            if ( node.classList.contains('sceneHeadings')) {
                allScenes.push({
                    title: node.textContent,
                    color: node.getAttribute('data-color'),
                    summary: node.getAttribute('data-summary') || '',
                    sceneHeight: '1/8'
                })
                if(sceneIndex > 0){
                    allScenes[sceneIndex-1].sceneHeight = toEighths(sceneHeight/PAGE_HEIGHT)
                }
                sceneHeight = 0
                sceneIndex ++
            }
            if (node.isSameNode(selectedNode)) {
                lastSceneIndex = allScenes.length - 1;
            }
        })

        if (lastSceneIndex >= 0) {
            return { index: lastSceneIndex + 1, allScenes };
        } else {
            return { index: null, allScenes }
        }
    }
}

export const goToScene = (index, editor) => {
    if (editor) {

        const sceneHeadings = editor.dom.select('.sceneHeadings');
        if (index >= 0 && index < sceneHeadings.length) {
            const targetNode = sceneHeadings[index];

            // Scroll the node into view
            targetNode.scrollIntoView({ behavior: 'smooth' });

            // Set the cursor to the start of the node
            const newRange = editor.dom.createRng();
            newRange.setStart(targetNode, 0);
            newRange.setEnd(targetNode, 0);
            editor.selection.setRng(newRange);

            // Focus the editor
            editor.focus();
        }
    }
}

export const setScreenIndexes = (editor) => {

    if (!editor) {
        return
    }
        let count = 0;
        const body = editor.getBody();

        const traverseNodes = (node) => {
            if (node.nodeType === 1 && node.classList.contains('sceneHeadings')) {
                count++;
                editor.dom.setAttrib(node, 'data-scene-index', count.toString());
                
            }
            Array.from(node.childNodes).forEach(childNode => traverseNodes(childNode));
        };

        traverseNodes(body);

    // Force a content update
    editor.fire('Change');
}

export const setIntro = (editor,intro,node) =>{
    const sceneName = node.textContent
    const titleSplit = getTitle(sceneName)
    const {title,time} = titleSplit
    const newSceneName = `${intro ? intro + '. ' : ''}${title}${time ? ' - ' + time : ''}`
    editor.dom.setHTML(node,newSceneName)
}

export const setTime = (editor,time,node) =>{
    const sceneName = node.textContent
    const titleSplit = getTitle(sceneName)
    const {intro, title} = titleSplit
    const newSceneName = `${intro ? intro + '. ' : ''}${title}${time ? ' - ' + time : ''}`
    editor.dom.setHTML(node,newSceneName)
}

export const setScene = (editor, sceneObject, index) =>{
    if(!editor) return
    const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]')
    if (selectedNodes.length > 0) {
        const selectedNode = selectedNodes[0]
        editor.dom.setHTML(selectedNode,sceneObject.title)
        selectedNode.setAttribute('data-color', sceneObject.color || '')
        selectedNode.setAttribute('data-summary', sceneObject.summary)
    }
}
