import { setNotes } from "../../../store/scriptSlice";

export const getAllNotes = (editorRef,dispatch) => {
    const body = editorRef.getBody();
    let sceneCount = 0
    const noteList = []

    body.childNodes.forEach((node) => {
        if (node.classList[0] === 'sceneHeadings') {
            sceneCount++;
        }
        if (node.getAttribute('data-note-list')) {
            const tempNoteList = JSON.parse(node.getAttribute('data-note-list') || "null") || []
            noteList.push(...tempNoteList.map(note => {
                return {...note,scene:sceneCount}
            }));
        }
    })

    dispatch(setNotes(noteList));
    return noteList;
  };