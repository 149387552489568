// components/LanguageSelector.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSortedLanguages, languageCountriesCodes, LANGUAGES } from '../constants/languages';
import { setSelectedLanguage } from '../store/navSlice';
import Dropdown from '../components/Common/DropDown';
import ReactCountryFlag from 'react-country-flag';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { countries } from '../constants/countries';

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  
  const selectedLanguage = useSelector((state) => state.nav.selectedLanguage);
  const [value, setValue] = useState('');
  const handleLanguageChange = (languageCode) => {
    const selectElement = document.querySelector('.goog-te-combo');
    
    if (selectElement) {
      // Update Google Translate
      console.log('languagecode',languageCode || 'en')
      selectElement.value = languageCode || 'en';
      selectElement.dispatchEvent(new Event('change'));
      selectElement.dispatchEvent(new Event('goog-te-changed'));
      
      // Update Redux and localStorage
      localStorage.setItem('preferred_language', languageCode || 'en');
      dispatch(setSelectedLanguage(languageCode));
    }
  };

  useEffect(()=>{
    setValue(languageCountriesCodes[selectedLanguage])
  },[selectedLanguage])

  return (<div className='flex justify-center  mt-8'>
   <Dropdown
      alignment="left"
      close={value}
      trigger={
        <button className="border h-[48px] px-3 w-[200px]  rounded-lg border-[#CACACA] flex gap-2 items-center justify-between">
          <div className='flex items-center gap-2'>
          {LANGUAGES[selectedLanguage]}
          </div>
          
          <MdKeyboardArrowDown  color="#868686" size={18}/>
        </button>
      }
    >
      <div className="flex w-[200px]  flex-col gap-2 max-h-[300px] overflow-y-auto">
        <div className="p-1 ">
          <input
            className="p-2 w-full border-[#CACACA] border-2 rounded-md"
            placeholder="search language"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </div>
        <div className="flex flex-col gap-2 overflow-y-auto">
        {getSortedLanguages()
          .slice()
          .filter(([code, name]) =>
            name.toLowerCase().includes(search.toLowerCase())
          || search.toUpperCase() === code
          )
          .map(([code, name]) => (
            <div key={code} >
              <button
                
                onClick={() => {
                    handleLanguageChange(code)
                }}
                className="flex gap-3 items-center w-full p-2"
              >
                <label className="truncate max-w-[150px] cursor-pointer">
                    {name}
                    </label>
              </button>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </Dropdown>
  </div>
   
  );
};

export default LanguageSelector;