import React, { useRef } from 'react'
import Dropdown from '../../Common/DropDown'
import { useState } from 'react'
import { Divider } from 'antd'
import { useNavigate } from 'react-router-dom'
import DeleteMenuPopUp from '../ScriptInfo/DeleteMenu'
import { handleFileInput, saveContent, updateStatus } from './utils/scriptUtils'
import { toast } from 'react-toastify'
import { autoFormatScript} from './utils/formatUtils'
import { useDispatch, useSelector } from 'react-redux'
import SaveAsModal from '../modals/SaveAsModal'
import html2pdf from 'html2pdf.js'
import { PADDING } from './utils/pageUtils'
import { exportContentStyle } from './utils/exportUtils'
import CharactersModal from './CharactersModal'
import { setIsTranslateOpen } from '../../../store/navSlice'

const DesktopMenuBar = ({ setShowScriptLoading, scriptData, editor,documentType }) => {
  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false)
  const scenes = useSelector(state=>state.script.scenes.allScenes)
  const [isSaveAsModalOpen, setIsSaveAsModalOpen] = useState(false)
  const [isCharacterModalOpen,setIsCharacterModalOpen] = useState(false)
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const pdfInputRef = useRef(null)
  const fdxInputRef = useRef(null)
  const [isLoading, setisLoading] = useState(null)
  const user = JSON.parse(localStorage.getItem('user'))

  const handleDeleteClcik = () => {
    setIsDeleteMenuOpen(true)
  }

  const exportToPdf = async () => {

    if(scriptData.userId !== user._id){
      toast.error("Only Admin is allowed to download the script")
      return
    }

    if (editor) {
      setShowScriptLoading(true)
      const body =  editor.getBody()

      const wrapper = document.createElement('pdfupload');

    

      wrapper.className = 'pdf-container'
      wrapper.style.display = 'block'
      wrapper.style.paddingTop = `${PADDING}px`
      wrapper.style.paddingBottom = `${20}px`
      wrapper.style.width = `${794.993}px`

      

      body.childNodes.forEach(node =>{
        const nodeClass = node.classList[0]
        const cloneNode = node.cloneNode(true)
        if(nodeClass === "custom-pagebreak"){
          cloneNode.style.height = `5px`
        }
        wrapper.appendChild(cloneNode)
      })

      const styles = document.createElement('style');
      styles.innerHTML = exportContentStyle;
      wrapper.prepend(styles);

      html2pdf()
      .set({
          filename: 'script.pdf',
          format: 'letter',
          autoPaging: true,
          pagebreak: { 
              mode: ['avoid-all', 'css', 'legacy'],
              before: '.page-break-before',
              after: '.page-break-after',
              avoid: '.page-break-avoid'
          },
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      })
      .from(wrapper)
      .save().then(()=>{setShowScriptLoading(false)});
    }
  };

  const handleInput = async (e) => {
    setisLoading(true)
    setShowScriptLoading(true)
    const body = await handleFileInput(e)
    setisLoading(false)
    setShowScriptLoading(false)
    pdfInputRef.current.value = null
    fdxInputRef.current.value = null
    if(!body){
      return
    }
    
    const formattedContent = autoFormatScript(body.text,documentType)
    
    if(editor){
      editor.setContent(formattedContent.toString())
    }
    else{
      toast.error('editor not loaded')
    }
    
    
}

  const handleSaveClick = () =>{
    setShowScriptLoading(true)
    const onComplete = ()=>{
      toast.success('Saved')
      setShowScriptLoading(false)
    }
    if(editor){
      saveContent(editor,scriptData,onComplete,scenes?.length)
    }
  }

  const handleMarkScript = async(status)=>{
    setShowScriptLoading(true)
    const onComplete = ()=>{
      return toast.success('Saved')
     }
     await updateStatus(scriptData,status,onComplete)
     setShowScriptLoading(false)  
  }

  return (
    <>
      {isSaveAsModalOpen && <SaveAsModal scripData={scriptData} isSaveAsModalOpen={isSaveAsModalOpen} setIsSaveAsModalOpen={setIsSaveAsModalOpen}/>}
      {isDeleteMenuOpen && <DeleteMenuPopUp onDelete={() => { navigate(`/scripts`) }} isOpen={isDeleteMenuOpen} setIsOpen={setIsDeleteMenuOpen} id={scriptData._id} />}
      <input onChange={handleInput} ref={pdfInputRef} type="file" accept="application/pdf" className="hidden" />
      <input onChange={handleInput} ref={fdxInputRef} type="file" accept=".fdx" className="hidden" />
      <div className={`flex items-center gap-2 ${isLoading && 'cursor-progress'}`}>
        <Dropdown
          alignment='left'
          triggerAction='hover'
          showArrow={true}
          trigger={<button className='ms-1 text-[14px] font-bold text-[#868686] hover:text-[#292929]'>File</button>}>
          <div className='w-fit min-w-[150px] flex flex-col'>
              
            <button onClick={()=>{pdfInputRef.current.click()}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Open</button>
            <button onClick={()=>{pdfInputRef.current.click()}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Import Script</button>
            <button onClick={()=>{pdfInputRef.current.click()}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Upload PDF</button>
            <button onClick={()=>{fdxInputRef.current.click()}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Upload FDX</button>
            <button className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Auto Format</button>
            <button onClick={()=>{exportToPdf()}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686] text-start'>Export</button>
            <button onClick={handleSaveClick} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Save</button>
            <button onClick={()=>{setIsSaveAsModalOpen(true)}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Save a Copy</button>
            <button onClick={()=>{handleMarkScript('INCOMPLETE')}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Mark Script in Progress</button>
            <button onClick={()=>{handleMarkScript('INREVIEW')}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686] text-start'>Mark Script In Review</button>
            <button onClick={()=>{handleMarkScript('COMPLETE')}} className='p-2 text-[13px] hover:text-[#292929] text-[#868686] text-start'>Mark Script Complete</button>
            <button onClick={handleDeleteClcik} className='p-2 text-[13px] hover:text-[#292929] text-[#868686] text-start'>Delete</button>
          </div>
        </Dropdown>
        <Divider type='vertical' className='bg-gray-300 w-[1px] h-4' />

        <Dropdown
          alignment='left'
          triggerAction='hover'
          showArrow={true}
          trigger={<button className='ms-1 text-[14px] font-bold text-[#868686] hover:text-[#292929]'>Document</button>}>
          <div className='w-fit min-w-[150px] flex flex-col'>
            <button onClick={()=>setIsCharacterModalOpen(true)} className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Characters</button>
            <button className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Headings</button>
          </div>
        </Dropdown>
        <Divider type='vertical' className='bg-gray-300 w-[1px] h-4' />
        <Dropdown
          alignment='left'
          triggerAction='hover'

          showArrow={true}
          trigger={<button className='ms-1 text-[14px] font-bold text-[#868686] hover:text-[#292929]'>Help</button>}>
          <div className='w-fit min-w-[150px] flex flex-col'>
            <button className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>Get in touch</button>
            <button className='p-2 text-[13px] hover:text-[#292929] text-[#868686]  text-start'>FAQ</button>
          </div>
        </Dropdown>
        <Divider type='vertical' className='bg-gray-300 w-[1px] h-4' />
        <button onClick={()=>{
          dispatch(setIsTranslateOpen(true))
        }} className='ms-1 text-[14px] font-bold text-[#868686] hover:text-[#292929]'>Select Language</button>

        {/* <button onClick={()=>setAlexClose(true)} className='ms-1 text-[14px] font-bold text-[#868686] hover:text-[#292929]'>Alex AI</button> */}
        {/* <AlexAI open={isAlexOpen} onClose={()=>setAlexClose(false)} /> */}
        <CharactersModal open={isCharacterModalOpen} setOpen={setIsCharacterModalOpen}/>
      </div>
    </>
  )
}

export default DesktopMenuBar