import React, { useState } from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAccessToken } from '../../../services/authService';
import { MdClose } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { setConnectionErrorOpen } from '../../../store/navSlice';
import LoaderSpin from '../../Common/LoaderSpin';

const Treatment = ({ closeModal }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    title: '',
    byline: '',
    basedOn: '',
    copyright: '',
    author: '',
    email: '',
    phoneNumber: '',
  });

  const [isLoading, setisLoading] = useState(false)

  const createScript = async () => {
    setisLoading(true)
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/document`
    try {
      if (!token) {
        throw new Error('A valid session not found')
      }
      const newData = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== '')
    )
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ type: 'TREATMENT', content: '<p></p>', status: 'INCOMPLETE', ...newData })
      })
      const body = await res.json()
      if (!res.ok) {
        console.error(res)
        throw new Error(body.message ? body.message : 'Error getting response')
      }
      setisLoading(false)
      if(window.innerWidth<1024){
        navigate(`/scripts/${body.data._id}`)
    }
    else{
        navigate(`/editor/${body.data._id}`)
    }

    } catch (error) {
      if (error.message === 'Failed to fetch' | error.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      setisLoading(false)
      toast.error(error.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    createScript()
  };

  const isValidEmail = (email) => {
    return email === ''? true: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const isValidPhoneNumber = (phoneNumber) => {
    return phoneNumber === ''? true: /^\+?\d{10,15}$/.test(phoneNumber);
};

  // Check if all fields are filled, are not just spaces, and if email and phone number are valid
  const isFormValid =
  ["title", "copyright", "author"].every(key => formData[key]?.trim() !== '') &&
    isValidEmail(formData.email) &&
    isValidPhoneNumber(formData.phoneNumber);

  return (
    <div className='overflow-y-hidden = h-full'>
      <div className='p-4 '>
        <div className='flex justify-between items-center'>
          <div className='flex gap-2 items-center'>
            <Link to={'/scripts'} className='lg:hidden'> <MdArrowBackIosNew size={24} color='#292929' /> </Link>
            <h1 className='text-[20px] font-bold text-[#292929]'>New Treatment</h1>
          </div>
          
          {closeModal && <button onClick={closeModal}>
            <MdClose size={24} />
          </button>}
        </div>
        <h2 className='mt-8 font-bold text-[#292929] text-base'>Title Page</h2>
      </div>
      <hr />
      <div className=' overflow-y-auto pb-[8rem]' style={{ height: '100%' }}>
        <div className='p-4'>
          <label className='text-14 text-[#868686]'>Title <label className='text-red-500'>*</label></label>
          <input
            name='title'
            value={formData.title}
            onChange={handleChange}
            className='border outline-none mb-4 mt-2 border-[#CACACA] rounded-lg w-full p-3 text-14 text-[#404040]'
            placeholder='Enter treatment title'
          />

          <label className='text-14 text-[#868686]'>By Line </label>
          <input
            name='byline'
            value={formData.byline}
            onChange={handleChange}
            className='border outline-none mb-4 mt-2 border-[#CACACA] rounded-lg w-full p-3 text-14 text-[#404040]'
            placeholder='Enter film byline'
          />

          <label className='text-14 text-[#868686]'>Based On </label>
          <input
            name='basedOn'
            value={formData.basedOn}
            onChange={handleChange}
            className='border outline-none mb-4 mt-2 border-[#CACACA] rounded-lg w-full p-3 text-14 text-[#404040]'
            placeholder='What the script is based on...'
          />

          <label className='text-14 text-[#868686]'>Copyright <label className='text-red-500'>*</label></label>
          <input
            name='copyright'
            value={formData.copyright}
            onChange={handleChange}
            className='border outline-none mt-2 border-[#CACACA] rounded-lg w-full p-3 text-14 text-[#404040]'
            placeholder='Enter copyright'
          />
        </div>
        <div className='py-3 font-bold px-4 bg-[#f6f6f6] text-[#757575] text-base'>
          Contact Information
        </div>
        <div className='p-4'>
          <label className='text-14 text-[#868686]'>Author <label className='text-red-500'>*</label></label>
          <input
            name='author'
            value={formData.author}
            onChange={handleChange}
            className='border outline-none mb-4 mt-2 border-[#CACACA] rounded-lg w-full p-3 text-14 text-[#404040]'
            placeholder='Enter author name'
          />

          <label className='text-14 text-[#868686]'>Email </label>
          <input
            name='email'
            value={formData.email}
            onChange={handleChange}
            className={`border outline-none mb-4 mt-2 border-[#CACACA] rounded-lg w-full p-3 text-14 ${!isValidEmail(formData.email) && formData.email && 'border-red-500'
              }`}
            placeholder='Enter author email'
          />
          {!isValidEmail(formData.email) && formData.email && (
            <p className='text-red-500 text-sm'>Invalid email format</p>
          )}

          <label className='text-14 text-[#868686]'>Phone number </label>
          <input
            type='phone'
            name='phoneNumber'
            value={formData.phoneNumber}
            onChange={(e)=>{
              if(/^\+?[0-9 ]*$/.test(e.target.value))
                  handleChange(e)}
          }
            className={`border outline-none mt-2 border-[#CACACA] rounded-lg w-full p-3 text-14 ${!isValidPhoneNumber(formData.phoneNumber) && formData.phoneNumber && 'border-red-500'
              }`}
            placeholder='Enter author phone number'
          />
          {!isValidPhoneNumber(formData.phoneNumber) && formData.phoneNumber && (
            <p className='text-red-500 text-sm'>Invalid phone number format</p>
          )}

          <button
            className={`rounded-full w-full p-4 mt-8 mb-4 font-bold text-[#868686] 
                        ${!isFormValid || isLoading ? 'bg-[#F6F6F6]' : 'bg-[#0099FF] text-white'} `}
            onClick={handleSave}
            disabled={!isFormValid || isLoading}
          >
            {isLoading ? <LoaderSpin/> :'Create script'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Treatment;
