import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineSearch, MdOutlineTune, } from "react-icons/md";
import AllScripts from './AllScripts';
import SharedScripts from './SharedScript';
import MobileSearch from './MobileSearch';
import CreateScriptModal from '../Modals/CreateScriptModals';
import ScriptInfoModals from '../Modals/CreateScriptModals/ScriptInfoModals';
import SearchBar from './SearchBar';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteMenuPopUp from './ScriptInfo/DeleteMenu';

const Scripts = () => {
  const location = useLocation();
  const { sharedTabOpen } = location.state || {sharedTabOpen: false}
  
  const [isMyScriptsTabActive, setIsMyScriptsTabActive] = useState(!sharedTabOpen)
  const [showSearch, setShowSearch] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const [mobileSearchVal, setMobileSearchVal] = useState('')
  const [showCreateScriptModal, setShowCreateScriptModal] = useState(false)
  const [showCreateModalType, setShowCreateModalType] = useState('')
  const [showFilterModal, setShowFilterModal] = useState(false)
  const itemsPerPage = 12
  const scrollRef = useRef(null)
  const inviteUrl = localStorage.getItem('invite_url')
  const navigate = useNavigate()

  useEffect(()=>{
    if(inviteUrl) {
      navigate(inviteUrl)
      localStorage.removeItem('invite_url')
    }
  })

  return (
    <>
      <div className='h-full lg:max-w-[90vw] mx-auto flex flex-col overflow-hidden'>
        {!showSearch ? <>
          <div className='p-4 lg:mt-4 max-lg:border-b-[1.2px] max-lg:drop-shadow-sm bg-white'>
            <div>
              <div className='flex items-center justify-between'>
                <div className='lg:flex gap-4 items-center'>
                  {<h1 className='text-[20px] font-bold text-[#292929]'>Scripts</h1>}
                  <div className='max-lg:hidden flex p-1 gap-1 rounded-[9px] bg-[#EFEFEF]'>
                    <button className={` w-28 text-[13px] font-bold p-2 text-[#292929] text-center rounded-[9px] ${isMyScriptsTabActive && 'bg-white shadow-md'}`}
                      onClick={() => { setIsMyScriptsTabActive(true) }}>
                      My Scripts
                    </button>
                    <button className={` w-28 text-[13px] font-bold p-2 text-[#292929] text-center rounded-[9px] ${!isMyScriptsTabActive && 'bg-white shadow-md'} `}
                      onClick={() => { setIsMyScriptsTabActive(false) }}>
                      Shared
                    </button>
                  </div>
                </div>


                <div className=' flex gap-4 items-center'>
                  <button onClick={() => { setShowCreateScriptModal(true) }} className=' max-lg:hidden w-fit rounded-full text-white font-bold bg-[#0099FF] text-4 px-4 py-3 text-center text-nowrap'>
                    Create script
                  </button>
                  <div className='max-lg:hidden w-[400px]'>
                    <SearchBar setSearchVal={setSearchVal} />
                  </div>
                  
                  <MdOutlineSearch color='#AAAAAA' className='size-7 lg:hidden ' onClick={() => setShowSearch(true)} />

                  <MdOutlineTune onClick={()=>setShowFilterModal(true)} color='#AAAAAA' className='size-7 cursor-pointer' />
                </div>

              </div>
              <div className='lg:hidden mt-5 flex p-1 gap-1 rounded-[9px] bg-[#EFEFEF]'>
                <button className={` grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${isMyScriptsTabActive && 'bg-white shadow-md'}`}
                  onClick={() => { setIsMyScriptsTabActive(true) }}>
                  My Scripts
                </button>
                <button className={`grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${!isMyScriptsTabActive && 'bg-white shadow-md'} `}
                  onClick={() => { setIsMyScriptsTabActive(false) }}>
                  Shared
                </button>
              </div>
            </div>
          </div>
          <div ref={scrollRef} className='max-lg:bg-[#F6F6F6] p-4 pb-0 grow overflow-y-auto'>

            {isMyScriptsTabActive
              ? <AllScripts itemsPerPage={itemsPerPage} searchVal={searchVal} setShowCreateScriptModal={setShowCreateScriptModal} setShowFilterModal={setShowFilterModal} showFilterModal={showFilterModal} />
              : <SharedScripts itemsPerPage={itemsPerPage} searchVal={searchVal}  setShowFilterModal={setShowFilterModal} showFilterModal={showFilterModal} />}
          </div>
        </> :
          <MobileSearch searchVal={mobileSearchVal} itemsPerPage={itemsPerPage}
            setSearchVal={setMobileSearchVal} setShowSearch={setShowSearch}
            isMyScriptsTabActive={isMyScriptsTabActive} />
        }
      </div>
      {showCreateScriptModal && <CreateScriptModal setShowModal={setShowCreateScriptModal} setType={setShowCreateModalType} />}
      <ScriptInfoModals type={showCreateModalType} setType={setShowCreateModalType} />
    </>


  )
}

export default Scripts