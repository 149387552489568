import React, { useEffect, useState } from 'react'
import { MdArrowBackIosNew, MdOutlineSearch, MdClose } from "react-icons/md";
import searchVector from '../../assets/backgrounds/shared_scripts_empty.webp'
import ScriptCard from './ScriptCard';
import ScrollPagination from './ScrollPagination';
import { getAccessToken } from '../../services/authService';
import SearchBar from './SearchBar';
import { setConnectionErrorOpen } from '../../store/navSlice';
import { useDispatch } from 'react-redux';

const MobileSearch = ({ setShowSearch, itemsPerPage, setSearchVal, searchVal, isMyScriptsTabActive }) => {

  const [loading, setLoading] = useState(1)
  const [scriptlist, setScriptList] = useState([])
  const [totalScripts, setTotalScripts] = useState(0)
  const [lastId, setLastId] = useState(null)
  const [fetchScript, setFetch] = useState(false)
  const dispatch = useDispatch()
  const fetchScriptList = async (id) => {
    setLoading(1)
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/${isMyScriptsTabActive ? 'document' : '/shared-document'}?sort=recently_updated&limit=${itemsPerPage}${searchVal !== '' ? '&search=' + searchVal : ''}${id ? '&lastId=' + id : ''}`
    try {
      if (!token) {
        throw new Error('A valid session not found')
      }
      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      if (!res.ok) {
        throw new Error('Error getting response')
      }
      const body = await res.json()
      const list = body.data
      setTotalScripts(body.totalDocuments);
      if (lastId)
        setScriptList(prev => [...prev, ...list])
      else setScriptList(list)
      setLoading(0)
      setLastId(list[list.length - 1]?._id)
    } catch (error) {
      if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      setLoading(2)
      console.error(error)
    }
  }

  useEffect(() => {
    if (lastId)
      fetchScriptList(lastId)
  }, [fetchScript])

  useEffect(() => {
    setLastId(null)
    setScriptList([])
    fetchScriptList(null)
  }, [searchVal])

  return (
    <>
      <div className='p-4 pb-8 max-lg:border-b-[1.2px] max-lg:drop-shadow-sm bg-white'>
        <div className='flex items-center justify-between gap-2'>
          <button onClick={() => { setShowSearch(false) }}>
            <MdArrowBackIosNew size={24} color='#292929' />
          </button>
          <SearchBar setSearchVal={setSearchVal}/>
        </div>
      </div>
      <div className='max-lg:bg-[#F6F6F6] lg:flex-wrap  lg:flex-row p-4 max-lg:grow overflow-y-auto flex flex-col gap-4'>
        {scriptlist.map((script) =>
          <ScriptCard script={script} />)}
        <ScrollPagination loading={loading} lastId={lastId} setFetch={setFetch} totalItems={totalScripts} length={scriptlist.length} />
        {scriptlist.length === 0 && loading === 0 &&
          <div className='mt-50 mx-auto flex flex-col p-4 gap-4 items-center'>
            <img src={searchVector} className='w-[177px] h-[143px] object-cover' />
            <h2 className='text-[#404040] text-[20px] font-bold text-center'>No script matched your search</h2>
            <h3 className='font-medium text-[15px] text-[#868686] text-center'>Check your filters or try searching for a different script</h3>
          </div>}
      </div>


    </>
  )
}

export default MobileSearch