import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDelete, MdDragIndicator } from 'react-icons/md';
import EmptyPage from '../Editor/sidepanel/EmptyPage';
import { getAccessToken } from '../../../services/authService';
import { toast } from 'react-toastify';
import { parse, serialize } from 'parse5';
import LoaderSpin from '../../Common/LoaderSpin';
import { bodyToString } from '../Editor/utils/scriptUtils';

const SceneCardsTab = ({ allSceneCards, pNodes, body, scriptData, searchVal, setUpdateData, addClickUpdate, setAddClickUpdate }) => {
    const [sceneCardsList, setSceneCardsList] = useState(allSceneCards);
    const [isLoading, setIsLoading] = useState(false)
    const [allLoading, setAllLoading] = useState(false)
    const [headingLoading, setHeadingLoading] = useState(false)
    const [loadingArray, setLoadingArray] = useState([])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(sceneCardsList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setSceneCardsList(items);
    }

    const handletitleChange = (text, index) => {
        setSceneCardsList(prevSceneList => {
            const newList = [...prevSceneList]
            newList[index].title = text
            return newList
        })
    }
    const handleActionChange = (text, index) => {
        setSceneCardsList(prevSceneList => {
            const newList = [...prevSceneList]
            newList[index].action = text
            return newList
        })
    }

    const addSceneCard = () => {
        setSceneCardsList(prevList => {
            const newList = [...prevList]
            newList.push({
                title: '',
                action: '',
                color: null
            })

            return newList
        })
    }

    const deleteCard = (index) => {
        setSceneCardsList(prevList => {
            const newList = [...prevList]
            newList.splice(index, 1)
            return newList
        })
    }

    const getPNodeArrtibute = (node, attribute) => {
        return node.attrs.find(attr => attr.name === attribute)?.value
    }

    const createPNode = (tag, text, attributes) => {
        const nodeObj = {
            nodeName: tag,
            tagName: tag,
            namespaceURI: "http://www.w3.org/1999/xhtml",
            attrs: Object.entries(attributes).map(([name, value]) => ({
                name,
                value
            })),
            childNodes: [
                {
                    nodeName: "#text",
                    value: text,
                }
            ],
            parentNode: null  // This will be set automatically when added to parent
        };
        return nodeObj
    }

    const migrateHeadings = async () => {
        setHeadingLoading(true)
        sceneCardsList.forEach((sceneCard, index) => {
            const selectedNode = body.querySelector('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]')
            if (selectedNode) {
                selectedNode.textContent = sceneCard.title
            }
            else {
                var sceneNode = document.createElement('p');
                sceneNode.classList.add('sceneHeadings');
                sceneNode.textContent = sceneCard.title;
                sceneNode.setAttribute('data-scene-index', index + 1);

                // Get the last child of the body element
                const lastNode = body.lastChild;

                if (lastNode && lastNode.nodeType === 1 && lastNode.classList.length !== 0) {
                    // Insert the sceneNode after the last node
                    lastNode.insertAdjacentElement('afterend', sceneNode);
                } else {
                    // If no valid lastNode, simply append the sceneNode
                    body.appendChild(sceneNode);
                }
            }
        })

        const content = bodyToString(body)
        await updateContent(content)
        setHeadingLoading(false)
    }

    const migrateAll = async () => {
        setAllLoading(true)
        sceneCardsList.forEach((scene, index) => {
            let lastIndex = 0
            const updateIndex = pNodes.findIndex(node => getPNodeArrtibute(node, "class") === 'sceneHeadings' && getPNodeArrtibute(node, 'data-scene-index') === `${index + 1}`)
            if (updateIndex !== -1) {
                pNodes[updateIndex].childNodes[0].value = scene.title
                lastIndex = updateIndex
                let currentIndex = updateIndex + 1

                // remove previous action nodes of this scene
                while (pNodes[currentIndex] && getPNodeArrtibute(pNodes[currentIndex], 'class') !== "sceneHeadings") {
                    if (getPNodeArrtibute(pNodes[currentIndex], 'class') === "action") {
                        pNodes.splice(currentIndex, 1);
                    }
                    else {
                        currentIndex++
                    }
                }
            }
            else {
                const nodeObj = createPNode("p", scene.title,
                    { 'class': "sceneHeadings", 'data-scene-index': `${index + 1}` })
                pNodes.push(nodeObj);
                lastIndex = pNodes.length - 1
            }
            const actionsList = scene.action.split('\n')

            if (actionsList) {
                actionsList.forEach(action => {
                    if (action !== "") {
                        const newActionNode = createPNode("p", action, { 'class': "action" })
                        pNodes.splice(lastIndex + 1, 0, newActionNode);
                        lastIndex++
                    }

                })
            }
            else if (scene.action !== "") {
                const newActionNode = createPNode("p", scene.action, { 'class': "action" })
                pNodes.splice(lastIndex + 1, 0, newActionNode);
            }
        })

        const content = serialize({ childNodes: pNodes })
        await updateContent(content)
        setAllLoading(false)
    }

    const migrateCard = async (index, scene) => {
        setLoadingArray(prev => {
            const newLoadingArray = [...prev]
            newLoadingArray[index] = true
            return newLoadingArray
        })
        let lastIndex = 0
        const updateIndex = pNodes.findIndex(node => getPNodeArrtibute(node, "class") === 'sceneHeadings' && getPNodeArrtibute(node, 'data-scene-index') === `${index + 1}`)
        if (updateIndex !== -1) {
            pNodes[updateIndex].childNodes[0].value = scene.title
            lastIndex = updateIndex
            let currentIndex = updateIndex + 1

            // remove previous action nodes of this scene
            while (pNodes[currentIndex] && getPNodeArrtibute(pNodes[currentIndex], 'class') !== "sceneHeadings") {
                if (getPNodeArrtibute(pNodes[currentIndex], 'class') === "action") {
                    pNodes.splice(currentIndex, 1);
                }
                else {
                    currentIndex++
                }
            }
        }
        else {
            const nodeObj = createPNode("p", scene.title,
                { 'class': "sceneHeadings", 'data-scene-index': `${index + 1}` })
            pNodes.push(nodeObj);
            lastIndex = pNodes.length - 1
        }
        const actionsList = scene.action.split('\n')

        if (actionsList) {
            actionsList.forEach(action => {
                if (action !== "") {
                    const newActionNode = createPNode("p", action, { 'class': "action" })
                    pNodes.splice(lastIndex + 1, 0, newActionNode);
                    lastIndex++
                }

            })
        }
        else if (scene.action !== "") {
            const newActionNode = createPNode("p", scene.action, { 'class': "action" })
            pNodes.splice(lastIndex + 1, 0, newActionNode);
        }


        const content = serialize({ childNodes: pNodes })
        await updateContent(content)

        setLoadingArray(prev => {
            const newLoadingArray = [...prev]
            newLoadingArray[index] = false
            return newLoadingArray
        })
    }

    const updateContent = async (content) => {
        setIsLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${scriptData?._id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'PUT',
                body: JSON.stringify({ content })

            })
            if (!res.ok) {
                console.error('res', res, res.message)
                throw new Error('Error getting response')
            }
            const body = await res.json()
            toast.success('Scene Updated')
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            toast.error('Scene Update failed')
        }
    }

    useEffect(() => {
        if (addClickUpdate) {
            addSceneCard()
            setAddClickUpdate(prev => !prev)
        }
    }, [addClickUpdate])

    return (
        <div className='w-full h-full'>
            <div >
                {false ? (
                    <EmptyPage
                        title={'You don\'t have any elements or scenes'}
                        subtitle={'Start creating scenes and add elements to see here'}
                    />
                ) : (
                    <div className="bg-[#F3F4F6] overflow-y-auto h-full ">

                        {sceneCardsList.length > 0 ? <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="list">
                                {(provided) => (
                                    <ul {...provided.droppableProps} ref={provided.innerRef} className="">
                                        {sceneCardsList.map((sceneCard, index) => (
                                            <Draggable
                                                key={`scene-${index}`}
                                                draggableId={`scene-${index}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}

                                                        className={` ${index !== sceneCardsList.length - 1 && "mb-2"} ${searchVal && !sceneCard.title.toUpperCase().includes(searchVal.toUpperCase()) && "hidden"} flex justify-between items-center transition-colors duration-200 ${snapshot.isDragging ? 'bg-gray-100' : ''
                                                            }`}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        <div className='bg-white shadow-sm relative w-full p-2 group'>

                                                            <div style={{ borderColor: sceneCard.color }} className='items-center flex text-[12px] gap-2 justify-between  w-full border-s-4'>
                                                                <p className='ms-1 font-bold'>{index + 1}.</p>
                                                                <input

                                                                    onChange={(e) => { handletitleChange(e.target.value, index) }}
                                                                    value={`${sceneCard.title}`}
                                                                    className='w-full rounded p-2 -ms-1' />

                                                                {searchVal === '' && <button  {...provided.dragHandleProps}>
                                                                    <MdDragIndicator size={14} />
                                                                </button>}
                                                            </div>
                                                            <div className='mt-2'>
                                                                <textarea

                                                                    onChange={(e) => {
                                                                        handleActionChange(e.target.value, index)
                                                                        e.target.style.height = 'auto';
                                                                        e.target.style.height = `${e.target.scrollHeight}px`;

                                                                    }}

                                                                    value={sceneCard.action}
                                                                    className='border w-full min-h-[150px] overflow-hidden resize-none rounded-lg p-2 text-[12px]' />
                                                            </div>

                                                            <div className='mb-2 mt-3 flex justify-center gap-4 px-4'>
                                                                <button
                                                                    disabled={loadingArray[index] || isLoading}
                                                                    onClick={() => { deleteCard(index) }}
                                                                    className='rounded text-[#868686] text-[14px] p-2 disabled:saturate-0'>
                                                                    Delete
                                                                </button>
                                                                <button
                                                                    disabled={loadingArray[index] || isLoading}
                                                                    onClick={() => { migrateCard(index, sceneCard) }}

                                                                    className='rounded-full text-[#0099ff] w-[75px] bg-[#F5FBFF] text-[14px] px-3 py-2 disabled:saturate-0 '>

                                                                    {loadingArray[index] ? <LoaderSpin size={14} /> : "Migrate"}
                                                                </button>
                                                            </div>



                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                            :
                            <div className='h-[calc(100vh-72px)]'>
                                <EmptyPage title={"No scene cards are created"} subtitle={"Create a scene card to see here"} />

                            </div>}
                                
                        {searchVal === '' && sceneCardsList.length > 0 && <div className='bg-white pt-8 p-4 flex justify-center gap-4'>

                            <button disabled={allLoading || headingLoading} onClick={migrateHeadings}
                                className='py-3 px-4 w-[161px] text-[15px] font-bold bg-[#F5FBFF] rounded-full text-[#0099ff] disabled:saturate-0'>

                                {headingLoading && !loadingArray.includes(true) ? <LoaderSpin size={15} /> : "Migrate Headings"}
                            </button>

                            <button disabled={allLoading || headingLoading} onClick={migrateAll}
                                className='py-3 px-4 text-[15px] w-[110px] font-bold bg-[#0099ff] rounded-full text-[white] disabled:saturate-0'>

                                {allLoading && !loadingArray.includes(true) ? <LoaderSpin color={'white'} size={15} /> : "Migrate All"}
                            </button>
                        </div>}
                    {/* {searchVal &&                                    !sceneCard.title.toUpperCase().includes(searchVal.toUpperCase()) && "hidden"} */}
                        {searchVal && sceneCardsList.filter(sceneCard => sceneCard.title.toUpperCase().includes(searchVal.toUpperCase())).length===0 && 
                        <div className='h-[calc(100vh-72px)]'>         
                            <EmptyPage title={'Search result not found'} subtitle={"Try searching for a different scene"}/>
                        </div>}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SceneCardsTab