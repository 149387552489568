import React, { useState } from "react";
import Dropdown from "../Common/DropDown";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReactCountryFlag from "react-country-flag";
import { countries } from "../../constants/countries";

const CountrySelector = ({ value, setValue }) => {
  const [search, setSearch] = useState("");
  return (
    <Dropdown
    close={value}
      alignment="left"
      trigger={
        <button className="border h-[48px] w-[78px]  rounded-lg border-[#CACACA] flex gap-1 items-center justify-center">
          <ReactCountryFlag
            style={{ width: "31px", height: "21px" }}
            countryCode={value}
            svg
          />
          <MdKeyboardArrowDown  color="#868686" size={18}/>
        </button>
      }
    >
      <div className="flex w-[200px] flex-col gap-2 max-h-[300px] overflow-y-auto">
        <div className="p-1 ">
          <input
            className="p-2 w-full border-[#CACACA] border-2 rounded-md"
            placeholder="search country"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </div>
        <div className="flex flex-col gap-2 overflow-y-auto">
        {countries
          .slice()
          .filter(({ code, name }) =>
            name.toLowerCase().includes(search.toLowerCase())
          || search.toUpperCase() === code
          )
          .map(({ code, name }) => (
            <div key={code} >
              <button
                
                onClick={() => {
                  setValue(code);
                }}
                className="flex gap-3 items-center w-full p-2"
              >
                <ReactCountryFlag
                  className="scale-150"
                  countryCode={code}
                  svg
                />
                <label className="truncate max-w-[150px] ">{name}</label>
              </button>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </Dropdown>
  );
};

export default CountrySelector;
