import React from 'react';
import { countries } from '../../../constants/countries';

const PhoneInput = ({ countryCode , phoneNumber , setPhoneNumber }) => {

  phoneNumber = phoneNumber || ''
  countryCode = countryCode || 'US'
  const phoneCode = countries.find(({code})=> code === countryCode).phoneCode

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, '');
    let formatted = cleaned;
    
    if (cleaned.length > 3) {
      formatted = cleaned.slice(0, 3) + '-' + cleaned.slice(3);
    }
    if (cleaned.length > 6) {
      formatted = formatted.slice(0, 7) + '-' + formatted.slice(7);
    }
    
    return `${phoneCode}  ` + formatted;
  };

  const handleChange = (event) => {
    const input = event.target.value;
    if(!input.includes(' ') || input.includes('e')){
      return
    }
    const cleaned = input.replace(phoneCode,'').replace(/\D/g, '');
    setPhoneNumber(cleaned === ''?' ':cleaned);  // Update the parent component with raw number
  };

  return (
    <input
      type="tel"
      value={formatPhoneNumber(phoneNumber)}
      onChange={handleChange}
      placeholder="Enter phone number"
      className="p-3 rounded-lg border tracking-[0.02em] leading-[22px] font-medium border-[#CACACA] text-[#404040] grow "
    //   maxLength="12"
    />
  );
};

export default PhoneInput;