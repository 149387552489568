import React from 'react'
import BackButton from '../BackButton'
import AlexAiModal from './AlexAiModal'

const AlexAIPanel = ({setPanel,editor}) => {
    return (
        <div className='w-full h-full flex flex-col'>
            <BackButton setPanel={setPanel} />
            <div className='w-full shadow-sm grow'>
                <AlexAiModal editor={editor}/>
            </div>
        </div>
    )
}

export default AlexAIPanel