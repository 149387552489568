import { Checkbox, ColorPicker } from 'antd'
import React, { useState } from 'react'
import { addElements } from '../../utils/elementsUtils'
import { setNotes } from '../../../../../store/scriptSlice'
import Dropdown from '../../../../Common/DropDown'
import { MdExpandMore } from 'react-icons/md'

const AddElementModal = ({ editor, categoryList, sceneIndex, setUpdateElements, close }) => {
  const [category, setCategory] = useState(categoryList.find(category => category.isDefault) || categoryList[0] || {name:'', color:'#d9dee3'}) 
  const [closeDropDown, setCloseDropDown] = useState(false)
  const [name, setName] = useState('')
  const [notes, setNotes] = useState('')

  const handleSave = () => {
    const elementData = { name, notes, category: category.name }
    addElements(editor, elementData, sceneIndex)
    setUpdateElements(prev => !prev)
    close()
  }

  return (
    <div className='bg-[#00000030] text-[12px] z-[80] fixed top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <div className='w-[400px] bg-white rounded-lg p-4'>
        <h2 className='w-full font-bold text-center text-[#868686] text-[16px]'>Add element</h2>


        <Dropdown
          className={'w-full'}
          disabled={categoryList.length === 0}
          close={closeDropDown}
          trigger={
          <button className='border-2 flex justify-between w-full text-start rounded mt-8 px-3 py-2'>
            <div style={{borderColor:category.color} } className='border-s-2 ps-2 -ms-2'>
            {category.name || 'No categories'}
            </div>
             
             <MdExpandMore color='#CACACA' size={20}/>
             </button>}>
          <div className='flex flex-col w-full'>
            {categoryList.map(cat =>
              <>
                <button
                  onClick={() => { setCategory(cat)
                     setCloseDropDown(prev=>!prev) }}
                  style={{ borderColor: cat.color }}
                  className={`text-start w-[368px] hover:bg-[#e2e2e2] px-3 py-3 border-s-2`}>
                  {cat.name}
                </button>
                <hr/>
              </>
            )}


          </div>
        </Dropdown>


        <p className='text-[#868686] text-[12px] mt-4 mb-2'>Element name</p>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className='border text-[12px] w-full p-2 rounded-lg'
          placeholder='Element name' />

        <p className='text-[#868686] text-[12px] mt-4 mb-2'>Item notes</p>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          className='border h-40 text-[12px] w-full p-2 rounded-lg'
          placeholder='Add notes' />



        <div className='flex justify-between mt-4'>
          <button onClick={() => { close() }} className='text-[12px] px-3 py-2 border rounded-lg'>Cancel</button>
          <button onClick={() => { handleSave() }} className='text-[12px] px-3 py-2 bg-[#0099ff] rounded-lg text-white'>Save</button>
        </div>
      </div>
    </div>
  )
}

export default AddElementModal