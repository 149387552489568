import React, { useEffect, useRef, useState } from 'react'
import { checkforCharContinued } from './characterUtils'
import { getTitle, setIntro, setTime } from './sceneutils'

const SceneSugggest = ({ sceneMenuY, setSceneMenuY, editor }) => {

    const [update,setUpdate] = useState(false)

    const introList = ["INT", "EXT", "I/E"]
    const timeList = [
        'DAY',
        'NIGHT',
        'MORNING',
        'AFTERNOON',
        'SUNRISE',
        'SUNSET',
        'DUSK',
        'LATER',
        'EVENING',
    ]

    const menuRef = useRef(null)
    const selectedNode = editor.selection.getNode()
    const text = selectedNode.textContent?.toUpperCase()

    const [selectedIndex, setSelectedIndex] = useState(0)
    const textList = text.split(" ")
    const { intro, title, time } = getTitle(text)
    const lastWord = textList[textList.length - 1]
    const filteredList = introList.filter(intro =>
        intro.includes(lastWord) && lastWord !== "" && lastWord !== intro && textList.length === 1
    );
    if (textList.length > 2) {
        filteredList.push(
            ...timeList.filter(time =>
                time.includes(lastWord) && lastWord !== "" && lastWord !== time
            )
        )
    }

    const selectedSuggestionRef = useRef(filteredList[selectedIndex])

    if (text === '' || filteredList.length === 0) {
        setSceneMenuY(null)
    }

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setSceneMenuY(null);
        }
    };

    const handleSuggestionClick = (text) => {
        selectSuggestion(text)
    }

    useEffect(() => {
        selectedSuggestionRef.current = filteredList[selectedIndex]
    }, [filteredList])

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) { //enter
            e.preventDefault()
            selectSuggestion(selectedSuggestionRef.current)

        }

        if (e.keyCode === 38) {
            // Up Arrow
            e.preventDefault()
            setSelectedIndex(prevIndex => {
                const newIndex = prevIndex - 1
                selectedSuggestionRef.current = filteredList[newIndex < 0 ? prevIndex : newIndex]
                return newIndex < 0 ? prevIndex : newIndex
            })
        }

        if (e.keyCode === 40) { // Down Arrow
            e.preventDefault()
            setSelectedIndex(prevIndex => {
                const newIndex = prevIndex + 1
                selectedSuggestionRef.current = filteredList[newIndex > 3 ? prevIndex : newIndex]
                return newIndex > 3 ? prevIndex : newIndex
            })
        }

        if (e.keyCode === 27) { // Down Arrow
            e.preventDefault()
            setSceneMenuY(null)
        }
    }

    const selectSuggestion = (text) => {
        textList.pop()
        selectedNode.textContent = textList.join(" ")
        if (introList.includes(text))
            setIntro(editor, text, selectedNode)
        else
            setTime(editor, text, selectedNode)
        setUpdate(prev=>!prev)  
    }

    useEffect(() => {
        editor.on('click', handleClickOutside)
        document.addEventListener('click', handleClickOutside)
        editor.on('keydown', handleKeyDown)
        return () => {
            editor.off('click')
            document.removeEventListener('click', handleClickOutside)
            editor.off('keydown', handleKeyDown)
        };
    }, [menuRef]);

    const handleScroll = (e) => {
        setSceneMenuY(null)
    }

    useEffect(() => {
        if (editor) {
            const body = editor.getBody().parentNode.parentNode
            body.addEventListener('scroll', handleScroll)
            return () => {
                body.removeEventListener('scroll', handleScroll);
            };

        }
    }, [editor])

    const isMobileView = window.innerWidth < 800
    const initialPadding = ((editor.getDoc().childNodes[1].getBoundingClientRect().width - editor.getBody().getBoundingClientRect().width) / 2) + (isMobileView ? 0 : 133);

    return (
        <div style={{ top: sceneMenuY.top + 'px', left: `${initialPadding + sceneMenuY.text.length * 9}px`, translate: "50%" }} className={`translate-x-[-50%] mt-3 flex flex-col items-center w-fit z-50 absolute`}>
            {<div ref={menuRef} className='bg-white rounded flex shadow border flex-col gap-1 p-1 max-h-[200px] max-w-[400px] overflow-auto'>
                {filteredList.slice(0, 4).
                    map((character, index) =>
                        <button className={`border-b p-1 px-2 rounded text-start  ${index == selectedIndex ? 'bg-[#0099ff] text-white' : ' hover:bg-[#e5e7eb]'}`}
                            onClick={() => { handleSuggestionClick(character) }}>
                            {character}
                        </button>)}
            </div>}

        </div>

    )
}

export default SceneSugggest