import React, { useEffect, useState } from 'react'
import BackButton from '../BackButton'
import EmptyPage from '../EmptyPage'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdAdd, MdDelete, MdDragIndicator } from 'react-icons/md';
import { getTitle } from '../../utils/sceneutils';
import { migrateAll, migrateHeadings } from '../../utils/sceneCardsUtils';
import { autoPaginate } from '../../utils/pageUtils';

const SceneCardPanel = ({ setPanel, sceneCards, editor, permissionToEdit }) => {
  const [sceneCardsList, setSceneCardsList] = useState(sceneCards.allScenes);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(sceneCardsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSceneCardsList(items);
  }

  const handletitleChange = (text,index)=>{
    setSceneCardsList(prevSceneList =>{
      const newList = [...prevSceneList]
       newList[index].title = text
       return newList
    })
  } 

  const handleActionChange = (text,index)=>{
    setSceneCardsList(prevSceneList =>{
      const newList = [...prevSceneList]
       newList[index].action = text
       return newList
    })
  }

  const addSceneCard = ()=>{
      setSceneCardsList(prevList => {
        const newList = [...prevList]
        newList.push({
          title:'',
          action:'',
          color:null 
        })

        return newList
      })
  }

  const migrateCurrentHeadings=()=>{
      migrateHeadings(editor,sceneCardsList)
      editor.fire("change")
  }

  const migrateCurrentAll = ()=>{
      migrateAll(editor,sceneCardsList)
      editor.fire("change")
      
  }

  const deleteCard = (index)=>{
    setSceneCardsList(prevList => {
      const newList = [...prevList]
      newList.splice(index,1)
      return newList
    })
  }

  useEffect(()=>{
    setSceneCardsList(sceneCards.allScenes)
  },[sceneCards.allScenes])

  return (
    <div className='w-full h-full'>
      <BackButton setPanel={setPanel} />
      <div className='w-full h-[calc(100%-65px)] mt-4 shadow-sm overflow-hidden'>
        {false ? (
          <EmptyPage
            title={'You don\'t have any elements or scenes'}
            subtitle={'Start creating scenes and add elements to see here'}
          />
        ) : (
          <div className="bg-[#F3F4F6] overflow-y-auto h-full rounded flex flex-col gap-4 pt-2 pb-1">
            <div className="flex justify-between items-center px-4 ">
              <h3 className="font-bold text-[13px] ">Scene Cards</h3>
              <div className='flex gap-1'>
              <button disabled={!permissionToEdit} onClick={migrateCurrentHeadings} className='text-[11px] border rounded p-1 bg-white'>Migrate Headings</button>
              <button disabled={!permissionToEdit} onClick={migrateCurrentAll} className='text-[11px] border rounded p-1 bg-white'>Migrate all</button>
              <button disabled={!permissionToEdit} onClick={addSceneCard}><MdAdd/></button>
              </div>
              
            </div>
            <hr className="-mt-2" />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} className="px-4">
                    {sceneCardsList.map((sceneCard, index) => (
                      <Draggable
                        key={`scene-${index}`}
                        draggableId={`scene-${index}`}
                        index={index}
                        isDragDisabled={!permissionToEdit}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}

                            className={`rounded-md shadow-sm mb-2 flex justify-between items-center transition-colors duration-200 ${snapshot.isDragging ? 'bg-gray-100' : ''
                              }`}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <div className='bg-white relative w-full rounded-lg p-2 group'>
                              <button  
                              disabled={!permissionToEdit}
                              onClick={()=>{deleteCard(index)}}
                              className='bg-white rounded-full text-red-400 transition-all size-7 shadow-lg border flex justify-center items-center absolute -bottom-2 -right-2 opacity-0 group-hover:opacity-100'>
                                  <MdDelete/>
                              </button>
                              <div style={{borderColor: sceneCard.color}} className='items-center flex text-[12px] gap-2 justify-between  w-full border-s-4'>
                                <p className='ms-1 font-bold'>{index+1}.</p>
                                <input 
                                disabled={!permissionToEdit}
                                onChange={(e)=>{handletitleChange(e.target.value,index)}} 
                                value={`${sceneCard.title}`}
                                className='w-full rounded p-2 -ms-1' />

                                <button disabled={!permissionToEdit} {...provided.dragHandleProps} className=''>
                                  <MdDragIndicator />
                                </button>
                              </div>
                              <div className='mt-2'>
                                <textarea  
                                disabled={!permissionToEdit}
                                onChange={(e)=>{handleActionChange(e.target.value,index)}} 
                                value={sceneCard.action}
                                className='border w-full h-[150px] resize-none rounded-lg p-2 text-[12px]'/>
                              </div>

                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>
    </div>
  )
}

export default SceneCardPanel