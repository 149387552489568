import { useEffect, useRef, useState } from "react"
import { getTitle, timeColors } from "../Editor/utils/sceneutils";
import { serialize } from "parse5";
import { getAccessToken } from "../../../services/authService";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import LoaderSpin from "../../Common/LoaderSpin";
import SuggestionDropDown from "../Editor/sidepanel/SuggestionDropDown";
import { Checkbox, ColorPicker, Modal } from "antd";
import { useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { getPNodeArrtibute } from "../ScriptInfo/functions";



const AddCategory = ({ activeCategory, setActiveCategory, sceneIndex: index, setUpdateData, categoryList, isOpen, setIsOpen, pNodes, permissionToEdit }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    const [name, setName] = useState(activeCategory?.name || '')
    const [color, setColor] = useState(activeCategory?.color || '#d9dee3')
    const [isDefault, setIsDefault] = useState(activeCategory?.isDefault || false)

    const { id } = useParams()
    const handleSave = () => {
        if (name == "" || (!activeCategory && categoryList.find(category => category.name === name))) {
            toast.error("Category Already exists")
            return
        }

        // update index for default category
        if (activeCategory?.scene || activeCategory?.scene === 0) {
            index = activeCategory.scene - 1
        }

        const scenePnodes = pNodes.filter(node => node.attrs.find(attr => attr.name === 'class')?.value === 'sceneHeadings')
        const currentScene = scenePnodes[index]

        const newCategoryList = JSON.parse(getPNodeArrtibute(currentScene, 'data-category-list') || 'null') || []

        if (activeCategory) {
            const updateIndex = categoryList.findIndex(category => category.name === activeCategory.name)
            newCategoryList[updateIndex] = { name, color, isDefault }
        }
        else newCategoryList.push({
            name, color, isDefault
        })




        if (currentScene.attrs.find(({ name }) => name === 'data-category-list')?.value)
            pNodes.find(node => node === currentScene).attrs.find(({ name }) => name === 'data-category-list').value = JSON.stringify(newCategoryList)
        else pNodes.find(node => node === currentScene).attrs.push({ name: 'data-category-list', value: JSON.stringify(newCategoryList) })

        const content = serialize({ childNodes: pNodes })
        updateContent(content)
        // setUpdateElements(prev=>!prev)

    }
    const deleteCategory = () => {
        setIsDeleteOpen(false)
        if (activeCategory?.scene || activeCategory?.scene === 0) {
            index = activeCategory.scene - 1
        }

        const scenePnodes = pNodes.filter(node => node.attrs.find(attr => attr.name === 'class')?.value === 'sceneHeadings')
        const currentScene = scenePnodes[index]

        const newCategoryList = JSON.parse(getPNodeArrtibute(currentScene, 'data-category-list') || 'null')
            .filter(category => category.name !== activeCategory.name) || []

        pNodes.find(node => node === currentScene).attrs.find(({ name }) => name === 'data-category-list').value = JSON.stringify(newCategoryList)

        const content = serialize({ childNodes: pNodes })
        updateContent(content)
    }
    const updateContent = async (content) => {
        setIsLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }

            const data = {
                content
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'PUT',
                body: JSON.stringify(data)

            })
            if (!res.ok) {
                throw new Error('Error getting response')
            }
            const body = await res.json()
            toast.success('Category Updated')
            setIsLoading(false)
            setUpdateData(prev => !prev)
            close()
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            toast.error('Category Update Failed')
        }
    }

    const close = () => {
        setActiveCategory(null)
        setIsOpen(false)
    }

    return (
        <div className='bg-[#00000030] z-[90] fixed top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
            <div className='w-[400px] max-w-[95%] bg-white rounded-lg p-4'>
                <div className="w-full flex items-center">
                    <h2 className='w-full font-bold text-center text-[#868686]'>{activeCategory ? "Edit category" : "Add category"}</h2>
                    {activeCategory && <button disabled={!permissionToEdit} onClick={() => { setIsDeleteOpen(true) }} className="text-red-400 hover:scale-110 transition-all"><MdDelete size={24} /></button>}
                </div>

                <p className='text-[#868686] text-[12px] mt-8 mb-2'>Category name</p>
                <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={!permissionToEdit}
                    className='border text-[12px] w-full p-2 rounded-lg' placeholder='Category name' />

                <div className='flex gap-4 mt-4'>
                    <div className='flex gap-2 items-center'>
                        <ColorPicker size={'small'} defaultValue={color} onChange={(val, hex) => { setColor(hex) }} disabled={!permissionToEdit} />
                        <p className='text-[#868686] text-[12px]'>Category color</p>
                    </div>

                    <div className='flex gap-2 items-center'>
                        <Checkbox className='flex items-center text-[12px]' checked={isDefault} onChange={(e) => { setIsDefault(e.target.checked) }} disabled={!permissionToEdit} />
                        <p className='text-[#868686] text-[12px]'>Default category</p>
                    </div>
                </div>


                <div className='flex justify-between mt-4'>
                    <button onClick={() => { close() }} disabled={isLoading} className='text-[12px] px-3 py-2 border rounded-lg'>Cancel</button>
                    <button disabled={!permissionToEdit || isLoading} onClick={() => { handleSave() }} className='text-[12px] w-[52px] px-3 py-2 bg-[#0099ff] rounded-lg text-white'>
                        {isLoading ? <LoaderSpin color={'white'} /> : "Save"}
                    </button>
                </div>
                <Modal
                    okText={"Delete"}
                    open={isDeleteOpen}
                    closeIcon={false}
                    onCancel={() => { setIsDeleteOpen(false) }}
                    onOk={deleteCategory}
                    loading={isLoading} >
                    Are you sure you want to delete this category
                </Modal>
            </div>
        </div>)
}

export default AddCategory