import Film from '../../scripts/CreateScripts.js/Film'
import Modal from '..'
import Treatment from '../../scripts/CreateScripts.js/Treatment'
import TV from '../../scripts/CreateScripts.js/TV'
import { useNavigate } from 'react-router-dom'

const ScriptInfoModals = ({ type, setType }) => {
  const navigate = useNavigate()

  const close = () => {
    setType('')
  }
  const closeOnSave = () => {
    navigate(0)
  }
  return (
    <div >
      {type === 'film' && <Modal closeModal={close} closeOnClickOutside={false}>
        <Film close={closeOnSave} closeModal={close} />
      </Modal>}
      {type === 'treatment' && <Modal closeModal={close} closeOnClickOutside={false}>
        <Treatment close={closeOnSave} closeModal={close} />
      </Modal>}
      {type === 'tv' && <Modal closeModal={close} closeOnClickOutside={false}>
        <TV close={closeOnSave} closeModal={close} />
      </Modal>}
    </div>
  )
}

export default ScriptInfoModals