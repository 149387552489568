import { useEffect, useRef, useState } from "react"
import { getTitle, timeColors } from "../Editor/utils/sceneutils";
import { serialize } from "parse5";
import { getAccessToken } from "../../../services/authService";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import LoaderSpin from "../../Common/LoaderSpin";
import SuggestionDropDown from "../Editor/sidepanel/SuggestionDropDown";
import { MdArrowBackIos, MdDelete, MdExpandMore } from "react-icons/md";
import Dropdown from "../../Common/DropDown";
import { useParams } from "react-router-dom";
import { getNameWithoutbrackets } from "../Editor/utils/characterUtils";
import { ColorPicker, Modal } from "antd";

const ElementDetailsSlider = ({ scriptData, setUpdateData, sceneElements, activeElement, categoryList, elementList, index, setActiveElement, pNodes, permissionToEdit }) => {

    const [elementState, setElementState] = useState(activeElement)
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [closeDropDown, setCloseDropDown] = useState(false)

    const [category, setCategory] = useState(categoryList.find(cat=> cat.name === activeElement.category)?.name || '')
    const [name, setName] = useState(activeElement.name)
    const [notes, setNotes] = useState(activeElement.notes)

    const categoryObject = categoryList.find(cat=> cat.name === category)

    const { id } = useParams()

    const handleSave = async () => {
        const newElementList = [...elementList]
        const updateIndex = newElementList.findIndex(elem=> elem.name === activeElement.name)

        newElementList[updateIndex].name = name
        newElementList[updateIndex].category = category
        newElementList[updateIndex].notes = notes

        const scenePnodes = pNodes.filter(node => node.attrs.find(attr => attr.name === 'class').value === 'sceneHeadings')
        const currentScene = scenePnodes[index]
        pNodes.find(node => node === currentScene).attrs.find(({ name }) => name === 'data-element-list').value = JSON.stringify(newElementList)

        const content = serialize({ childNodes: pNodes })
        updateContent(content)
    }

    const deleteElement = ()=>{
        setIsDeleteOpen(false)
        const newElementList = elementList.slice().filter(element => element.name !== activeElement.name)

        const scenePnodes = pNodes.filter(node => node.attrs.find(attr => attr.name === 'class')?.value === 'sceneHeadings')
        const currentScene = scenePnodes[index]

        pNodes.find(node => node === currentScene).attrs.find(({ name }) => name === 'data-element-list').value = JSON.stringify(newElementList)

        const content = serialize({ childNodes: pNodes })
        updateContent(content)
    }

    const handleDelete = async () => {
        const newElementList = [...elementList]
        const updateIndex = newElementList.filter(elem=> elem.name !== activeElement.name)

        const scenePnodes = pNodes.filter(node => node.attrs.find(attr => attr.name === 'class').value === 'sceneHeadings')
        const currentScene = scenePnodes[index]
    
        pNodes.find(node => node === currentScene).attrs.find(({ name }) => name === 'data-element-list').value = JSON.stringify(newElementList)

        const content = serialize({ childNodes: pNodes })
        updateContent(content)
    }

    const updateContent = async (content) => {
        setIsLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }

            const data = {
                content
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'PUT',
                body: JSON.stringify(data)

            })
            if (!res.ok) {
                throw new Error('Error getting response')
            }
            const body = await res.json()
            toast.success('Element Updated')
            setIsLoading(false)
            setUpdateData(prev => !prev)
            setActiveElement(null)
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            toast.error('Element Update Failed')
        }
    }


    return (
        <div className="fixed z-[90] bg-white top-0 left-0 h-[100svh] w-[100svw]" >

            <div className="overflow-hidden max-h-[72px] min-h-[72px] border-b p-4">
                <div className="flex pb-0 justify-between items-center ">
                    <div className="flex items-center  ">
                        <button onClick={() => { setActiveElement(null) }} className="w-fit">
                            <MdArrowBackIos size={24} />
                        </button>
                        <h2 className="text-[20px] font-bold">Element</h2>
                    </div>
                    <button disabled={!permissionToEdit} onClick={()=>{setIsDeleteOpen(true)}} className="text-red-400 text-[20px] hover:scale-110 transition-all"><MdDelete/></button>
                </div>
            </div>


            <div className="overflow-y-auto text-[14px] h-[calc(100svh-119px)] p-4">

            <label className="mb-2 text-[12px] text-[#868686]">Name</label>
            <input disabled={!permissionToEdit} value={name} onChange={(e) => { setName(e.target.value) }} className="w-full mb-4 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />
            
            <label className="mb-2 text-[12px] text-[#868686]">Category</label>
            <Dropdown
                disabled={!permissionToEdit}
                className={'w-full'}
                close={closeDropDown}
                alignment="left"
                trigger={
                    <button disabled={!permissionToEdit || categoryList.length===0} className='border-2 flex justify-between w-full text-start rounded mb-4 px-3 py-2'>
                        <div style={{ borderColor: categoryObject?.color }} className='border-s-2 ps-2 -ms-2'>
                            {categoryList.length!==0 ? (category || 'Select Category') : "No Categories"}
                        </div>

                        <MdExpandMore color='#CACACA' size={20} />
                    </button>}>
                <div className='flex flex-col w-full'>
                    {categoryList.map(cat =>
                        <>
                            <button
                                disabled={!permissionToEdit}
                                onClick={() => { setCategory(cat.name)
                                    setCloseDropDown(prev=>!prev)
                                 }}
                                style={{ borderColor: cat.color }}
                                className={`text-start min-w-[200px] hover:bg-[#e2e2e2] px-3 py-3 border-s-2`}>
                                {cat.name}
                            </button>
                            <hr />
                        </>
                    )}


                </div>
            </Dropdown>

            <label className="mb-2 text-[12px] text-[#868686]">Item notes</label>
            <textarea disabled={!permissionToEdit} onChange={(e) => { setNotes(e.target.value) }} value={notes} className="w-full h-[150px] mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

            <button disabled={!permissionToEdit} onClick={handleSave} className="bg-[#0099ff] px-3 py-2 rounded-full text-white mt-4 font-medium w-full">
               { isLoading?<LoaderSpin color={"white"} size={16}/>:"Save"}
            </button>
            </div>

            <Modal 
                okText={"Delete"} 
                open={isDeleteOpen} 
                closeIcon={false} 
                onCancel={()=>{setIsDeleteOpen(false)}}
                onOk={deleteElement}
                loading={isLoading} >
                    Are you sure you want to delete this Element
                </Modal>
        </div>)
}

export default ElementDetailsSlider