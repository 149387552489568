const subscriptions = [
  {
    title: "Free",
    price: "0",
    description:
    "Create script and use screenwriting anytime with 3 projects",
    isActive: true,  
    features: [
    { name: "Alex AI 2 Prompts only", available: true },
    { name: "3 Documents", available: true },
    { name: "Film, TV, Treatment", available: true },
    { name: "Unlimited Disk Space", available: true },
    { name: "Auto Industry Format", available: true },
    { name: "Character Breakdown", available: true },
    { name: "Categories and Elements", available: true },
    { name: "Scene Breakdown", available: true },
    { name: "Scene Cards", available: true },
    { name: "Notes and Revision", available: true },
    { name: "Collaborate and Share", available: true },
    { name: "Block Actor Dialogue", available: true },
    { name: "Upload Final Draft Script", available: true },
    { name: "Upload and Download Script (PDF) Admin Only", available: true },
    ],
  },
  {
    title: "Freemium",
    price: "0",
    description:
    "Create script and use screenwriting anytime with 3 projects",
    isActive: true,  
    features: [
    { name: "3 Documents", available: true },
    { name: "Film, TV, Treatment", available: true },
    { name: "Unlimited Disk Space", available: true },
    { name: "Auto Industry Format", available: true },
    { name: "Character Breakdown", available: false },
    { name: "Categories and Elements", available: false },
    { name: "Scene Breakdown", available: false },
    { name: "Scene Cards", available: false },
    { name: "Notes and Revision", available: false },
    { name: "Collaborate and Share", available: false },
    { name: "Block Actor Dialogue", available: false },
    { name: "Upload Final Draft Script", available: false },
    { name: "Upload and Download Script (PDF) Admin Only", available: true },
    ],
  },
  {
    title: "Essential",
    price: "4.99",
    description:
    "Create your script with unlimited documents, anytime and anywhere",
    features: [
    { name: "Unlimited Documents", available: true },
    { name: "Film, TV, Treatment", available: true },
    { name: "Unlimited Disk Space", available: true },
    { name: "Auto Industry Format", available: true },
    { name: "Character Breakdown", available: true },
    { name: "Categories and Elements", available: true },
    { name: "Scene Breakdown", available: true },
    { name: "Scene Cards", available: false },
    { name: "Notes and Revision", available: false },
    { name: "Collaborate and Share", available: false },
    { name: "Block Actor Dialogue", available: true },
    { name: "Upload Final Draft Script", available: false },
    { name: "Upload and Download Script (PDF) Admin Only", available: true },
    ],
  },
  {
    title: "Professional",
    price: "9.99",
    description:
    "Create your script and collaborate with your team in real-time",
    features: [
    { name: "Unlimited Documents", available: true },
    { name: "Film, TV, Treatment", available: true },
    { name: "Unlimited Disk Space", available: true },
    { name: "Auto Industry Format", available: true },
    { name: "Character Breakdown", available: true },
    { name: "Categories and Elements", available: true },
    { name: "Scene Breakdown", available: true },
    { name: "Scene Cards", available: true },
    { name: "Notes and Revision", available: true },
    { name: "Collaborate and Share", available: true },
    { name: "Block Actor Dialogue", available: true },
    { name: "Upload Final Draft Script", available: true },
    { name: "Upload and Download Script (PDF) Admin Only", available: true },
    ],
  },
  // {
  //   title: "Alex AI",
  //   price: "5",
  //   description: "Available on your customer portal with subscription",
  //   features: [
  
  //   ],
  // },
  ];

  export default subscriptions