import { combineReducers, configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import authReducer from "./authSlice";
import navReducer from './navSlice'
import scriptReducer from './scriptSlice'

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  nav: navReducer,
  script: scriptReducer,
  // Add all your reducers here
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});
