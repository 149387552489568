import React, { useEffect, useRef, useState } from 'react'
import { checkforCharContinued } from './characterUtils'

const CharacterSugggest = ({ characterMenuY, setCharacterMenuY, editor, characterList }) => {
  const menuRef = useRef(null)
  const selectedNode = editor.selection.getNode()
  const text = selectedNode.textContent?.toUpperCase()
  const [update,setUpdate] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const upperCaseList = characterList.map(char => char.toUpperCase())
  const filteredList = upperCaseList.slice().filter(char => char.startsWith(text) && char !== text) || []
  const selectedSuggestionRef = useRef(filteredList[selectedIndex])

  if(text === '' || filteredList.length === 0){
    setCharacterMenuY(null)
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setCharacterMenuY(null);
    }
  };

  const handleSuggestionClick = (text) => {
    selectSuggestion(text)
  }

  useEffect(()=>{
    selectedSuggestionRef.current = filteredList[selectedIndex]
  },[filteredList])

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) { //enter
      e.preventDefault()
      selectSuggestion(selectedSuggestionRef.current)
      
    }

    if (e.keyCode === 38) {
       // Up Arrow
        e.preventDefault()
        setSelectedIndex(prevIndex=>{
        const newIndex = prevIndex-1
        selectedSuggestionRef.current = filteredList[newIndex < 0 ? prevIndex : newIndex]
        return newIndex < 0 ? prevIndex : newIndex
      })
    }

    if (e.keyCode === 40) { // Down Arrow
      e.preventDefault()
      setSelectedIndex(prevIndex=>{
        const newIndex = prevIndex+1
        selectedSuggestionRef.current = filteredList[newIndex > 3 ? prevIndex :newIndex]
        return newIndex > 3 ? prevIndex :newIndex
      })
    }

    if (e.keyCode === 27) { // Down Arrow
      e.preventDefault()
      setCharacterMenuY(null)
    }
  }

  const selectSuggestion = (text) => {
    editor.dom.setHTML(selectedNode, text)
    setCharacterMenuY(null)
    setTimeout(() => {
      checkforCharContinued(editor)
    }, 0);
    setUpdate(prev=>!prev) 
  }

  useEffect(() => {
    editor.on('click', handleClickOutside)
    document.addEventListener('click', handleClickOutside)
    editor.on('keydown', handleKeyDown)
    return () => {
      editor.off('click')
      document.removeEventListener('click', handleClickOutside)
      editor.off('keydown', handleKeyDown)
    };
  }, [menuRef]);

  const handleScroll = (e)=>{
    setCharacterMenuY(null)
  }

  useEffect(() => {
    if(editor){
      const body = editor.getBody().parentNode.parentNode
        body.addEventListener('scroll', handleScroll)
        return () => {
          body.removeEventListener('scroll', handleScroll);
        };
    
    }
  },[editor])

  return (
    <div style={{ top: characterMenuY.top + 'px', left: '50%' }} className={`translate-x-[-50%] mt-3 flex flex-col items-center w-full z-50 absolute`}>
      {<div ref={menuRef} className='bg-white rounded flex shadow border flex-col gap-1 p-1 max-h-[200px] max-w-[400px] overflow-auto'>
          {filteredList.slice(0,4).
            map((character, index) =>
              <button className={`border-b p-1 px-2 rounded text-start  ${index == selectedIndex ? 'bg-[#0099ff] text-white' : ' hover:bg-[#e5e7eb]'}`}
                onClick={() => { handleSuggestionClick(character) }}>
                {character}
              </button>)}
        </div>}

    </div>

  )
}

export default CharacterSugggest