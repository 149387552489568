import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdArrowForwardIos, MdClose } from "react-icons/md";
import Slider from "react-slick";
import subscriptions from "./data";
import { getAccessToken } from "../../../services/authService";
import { useState } from "react";
import LoaderSpin from "../../Common/LoaderSpin";
import { useNavigate } from "react-router-dom";

const Subscription = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    gap: 20,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const userObj = JSON.parse(user);
      const activePlan = userObj.subscription.plan;
      const plans = ["Free", "Freemium", "Essential", "Professional"];

      // Find the index of the active plan
      const activeIndex = plans.indexOf(activePlan);

      // Iterate through each plan and update the button text
      subscriptions.forEach((plan, index) => {

        if (index < activeIndex) {
          // For previous plans
          plan.btnText = 'Proceed';
          plan.btnShowArrow = true
        } else if (index > activeIndex) {
          // For next plans
          plan.btnText = 'Proceed';
          plan.btnShowArrow = true
        } else {
          // For the active plan
          plan.btnText = 'Current Plan';
          plan.btnShowArrow = false
        }

        if (plan.title === "Free" && index < activeIndex) {
          plan.btnText = 'Expired';
          plan.isDisabled = true;
          plan.btnShowArrow = false  // Disable the button if the active plan is above "Free"
        }
      });

    }
  }

    , []);

  const handleClick = () => {
    const createCustomerPortalSession = async () => {
      setLoading(true);
      try {
        const token = getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_DEV_API}/payment/create-customer-portal-session`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        window.open(data.url, "_blank");
        navigate("/scripts");
        setLoading(false);
      } catch (error) {
        console.error("Error creating customer portal session:", error);
      }
    };

    createCustomerPortalSession();
  };

  return (
    <div className="h-full  mx-2 flex flex-col lg:max-w-[90vw] mx-auto">
      <div className="flex gap-2 m-4 items-center justify-between ">
        <Link to={"/myprofile"} className="lg:hidden">
          <IoIosArrowBack size={24} color="#292929" />
        </Link>
        <h1 className="grow lg:text-[28px] text-[#292929] font-[700] lg:text-center lg:mt-4">
          Change your plan
        </h1>
        {close && (
          <button onClick={close}>
            <MdClose size={24} />
          </button>
        )}
      </div>
      <hr className="lg:hidden w-full" />

      <div className="overflow-x-hidden overflow-y-scroll px-6 py-4">
        <div className="flex w-full p-4 items-center mb-4 justify-between text-gray-900 bg-white rounded-lg border shadow md:shadow-none">
          <div className="">
            <p className="font-bold mb-4 text-2xl "> Alex AI</p>

            <p className="font-light text-gray-500 sm:text-base">
              {" "}
              Available on your customer portal with subscription
            </p>
          </div>
          <div className="w-[250px] ">
            <div className="flex justify-center items-baseline my-3">
              <span className="mr-2 text-5xl max-lg:text-3xl font-extrabold">$5</span>
              <span className="text-gray-500">/month</span>
            </div>
            <p className=" text-[14px] ms-4 mb-2 text-gray-500"> 12  Month Contract Billed Monthly</p>
            <button
              onClick={handleClick}
              // disabled={info.btnText === "Current Plan" || info.isDisabled}
              // disabled={info.btnText == "Current plan"}
              // type={info.btnText == "Current plan" ? "light" : ""}
              className="w-full flex items-center justify-center rounded-full bg-[#0ea5e9] p-4 text-white text-[16px] disabled:bg-[#f5f5f5] disabled:text-[#a3a3a3] font-bold"
            >
              {loading ?
                <LoaderSpin color={"white"} size={24} /> :
                <div className="flex items-center w-full ">
                  <p className="grow w-full">{"Proceed"} </p>
                  { <MdArrowForwardIos size={24} />}
                </div>}
            </button>
          </div>
        </div>
        <Slider {...settings}>
          {subscriptions.map((s, key) => (
            <SubscriptionCard handleClick={handleClick} key={key} info={s} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

const SubscriptionCard = ({ info }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    const createCustomerPortalSession = async () => {
      setLoading(true);
      try {
        const token = getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_DEV_API}/payment/create-customer-portal-session`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        window.open(data.url, "_blank");
        // navigate("/scripts");
        setLoading(false);
      } catch (error) {
        console.error("Error creating customer portal session:", error);
      }
    };

    createCustomerPortalSession();
  };

  return (
    <div className="flex flex-col p-4 max-w-md text-center text-gray-900 bg-white rounded-lg border shadow md:shadow-none h-[1000px]">
      <h3 className="mb-4 text-2xl font-semibold ">{info.title}</h3>
      <p className="font-light text-gray-500 sm:text-base lg:min-h-[72px]">
        {info.description}
      </p>
      {info.price != "0" && (
        <>
          <div className="flex justify-center items-baseline my-8">
            <span className="mr-2 text-5xl font-extrabold">${info.price}</span>
            <span className="text-gray-500">/month</span>
          </div>
          <p className="-mt-4 text-[14px] mb-4 text-gray-500">12  Month Contract Billed Monthly</p>
        </>
      )}
      {info.title == "Free" && (
        <h3 className="mr-2 text-3xl max-lg:text-xl font-extrabold my-10 mb-[58px] text-sky-500">
          14 Days Free
        </h3>
      )}
      {info.title == "Freemium" && (
        <h3 className="mr-2 text-3xl max-lg:text-xl font-extrabold my-10 mb-[58px] text-sky-500">
          Free for Ever
        </h3>
      )}
      <button
        onClick={handleClick}
        disabled={info.btnText === "Current Plan" || info.isDisabled}
        // disabled={info.btnText == "Current plan"}
        type={info.btnText == "Current plan" ? "light" : ""}
        className="w-full flex items-center justify-center rounded-full bg-[#0ea5e9] p-4 text-white text-[16px] disabled:bg-[#f5f5f5] disabled:text-[#a3a3a3] font-bold"
      >
        {loading ?
          <LoaderSpin color={"white"} size={24} /> :
          <div className="flex items-center w-full ">
            <p className="grow w-full">{info.btnText} </p>
            {info.btnShowArrow && <MdArrowForwardIos size={24} />}
          </div>}
      </button>

      <ul role="list" className="mb-8 space-y-4 text-left mt-6 text-sm">
        {info.features.map((item, index) => (
          <li key={index} className="flex items-center space-x-3">
            {item.available ? (
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                class="w-[20px] h-[20px] text-red-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            )}
            <span>{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CustomButton = ({
  type = "default",
  customClassName = "",
  children,
  handleClick,
  loading = false,
  disabled = false,
}) => {
  if (type === "light")
    return (
      <>
        <div
          className={`w-full h-14 justify-start items-start inline-flex ${customClassName}`}
        >
          {disabled ? (
            <div className="grow shrink basis-0 h-14 p-4 bg-neutral-100 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
              <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                {children}
              </div>
            </div>
          ) : loading ? (
            <div className="grow shrink basis-0 h-14 p-4 bg-slate-50 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-not-allowed">
              <div className="text-sky-500 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                <div className="w-8 h-8 border-4 border-neutral-300 border-t-transparent border-solid rounded-full animate-spin"></div>
              </div>
            </div>
          ) : (
            <div
              className="grow shrink basis-0 h-14 p-4 bg-slate-50 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
              onClick={handleClick}
            >
              <div className="text-sky-500 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                {children}
              </div>
            </div>
          )}
        </div>
      </>
    );

  if (type === "light2") {
    return (
      <div
        className={`w-full h-14 justify-start items-start inline-flex ${customClassName}`}
      >
        <div
          className="grow shrink basis-0 h-14 p-4 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
          onClick={handleClick}
        >
          <div className="text-sky-500 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            {children}
          </div>
        </div>
      </div>
    );
  }
  if (type === "extraLight") {
    return (
      <div
        className={`w-full h-14 justify-start items-start inline-flex ${customClassName}`}
      >
        {disabled ? (
          <div className="grow shrink basis-0 h-14 p-4 bg-neutral-100 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
            <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
              {children}
            </div>
          </div>
        ) : (
          <div
            className="grow shrink basis-0 h-14 p-4 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
            onClick={handleClick}
          >
            <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
              {children}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`w-full h-14 justify-start items-start inline-flex ${customClassName}`}
    >
      {disabled ? (
        <div className="grow shrink basis-0 h-14 p-4 bg-neutral-100 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
          <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            {children}
          </div>
        </div>
      ) : loading ? (
        <div className="grow shrink basis-0 h-14 p-4 bg-sky-500 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
          <div className="text-white text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            <div className="w-8 h-8 border-4 border-sky-600 border-t-transparent border-solid rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div
          className="grow shrink basis-0 h-14 p-4 bg-sky-500 rounded-[32px] justify-center items-center gap-2 flex hover:bg-sky-400 cursor-pointer"
          onClick={handleClick}
        >
          <div className="text-white text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
