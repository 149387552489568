import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'

const ErrorPopUp = ({isOpen,imageSrc}) => {
    const [isVisible, setIsVisible] = useState(false)
    const menuRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        setIsVisible(isOpen)
    }, [isOpen])

    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} pointer-events-none lg:justify-center lg:items-center transition-all duration-200 flex justify-end items-end z-[90] fixed top-0 left-0 w-[100svw] h-[100svh] `}>
            <div ref={menuRef}
                className={`${isVisible ? 'w-full' : 'opacity-0'} pointer-events-auto  lg:w-fit lg:rounded-2xl px-10 transition-all duration-300 mx-auto rounded-t-2xl bg-white shadow flex flex-col items-center`}>
                    <div className="bg-[#E4E4E4] w-[56px] mx-auto mt-2 rounded-full h-[6px]" />
                    <img src={imageSrc} className='w-[171px] h-[155px] mt-10'/>
                    <h3 className='text-center text-[#292929] mx-auto max-w-[287px] text-[20px] font-light tracking-tighter leading-tight'>Noooooo..!! <br/>Your Connection is lost</h3>
                    <p className='mt-3 text-center text-[#868686] text-[15px]'>We are sorry, no internet connection. <br/> Please try again later</p>
                    <button onClick={()=>{navigate(0)}} className='my-6 py-4 w-full font-bold bg-[#F5FBFF] rounded-full text-[#0099FF] text-[16px]'>Try Again</button>
            </div>
        </div>)
}

export default ErrorPopUp