import React from 'react';
import { countries } from '../../constants/countries';

const PhoneInput = ({ onKeyDown, countryCode , phoneNumber , setPhoneNumber }) => {

  const phoneCode = countries.find(({code})=> code === countryCode).phoneCode

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, '');
    let formatted = cleaned;
    
    if (cleaned.length > 3) {
      formatted = cleaned.slice(0, 3) + '-' + cleaned.slice(3);
    }
    if (cleaned.length > 6) {
      formatted = formatted.slice(0, 7) + '-' + formatted.slice(7);
    }
    
    return formatted;
  };

  const handleChange = (event) => {
    const input = event.target.value;
    if( input.includes('e')){
      return
    }
    const cleaned = input.replace(/\D/g, '');
    setPhoneNumber(cleaned === ''?' ':cleaned);  // Update the parent component with raw number
  };

  return (
    <div className='flex items-center p-3 gap-2 grow rounded-lg border tracking-[0.02em] leading-[22px] font-medium  border-[#CACACA] text-[#404040]'>
      <p>{phoneCode}</p>
    <input
      type="tel"
      value={formatPhoneNumber(phoneNumber)}
      onKeyDown={onKeyDown}
      onChange={handleChange}
      placeholder="Enter phone number"
      className="tracking-[0.02em] leading-[22px] focus:border-none "
    //   maxLength="12"
    />
    </div>
  );
};

export default PhoneInput;