import React, { useEffect, useState } from 'react'
import { MdArrowBackIos, MdClose, MdExpandMore, MdSearch } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from '../../../services/authService'
import { setConnectionErrorOpen } from '../../../store/navSlice'
import { useDispatch } from 'react-redux'
import LoaderSpin from '../../Common/LoaderSpin'
import ScriptTab from '../Scenes/ScriptTab'
import CharactersTab from './CharactersTab'
import Dropdown from '../../Common/DropDown'
import { parseToBody } from '../Editor/utils/scriptUtils'
import { toast } from 'react-toastify'
import { getNameWithoutbrackets } from '../Editor/utils/characterUtils'

const Characters = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isScriptTabActive, setIsScriptTabActive] = useState(true)
    const [searchVal, SetSearchVal] = useState('')
    const [isSearchOpen, setIsSearchOpen] = useState(false)

    const [loading, setLoading] = useState(1)
    const [scriptData, setScriptData] = useState({})
    const dispatch = useDispatch()
    const [updateData, setUpdateData] = useState(false)
    const [sceneIndex, setSceneIndex] = useState(0)
    const [sceneCharactersList, setSceneCharactersList] = useState([])
    const [blockDialogueList, setBlockDialogueList] = useState([])

    const fetchScript = async () => {
        setLoading(1)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const body = await res.json()
            setScriptData(body.data)

            setLoading(0)
        } catch (error) {
            if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
                dispatch(setConnectionErrorOpen(true))
            }
            setLoading(2)
            console.error(error)
        }
    }

    const { content } = scriptData
    const body = parseToBody(content)


    const handleBlockDialogue = () => {
        const scriptHolder = document.querySelector('.scriptHolder')
        console.log('scriptholder',scriptHolder)
        if(scriptHolder){
            scriptHolder.childNodes.forEach(node => {
                if (node.nodeType === 1 && node.classList[0] === 'character'  ) {
                    const name = getNameWithoutbrackets(node.textContent.toUpperCase())
                    const color = blockDialogueList.find(char=> char.name === name)?
                    blockDialogueList.find(char=> char.name === name).color
                    :'none'
                    node.style.background = color;
    
                    let nextNode = node.nextSibling;
    
                    while (nextNode && (nextNode.nodeType === 3 || !nextNode.textContent.trim())) {
                        nextNode = nextNode.nextSibling;
                    }
    
                    // Check if the next node has the 'dialogue' class
                    while (nextNode && nextNode.nodeType === 1 && (nextNode.classList.contains('dialogue') || nextNode.classList.contains('parentheses'))) {
                        // Apply the same background color to the dialogue node
                        nextNode.style.background = color;
                        nextNode = nextNode.nextSibling;
                        while (nextNode && (nextNode.nodeType === 3 || !nextNode.textContent.trim())) {
                            nextNode = nextNode.nextSibling;
                        }
                    }
                }
            })
        }
        
    }

    const scenes = []

    body.childNodes.forEach((node, index) => {
        if (node.nodeType === 1) {
            if (node.classList[0] === 'sceneHeadings') {
                scenes.push({
                    color: node.getAttribute('data-color'),
                    summary: node.getAttribute('data-summary'),
                    title: node.textContent,
                    notes: 0
                })
            }
        }
    })

    useEffect(() => {
        fetchScript()
    }, [updateData])

    useEffect(() => {
        console.log('ran')
        setIsScriptTabActive(true)
    }, [blockDialogueList])

    useEffect(()=>{
        if(isScriptTabActive){
            handleBlockDialogue()
        }
    },[blockDialogueList,isScriptTabActive])



    return (
        <div className=" bg-white flex flex-col h-full overflow-y-hidden">
            <nav className='h-[72px] max-h-[72px] min-h-[72px] p-4 gap-4 flex border-b border-[#0000001A] justify-between items-center'>
                {isSearchOpen && !isScriptTabActive ?
                    <>
                        <div className='bg-white border grow border-[#CACACA] gap-2 rounded-lg p-3 flex items-center'>
                            <MdSearch size={24} color='#AAAAAA' />
                            <input placeholder={`Search Characters`}
                                className='outline-none grow focus:border-none'
                                value={searchVal}
                                onChange={(e) => { SetSearchVal(e.target.value) }} />

                        </div>
                        <MdClose size={28} color='#868686' onClick={() => {
                            setIsSearchOpen(false)
                            SetSearchVal('')
                        }} />
                    </>
                    :
                    <>
                        <div className='flex gap-1 '>
                            <button className='h-fit pt-1' onClick={() => { navigate(`/scripts/${id}`) }}>
                                <MdArrowBackIos size={22} color='#292929' />
                            </button>
                            <div className=''>
                                <h1 className='text-[#292929] font-bold text-[20px]'>Characters</h1>
                            </div>
                        </div>
                        <div className='flex gap-4 items-center'>

                            {scenes.length > 0 &&
                                <Dropdown close={sceneIndex} alignment='right' trigger={
                                    <button className="bg-white hover:bg-[#efefef] text-[14px] text-start rounded-lg flex items-center justify-between border text-[#868686] p-3 py-2 w-[130px] ">
                                        Scene {sceneIndex + 1}
                                        <MdExpandMore size={18} color="#CACACA" />
                                    </button>}>
                                    <div className=' flex flex-col w-[130px] max-h-[250px] z-[90] overflow-y-auto'>
                                        {scenes.map((_, index) =>
                                            <button
                                                style={{ color: index === sceneIndex ? '#0099ff' : '', background: index === sceneIndex ? '#F0F9FF' : '' }}
                                                className='border-b py-2 text-start text-[#868686] text-[13px] px-2 hover:bg-[#e2e2e2]'
                                                onClick={() => {
                                                    setSceneIndex(index)
                                                }}>
                                                Scene {1 + index}
                                            </button>
                                        )}
                                    </div>
                                </Dropdown>}

                            {!isScriptTabActive && <button className='pt-1'>
                                <MdSearch size={28} color='#AAAAAA' onClick={() => { setIsSearchOpen(true) }} />
                            </button>}
                        </div>
                    </>}
            </nav>
            <div className="overflow-y-hidden mt-5 mx-4 flex p-1 mb-2 gap-1 rounded-[9px] bg-[#EFEFEF]">
                <button
                    className={` grow text-[13px] flex items-center justify-center p-2 text-[#292929] text-center rounded-[9px] ${isScriptTabActive && "bg-white shadow-md"
                        }`}
                    onClick={() => {
                        setIsScriptTabActive(true);
                    }}
                >
                    Script
                </button>
                <button
                    className={`grow text-[13px] flex items-center justify-center p-2 text-[#292929] text-center rounded-[9px] ${!isScriptTabActive && "bg-white shadow-md"
                        }`}
                    onClick={() => {
                        setIsScriptTabActive(false);
                    }}
                >
                    Characters
                </button>
            </div>
            <div className='h-[calc] overflow-hidden z-[100]'>
                {loading === 1 && <div className='h-full py-20 flex items-center justify-center'><LoaderSpin size={40} /></div>}
                {loading === 0 && <div className='overflow-y-hidden'>

                    <div className='overflow-y-scroll h-[calc(100svh-100px)] pb-10 mt-2'>
                        {isScriptTabActive ? <ScriptTab content={scriptData.content} sceneIndex={sceneIndex + 1} /> :
                            <CharactersTab
                                body={body}
                                searchVal={searchVal}
                                scriptData={scriptData}
                                setUpdateData={setUpdateData}
                                sceneIndex={sceneIndex}
                                setSceneCharactersList={setSceneCharactersList}
                                blockDialogueList={blockDialogueList}
                                setBlockDialogueList={setBlockDialogueList} />}
                    </div>
                </div>}
            </div>

        </div>
    )
}

export default Characters