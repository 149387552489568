import { useEffect, useRef, useState } from "react"
import BackButton from "../BackButton"
import { goToScene } from "../../utils/formatUtils"
import { MdArrowBack, MdArrowBackIos, MdExpandMore } from "react-icons/md"
import { blockCharacterDialogue, findAndSetAttributeOnCharacter, findAndUpdateTextOnCharacter, getCharacter } from "../../utils/characterUtils"
import EmptyPage from "../EmptyPage"
import { useSelector } from "react-redux"
import Dropdown from "../../../../Common/DropDown"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import { getAccessToken } from "../../../../../services/authService"
import LoaderSpin from "../../../../Common/LoaderSpin"
import { editElement } from "../../utils/elementsUtils"

const ElementDetails = ({ categoryList, openedElement, setOpenedElement, editor, sceneIndex, setUpdateElements }) => {
    
    const [category, setCategory] = useState(categoryList.find(cat=> cat.name === openedElement.category)?.name || '')
    const [closeDropDown, setCloseDropDown] = useState(false)
    const [name, setName] = useState(openedElement.name)
    const [notes, setNotes] = useState(openedElement.notes)

    const categoryObject = categoryList.find(cat=> cat.name === category)


    const handleSave = () => {
        const elementData = { name, category, notes }
        editElement(editor, elementData, openedElement.name, sceneIndex)
        setUpdateElements(prev => !prev)
        setOpenedElement(null)
    }

    return <div className="h-full">
        <div className="flex items-center border-b pb-2 overflow-hidden">
            <button onClick={() => { setOpenedElement(null) }}
                className="hover:text-[#636363] flex justify-center p-1 items-center">
                <MdArrowBackIos size={15} />
            </button>

            <h2 className="text-[13px]">Element</h2>
        </div>
        <div className="overflow-y-auto text-[12px] h-[calc(100%-20px)] p-2">



            <label className="mb-2 text-[12px] text-[#868686]">Name</label>
            <input value={name} onChange={(e) => { setName(e.target.value) }} className="w-full mb-4 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />
            
            <label className="mb-2 text-[12px] text-[#868686]">Category</label>
            <Dropdown
                className={'w-full'}
                close={closeDropDown}
                alignment="left"
                trigger={
                    <button disabled={ categoryList.length===0} className='border-2 flex justify-between w-full text-start rounded mb-4 px-3 py-2'>
                        <div style={{ borderColor: categoryObject?.color }} className='border-s-2 ps-2 -ms-2'>
                        {categoryList.length!==0 ? (category || 'Select Category') : "No Categories"}
                        </div>

                        <MdExpandMore color='#CACACA' size={20} />
                    </button>}>
                <div className='flex flex-col w-full'>
                    {categoryList.map(cat =>
                        <>
                            <button
                                onClick={() => { setCategory(cat.name)
                                    setCloseDropDown(prev=>!prev)
                                 }}
                                style={{ borderColor: cat.color }}
                                className={`text-start min-w-[200px] hover:bg-[#e2e2e2] px-3 py-3 border-s-2`}>
                                {cat.name}
                            </button>
                            <hr />
                        </>
                    )}
                </div>
            </Dropdown>

            <label className="mb-2 text-[12px] text-[#868686]">Item notes</label>
            <textarea onChange={(e) => { setNotes(e.target.value) }} value={notes} className="w-full h-[150px] mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

            <button onClick={handleSave} className="bg-[#0099ff] px-3 py-2 rounded-full text-white mt-4 font-medium w-full">
                Save
            </button>

        </div>
    </div>
}

export default ElementDetails;