import React from "react";
import {
  MdPerson,
  MdLock,
  MdNotificationsActive,
  MdAttachMoney,
  MdContactSupport,
  MdLogout,
} from "react-icons/md";
import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { logOutAsync } from "../../store/asyncLogout";

const ProfileSectionHolder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('user'))

  const handleLogoutClick = () => {
    dispatch(logOutAsync());
    localStorage.removeItem("user");
    localStorage.removeItem("session");
    navigate('/login')
  };

  const ProfileSection = ({ user, Icon, title, subtitle, onClick, disabled }) => {
    return (
      <div
        onClick={disabled? ()=>{}: onClick}
        className={` ${!disabled && "cursor-pointer"} flex text-[#585858] justify-between items-center p-4 ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      >
        <div className="flex items-center gap-3">
          <Icon className=' size-[24px] font-medium' color="#AAAAAA" />
          <p className=" text-[#585858] text-[15px]">{title}</p>
        </div>
        <p className=" [#b1b1b1] text-[14px] text-[#757575] font-medium">{subtitle}</p>
      </div>
    );
  };
  return (
    <div className="lg:hidden mx-auto -lg w-full bg-[#FFFFFF] border-[1px] border-[#E4E4E4] rounded-xl">
      <div className=" flex-wrap gap-[2%]">
        <ProfileSection
          Icon={MdPerson}
          title="Profile Information"
          subtitle=""
          onClick={()=>{navigate("/myprofile/profileinfo")}}
        />
        <hr className="" />
        <ProfileSection
          Icon={MdLock}
          title="Password Security"
          subtitle=""
          onClick={()=>{navigate("/myprofile/changepass")}}
          disabled={user?.loginType !== 'EMAIL'}
        />
        <hr className="" />
        <ProfileSection
          Icon={MdNotificationsActive}
          title="Notification"
          subtitle=""
          disabled={true}
        />{/* TODO: remove disabled as true after notification is implemented */}
        <hr className="" />
        <ProfileSection
          Icon={MdAttachMoney}
          title="Subscription"
          subtitle="360 Days ago"
          onClick={()=>{navigate("/myprofile/subscription")}}
        />
        <hr className="" />
        <ProfileSection Icon={MdContactSupport} title="Support" subtitle="" disabled={true} />
        <hr className="" />
        <ProfileSection
          Icon={MdLogout}
          title="Log Out"
          onClick={handleLogoutClick}
        />{/* TODO: remove disabled as true after notification is implemented */}
      </div>
    </div>
  );
};

export default ProfileSectionHolder;
