import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { getAccessToken } from "../../../services/authService"
import LoaderSpin from "../../Common/LoaderSpin"
import { setConnectionErrorOpen } from "../../../store/navSlice"
import { useDispatch } from "react-redux"

const DeleteMenuPopUp = ({ isOpen, setIsOpen, id, onDelete=()=>{} }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const menuRef = useRef(null)

    const dispatch = useDispatch()

    const deleteScript = async () => {
        setLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'DELETE'
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const body = await res.json()
            onDelete()

            setLoading(false)
        } catch (error) {
            if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
                dispatch(setConnectionErrorOpen(true))
              }
            setLoading(false)
            toast.error('Failed to delete the script')
            console.error(error)
        }
    }

    const close = () => {
        setIsVisible(false)
        setTimeout(() => {
            setIsOpen(false)
        }, 300);
    }



    useEffect(() => {
        setIsVisible(isOpen)
    }, [isOpen])

    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} transition-all z-[100] duration-200 bottom-0 flex flex-col justify-center gap-2 items-center fixed top-0 left-0 w-[100vw] h-[100vh] `}>
            <div ref={menuRef}
                className={`${isVisible ? '' : 'opacity-0'} transition-all duration-300 w-[80%] max-w-[350px] mx-auto rounded-2xl lg:bg-white bg-[#f2f2f2] shadow flex flex-col items-center`}>
                <h2 className="p-4 pb-2 font-bold text-center text-[17px]">Are you sure you want to delete this Script?</h2>
                <h2 className="p-4 pt-0 px-8 text-center text-[13px] ">You can’t restore this script if you delete it</h2>
                <hr className="w-full border-[#bbbbbd]" />
                {!loading ? <div className="flex w-full">
                    <button className="hover:bg-[#f4f4f4] active:bg-white rounded-bl-2xl py-4 text-center text-[17px] grow text-[#007AFF] " onClick={close}>Cancel</button>
                    <button className="hover:bg-[#f4f4f4] active:bg-white rounded-br-2xl py-4 text-center text-[17px] grow text-[#007AFF] border-s border-[#bbbbbd] " onClick={deleteScript}>Delete</button>
                </div> :
                    <div className="py-4">
                        <LoaderSpin size={25} />
                    </div>}

            </div>
        </div>)
}

export default DeleteMenuPopUp