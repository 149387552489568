import { useEffect, useRef, useState } from "react";

const TransitionMenu = ({ transitionMenuY, setTransitionMenuY, editor, showOnTop = false }) => {
  const menuRef = useRef(null)

  const setTransition = (type) => {
    var selectedNode = editor.selection?.getNode();
    if (selectedNode) {
      if (selectedNode.classList[0] === 'transition') {
        var paragraphNode = editor.dom.getParent(selectedNode, 'p');
        editor.dom.setHTML(paragraphNode, type);
        
        editor.focus()
        editor.selection.select(paragraphNode, true);
        editor.selection.collapse(false);
      } else {
        var transitionNode = editor.dom.create('p', {class: 'transition'}, type);
        editor.dom.insertAfter(transitionNode, selectedNode);
        editor.focus()
        
        editor.selection.select(transitionNode, true);
        editor.selection.collapse(false);
      }
    }
    setTransitionMenuY(null);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setTransitionMenuY(null);
    }
  };

  useEffect(() => {
    const body = editor.getBody()
    editor.on('click', handleClickOutside)
    body.addEventListener('click', handleClickOutside)
    document.addEventListener('click', handleClickOutside)
    return () => {
      editor.off('click')
      document.removeEventListener('click', handleClickOutside)
      body.removeEventListener('click', handleClickOutside)
    };
  }, [menuRef]);

  return (
    <div style={{ top: transitionMenuY + 'px', left: '50%' }} className={`translate-x-[-50%] mt-3 flex flex-col items-center w-full z-50 absolute`}>
      <div ref={menuRef} style={{ scrollbarWidth: 'none' }} className={` z-10 bg-[#292929] overflow-x-scroll w-[calc(100%-8px)] max-w-[400px] rounded-lg text-white absolute`}>
        <div className={`transition-transform flex `}>
          <button onClick={() => { setTransition('FADE IN:') }} className='grow text-center border-r p-2 text-[15px] text-nowrap'>Fade In</button>
          <button onClick={() => { setTransition('FADE OUT:') }} className='grow text-center border-r  p-2 text-[15px] text-nowrap'>Fade Out</button>
          <button onClick={() => { setTransition('FADE TO BLACK:') }} className='grow text-center p-2 text-[15px] text-nowrap'>Fade to Black</button>
        </div>
      </div>
      <div className={`size-8 bg-black rotate-45 ${showOnTop?'mb-1':'mt-3'} `}></div>

    </div>

  )
}

export default TransitionMenu