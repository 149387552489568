import { useState } from "react"
import BackButton from "../BackButton"
import { getTitle, goToScene, timeColors } from "../../utils/sceneutils"
import {  useSelector } from "react-redux"
import EmptyPage from "../EmptyPage"
import SceneDetails from "./SceneDetails"

const ScenePanel = ({ setUpdateScenes, setPanel, editor, permissionToEdit }) => {
    const scenes = useSelector((state) => state.script.scenes)?.allScenes || []
    
    const [openedScene, setOpenedScene] = useState(null)

    const handleSceneClick = (index) => {
        goToScene(index, editor)
        setOpenedScene(index + 1)
    }

    

    return <>
        <div className='w-full h-full '>
            {openedScene ?
                <SceneDetails scenes={scenes} setUpdateScenes={setUpdateScenes} setOpenedScene={setOpenedScene} editor={editor} scene={scenes[openedScene - 1]} index={openedScene} permissionToEdit={permissionToEdit} /> :
                <>
                    <BackButton setPanel={setPanel} />
                    <div className='w-full h-[calc(100%-45px)] mt-4 shadow-sm overflow-hidden'>
                        {scenes.length === 0 && 
                        <EmptyPage title={'You have not created any scenes'} subtitle={'Start typing some scenes to see here'}/>}
                        {scenes.length > 0 &&
                            <div className=" bg-[#F3F4F6] h-full rounded flex flex-col gap-4">
                                <h3 className="font-bold px-4 pt-2 text-[14px] text-[#292929]">Scenes</h3>
                                <hr className="-mt-2" />
                                <div className="-mt-4 py-4 px-4 overflow-y-auto gap-[1px] flex flex-col">
                                    {scenes.map((scene, index) =>
                                        <button
                                            onClick={() => { handleSceneClick(index) }}
                                            className="flex rounded-lg border hover:shadow hover:bg-[#f3f3f3] text-start font-medium bg-white text-[#868686] w-full">
                                            <div style={{ background: scene.color || timeColors[getTitle(scene.title).time] || '#d9dee3' }} className="h-[37px] w-2 rounded-s-lg "></div>
                                            <div className="flex justify-between text-[12px] truncate  gap-4 px-3 py-2 w-full">
                                                <label >Scene {index + 1}</label>
                                                <label>{scene.sceneHeight}</label>
                                            </div>

                                        </button>)}
                                </div>
                            </div>}
                    </div>
                </>
            }
        </div>
    </>
}

export default ScenePanel