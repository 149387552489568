import React, {  useRef, useState } from 'react'
import { MdOutlineSearch, MdClose, } from "react-icons/md";

const SearchBar = ({ setSearchVal }) => {
    const [value, setValue] = useState('')
    const timeoutRef = useRef(null);

    const handleSearch = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        clearTimeout(timeoutRef.current);
        
        timeoutRef.current = setTimeout(() => {
            setSearchVal(newValue);
        }, 1000);
    };

    return (
        <div className=' border w-full border-[#CACACA] text-[14px] flex gap-2 p-3 rounded-lg'>
            <MdOutlineSearch color='#AAAAAA' className='size-6 cursor-pointer' />
            <input placeholder='Find script' value={value} onChange={handleSearch} className=' focus:border-none outline-none grow' />
            {value !== '' && <MdClose color='#AAAAAA' className='size-6 cursor-pointer' onClick={() => { setSearchVal('') 
                setValue('') }} />}
        </div>
    )
}

export default SearchBar