import React, { useState, useEffect } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import InviteBanner from "../../../assets/invite-banner.png";
import LoaderSpin from "../../Common/LoaderSpin";
import Checkbox from "antd/es/checkbox/Checkbox";
import ManagePermissions from "./ManagePermissions";
import AddPeoplePopUp from "./AddPeoplePopUp";
import { FloatButton } from "antd";
import { useParams } from "react-router-dom";
import AcceptInvitePopup from "./AcceptInvitePopUp";
import { getAccessToken } from "../../../services/authService";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const AcceptInvite = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

    useEffect(()=>{
        handleAcceptInvitation()
    },[])

  const handleAcceptInvitation = async () => {
    const token = getAccessToken();
    const url = `${process.env.REACT_APP_DEV_API}/invite/${id}/accept`;

    try {
      if (!token) {
        throw new Error("A valid session not found");
      }

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const body = await res.json();
      if (!res.ok) {
        console.error(res);
        throw new Error(body.message ? body.message : "Error accepting invite");
      }

      toast.success("Invitation accepted successfully");
      navigate('/scripts',{state:{sharedTabOpen:true}})
    } catch (error) {
      if (error.message === "Failed to fetch" || error.name === "TypeError") {
        dispatch(setConnectionErrorOpen(true));
      }
      toast.error(error.message);
      navigate('/scripts',{state:{sharedTabOpen:true}})
      console.error(error);
    }
  }


  return (
  <div className="h-full flex justify-center items-center">
    <LoaderSpin size={36} loading={true} />
  </div>
  );
};

export default AcceptInvite;
