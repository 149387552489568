import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoWhiteBg from "../../assets/morphosys-logo.png";

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/welcome"); // Replace with your target route
    }, 3000);

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div
      className="fixed top-0 left-0 h-[100vh] w-[100vw] z-[11] flex flex-col justify-start items-center"
      onClick={() => {
        navigate("/welcome");
      }}
    >
      <div className=" h-full w-full relative bg-[#FFA300]">
        <div className="top-triangle w-[42vw] h-[19vh] absolute z-[14]"></div>
        <div className="bottom-triangle w-[30vw] h-[18vh] absolute z-[14] bottom-0 right-0"></div>
        <div className="h-[150vh] w-[150vh] right-[25vh] top-[-15vh] blue-semi-circle blue-semi-circle-animation rounded-full z-[13] absolute" />
        <div className="h-[150vh] w-[150vh] left-[-42vh] top-[38vh] dark-blue-circle dark-blue-circle-animation rounded-full z-[12] absolute" />
      </div>

      {/* <div className="text-white text-4xl font-bold font-['Eudoxus Sans'] leading-loose m-auto">
        Morphosys
      </div> */}
      <div className="flex flex-row z-[15] fixed w-full gap-[19px] items-center justify-center mt-[44vh] font-logo font-logo-animation">
        <img className="h-16" src={logoWhiteBg} />
      </div>
      <div className="text-center text-white text-lg font-bold font-['Eudoxus Sans'] leading-relaxed tracking-tight w-full fixed bottom-[14px] z-[15]">
        Transform Your Ideas
      </div>
    </div>
  );
};

export default SplashScreen;
