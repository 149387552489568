import React, { useEffect, useRef } from 'react'

const SuggestionDropDown = ({ suggestions, value, isOpen, setIsOpen, width, setText }) => {
    const dropdownRef = useRef(null);

    const handleClick = (text) => {
        setIsOpen(false)
        setText(text)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <>
            {isOpen && !!suggestions.find((suggestion) => suggestion.toUpperCase().startsWith(value.toUpperCase())) &&
                <div ref={dropdownRef} style={{ width }} className='overflow-y-auto max-h-[200px] flex flex-col absolute bg-white min-w-20 w-fit shadow-lg border rounded-lg '>
                    {suggestions.filter((suggestion) => suggestion.toUpperCase().startsWith(value.toUpperCase())).map((text) =>
                        <button className='text-[12px] p-2 text-start hover:bg-slate-200 rounded-lg' onClick={() => handleClick(text)}>{text}</button>)}
                </div>
            }
        </>
    )
}

export default SuggestionDropDown