import React from 'react'
import lockVector from "../../../assets/backgrounds/lock_vector.svg"

const SucessSplashScreen = () => {
  return (
    <div className="bg-white px-3 h-full w-full overflow-auto flex flex-col">
            <h1 className="text-[24px] mt-[27px] text-center font-[700] text-[#292929]">Morphosys</h1>
            <img className="lg:h-[200px] lg:mt-9 mx-auto mt-[61px]" src={lockVector}/>
            <p className="mt-20 lg:mt-4 text-2xl font-bold text-center text-[#292929]">Your password has been successfully changed</p>
            <p className="mt-6 text-[16px] font-normal text-[#757575] text-center">Successfully changed password, you will be logged out of your account to log back in.</p>
      </div>
  )
}

export default SucessSplashScreen