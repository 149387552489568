import React, { useState } from 'react'
import Dropdown from '../../../../Common/DropDown'
import { MdAdd, MdArrowBackIosNew, MdExpandMore } from 'react-icons/md'
import { useSelector } from 'react-redux'

const AddNonSpeakingModal = ({ onCancel, title, onSave }) => {
  const characterList = useSelector(state => state.script.characters.allCharacters)
  const [text, setText] = useState('')
  const [selectedCharacter, setSelectedCharacter] = useState('')
  const [showAddNewCharacter, setShowAddNewCharacter] = useState(false)

  const handleSave = () => {
    if (showAddNewCharacter) {
      onSave(text)
    }
    else {
      onSave(selectedCharacter)
    }
  }

  return (
    <div className='bg-[#00000030] text-[12px] text-[#868686] z-[80] fixed top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
      <div className='w-[400px] bg-white rounded-lg p-4'>
        <div className='flex items-center'>
          {showAddNewCharacter && <button className='flex me-2' onClick={() => { setShowAddNewCharacter(false) }}><MdArrowBackIosNew size={18} /></button>}
          <h2 className='w-full font-bold text-[16px] text-[#868686] text-start'>{title}</h2>
        </div>


        {showAddNewCharacter ?
          <div className='mt-4'>
            <p className='text-[#868686] text-[12px] mb-2'>Character name</p>
            <input
              value={text}
              onChange={(e) => setText(e.target.value.toUpperCase())}
              className='border text-[12px] w-full p-2 rounded-lg' placeholder='Character name' />
          </div>


          :
          <div className='mt-8'>

            {characterList &&
              <Dropdown
                close={selectedCharacter}
                className={'w-full'}
                trigger={
                  <button className='bg-white hover:bg-[#efefef] text-[12px] text-start rounded-lg flex items-center justify-between border text-[#868686] px-2 w-full py-2'>
                    {selectedCharacter || 'Select character'}
                    <MdExpandMore size={18} />
                  </button>
                }
              >
                <div className=' flex flex-col w-[368px] max-h-[200px] overflow-y-auto '>
                  {characterList.map((character, index) =>
                    <button
                      key={index}
                      style={{ color: selectedCharacter === character ? '#0099ff' : '', background: selectedCharacter === character ? '#F0F9FF' : '' }}
                      className={`${index === characterList.length - 1 && "rounded-b-lg "} border-b  py-2 text-start text-[#868686] text-[13px] px-2 hover:bg-[#e2e2e2]`}
                      onClick={() => {
                        setSelectedCharacter(character)
                      }}>
                      {character}
                    </button>
                  )}
                </div>

              </Dropdown>}
            <button className='flex items-center mx-auto border p-2 mt-4 hover:bg-[#efefef] rounded-lg'
              onClick={() => { setShowAddNewCharacter(true) }}>
              Add new character <MdAdd size={20} /> </button>
          </div>
        }
        <div className='flex justify-between mt-4'>
          <button onClick={() => onCancel(text)} className='text-[12px] px-3 py-2 border rounded-lg'>Cancel</button>
          <button onClick={handleSave} className='text-[12px] px-3 py-2 bg-[#0099ff] rounded-lg text-white'>Save</button>
        </div>
      </div>
    </div>
  )
}

export default AddNonSpeakingModal