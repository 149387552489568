import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'; 
import { saveScriptAs } from '../Editor/utils/scriptUtils'
import { toast } from 'react-toastify'
import LoaderSpin from '../../Common/LoaderSpin'

const SaveAsModal = ({ isSaveAsModalOpen, setIsSaveAsModalOpen, scripData }) => {
    const [title, setTitle] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const onCancel = () => {
        setIsSaveAsModalOpen(false)
    }
    const onSave = async () => {
        setIsLoading(true);
        await saveScriptAs(scripData, title, () => { toast.success('Script saved sucessfully') })
        setIsLoading(false);
        setIsSaveAsModalOpen(false);
        navigate(`/scripts`);
    }
    return (
        <div className='bg-[#00000030] z-[80] fixed top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
            <div className='max-w-[420px] w-[400px] max-lg:w-[90%] bg-white rounded-lg p-4'>
                <h2 className='w-full font-bold text-center text-[#868686]'>Save Script As</h2>
                <p className='text-[#868686] text-[12px] max-lg:text-[14px] mt-8 mb-2'>Script Title</p>
                <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className='border text-[12px] max-lg:text-[14px] w-full p-2 max-lg:p-3 rounded-lg' placeholder='Enter script title' />
                <div className='flex justify-between mt-4'>
                    <button onClick={onCancel} disabled={isLoading} className='text-[12px] max-lg:text-[14px] w-[80px] 2-12 px-3 py-2  border rounded-lg'>
                        Cancel
                    </button>
                    <button onClick={onSave} disabled={isLoading} className='text-[12px] max-lg:text-[14px] w-[80px] px-3 py-2 bg-[#0099ff] rounded-lg text-white'>
                        {isLoading ? <LoaderSpin color={'white'} /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SaveAsModal