import React from 'react'

const CharacterButton = ({ onClick, character, name, key }) => {
  return (
    <div key={key} className='flex border-b p-4 justify-between items-center cursor-pointer' onClick={onClick}>
      <div className='flex gap-4 h-[51.2px]'>


        <div style={{ background: character?.color || '#d9dee3' }} className='w-1' />
        <div className='tracking-wide'>
          <div className='flex items-center gap-1 mb-1 text-[16px] font-medium'>
            <p className=''>{character.name}</p>
          </div>
          <div className='flex items-center  text-[14px] text-[#868686] font-medium'>
            {<label>{character?.gender && character.gender.charAt(0).toUpperCase() + character.gender.slice(1).toLowerCase()}</label>}
            {character?.age && character?.gender ? ', ' : ''}
            {character?.age && <label>{character.age} years old</label>}
          </div>
        </div>

      </div>
      <button className='rounded-full tracking-wide bg-[#F5FBFF] text-[#0099ff] font-medium text-[13px] px-[13px] py-[10px] h-fit'>
        Details
      </button>
    </div>
  )
}

export default CharacterButton