import { toast } from "react-toastify"
import { getAccessToken } from "../../../../services/authService"

export const deleteUser = async (documentId, userId) => {
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/documents/${documentId}/collaborators/remove`
    try {
        if (!token) {
            throw new Error('A valid session not found')
        }
        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            body: JSON.stringify({userId})
        })
        const body = await res.json()
        if (!res.ok) {
            console.error('res', res, body)
            throw new Error('')
        }
        toast.success('User Deleted Sucessfully')

    } catch (error) {
        toast.error('Failed to delete the user')
        console.error(error)
    }
}