import React, { useEffect, useRef } from 'react'

const Modal = ({children,closeModal,closeOnClickOutside = true}) => {

    const modalRef= useRef(0)

const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && closeOnClickOutside) {
          closeModal()
        }
      };

const handleKeyDown = (event) => {
  if (event.key === 'Escape') {
    closeModal()
  }
}

useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [modalRef]);

useEffect(()=>{
  document.addEventListener('keydown',handleKeyDown)
  return () => {
    document.removeEventListener('keydown',handleKeyDown)
  };
})

  return (
    <div className='top-[7vh] backdrop-blur-sm cursor-pointer z-[80] fixed h-[93vh] w-full flex items-center justify-center max-lg:hidden bg-[#0000005d]'>
        <div ref={modalRef} className='bg-white cursor-auto p-2 relative w-[450px] h-[90%] max-h-[900px] rounded-xl shadow-xl'>
            {children}
        </div>
    </div>
  )
}

export default Modal