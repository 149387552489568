import React, { useState } from 'react'
import Dropdown from '../../../../Common/DropDown'

const AddCharacterModal = ({onCancel,title,onSave, isNonSpeaking = false}) => {
    const [text,setText] = useState('')
  return (
    <div className='bg-[#00000030] z-[80] fixed top-0 left-0 h-[100vh] w-[100vw] flex justify-center items-center'>
        <div className='w-[400px] bg-white rounded-lg p-4'>
                <h2 className='w-full font-bold text-center text-[#868686]'>{title}</h2>
                <p className='text-[#868686] text-[12px] mt-8 mb-2'>Character name</p>
                <input
                value={text}
                onChange={(e)=>setText(e.target.value.toUpperCase())}
                className='border text-[12px] w-full p-2 rounded-lg' placeholder='Character name'/>
                <div className='flex justify-between mt-4'>
                    <button onClick={()=>onCancel(text)} className='text-[12px] px-3 py-2 border rounded-lg'>Cancel</button>
                    <button onClick={()=>onSave(text)} className='text-[12px] px-3 py-2 bg-[#0099ff] rounded-lg text-white'>Save</button>
                </div>
        </div>
    </div>
  )
}

export default AddCharacterModal