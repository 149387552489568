import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdArrowBackIos,
  MdClose,
  MdExpandMore,
  MdSearch,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getAccessToken } from "../../../services/authService";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { useDispatch, useSelector } from "react-redux";
import { parse, serialize } from "parse5";
import LoaderSpin from "../../Common/LoaderSpin";
import ScriptTab from "../Scenes/ScriptTab";
import CharactersTab from "./CharactersTab";
import Dropdown from "../../Common/DropDown";
import NotesModal from "./index-mobile";
import Editor from "./Editor";
import { Button } from "antd";
import { Modal, Input, Form, Card } from "antd";
import { setNotes } from "../../../store/scriptSlice";
import messageBubble from "../../../assets/messages-bubble.png";
import { toast } from "react-toastify";
import { getParentPElement } from "../Editor/utils/formatUtils";
import { getAllNotes } from "./functions";


const Notes = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isScriptTabActive, setIsScriptTabActive] = useState(true);
  const [searchVal, SetSearchVal] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const editorRef = useSelector((state) => state.script.editorRef);
  const notes = useSelector((state) => state.script.notes);
  const scriptContent = useSelector((state) => state.script.content);
  const [selectedNoteId, setSelectedNoteId] = useState(null);

  const [loading, setLoading] = useState(1);
  const [scriptData, setScriptData] = useState({});
  const dispatch = useDispatch();
  const [updateData, setUpdateData] = useState(false);
  const [visible, setVisible] = useState(false);

  const userData = JSON.parse(localStorage.getItem("user"));
  const [users, setUsers] = useState([]);
  const [permissionToEdit, setPermissionToEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (users.length === 0) fetchCollaborator();
  }, []);

  const fetchCollaborator = async () => {
    setIsLoading(true);
    const token = getAccessToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/documents/${id}/collaborators/list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUsers(data.data);
      const isAdmin = !data.data.find(user => user.email === userData.email);
      const allowedToEdit = isAdmin || data.data.find(user => user.email === userData.email)?.permission?.SCRIPT === "WRITE";
      setPermissionToEdit(allowedToEdit);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };


  const [form] = Form.useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getCurrentTimeString = () => {
    const now = new Date();
    return `${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getDate()).padStart(2, '0')}/${String(now.getFullYear()).slice(-2)}`;
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        const range = editorRef.selection.getRng();
        const selectedNode = getParentPElement(editorRef.selection.getNode())
        const editor = editorRef;
        editor.selection.setRng(range);
        editor.focus();

        const noteId = notes.reduce((acc, note) => Math.max(acc, note.noteId), 0) + 1
        const noteTime = getCurrentTimeString()
        const noteUser = JSON.parse(localStorage.getItem('user')).name
        const noteList = JSON.parse(selectedNode.getAttribute("data-note-list") || "null") || []

        noteList.push({ noteId, text: values.notes, user: noteUser, time: noteTime })

        selectedNode.setAttribute(`data-note-id-${noteId}`, true)
        selectedNode.setAttribute("data-note-list", JSON.stringify(noteList))
        getAllNotes(editorRef,dispatch)

        range.collapse(false);
        editor.selection.setRng(range);
        editor.focus();

        editor.save();

        setVisible(false);
        editor.fire("change")
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const fetchScript = async () => {
    setLoading(1);
    const token = getAccessToken();
    const url = `${process.env.REACT_APP_DEV_API}/document/${id}`;
    try {
      if (!token) {
        throw new Error("A valid session not found");
      }
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        throw new Error("Error getting response", res);
      }
      const body = await res.json();
      setScriptData(body.data);

      setLoading(0);
    } catch (error) {
      setLoading(2);
      console.error(error);
    }
  };

  const handleAddNote = () => {
    const selectedContent = editorRef.selection.getContent();
    if (selectedContent?.length === 0) {
      console.error("No text selected");
      toast.error("Please select some text to add a note.");
      return;
    } else {
      showModal();
    }
  };

  useEffect(() => {
    fetchScript();
  }, [updateData]);

  useEffect(()=>{
    if(editorRef && editorRef.getBody()){
      // Hide the caret
      editorRef.getBody().style.caretColor = 'transparent';
    }
  },[editorRef])

  return (
    <div className=" bg-white flex flex-col h-full overflow-y-hidden">
      <nav className="h-[72px] max-h-[72px] min-h-[72px] p-4 gap-4 flex border-b border-[#0000001A] justify-between items-center">
        {isSearchOpen && !isScriptTabActive ? (
          <>
            <div className="bg-white border grow border-[#CACACA] gap-2 rounded-lg p-3 flex items-center">
              <MdSearch size={24} color="#AAAAAA" />
              <input
                placeholder={`Search ${isScriptTabActive ? "script" : "notes"}`}
                className="outline-none grow focus:border-none "
                value={searchVal}
                onChange={(e) => {
                  SetSearchVal(e.target.value);
                }}
              />
            </div>
            <MdClose
              size={28}
              color="#868686"
              onClick={() => {
                setIsSearchOpen(false);
                SetSearchVal("");
              }}
            />
          </>
        ) : (
          <>
            <div className="flex gap-1 ">
              <button
                className="h-fit pt-1"
                onClick={() => {
                  navigate(`/scripts/${id}`);
                }}
              >
                <MdArrowBackIos size={22} color="#292929" />
              </button>
              <div className="">
                <h1 className="text-[#292929] font-bold text-[20px]">Notes</h1>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              {!isScriptTabActive && <button className="pt-1">
                <MdSearch
                  size={28}
                  color="#AAAAAA"
                  onClick={() => {
                    setIsSearchOpen(true);
                  }}
                />
              </button>}
            </div>
          </>
        )}
      </nav>

      <div className="overflow-y-hidden mt-5 mx-4 flex p-1 mb-2 gap-1 rounded-[9px] bg-[#EFEFEF]">
        <button
          className={` grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${isScriptTabActive && "bg-white shadow-md"
            }`}
          onClick={() => {
            setIsScriptTabActive(true);
          }}
        >
          Script
        </button>
        <button
          className={`grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${!isScriptTabActive && "bg-white shadow-md"
            }`}
          onClick={() => {
            setIsScriptTabActive(false);
          }}
        >
          Notes
        </button>
      </div>

      <div className=" overflow-hidden z-[100]">
        {(loading === 1 || isLoading) && (
          <div className="h-fulloverflow-hidden py-20 flex items-center justify-center">
            <LoaderSpin size={40} />
          </div>
        )}
        {loading === 0 && !isLoading && (
          <div className="overflow-y-hidden">

            <div className=" h-[calc(100svh-100px)] pb-10 mt-2">
              <div
                style={{
                  display: isScriptTabActive ? 'flex' : 'none',
                  height: 'calc(100vh - 150px)',
                }}
              ><Editor /> </div>
              {!isScriptTabActive && <NotesModal searchVal={searchVal} setIsScriptTabActive={setIsScriptTabActive} permissionToEdit={permissionToEdit} />}
              {permissionToEdit && scriptData?.content !== '<p></p>' && isScriptTabActive &&
                <button
                  onClick={handleAddNote}
                  type="primary"
                  className="absolute rounded-full z-[999] size-[40px] bg-[#0099ff] text-white flex justify-center items-center top-4 right-2"
                >
                  <MdAdd size={24} />
                </button>
              }
            </div>
          </div>
        )}
      </div>
      <Modal
        title="Save Note"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="notes_form">
          <Form.Item
            name="notes"
            label="Notes"
            rules={[{ required: true, message: "Please input your note!" }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Notes;
