import React from 'react'
import BackButton from '../BackButton'
import RevisionTab from './RevisionTab.js'
import EmptyPage from '../EmptyPage'

const RevisionsPanel = ({setPanel,scriptData,setRevisions}) => {
    return <div className='w-full h-full'>
        <BackButton setPanel={setPanel} />
        <RevisionTab scriptData={scriptData} setRevisionData={setRevisions} setPanel={setPanel}/>
    </div>
}

export default RevisionsPanel