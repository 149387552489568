import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { getAccessToken } from '../../../../services/authService';
import LoaderSpin from '../../../Common/LoaderSpin';
import { useNavigate } from 'react-router-dom';

const PageTitleModal = ({scriptData, setIsPageTitleOpen, }) => {
    const [formData, setFormData] = useState({
        title: scriptData.title,
        byline: scriptData.byline,
        basedOn: scriptData.basedOn,
        copyright: scriptData.copyright,
        author: scriptData.author,
        email: scriptData.email,
        phoneNumber: scriptData.phoneNumber,
    });

    const [isLoading, setisLoading] = useState(false)

    const navigate = useNavigate()

    const updateScript = async () => {
        setisLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${scriptData._id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const newData = Object.fromEntries(
                Object.entries(formData).filter(([_, value]) => value !== '')
            )
            const res = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ...newData })
            })
            const body = await res.json()
            if (!res.ok) {
                console.error(res)
                throw new Error(body.message ? body.message : 'Error getting response')
            }
            setisLoading(false)
            navigate(0)
            setIsPageTitleOpen(false)
            toast.success('Updated page title')
            

        } catch (error) {
            setisLoading(false)
            toast.error(error.message)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = () => {
        updateScript()
    };

    const isValidEmail = (email) => {
        return email === ''? true: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidPhoneNumber = (phoneNumber) => {
        return phoneNumber === ''? true : /^\+?\d{10,15}$/.test(phoneNumber);
    };

    const isFormValid =
    ["title", "copyright", "author"].every(key => formData[key]?.trim() !== '') &&
        isValidEmail(formData.email) &&
        isValidPhoneNumber(formData.phoneNumber);

    return (
        <div className='fixed max-lg:hidden top-[7vh] border border-red-500  left-0 h-[93vh] w-full bg-[#00000028] z-[82] flex justify-center items-center'>
            <div className='bg-white max-h-[90vh] overflow-y-auto p-4 rounded-lg w-full max-w-[500px] '>
                <h1 className='font-bold text-[#292929] mb-2 text-center '>Title Page</h1>
                <hr />
                <div className=' mt-4 '>

                    <label className="mb-2 text-[12px] text-[#868686]">Title</label>
                    <input value={formData.title} onChange={handleChange} name='title' className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

                    <label className="mb-2 text-[12px] text-[#868686]">Byline</label>
                    <input value={formData.byline} onChange={handleChange} name='byline' className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

                    <label className="mb-2 text-[12px] text-[#868686]">Author</label>
                    <input value={formData.author} onChange={handleChange} name='author' className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

                    <label className="mb-2 text-[12px] text-[#868686]">Based on</label>
                    <input value={formData.basedOn} onChange={handleChange} name='basedOn' className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

                    <label className="mb-2 text-[12px] text-[#868686]">Copyright</label>
                    <input value={formData.copyright} onChange={handleChange} name='copyright' className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

                    <label className="mb-2 text-[12px] text-[#868686]">Email</label>
                    <input value={formData.email} onChange={handleChange} name='email' className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

                    <label className="mb-2 text-[12px] text-[#868686]">Phone Number</label>
                    <input value={formData.phoneNumber} onChange={handleChange} name='phoneNumber' type="number" className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

                    <div className='mt-4 flex justify-between'>
                        <button onClick={()=>{setIsPageTitleOpen(false)}} className='w-[80px] text-[12px] border-2 text-[#868686] px-3 py-2 rounded-lg'>Cancel</button>
                        <button onClick={updateScript} className='text-[12px] w-[80px] bg-[#0099ff] text-white px-3 py-2 rounded-lg'>
                            {isLoading?<LoaderSpin color={'white'}/>:'Save'}
                        </button>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default PageTitleModal