export const logOutFromLocal = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const refreshToken = JSON.parse(localStorage.getItem("session"))?.refreshToken;
      const token = JSON.parse(localStorage.getItem("session"))?.accessToken;
      const baseUrl = `${process.env.REACT_APP_DEV_API}`;
      
      const response = await fetch(`${baseUrl}/iam/sign-out`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: refreshToken }),
      });

      if (response.ok) {
        localStorage.removeItem("user");
        localStorage.removeItem("session");
        resolve();
      } else {
        reject("Logout failed");
      }
    } catch (error) {
      console.error("Error:", error);
      reject(error);
    }
  });
};

export const saveUserToLocal = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const getAccessToken = () => {
  const token = JSON.parse(localStorage.getItem("session"))?.accessToken;
  if (token) {
    return token;
  } else {
    logOutFromLocal();
    return null;
  }
};

export const handleGoogleSignIn = () => {
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

  // Generate a random state value for security
  const state = Math.random().toString(36).substring(7);
  // Store state in localStorage to verify later
  localStorage.setItem('googleOAuthState', state);

  const params = {
    client_id: '684693976318-vdeibf9jgr6foqdv73plb3t7nnds9olo.apps.googleusercontent.com',
    redirect_uri: 'https://morphosys.thinkscoopinc.com/auth/callback',
    response_type: 'token',
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    include_granted_scopes: 'true',
    state: state,
    prompt: 'select_account',
  };

  const form = document.createElement('form');
  form.setAttribute('method', 'GET');
  form.setAttribute('action', oauth2Endpoint);

  for (const p in params) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const handleGoogleCallback = async () => {
  try {
    // Get the hash fragment from the URL
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    
    // Get access token and state from URL
    const accessToken = params.get('access_token');
    const receivedState = params.get('state');
    
    // Verify state to prevent CSRF attacks
    const savedState = localStorage.getItem('googleOAuthState');
    if (receivedState !== savedState) {
      throw new Error('Invalid state parameter');
    }
    
    // Clear the stored state
    localStorage.removeItem('googleOAuthState');

    // Use the access token to get user info
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get user info');
    }

    const data = await response.json();

    return {
      accessToken,
      email: data.email,
      name: data.name,
      picture: data.picture, // optional
      verified_email: data.email_verified // optional
    };

  } catch (error) {
    console.error('Error during Google OAuth callback:', error);
    throw error;
  }
};

export const handleFacebookLogin = async () => {
  try {
    const response = await new Promise((resolve, reject) => {
      window.FB.login((response) => {
        if (response.authResponse) {
          resolve(response);
        } else {
          reject('User cancelled login or did not fully authorize.');
        }
      }, { 
        scope: 'public_profile,email',
        return_scopes: true // This returns granted permissions
      });
    });

    // Get access token and other auth details
    const authData = {
      accessToken: response.authResponse.accessToken,
      userID: response.authResponse.userID,
      expiresIn: response.authResponse.expiresIn,
      signedRequest: response.authResponse.signedRequest,
      // Convert expires_in to actual date
      expirationDate: new Date(Date.now() + response.authResponse.expiresIn * 1000)
    };

    // Get user data
    const userResponse = await new Promise((resolve) => {
      window.FB.api('/me', { 
        fields: 'name,email,picture',
        access_token: authData.accessToken 
      }, (response) => {
        resolve(response);
      });
    });

    const userData = {
      ...userResponse,
      ...authData
    };

    console.log('Login successful:', userData);
    // You can now use userData.accessToken for API calls
    
    return userData;

  } catch (error) {
    console.error('Error during Facebook login:', error);
    throw error;
  }
};
