import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomButton from "../CustomButton";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import morphosys from "../../assets/morphosys.png";

import logoWhiteBg from "../../assets/morphosys-logo.png";

import arrowBack from "../../assets/arrow_back_ios.svg";
import iconSmall from "../../assets/icon-small.svg";
import OtpInput from "../OtpInput";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa6";
import { setConnectionErrorOpen } from "../../store/navSlice";
import { useDispatch } from "react-redux";

const ForgotPassword = ({ emailImg, lockImg }) => {
  const [resetPage, setResetPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerateOtpLoading, setIsGenerateOtpLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const [errors, setErrors] = useState({ email: "" });

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [passwordCheck1, setPasswordCheck1] = useState(false);
  const [passwordCheck2, setPasswordCheck2] = useState(false);
  const [passwordCheck3, setPasswordCheck3] = useState(false);
  const [passwordCheck4, setPasswordCheck4] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleNext = () => {
    setResetPage(resetPage + 1);
  };

  const handleBack = () => {
    if (resetPage == 0) {
      navigate("/login");
    }

    setResetPage(resetPage - 1);
  };

  useEffect(() => {
    if (resetPage == 1) {
      const timer = setTimeout(() => {
        setResetPage(2);
      }, 3000);

      // Cleanup the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [resetPage]);

  let initialTime = 30;

  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  // const [restart, setRestart] = useState(false);
  const [enableResendOtp, setEnableResendOtp] = useState(false);

  const timerRestart = () => {
    setTimeLeft(initialTime);
    setEnableResendOtp(false);
  };

  const timerComplete = () => {
    setEnableResendOtp(true);
  };

  // useEffect(() => {
  //   console.log("isTimerCompleted", isTimerCompleted);
  //   if (isTimerCompleted) {

  //   }
  // }, [isTimerCompleted]);

  useEffect(() => {
    if (timeLeft === 0) {
      // setIsTimerCompleted(true);
      timerComplete();
      return;
    }

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime =
    initialTime > 59
      ? `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
      : seconds;


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailRegex.test(email) ? "" : "Invalid email format",
    }));
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const joinOtp = (arr) => {
    return arr.join("");
  };

  const verifyOtp = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/verify-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            otp: joinOtp(otp),
            isResetPassword: true,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setIsLoading(false);
        toast.success("OTP verified Succesfully");
        handleNext();
      } else {
        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      setIsLoading(false);
      console.error("Error while verifing OTP:", error);
      toast.error("An error occurred, please try again.");
    }
  };
  const generateOtp = async () => {
    try {
      setIsGenerateOtpLoading(true);
      const response = await fetch(`${process.env.REACT_APP_DEV_API}/iam/otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await response.json();

      if (data.status === "success") {
        setIsGenerateOtpLoading(false);
        toast.success("OTP Sent Succesfully");
        if (resetPage !== 2) handleNext();
        timerRestart();
      } else {
        setIsGenerateOtpLoading(false);
        toast.error(data.message || "An error occurred");
        timerComplete();
      }
    } catch (error) {
      if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      setIsGenerateOtpLoading(false);
      console.error("Error while sending OTP:", error);
      toast.error("An error occurred, please try again.");
      timerComplete();
    }
  };

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, newPassword: password }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setIsLoading(false);
        toast.success("Password Reset Succesfull");
        handleNext();
      } else {
        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      setIsLoading(false);
      console.error("Error while resetting the password:", error);
      toast.error("An error occurred, please try again.");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full ">
        {resetPage != 1 && resetPage !== 4 ? (
          <div className="z-20  w-full p-[18px] flex-row flex justify-between items-center m-auto ">
            {resetPage === 3 || resetPage === 2 ? (
              <div className="justify-start items-center gap-3 inline-flex cursor-pointer">
                <img src={iconSmall} className="w-10 h-10 " />
                <div className="text-white text-2xl font-bold font-['Eudoxus Sans'] ">
                  Morphosys
                </div>
              </div>
            ) : (
              <div
                className="justify-start items-center gap-2 inline-flex rounded-full cursor-pointer"
                onClick={handleBack}
              >
                <img src={arrowBack} className="w-6 h-6 " />
                <div className="text-white text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                  Reset Password
                </div>
              </div>
            )}

            {resetPage === 0 ? (
              <div className="text-right text-white text-lg font-medium font-['Eudoxus Sans'] leading-relaxed tracking-tight">
                Step 1 of 3
              </div>
            ) : (
              <div className="text-right text-white text-lg font-medium font-['Eudoxus Sans'] leading-relaxed tracking-tight">
                Step {resetPage} of 3
              </div>
            )}
          </div>
        ) : (
          <div className="z-30  w-full lg:mt-8 p-[18px] flex-row flex justify-center items-center m-auto ">
            <div className="flex flex-col lg:gap-2 items-center gap-[20px] w-full justify-start ">
              <div className="text-white  text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                Morphosys
              </div>
              <div className=" lg:w-[50%] lg:h-[120px] h-[256px] w-[263px] relative ">
                <img
                  className="absolute top-0"
                  src={resetPage === 1 ? emailImg : lockImg}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`${
            resetPage !== 1 && resetPage !== 4
              ? "h-[86%] rounded-tl-3xl rounded-tr-3xl "
              : "h-[50%] "
          } w-full bg-white  z-20 lg:rounded-xl overflow-y-scroll no-scrollbar`}
        >
          {resetPage === 0 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[27px] pb-[18px] px-4 ">
              <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans']">
                Enter your email address <br />
                to receive verification code
              </div>
              <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex mb-7">
                <div className="text-zinc-500 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                  Email
                </div>
                <div className="w-full flex flex-col">
                  <input
                    onChange={handleEmailChange}
                    value={email}
                    type="email"
                    className="w-full pl-3 py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                    placeholder="Enter email address"
                    style={{
                      outline: !!errors.email ? "1px solid red" : "",
                    }}
                    required
                  />
                  {!!errors.email && (
                    <label className="text-[10px] text-red-600 text-start">
                      {errors.email}
                    </label>
                  )}
                </div>
              </div>
              <CustomButton
                handleClick={() => {
                  generateOtp();
                }}
                loading={isGenerateOtpLoading}
                disabled={!!errors.email}
              >
                Send Email
              </CustomButton>

              <CustomButton
                type="light2"
                handleClick={() => {
                  navigate("/login");
                }}
              >
                Back to Login
              </CustomButton>
            </div>
          )}
          {resetPage === 1 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[27px] pb-[18px] px-4 ">
              <div className="w-[343px] text-center text-zinc-800 text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                We have sent you a verification code to your email
              </div>
              <div className="w-[343px] text-center">
                <span className="text-neutral-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  Waiting for email verification for{" "}
                </span>
                <span className="text-sky-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  {email}
                </span>
              </div>
              {/* <CustomButton handleClick={handleNext}>Next</CustomButton> */}
            </div>
          )}
          {resetPage === 2 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[27px] pb-[18px] px-4 ">
              <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans'] ">
                We have sent you an <br />
                Verification code on email
              </div>
              <div className="text-neutral-400 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                {email}
              </div>

              <OtpInput otp={otp} setOtp={setOtp} />
              <CustomButton
                loading={isLoading}
                handleClick={verifyOtp}
                disabled={joinOtp(otp).length !== 6 || isGenerateOtpLoading}
              >
                Verify
              </CustomButton>
              <CustomButton
                type="light"
                disabled={enableResendOtp === false || isLoading}
                handleClick={generateOtp}
                loading={isGenerateOtpLoading}
              >
                Send again {formattedTime !== 0 && formattedTime}
              </CustomButton>
            </div>
          )}
          {resetPage === 3 && (
            <div className="flex flex-col items-center h-full justify-between pt-[27px] pb-[18px] px-4 ">
              <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans'] ">
                Setup new password <br />
                for your account
              </div>
              <div className="flex flex-col gap-6">
                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex mb-2">
                  <div className="text-zinc-500 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    New Password
                  </div>
                  <div className="w-full flex flex-col relative">
                    <input
                      value={password}
                      onChange={(e) => {
                        setPasswordCheck1(
                          /^(?=.*[A-Z]).*$/.test(e.target.value)
                        );
                        setPasswordCheck2(/^.{6,}$/.test(e.target.value));
                        const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                        setPasswordCheck3(
                          symbolRegex.test(e.target.value)
                        );
                        setPasswordCheck4(
                          confirmPassword && confirmPassword === e.target.value
                        );

                        setPassword(e.target.value);
                      }}
                      type={showPassword ? "text" : "password"}
                      className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                      placeholder="Enter password"
                      required
                    />
                    <button
                      type="button"
                      className="absolute  right-[12px] top-[12px] focus:outline-none flex items-center justify-center rounded-md"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <MdOutlineRemoveRedEye color="#AAAAAA" size={24}/> : <FaRegEyeSlash color="#AAAAAA" size={24}/>}
                    </button>
                  </div>
                </div>
                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex mb-2">
                  <div className="text-zinc-500 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    Confirm Password
                  </div>
                  <div className="w-full flex flex-col relative">
                    <input
                      value={confirmPassword}
                      onChange={(e) => {
                        setPasswordCheck4(password === e.target.value);
                        setConfirmPassword(e.target.value);
                      }}
                      type={showConfirmPassword ? "text" : "password"}
                      className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                      placeholder="Enter password"
                      required
                    />
                   
                  </div>
                  {!passwordCheck4 && confirmPassword !== "" && <p className="text-xs text-red-500">Confirm password does not match</p>}
                </div>
              </div>
              <div className=" h-32 flex-col justify-start items-start gap-4 inline-flex">
                <div className=" justify-start items-start gap-4 inline-flex">
                  {passwordCheck1 ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150846)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#48A966"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150846">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150829)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#CACACA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150829">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <div className=" text-[#868686] text-start font-medium text-[16px]">
                  Use a capital letter in the password
                  </div>
                </div>
                <div className=" justify-start items-start gap-4 inline-flex">
                  {passwordCheck2 ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150846)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#48A966"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150846">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150829)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#CACACA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150829">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                <div className=" text-[#868686] text-start font-medium text-[16px]">
                Password must be 6 or more characters
                  </div>
                </div>
                <div className=" justify-start items-start gap-4 inline-flex">
                  {passwordCheck3 ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150846)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#48A966"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150846">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150829)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#CACACA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150829">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <div className=" text-[#868686] text-start font-medium text-[16px]">
                    Password must have at least one symbol
                  </div>
                </div>
                
              </div>
              <div className="gap-4 flex flex-col items-center">
                <CustomButton
                  handleClick={handleResetPassword}
                  disabled={
                    passwordCheck1 === false ||
                    passwordCheck2 === false ||
                    passwordCheck3 === false ||
                    passwordCheck4 === false
                  }
                  loading={isLoading}
                >
                  Change Password{" "}
                </CustomButton>
              </div>
            </div>
          )}
          {resetPage === 4 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[27px] pb-[18px] px-4 ">
              <div className="w-[343px] text-center text-[#292929] text-2xl font-bold font-['Eudoxus Sans']">
                Yay! Your password has been successfully changed
              </div>
              <div className="w-[343px] text-center">
                <span className="text-neutral-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  Successful password change for{" "}
                </span>
                <span className="text-sky-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  {email}
                </span>
              </div>
              <CustomButton
                handleClick={() => {
                  navigate("/login");
                }}
              >
                Login Now
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
