import { Checkbox, Radio, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import google from "../../assets/google.png";
import facebook from "../../assets/facebook.png";
import englishMini from "../../assets/english-mini.png";
import arrowBack from "../../assets/arrow_back_ios.svg";
import arrowBackBlack from "../../assets/arrow_back_ios_black.svg";
import logo from "../../assets/morphosys_logo_only.png";

import OtpInput from "../OtpInput";
import { ClickOutsideListener } from "../../utils/ClickOutsideListener";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { login, logout } from "../../store/authSlice";
import LoaderWithText from "../../utils/LoaderWithText";
import CountrySelector from "./CountrySelector";
import PhoneInput from "./PhoneInput";
import { setConnectionErrorOpen, setIsTranslateOpen } from "../../store/navSlice";
import { handleFacebookLogin, handleGoogleSignIn } from "../../services/authService";

const Register = ({ emailImg }) => {
  const dispatch = useDispatch();
  const [registerPage, setRegisterPage] = useState(0);
  const [radioValue, setRadioValue] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [school, setSchool] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [countryCode, SetCountryCode] = useState("IN");
  const { state } = useLocation();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [passwordCheck1, setPasswordCheck1] = useState(false);
  const [passwordCheck2, setPasswordCheck2] = useState(false);
  const [passwordCheck3, setPasswordCheck3] = useState(false);
  const [userId, setUserId] = useState("");

  const [isGenerateOtpLoading, setIsGenerateOtpLoading] = useState(false);

  const [focusedElement, setFocusedElement] = useState("");

  const [errors, setErrors] = useState({ school: "" });
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const [isLoading, setIsLoading] = useState(false);

  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useState(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    const session = JSON.parse(localStorage.getItem("session"))
    if (data && session) {
      setFullName(data?.name || "");
      setEmail(data?.email || "");
      let updatedUserData = {
        user: data,
        session: session
      }
      setUserData(updatedUserData || null);
      if (data?.isVerified === false) {
        setRegisterPage(3);
      } else {
        setRegisterPage(4);
      }
    }
  }, [])

  useEffect(() => {
    if (registerPage == 2) {
      const timer = setTimeout(() => {
        setRegisterPage(3);
      }, 3000);

      // Cleanup the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [registerPage]);

  useEffect(() => {
    if (state?.email) {
      setEmail(state.email);
      setRegisterPage(2);
      generateOtp(state?.email);
    }
  }, [state?.email]);

  const handleNext = () => {
    setRegisterPage(registerPage + 1);
  };
  const handleBack = () => {
    clearInputs();
    if (registerPage == 0) {
      navigate("/login");
    }

    setRegisterPage(registerPage - 1);
  };

  const clearInputs = () => {
    setEmail("");
    setFullName("");
    setPassword("");
    setPhone("");
    setSchool("");
    setErrors({ school: "" });
    setPasswordCheck1(false);
    setPasswordCheck2(false);
    setPasswordCheck3(false);
    SetCountryCode("IN");
    setIsChecked(false);
  };

  const onCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleFullNameChange = (event) => {
    const value = event.target.value;
    setFullName(value);
    validateFullName(value);
  };

  const handleSchoolChange = (event) => {
    const value = event.target.value;
    setSchool(value);
    validateSchool(value);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const handlePasswordChange = (event) => {
    // const value = event.target.value;
    // const sanitizedValue = value.replace(/[^0-9]/g, "");
    // if (sanitizedValue.length <= 10) {
    //   setPhone(sanitizedValue);
    // }

    let pass = event.target.value;

    let passError = "";

    const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    let check1 = /^(?=.*[A-Z]).*$/.test(pass);
    let check2 = /^.{6,}$/.test(pass);
    let check3 = symbolRegex.test(pass);
    setPasswordCheck1(check1);
    setPasswordCheck2(check2);
    setPasswordCheck3(check3);
    if (!check1) passError = "Use a capital letter in the password";
    else if (!check2) passError = "Password must be 6 or more characters";
    else if (!check3) passError = "Password must have at least one symbol";

    let isValidPass =
      /^(?=.*[A-Z]).*$/.test(pass) &&
      /^.{6,}$/.test(pass) &&
      /^(?=.*[!@#$%^&*]).*$/.test(pass);

    setErrors((prevErrors) => ({
      ...prevErrors,
      password: isValidPass ? "" : passError,
    }));

    setPassword(pass);
  };

  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    if (sanitizedValue.length <= 15) {
      setPhone(sanitizedValue);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailRegex.test(email) ? "" : "Invalid email format",
    }));
  };

  const validateFullName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    let error = "";

    if (radioValue === "student") {
      error = !!name
        ? "Student name can only contain letters and spaces"
        : "Student Name is requried";
    } else {
      error = !!name
        ? "Full name can only contain letters and spaces"
        : "Full Name is requried";
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      fullName: nameRegex.test(name) ? "" : error,
    }));
  };

  const validateSchool = (school) => {
    const schoolRegex = /^[a-zA-Z\s]+$/;
    let error = !!school
      ? "Student School can only contain letters and spaces"
      : "Student School is requried";

    setErrors((prevErrors) => ({
      ...prevErrors,
      school: schoolRegex.test(school) ? "" : error,
    }));
  };

  const handleRegister = async () => {
    if(isChecked === false ||
      fullName === "" ||
      email === "" ||
      phone.length !== 10 ||
      password.length < 6 ||
      (radioValue == "student" && errors.school)){
        return
      }
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/sign-up`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: fullName,
            email,
            password,
            isStudent: radioValue == "student",
            countryCode,
            phone,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setIsLoading(false);
        toast.success(data.message);
        localStorage.setItem("user", JSON.stringify(data.data.user));
        localStorage.setItem("userId", JSON.stringify(data.data.user._id));
        localStorage.setItem("session", JSON.stringify(data.data?.session));
        setUserId(data.data.user._id);
        // dispatch(login(data.data));
        setUserData(data.data);
        setRegisterPage(registerPage + 1);
      } else {
        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error during signup:", error);
      toast.error("An error occurred, please try again.");
    }
  };

  const generateOtp = async (e) => {
    try {
      setOtp(new Array(6).fill(""));
      setIsGenerateOtpLoading(true);
      const response = await fetch(`${process.env.REACT_APP_DEV_API}/iam/otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          { email: e, isVerification: true }
        ),
      });
      const data = await response.json();
      if (data.status === "success") {
        setIsGenerateOtpLoading(false);
        toast.success("OTP Sent Succesfully");
        if (registerPage !== 3) handleNext();
        setEnableResendOtp(false);
      } else {
        setIsGenerateOtpLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      setIsGenerateOtpLoading(false);
      console.error("Error while sending OTP:", error);
      toast.error("An error occurred, please try again.");
    }
  };

  const verifyOtp = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/verify-otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            otp: joinOtp(otp),
            isResetPassword: false,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        let updatedUserData = userData && userData.user ? { ...userData.user } : null;
        if (updatedUserData) {
          updatedUserData.isVerified = true;
          localStorage.setItem("user", JSON.stringify(updatedUserData));
        }
        setIsLoading(false);
        toast.success("OTP verified Succesfully");
        if (userData?.session) {
          localStorage.setItem("session", JSON.stringify(userData?.session));
          dispatch(login(userData));
        } else {
          const data = localStorage.getItem("session")
          if (data) {
            localStorage.setItem("session", JSON.stringify(data));
            dispatch(login(JSON.parse(data)))
          }
          else {
            localStorage.setItem("session", JSON.stringify(state.session));
            dispatch(login(state.session))
          }
        }
        handleNext();
      } else {
        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
      console.error("Error while verifing OTP:", error);
      toast.error("An error occurred, please try again.");
    }
  };

  const handleExternalSignUp = async (name, email, loginToken, loginType) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/social-sign-in`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            loginToken,
            loginType
          }),
        }
      );
      const data = await response.json();

      if (data.status === "success") {
        setIsLoading(false);
        const user = data.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("session", JSON.stringify(data.data.session));
        toast.success("Login Successful");
        dispatch(login(data.data));
        navigate("/scripts");
      } else {
        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error during login:", error);
      toast.error("An error occurred, please try again.");
    }
  }

  const handleFaceBookLoginClick = async () => {
    const userResponse = await handleFacebookLogin()
    if (userResponse) {
      const faceBookEmail = userResponse.email
      const facebookName = userResponse.name
      const faceBookToken = userResponse.accessToken
      handleExternalSignUp(facebookName, faceBookEmail, faceBookToken, "FACEBOOK")
    }
  }

  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "828390832166658", // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v18.0' // Use latest version
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  let initialTime = 30;

  const [enableResendOtp, setEnableResendOtp] = useState(false);

  const joinOtp = (arr) => {
    return arr.join("");
  };

  //image upload

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size;

      if (!["image/jpeg", "image/png"].includes(fileType)) {
        toast.error("Only JPG and PNG files are allowed.");
        return;
      }

      if (fileSize > 4 * 1024 * 1024) {
        // 4MB
        toast.error("File size must be less than 4MB.");
        return;
      }

      if (file) {
        const reader = new FileReader();
        
        // Convert the file to a data URL (base64 string)
        reader.onloadend = () => {
          setPreview(reader.result);  // Set the image URL for the preview
        };
        
        reader.readAsDataURL(file);  // Read the file as a data URL
      }
      setImage(file);
      // handleUpdateDetails(file);
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   setPreview(reader.result);
      // };
      // reader.readAsDataURL(file);
    }
  };

  const handleUpdateDetails = async (image) => {
    try {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("photo", image);
      // formData.append("gender", "MALE");
      // formData.append('city', 'Mohali');
      // formData.append('state', 'Punjab');
      // formData.append('country', 'india');
      // formData.append('countryCode', '');
      // formData.append('dob', '');
      // formData.append('address', '');
      // formData.append('zipcode', '');
      // formData.append('isStudent', 'true');
      // formData.append('schoolName', 'Sample School');
      // formData.append('termsAndConditionsAccepted', 'true');
      const session = JSON.parse(localStorage.getItem("session"))

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/user/${userId || JSON.parse(localStorage.getItem("userId"))
        }`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${session?.accessToken
              }`,
          },
          body: formData,
        }
      );

      const data = await response.json();

      if (data.message.includes("success")) {
        setIsLoading(false);
        toast.success(data.message);
        navigate('/scripts')
      } else {
        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error while updating details", error);
      toast.error("An error occurred, please try again.");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full ">
        {registerPage === 99 ? (
          <></>
        ) : registerPage === 2 ? (
          <div className="z-30  w-full p-[18px] lg:mt-[3.5%] flex-row flex justify-center items-center m-auto ">
            <div className="flex flex-col items-center gap-[20px] w-full justify-evenly">
              <div className="text-white text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                Morphosys
              </div>
              <div className="lg:w-[50%] lg:h-[120px] h-[256px] w-[263px] relative ">
                <img className=" absolute top-0" src={emailImg} />
              </div>
            </div>
          </div>
        ) : (
          <div className="z-20  w-full p-[18px] flex-row flex justify-between items-center m-auto ">
            {registerPage < 3 ? (
              <div
                className={`justify-start items-center gap-2 inline-flex rounded-full  ${registerPage === 3 ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                onClick={registerPage === 3 ? () => { } : handleBack}
              >
                <img src={arrowBack} className="w-6 h-6 " />
                <div className="text-white text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                  Register
                </div>
              </div>
            ) : (
              <div className="flex gap-3 items-center">
                <img src={logo} />
                <h1 className="text-white text-[25px] drop-shadow-sm font-bold ">
                  Morphosys
                </h1>
              </div>
            )}
            {registerPage == 0 ? (
              <div onClick={()=>{
                dispatch(setIsTranslateOpen(true))
              }} className="w-[99px] cursor-pointer h-9 px-2 py-1.5 bg-sky-600 rounded justify-start items-center gap-2 inline-flex">
                <img className="w-[22px] h-[15px]" src={englishMini} />
                <div className="text-white text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                  English
                </div>
              </div>
            ) : (
              <>
                <div className="text-right text-white text-lg font-medium font-['Eudoxus Sans'] leading-relaxed tracking-tight">
                  Step {registerPage === 1 ? 1 : registerPage - 1} of 3
                </div>
              </>
            )}
          </div>
        )}
        <div
          className={`${registerPage === 99
              ? "h-[100%] "
              : registerPage === 2
                ? "h-[50%] "
                : "h-[86%] rounded-tl-3xl rounded-tr-3xl "
            } w-full bg-white  z-20 lg:rounded-xl overflow-y-scroll no-scrollbar`}
        >
          {registerPage === 0 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[20px] pb-[18px] px-4 ">
              <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans'] text-center">
                Create Account,
                <br />
                Choose your account type
              </div>
              <Radio.Group onChange={onRadioChange} value={radioValue}>
                <Space direction="vertical">
                  <div
                    className={`w-[343px] h-12 pl-4 pr-2 py-3 bg-white rounded-lg border border-neutral-200 justify-between items-center inline-flex hover:border-neutral-400 cursor-pointer ${radioValue === "individual" ? "border-neutral-700" : ""
                      }`}
                    onClick={() => {
                      setRadioValue("individual");
                    }}
                  >
                    <div className="justify-start items-center gap-3 flex">
                      <div className="text-zinc-800 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                        Register as Individual
                      </div>
                    </div>
                    <Radio value={"individual"}></Radio>
                  </div>
                  {/* <div className="w-[343px] h-12 pl-4 pr-2 py-3 bg-white rounded-lg border border-neutral-200 justify-between items-center inline-flex">
                    <div className="justify-start items-center gap-3 flex">
                      <div className="text-zinc-800 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                        Register as Company
                      </div>
                    </div>
                    <Radio value={"company"}></Radio>
                  </div> */}
                  <div
                    className={`w-[343px] h-12 pl-4 pr-2 py-3 bg-white rounded-lg border border-neutral-200 justify-between items-center inline-flex hover:border-neutral-400 cursor-pointer ${radioValue === "student" ? "border-neutral-700" : ""
                      }`}
                    onClick={() => {
                      setRadioValue("student");
                    }}
                  >
                    <div className="justify-start items-center gap-3 flex">
                      <div className="text-zinc-800 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                        Register as Student
                      </div>
                    </div>
                    <Radio value={"student"}></Radio>
                  </div>
                </Space>
              </Radio.Group>
              <div className="gap-4 flex flex-col items-center">
                <CustomButton
                  handleClick={handleNext}
                  disabled={radioValue === ""}
                >
                  Sign up with email
                </CustomButton>
                <CustomButton handleClick={handleGoogleSignIn} type="social">
                  <img className="w-[23px] h-[23px]" src={google} />
                  Sign up with Google
                </CustomButton>
                <CustomButton
                  handleClick={handleFaceBookLoginClick} type="social">
                  <img className="w-[23px] h-[23px]" src={facebook} />
                  Sign up with Facebook
                </CustomButton>
              </div>
            </div>
          )}
          {registerPage === 1 && (
            <div className="flex flex-col items-center h-full justify-between pt-5 pb-[18px] px-4 ">
              <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans'] pb-8">
                Register as {radioValue},<br />
                Fill the input to continue
              </div>
              <div className="flex flex-col gap-4 items-center">
                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-[#9E9E9E] text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    {radioValue === "student" ? "Student Name" : "Full Name"}
                    <label className="text-[#D41E79]">&nbsp;*</label>
                  </div>
                  <div className="w-full flex flex-col">
                    <input
                      value={fullName}
                      onChange={handleFullNameChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleRegister()
                        }
                      }}
                      type="text"
                      className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                      placeholder="Enter your full name"
                      style={{
                        outline: !!errors.fullName ? "1px solid red" : "",
                      }}
                      required
                    />
                    {!!errors.fullName && (
                      <label className="text-[10px] text-red-600 text-start">
                        {errors.fullName}
                      </label>
                    )}
                  </div>
                </div>
                {radioValue === "student" && (
                  <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex">
                    <div className="text-[#868686] text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                      Student School
                      <label className="text-[#D41E79]">&nbsp;*</label>
                    </div>
                    <div className="w-full flex flex-col">
                      <input
                        value={school}
                        onChange={handleSchoolChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleRegister()
                          }
                        }}
                        type="text"
                        className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                        placeholder="Enter your School name"
                        style={{
                          outline: !!errors.school ? "1px solid red" : "",
                        }}
                        required
                      />
                      {!!errors.school && (
                        <label className="text-[10px] text-red-600 text-start">
                          {errors.school}
                        </label>
                      )}
                    </div>
                  </div>
                )}

                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-[#9E9E9E] text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    Email
                    <label className="text-[#D41E79]">&nbsp;*</label>
                  </div>
                  <div className="w-full flex flex-col">
                    <input
                      onChange={handleEmailChange}
                      value={email}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleRegister()
                        }
                      }}
                      type="email"
                      className="w-full pl-3 py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                      placeholder="Enter email address"
                      style={{
                        outline: !!errors.email ? "1px solid red" : "",
                      }}
                      required
                    />
                    {!!errors.email && (
                      <label className="text-[10px] text-red-600 text-start">
                        {errors.email}
                      </label>
                    )}
                  </div>
                </div>

                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex mb-2">
                  <div className="text-[#868686] text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    Password
                    <label className="text-[#D41E79]">&nbsp;*</label>
                  </div>
                  <ClickOutsideListener
                    className="w-full flex flex-col relative"
                    idsToExclude={[]}
                    onOutsideClick={() => setFocusedElement("")}
                  >
                    <input
                      value={password}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleRegister()
                        }
                      }}
                      onChange={handlePasswordChange}
                      type={showPassword ? "text" : "password"}
                      className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                      placeholder="Enter password"
                      style={{
                        outline: !!errors.password ? "1px solid red" : "",
                      }}
                      onFocus={() => setFocusedElement("Password")}
                      required
                    />
                    <button
                      type="button"
                      className="absolute right-[12px] top-[12px] focus:outline-none flex items-center justify-center rounded-md"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <MdOutlineRemoveRedEye size={24} color="#AAAAAA" />
                      ) : (
                        <FaRegEyeSlash size={24} color="#AAAAAA" />
                      )}
                    </button>
                    {!!errors.password && (
                      <label className="text-[10px] text-red-600 text-start">
                        {errors.password}
                      </label>
                    )}
                  </ClickOutsideListener>
                </div>
                {focusedElement === "Password" && (
                  <div className=" h-32 flex-col justify-start items-start gap-4 inline-flex">
                    <div className=" justify-start items-start gap-4 inline-flex">
                      {passwordCheck1 ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2599_150846)">
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                              fill="#48A966"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2599_150846">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2599_150829)">
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                              fill="#CACACA"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2599_150829">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <div className="grow shrink basis-0 text-zinc-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                        Use a capital letter in the password
                      </div>
                    </div>
                    <div className=" justify-start items-start gap-4 inline-flex">
                      {passwordCheck2 ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2599_150846)">
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                              fill="#48A966"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2599_150846">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2599_150829)">
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                              fill="#CACACA"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2599_150829">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <div className="text-zinc-500 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                        Password must be 6 or more characters
                      </div>
                    </div>
                    <div className=" justify-start items-start gap-4 inline-flex">
                      {passwordCheck3 ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2599_150846)">
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                              fill="#48A966"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2599_150846">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2599_150829)">
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                              fill="#CACACA"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2599_150829">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <div className="grow shrink basis-0 text-zinc-500 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                        Password must have at least one symbol
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-[343px] h-[90px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-[#9E9E9E] text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    Mobile Number
                    <label className="text-[#D41E79]">&nbsp;*</label>
                  </div>
                  <div className="w-full flex gap-4 items-center justify-start z-10">
                    <CountrySelector
                      setValue={SetCountryCode}
                      value={countryCode}
                    />
                    <PhoneInput
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleRegister()
                        }
                      }}
                      countryCode={countryCode}
                      phoneNumber={phone}
                      setPhoneNumber={setPhone}
                    />
                  </div>
                  {phone?.length !== 10 && phone !== "" && (
                    <p className="text-[10px] -mt-2 text-start w-full text-red-600">
                      Invalid phone number
                    </p>
                  )}
                </div>

                <Checkbox
                  className=""
                  checked={isChecked}
                  onChange={onCheckboxChange}
                  required
                >
                  <div className="text-start text-zinc-600 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                    I agree to the{" "}
                    <span
                      className="text-zinc-600 text-base font-medium font-['Eudoxus Sans'] underline leading-normal tracking-tight"
                      onClick={() => {
                        setRegisterPage(99);
                      }}
                    >
                      Terms of Service & Privacy Policy.
                    </span>
                  </div>
                </Checkbox>
              </div>
              <div className="gap-4 flex flex-col items-center pt-5 pb-6">
                <CustomButton
                  loading={isLoading}
                  handleClick={() => {
                    handleRegister();
                  }}
                  disabled={
                    isChecked === false ||
                    fullName === "" ||
                    email === "" ||
                    phone.length !== 10 ||
                    password.length < 6 ||
                    (radioValue == "student" && errors.school)
                  }
                >
                  Continue
                </CustomButton>
              </div>
            </div>
          )}
          {registerPage === 99 && (
            <div className="flex flex-col gap-7 items-start h-full justify-between pt-5 pb-[18px] px-4 overflow-y-scroll no-scrollbar">
              <div
                className="h-7 justify-start items-center gap-2 inline-flex cursor-pointer"
                onClick={() => {
                  setRegisterPage(1);
                }}
              >
                <img src={arrowBackBlack} className="w-6 h-6" />
                <div className="justify-start items-start gap-2 flex">
                  <div className="text-[#292929] text-xl font-bold font-['Eudoxus Sans'] leading-7 tracking-tight">
                    Terms of Service
                  </div>
                </div>
              </div>
              <div className="w-[343px] text-start text-[#868686] text-lg font-medium font-['Eudoxus Sans'] leading-relaxed tracking-tight">
                We really protect the privacy and security on devices and
                integrations used by our users
              </div>
              <div className="h-[234px] flex-col justify-start items-start gap-4 inline-flex">
                <div className="text-[#292929] text-lg  font-['Eudoxus Sans'] leading-relaxed tracking-tight">
                  Terms title
                </div>
                <div className="text-start  text-[#757575] text-[15px] font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent sagittis leo diam, ut tincidunt diam fermentum et.
                  Mauris a magna massa. In fringilla elit eu tortor fermentum,
                  nec elementum nibh suscipit. Aenean viverra sodales augue sed
                  bibendum. Sed molestie fermentum sem, vel feugiat magna
                  ullamcorper sit amet.
                </div>
              </div>
              <div className="h-[234px] flex-col justify-start items-start gap-4 inline-flex">
                <div className="text-[#292929] text-lg font-['Eudoxus Sans'] leading-relaxed tracking-tight">
                  Terms title
                </div>
                <div className="text-start  text-[#757575] text-[15px] font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent sagittis leo diam, ut tincidunt diam fermentum et.
                  Mauris a magna massa. In fringilla elit eu tortor fermentum,
                  nec elementum nibh suscipit. Aenean viverra sodales augue sed
                  bibendum. Sed molestie fermentum sem, vel feugiat magna
                  ullamcorper sit amet.
                </div>
              </div>
              <div className="h-[234px] flex-col justify-start items-start gap-4 inline-flex">
                <div className="text-[#292929] text-lg  font-['Eudoxus Sans'] leading-relaxed tracking-tight">
                  Terms title
                </div>
                <div className="text-start  text-[#757575] text-[15px] font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent sagittis leo diam, ut tincidunt diam fermentum et.
                  Mauris a magna massa. In fringilla elit eu tortor fermentum,
                  nec elementum nibh suscipit. Aenean viverra sodales augue sed
                  bibendum. Sed molestie fermentum sem, vel feugiat magna
                  ullamcorper sit amet.
                </div>
              </div>
              <div className="gap-4 flex flex-col items-center pt-5 pb-6 self-center">
                <CustomButton
                  handleClick={() => {
                    setIsChecked(true);
                    setRegisterPage(1);
                  }}
                >
                  I agree with the Terms
                </CustomButton>
              </div>
            </div>
          )}
          {registerPage === 2 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[27px] pb-[18px] px-4 ">
              <div className="w-[343px] text-center text-zinc-800 text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                We have sent you a verification code to your email
              </div>
              <div className="w-[343px] text-center">
                <span className="text-neutral-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  Waiting for email verification for{" "}
                </span>
                <span className="text-sky-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                  {email}
                </span>
              </div>
              {/* <CustomButton handleClick={handleNext}>Next</CustomButton> */}
            </div>
          )}
          {registerPage === 3 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[27px] pb-[18px] px-4 ">
              <div className="flex flex-col gap-8 w-full">
                <div className="text-[#292929] text-xl font-bold font-['Eudoxus Sans'] text-center">
                  We have sent you a <br />
                  Verification code to your email
                </div>
                <div className="text-[#757575] text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight text-center">
                  {email}
                </div>
                <div className="h-[50px] w-full">
                  <OtpInput otp={otp} setOtp={setOtp} />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <CustomButton
                  loading={isLoading}
                  handleClick={verifyOtp}
                  disabled={joinOtp(otp).length !== 6 || isGenerateOtpLoading}
                >
                  Verify
                </CustomButton>
                <CustomButton
                  type="light"
                  customClassName="relative"
                  disabled={enableResendOtp === false || isLoading}
                  handleClick={() => {
                    generateOtp(email)
                  }}
                  loading={isGenerateOtpLoading}
                >
                  Send again
                  {!enableResendOtp && (
                    <div className="absolute top-1/2 -translate-y-1/2 right-[30px]">
                      <LoaderWithText
                        initialTime={initialTime}
                        restart={enableResendOtp === false || isLoading}
                        onComplete={() => {
                          setEnableResendOtp(true);
                        }}
                      />
                    </div>
                  )}
                </CustomButton>
              </div>
            </div>
          )}
          {registerPage === 4 && (
            <div className="flex flex-col items-center h-full justify-evenly pt-[20px] pb-[18px] px-4 ">
              <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans'] ">
                Add your profile picture
              </div>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {preview ? (
                <img
                  onClick={handleUploadButtonClick}
                  src={preview}
                  alt="Profile Preview"
                  className="w-[163px] h-[163px] rounded-[90px] "
                />
              ) : (
                <div
                  className="w-[163px] h-[163px] bg-[#d41e79] rounded-[90px] justify-center items-center inline-flex"
                  onClick={handleUploadButtonClick}
                >
                  {preview?
                  <div className="rounded-full size-[163px]">
                    <image src={preview}/>
                  </div>
                  :<div className="text-center text-white text-5xl font-bold font-['Eudoxus Sans'] ">
                    {fullName.charAt(0).toUpperCase()}
                  </div>}
                </div>
              )}
              <div className="text-center text-[#868686] text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                Profile file must be JPG/PNG
                <br />
                max. 4mb, tap image to change
              </div>
              <div className="gap-4 flex flex-col items-center">
                {preview ? (
                  <CustomButton
                    loading={isLoading}
                    handleClick={() => {
                      handleUpdateDetails(image)
                    }}
                  >
                    Continue
                  </CustomButton>
                ) : (
                  <CustomButton
                    loading={isLoading}
                    handleClick={handleUploadButtonClick}
                    type="light"
                  >
                    Add Profile Picture
                  </CustomButton>
                )}
                <CustomButton
                  type="light"
                  handleClick={() => {
                    navigate("/scripts");
                  }}
                >
                  Skip
                </CustomButton>
              </div>
            </div>
          )}
          {/* {false && (
            <div className="flex flex-col items-center h-full justify-between pt-[27px] pb-[18px] px-4 ">
              <div className="flex flex-row justify-between w-full items-center  lg:hidden">
                <img className="h-12" src={morphosys} />
                <div className="w-[104px] h-10 p-2 bg-neutral-100 rounded justify-start items-center gap-2 inline-flex">
                  <img className="w-6 h-4" src={englishFlag} />
                  <div className="text-zinc-800 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                    English
                  </div>
                </div>
              </div>
              <div className="w-[319px] text-center text-zinc-800 text-xl font-medium font-['Eudoxus Sans'] leading-7 tracking-tight">
                Setup password for your account security
              </div>
              <div className="flex flex-col gap-6">
                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex mb-2">
                  <div className="text-zinc-500 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    New Password
                  </div>
                  <input
                    value={password}
                    onChange={(e) => {
                      setPasswordCheck1(/^(?=.*[A-Z]).*$/.test(e.target.value));
                      setPasswordCheck2(/^.{6,}$/.test(e.target.value));
                      setPasswordCheck3(
                        /^(?=.*[!@#$%^&*]).*$/.test(e.target.value)
                      );

                      console.log(e.target.value);
                      setPassword(e.target.value);
                    }}
                    type="password"
                    className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                    placeholder="Enter password"
                    required
                  />
                </div>
                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex mb-2">
                  <div className="text-zinc-500 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    Confirm Password
                  </div>
                  <input
                    value={confirmPassword}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setConfirmPassword(e.target.value);
                    }}
                    type="password"
                    className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                    placeholder="Enter password"
                    required
                  />
                </div>
              </div>
              <div className=" h-32 flex-col justify-start items-start gap-4 inline-flex">
                <div className=" justify-start items-start gap-4 inline-flex">
                  {passwordCheck1 ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150846)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#48A966"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150846">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150829)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#CACACA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150829">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <div className="grow shrink basis-0 text-zinc-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                    Use a capital letter at the beginning
                  </div>
                </div>
                <div className=" justify-start items-start gap-4 inline-flex">
                  {passwordCheck2 ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150846)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#48A966"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150846">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150829)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#CACACA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150829">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <div className="text-zinc-500 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                    Password must be 6 or more Character
                  </div>
                </div>
                <div className=" justify-start items-start gap-4 inline-flex">
                  {passwordCheck3 ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150846)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#48A966"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150846">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2599_150829)">
                        <path
                          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                          fill="#CACACA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2599_150829">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <div className="grow shrink basis-0 text-zinc-500 text-base font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                    Password must have at least one symbol
                  </div>
                </div>
              </div>
              <div className="gap-4 flex flex-col items-center">
                <CustomButton
                  handleClick={handleRegister}
                  disabled={
                    passwordCheck1 === false ||
                    passwordCheck2 === false ||
                    passwordCheck3 === false ||
                    confirmPassword === ""
                  }
                  loading={isLoading}
                >
                  Create account
                </CustomButton>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Register;
