import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scenes: [],
  characters: [],
  isEditorFocus: false,
  replaceAlexContent: null,
  currentDocumentType: null,
  notes: [],
  editorRef: null,
  revisions: null,
  content: null,
};

const scriptSlice = createSlice({
  name: "script",
  initialState,
  reducers: {
    setScenes: (state, action) => {
      state.scenes = action.payload;
    },
    setCharacters: (state, action) => {
      state.characters = action.payload;
    },
    setEditorFocus: (state, action) =>{
      state.isEditorFocus = action.payload
    },
    setAlexContent: (state, action) =>{
      state.replaceAlexContent = action.payload
    },
    setCurrentDocumentType: (state, action) => {
      state.currentDocumentType = action.payload
    },
    setNotes: (state, action) => {
      state.notes = action.payload
    },
    setEditor: (state, action) => {
      state.editorRef = action.payload
    },
    setRevisions: (state, action) => {
      state.revisions = action.payload
    },
    setContent: (state, action) => {
      state.content = action.payload
    }
  },
});

export const { setScenes,setEditorFocus,setCharacters, setAlexContent, setCurrentDocumentType, setNotes, setEditor, setRevisions, setContent } = scriptSlice.actions;
export default scriptSlice.reducer;
