import React, { useState } from "react";
import iconSmall from "../../assets/icon-small.svg";
import scriptActive from "../../assets/blue_script_icon.svg";
import scriptInactive from "../../assets/grey_script_icon.svg";
import ProfileActive from "../../assets/blue_profile_icon.svg";
import ProfileInactive from "../../assets/grey_profile_icon.svg";

const Dashboard = () => {
  const [tab, setTab] = useState("script");
  return (
    <>
      <div className="flex flex-col justify-between h-full ">
        <div className="z-20  w-full p-[18px] flex-row flex justify-between items-center m-auto ">
          <div className="justify-start items-center gap-[10px] inline-flex  px-4 rounded-full cursor-pointer">
            <img src={iconSmall} className="w-[35px] h-[35px]" />
            <div className="text-white text-2xl font-medium font-logo leading-loose">
              Morphosys
            </div>
          </div>
        </div>

        <div
          className={`h-[86%] rounded-tl-3xl rounded-tr-3xl  w-full bg-white  z-20 lg:rounded-xl overflow-y-scroll no-scrollbar`}
        >
          <div className="flex flex-col items-center h-full justify-between pt-[27px] ">
            <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans'] m-auto">
              Dashboard
            </div>
            <div className="w-full px-1 pt-3 lg:pb-4  bg-white shadow-inner justify-around items-start inline-flex cursor-pointer relative">
              <div
                className={`w-full flex-col justify-start items-center gap-2 inline-flex ${
                  tab === "script" ? "text-[#0099ff]" : "text-[#aaaaaa]"
                }`}
                onClick={() => {
                  setTab("script");
                }}
              >
                <img
                  className="w-6 h-6"
                  src={tab === "script" ? scriptActive : scriptInactive}
                />
                <div className=" text-xs font-bold font-['Eudoxus Sans'] leading-tight">
                  Scripts
                </div>
              </div>

              <div className="h-[65px] w-[65px] rounded-full bg-[#0099ff] absolute top-[-1rem] flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 24 24"
                  fill="#FFFFFF"
                  className="h-[35%] w-[35%]"
                >
                  <path
                    fill-rule="evenodd"
                    d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                  ></path>
                </svg>
              </div>

              <div
                className={`w-full flex-col justify-start items-center gap-2 inline-flex ${
                  tab === "profile" ? "text-[#0099ff]" : "text-[#aaaaaa]"
                }`}
                onClick={() => {
                  setTab("profile");
                }}
              >
                <img
                  className="w-6 h-6"
                  src={tab === "profile" ? ProfileActive : ProfileInactive}
                />
                <div className=" text-xs font-medium font-['Eudoxus Sans'] leading-tight">
                  Profile
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
