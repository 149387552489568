import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setContent, setNotes } from "../../../store/scriptSlice";
import messageBubble from "../../../assets/messages-bubble.png";
import { toast } from "react-toastify";
import { MdDelete, MdDeleteOutline, MdEdit } from "react-icons/md";
import EmptyPage from "../Editor/sidepanel/EmptyPage";
import { getParentPElement } from "../Editor/utils/formatUtils";
import { getAllNotes } from "./functions";
const NotesModal = ({ setIsScriptTabActive, searchVal, permissionToEdit }) => {
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false)
  const notes = useSelector((state) => state.script.notes);
  const editorRef = useSelector((state) => state.script.editorRef);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const dispatch = useDispatch();
  const scriptContent = useSelector((state) => state.script.content);


  const [form] = Form.useForm();

  const handleCancel = () => {
    setVisible(false);
  };

  const getCurrentTimeString = () => {
    const now = new Date();
    return `${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getDate()).padStart(2, '0')}/${String(now.getFullYear()).slice(-2)}`;
  };

  const handleEditSave = () => {
    form
      .validateFields()
      .then((values) => {
        const editor = editorRef;
        const body = editor.getBody();

        const noteId = selectedNoteId

        const selectedNode = body.querySelector(
          `p[data-note-id-${noteId}]`
        );

        const noteTime = getCurrentTimeString()
        const noteUser = JSON.parse(localStorage.getItem('user')).name
        const noteList = JSON.parse(selectedNode.getAttribute("data-note-list") || "null") || [];

        const updateIndex = noteList.findIndex((note) => note.noteId === noteId)

        noteList[updateIndex].text = values.notes
        noteList[updateIndex].user = noteUser
        noteList[updateIndex].time = noteTime

        selectedNode.setAttribute("data-note-list", JSON.stringify(noteList))
        getAllNotes(editorRef,dispatch)
        setVisible(false);
        editor.fire("change")

        form.resetFields();

      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  }

  const handleSave = () => {
    if (editVisible) {
      handleEditSave()
      return
    }
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        const range = editorRef.selection.getRng();
        const selectedNode = getParentPElement(editorRef.selection.getNode())
        const editor = editorRef;
        editor.selection.setRng(range);
        editor.focus();
        const element = editor.getBody();
        const noteId = notes.reduce((acc, note) => Math.max(acc, note.noteId), 0) + 1
        const noteTime = getCurrentTimeString()
        const noteUser = JSON.parse(localStorage.getItem('user')).name

        const noteList = JSON.parse(selectedNode.getAttribute("data-note-list") || "null") || []

        noteList.push({ noteId, text: values.notes, user: noteUser, time: noteTime })

        selectedNode.setAttribute(`data-note-id-${noteId}`, true)
        selectedNode.setAttribute("data-note-list", JSON.stringify(noteList))

        getAllNotes(editorRef,dispatch)

        range.collapse(false);
        editor.selection.setRng(range);
        editor.focus();

        editor.save();


        scrollToNoteInIframe(noteId)
        setVisible(false);
        editor.fire("change")
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const removePreviousActiveNotes = (body) => {
    body.childNodes.forEach(node => {
      if (node.getAttribute("data-active-note")) {
        node.removeAttribute("data-active-note")
      }
    });
  }

  const scrollToNoteInIframe = (noteId,sceneIndex) => {
    setIsScriptTabActive(true)
    const editor = editorRef;

    if (!editor) {
      console.warn("Editor not found.");
      return;
    }


    const body = editor.getBody();

    const notePara = body.querySelector(
      `p[data-note-id-${noteId}]`
    );

    const scene = body.querySelector(
      `.sceneHeadings[data-scene-index='${sceneIndex}']`
  );

    if (notePara) {
      removePreviousActiveNotes(body)
      notePara.setAttribute('data-active-note', "true")
      if (scene) {
        setTimeout(() => {
          scene.scrollIntoView({ behavior: "smooth" });
        }, 0)
        
    }
      
      // notePara.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      console.warn(`Note with ID ${noteId} not found.`);
    }
  };

  const handleDeleteNote = (noteId) => {
    const editor = editorRef;
    const body = editor.getBody();
    const notePara = body.querySelector(
      `p[data-note-id-${noteId}]`
    );
    const range = editor.selection.getRng();


    if (notePara) {
      const noteList = JSON.parse(notePara.getAttribute('data-note-list') || "null") || []
      const newNoteList = noteList.filter(note => note.noteId !== noteId)

      notePara.setAttribute('data-note-list', JSON.stringify(newNoteList))
      notePara.removeAttribute(`data-note-id-${noteId}`)
      if (newNoteList.length === 0) {
        notePara.removeAttribute('data-note-list')
      }
    }
    removePreviousActiveNotes(body)
    getAllNotes(editorRef,dispatch)

    // Update the content of the editor
    editor.save();
    editor.fire("change")
    setDeleteModalVisible(false);
  };

  console.log('notes', notes)



  const filteredList = searchVal && searchVal !== "" ?
    notes.slice().filter(note => note.text.toLowerCase().includes(searchVal.toLowerCase()) || note.user.toLowerCase().includes(searchVal.toLowerCase()))
    : notes

  return (
    <>

      <div className="max-h-full overflow-auto p-3 pt-0">
        {notes.length === 0 && <EmptyPage title={"You dont have any notes yet"} />}
        {notes.length !== 0 && filteredList.length === 0 &&
          <div className='mt-10'>

            <EmptyPage title={"No results found"} subtitle={"Try searching for another note"} /> :

          </div>}
        {filteredList.map((note, index) => (
          <button
            key={index}
            className="mt-2 w-full focus:shadow-lg text-start h-fit group cursor-pointer relative border shadow-sm p-2 text-[12px] rounded-lg"
            onClick={() => {
              scrollToNoteInIframe(note.noteId,note.scene);
            }}
          >
            <div className="flex justify-between text-[11px] text-[#868686] items-center">
              <p>{note.user}</p>
              <p>Scene {note.scene}</p>
            </div>

            <div className=" flex justify-between items-center">
              <p className="text-[#292929] break-all">{note.text}</p>
            </div>
            <div className="flex justify-between items-end">
              <div />
              <div className="flex justify-center gap-2 items-center mt-2">
                {permissionToEdit &&
                  <button
                    className="rounded-full text-[#0099ff] w-[56px] bg-[#F5FBFF] text-[12px] px-2 py-1 disabled:saturate-0"
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelectedNoteId(note.noteId);
                      form.setFieldValue("notes", note.text)
                      setEditVisible(true)
                      setVisible(true)
                    }}
                  >
                    Edit
                  </button>
                }

                {permissionToEdit &&
                  <button
                    className="rounded text-[#868686] text-[12px] px-2 py-1 disabled:saturate-0 "
                    disabled={false}
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelectedNoteId(note.noteId);
                      setDeleteModalVisible(true);
                    }}
                  >
                    Delete
                  </button>
                }
              </div>
              <div className="flex justify-between text-[11px] text-[#868686] items-center">
                <p>{note.time}</p>
              </div>
            </div>



          </button>
        ))}
      </div>

      <Modal
        title="Save Note"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="notes_form">
          <Form.Item
            name="notes"
            label="Notes"
            rules={[{ required: true, message: "Please input your note!" }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Delete Note"
        open={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={() => handleDeleteNote(selectedNoteId)}
      >
        <p>Are you sure you want to delete this note?</p>
      </Modal>
    </>
  );
};

export default NotesModal;
