import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAccessToken } from '../../../services/authService'
import { toast } from 'react-toastify'
import LoaderSpin from '../../Common/LoaderSpin'
import { MdDelete } from 'react-icons/md'

const CharactersModal = ({ open, setOpen }) => {
  const close = () => setOpen(false)
  const { id } = useParams()
  const [characterList, setCharacterList] = useState([])
  const [updateList, setUpdateList] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const fetchCharacterList = async () => {
    setLoading(true)
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/character?documentId=${id}`
    try {
      if (!token) {
        throw new Error('A valid session not found')
      }
      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      const body = await res.json()
      if (!res.ok) {
        throw new Error('Error getting response', body)
      }
      const data = body.data
      setCharacterList(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
      toast.error('Error fetching characters')
    }
  }

  const deleteCharacter = async (charID) => {
    setLoading(true)
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/character/${charID}`
    try {
      if (!token) {
        throw new Error('A valid session not found')
      }
      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        method: 'DELETE'
      })

      const body = await res.json()
      if (!res.ok) {
        throw new Error('Error getting response', body)
      }
      const data = body.data
      setUpdateList(prev => !prev)
    } catch (error) {
      setLoading(false)
      console.error(error)
      toast.error('Error fetching characters')
    }
  }

  useEffect(() => {
    if (open)
      fetchCharacterList()
  }, [updateList, open])

  return (
    <Modal open={open} onCancel={close} footer={false} title={"Character List"}>
      {isLoading ? <LoaderSpin /> :
        <div className='flex w-full flex-col gap-2 text-[12px]'>
          {characterList.length === 0 &&
            <>
              <p className='font-bold text-gray-400'>No saved characters found</p>
              <p className=' text-[12px] text-gray-400'>Save a character to see here</p>
            </>}
          {characterList.map((character, i) =>
            <button className='border cursor-default group rounded-lg flex text-start p-2 justify-between items-center'>
              {character.name}
              <button onClick={() => deleteCharacter(character._id)}
                className='text-red-400 hover:scale-110 transition-all opacity-0 group-hover:opacity-100  '>
                <MdDelete size={18} />
              </button>
            </button>
          )}
        </div>}
    </Modal>
  )
}

export default CharactersModal