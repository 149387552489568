import { toast } from "react-toastify"
import { getAccessToken } from "../../../../services/authService"

export const saveContent = async (editor, scriptData, onComplete = () => { }, scenes = 0, newContent) => {
    if (!newContent && !editor ) {
        console.error("content not found")
        return
    }
    //cannot save empty conent
    const content = editor?.getContent() || newContent || '<p></p>'
    const { author,
        basedOn,
        copyright,
        episodeNumber,
        episodeTitle,
        status,
        title,
        type, ...rest } = scriptData

    const data = {
        author,
        basedOn,
        content,
        copyright,
        episodeNumber,
        episodeTitle,
        status,
        scenes,
        title,
        type
    }
    await saveScript(data, scriptData._id, onComplete)
}

export const updateStatus = async (scriptData, status, onComplete = () => { }) => {
    const { author,
        basedOn,
        copyright,
        episodeNumber,
        episodeTitle,
        content,
        title,
        type, ...rest } = scriptData

    const data = {
        author,
        basedOn,
        status,
        copyright,
        episodeNumber,
        episodeTitle,
        status,
        title,
        type
    }
    await saveScript(data, scriptData._id, onComplete)
}

export const saveScript = async (data, id, onComplete) => {
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
    try {
        if (!token) {
            throw new Error('A valid session not found')
        }
        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            method: 'PUT',
            body: JSON.stringify(data)

        })
        const body = await res.json()
        if (!res.ok) {
            console.error('res', res, body)
        }
        onComplete()

    } catch (error) {
        console.error(error)
    }
}

export const handleFileInput = async (e) => {

    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)

    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/import-document`
    try {
        if (!token) {
            throw new Error('A valid session not found')
        }

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        })
        const body = await res.json()
        if (!res.ok) {
            console.error(res)
            throw new Error(body.message ? body.message : 'Error uploading file')
        }
        toast.success('File imported sucessfully')
        return body

    } catch (error) {
        toast.error(error.message)
    }
}

export const saveScriptAs = async (scriptData, title, onComplete = () => { }) => {
    const { author,
        basedOn,
        copyright,
        episodeNumber,
        episodeTitle,
        content,
        byline,
        scenes,
        status,
        type, ...rest } = scriptData

    const data = {
        author,
        basedOn,
        status,
        byline,
        copyright,
        episodeNumber,
        episodeTitle,
        content,
        scenes,
        title,
        type
    }
    await createScript(data, onComplete)
}

const createScript = async (data, onComplete) => {
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/document`
    try {
        if (!token) {
            throw new Error('A valid session not found')
        }
        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            body: JSON.stringify(data)

        })
        const body = await res.json()
        if (!res.ok) {
            console.error('res', res, body)
            throw new Error('')
        }
        onComplete()

    } catch (error) {
        toast.error('Failed to create script')
        console.error(error)
    }
}
export const editFindAndReplace = () => {
    const replaceNotificationStringWithWord = () => {
        const notification = document.querySelector(".tox-notification__body");
        const text = notification?.textContent
        notification.textContent = text?.replace("string", "word")
    }
    const findbutton = document.querySelector(".tox-dialog__footer-end");

    if (findbutton) {
        const handleClick = () => {
            setTimeout(() => {
                replaceNotificationStringWithWord();
            }, 0);
        };

        findbutton.addEventListener("click", handleClick);
        findbutton.addEventListener("touchstart", handleClick);
        findbutton.addEventListener("touchend", handleClick);
    }
}

export const parseToBody = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body;
};

// Take body element and return string of child nodes
export const bodyToString = (body) => {
    return Array.from(body.children)
        .map(node => node.outerHTML)
        .join('\n');
};
