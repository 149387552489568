import ColumnGroup from "antd/es/table/ColumnGroup";

export const formatText = (editor, format) => {
  if (!editor) return;

  const selectedNodes = editor.selection.getSelectedBlocks();

  // If no specific blocks are selected, get the current node
  if (!selectedNodes.length) {
    const node = editor.selection.getNode();
    if (node && !editor.dom.isBlock(node) && node !== editor.getBody()) {
      editor.dom.setAttrib(node, 'class', format);
    }
    return;
  }

  // Apply format to all selected blocks except body
  selectedNodes.forEach(node => {
    if (node !== editor.getBody() && node.classList[0] !== "custom-pagebreak") {
      editor.dom.setAttrib(node, 'class', format);
    }
  });
};

export const findAndReplace = (editor, findText, replaceText, replaceAll = false) => {
  if (editor) {
    let content = editor.getContent();
    const regExp = new RegExp(findText, 'g');
    if (replaceAll) {
      content = content.replace(regExp, replaceText);
    } else {
      const firstMatch = content.indexOf(findText);
      if (firstMatch !== -1) {
        content = content.substring(0, firstMatch) + replaceText + content.substring(firstMatch + findText.length);
      }
    }
    editor.setContent(content);
  }
};

export const removeAlignment = (editor) => {
  if (editor.queryCommandState('JustifyLeft'))
    editor.execCommand('JustifyLeft');

  if (editor.queryCommandState('JustifyCenter'))
    editor.execCommand('JustifyCenter');

  if (editor.queryCommandState('JustifyRight'))
    editor.execCommand('JustifyRight');

  if (editor.queryCommandState('JustifyFull'))
    editor.execCommand('JustifyFull');
};

function removeBrackets(str) {
  str = str.trim();
  return str.replace(/^\(|\)$/g, '').trim();
}

function removeNumberFromStart(str) {
  str = str.trim();
  if (/^\d/.test(str)) {
    str = str.replace(/^\d+/, '').trim();
  }
  return str;
}

function fixBeforeFormat(currentFormat, trimmedLine, previousFormat) {

  if (currentFormat === 'parentheses') {
    const lineWithoutBrackets = removeBrackets(trimmedLine)
    return lineWithoutBrackets
  }
  if (currentFormat === 'sceneHeadings') {
    const lineWithoutNumber = removeNumberFromStart(trimmedLine)
    return lineWithoutNumber
  }
  return trimmedLine
}

export const getNextFormat = (docType, current) => {
  const formatSequences = {
    'Film Script': {
      '': 'sceneHeadings',
      'sceneHeadings': 'action',
      'action': 'character',
      'parentheses': 'dialogue',
      'character': 'dialogue',
      'dialogue': 'character',
      'transition': 'sceneHeadings',
      'flashBack': 'sceneHeadings',
      'montage': 'sceneHeadings',
    },
    'TV Script': {
      '': 'coldOpening',
      'coldOpening': 'sceneHeadings',
      'sceneHeadings': 'action',
      'action': 'character',
      'parentheses': 'dialogue',
      'character': 'dialogue',
      'dialogue': 'character',
      'transition': 'sceneHeadings',
      'flashBack': 'sceneHeadings',
      'montage': 'sceneHeadings',
    },
    'Treatment': {
      '': 'treatmentPara',
      'treatmentPara': 'treatmentPara'
    }
  };
  return formatSequences[docType][current] || '';
};

export const isSceneHeading = (line) => {
  const sceneKeywords = ['INT.', 'EXT.', 'INT/EXT.', 'EST.', 'INT', 'EXT'];
  return sceneKeywords.some(keyword => line.startsWith(keyword));
};

export const isCharacterName = (line) => {
  return /^[A-Z\s]{1,30}$/.test(line.trim()) && line.trim().split(' ').length <= 3;
};

const calculateSceneHeadingScore = (line, previousFormat) => {
  let score = 0;
  const trimmedLine = line.trim();

  const sceneHeadingRegex = /^(?:\d+\.)?\s*(INT\.|EXT\.|INT\/EXT\.|EST\.)\s+/i;
  if (sceneHeadingRegex.test(trimmedLine)) {
    score += 30;
  }

  if (trimmedLine === trimmedLine.toUpperCase()) {
    score += 20;
  }

  const locationTimeRegex = /(INT\.|EXT\.|INT\/EXT\.|EST\.)\s+.+\s+-\s+(DAY|NIGHT|MORNING|EVENING)$/i;
  if (locationTimeRegex.test(trimmedLine)) {
    score += 20;
  }

  const timeOfDayRegex = /-(\s)*(DAY|NIGHT|MORNING|EVENING)$/i;
  if (timeOfDayRegex.test(trimmedLine)) {
    score += 10;
  }

  if (trimmedLine.length <= 60) {
    score += 5;
  }

  const punctuationRegex = /^[^!@#$%^&*()_+={}\[\];:'"\\|<>,.?~`]+$/;
  if (punctuationRegex.test(trimmedLine.replace(/[\.\-]/g, ''))) {
    score += 5;
  }

  const numberingRegex = /^\d+\.\s*(INT\.|EXT\.|INT\/EXT\.|EST\.)/i;
  if (numberingRegex.test(trimmedLine)) {
    score += 5;
  }

  if (previousFormat !== 'character' && previousFormat !== 'dialogue') {
    score += 5;
  }

  return score;
};

const calculateCharacterIntroductionScore = (line, previousFormat) => {
  let score = 0;
  const trimmedLine = line.trim();

  const characterIntroRegex = /^[A-Z][A-Z0-9\s]*,\s*\d{1,3},\s+.+$/;
  if (characterIntroRegex.test(trimmedLine)) {
    score += 30;
  }

  const startsWithNameRegex = /^[A-Z][A-Z0-9\s]*,/;
  if (startsWithNameRegex.test(trimmedLine)) {
    score += 25;
  }

  const ageInfoRegex = /^[A-Z][A-Z0-9\s]*,\s*\d{1,3},/;
  if (ageInfoRegex.test(trimmedLine)) {
    score += 15;
  }

  if (characterIntroRegex.test(trimmedLine)) {
    score += 10;
  }

  if ((trimmedLine.match(/,/g) || []).length >= 2) {
    score += 10;
  }

  if (!/^[A-Z\s]+[:.]$/.test(trimmedLine) && !/^(?:\d+\.)?\s*(INT\.|EXT\.|INT\/EXT\.|EST\.)\s+/i.test(trimmedLine)) {
    score += 5;
  }

  if (previousFormat === 'sceneHeadings' || previousFormat === 'action') {
    score += 5;
  }

  return score;
};

const calculateCharacterNameScore = (line, previousFormat) => {
  let score = 0;
  const trimmedLine = line.trim();

  const isAllUppercase = trimmedLine === trimmedLine.toUpperCase();

  if (isAllUppercase) {
    score += 35;

    if (/^[A-Z][A-Z0-9\s]+$/.test(trimmedLine)) {
      score += 25;
    }

    if (previousFormat === 'action' || previousFormat === 'sceneHeading') {
      score += 15;
    }

    if (!/[.,!?;:'"()]/.test(trimmedLine)) {
      score += 5;
    }

    if (
      !/^[A-Z\s]+[:.]$/.test(trimmedLine) &&
      !/^(?:\d+\.)?\s*(INT\.|EXT\.|INT\/EXT\.|EST\.)\s+/i.test(trimmedLine)
    ) {
      score += 5;
    }
  } else {
    score = 0;
  }

  return score;
};

const calculateDialogueScore = (line, previousFormat) => {
  let score = 0;
  const trimmedLine = line.trim();

  if (previousFormat === 'character' || previousFormat === 'parentheses') {
    score += 35;

    if (trimmedLine !== trimmedLine.toUpperCase() && trimmedLine !== trimmedLine.toLowerCase()) {
      score += 25;
    }

    if (/[.,!?;:'"]/.test(trimmedLine)) {
      score += 10;
    }

    if (
      !/^(?:\d+\.)?\s*(INT\.|EXT\.|INT\/EXT\.|EST\.)/i.test(trimmedLine) &&
      !/^[A-Z][A-Z0-9\s]+$/.test(trimmedLine)
    ) {
      score += 5;
    }

    if (/[!?]/.test(trimmedLine)) {
      score += 5;
    }
  } else {
    score = 0;
  }

  return score;
};

const calculateParentheticalScore = (line, previousFormat) => {
  let score = 0;
  const trimmedLine = line.trim();

  const isEnclosedInParentheses = /^\(.*\)$/.test(trimmedLine);
  if (isEnclosedInParentheses) {
    score += 40;

    if (trimmedLine.length <= 50) {
      score += 25;
    }

    if (previousFormat === 'character') {
      score += 15;
    }

    if (trimmedLine !== trimmedLine.toUpperCase()) {
      score += 5;
    }
  }

  return score;
};

const calculateActionScore = (line, previousFormat) => {
  let score = 0;
  const trimmedLine = line.trim();

  if (trimmedLine !== trimmedLine.toUpperCase() && trimmedLine !== trimmedLine.toLowerCase()) {
    score += 30;
  }

  if (/\b(he|she|they|walks|runs|looks|turns|stands|sits|gazes|smiles|nods|is)\b/i.test(trimmedLine)) {
    score += 25;
  }

  if (/\b\w+ing\b/.test(trimmedLine)) {
    score += 15
  }

  if (previousFormat === 'sceneHeadings' || previousFormat === 'transition' || previousFormat === '') {
    score += 15;
  }

  if (!/^[A-Z][A-Z0-9\s]+$/.test(trimmedLine) && !/^(?:\d+\.)?\s*(INT\.|EXT\.|INT\/EXT\.|EST\.)/i.test(trimmedLine)) {
    score += 10;
  }

  return score;
};

const calculateTransitionScore = (line, previousFormat) => {
  let score = 0;
  const trimmedLine = line.trim();

  if (/^[A-Z\s]+[:.]$/.test(trimmedLine)) {
    score += 40;

    if (!/\s/.test(trimmedLine.replace(/[:.]$/, ''))) {
      score += 25;
    }
  }

  if (previousFormat === 'action' || previousFormat === '') {
    score += 15;
  }

  if (trimmedLine.length <= 20) {
    score += 10;
  }

  if (!/[!?]/.test(trimmedLine)) {
    score += 5;
  }

  return score;
};

const calculateTitlePageElementScore = (line) => {
  let score = 0;
  const trimmedLine = line.trim();

  if (/^[A-Z\s]+$/.test(trimmedLine)) {
    score += 30;
  }

  if (/^by$/i.test(trimmedLine) || /^(by\s+[A-Z][A-Z\s]*)$/i.test(trimmedLine)) {
    score += 20;
  }

  if (/^©\d{4}$/i.test(trimmedLine)) {
    score += 15;
  }

  if (!/[^\w\s©]/.test(trimmedLine)) {
    score += 5;
  }

  return score;
};

const calculateConfidenceScores = (line, previousFormat) => {
  const scores = {};
  const entityTypes = [
    'sceneHeadings',
    'characterIntroduction',
    'character',
    'parentheses',
    'dialogue',
    'action',
    'transition',
    'titlePageElement',
  ];

  scores['sceneHeadings'] = calculateSceneHeadingScore(line, previousFormat);
  scores['characterIntroduction'] = calculateCharacterIntroductionScore(line, previousFormat);
  scores['character'] = calculateCharacterNameScore(line, previousFormat);
  scores['dialogue'] = calculateDialogueScore(line, previousFormat);
  scores['parentheses'] = calculateParentheticalScore(line, previousFormat);
  scores['action'] = calculateActionScore(line, previousFormat);
  scores['transition'] = calculateTransitionScore(line, previousFormat);
  scores['titlePageElement'] = calculateTitlePageElementScore(line);

  let highestScore = 0;
  let bestMatch = 'action';
  for (const [entityType, score] of Object.entries(scores)) {
    if (score > highestScore) {
      highestScore = score;
      bestMatch = entityType;
    }
  }

  return bestMatch;
};

const updateFormattedContent = (formattedContent, currentFormat, fixedLine, previousFormat) => {
  if (/^\d+\.$/.test(fixedLine)) {
    // skip this line for pagenumber text like 1. or 2.
  }
  else if (previousFormat === 'action' && currentFormat === 'action' || previousFormat === 'dialogue' && currentFormat === 'dialogue') {
    const position = formattedContent.length - 4;
    formattedContent = formattedContent.slice(0, position) + ` ${fixedLine}` + formattedContent.slice(position);
  }
  else {
    formattedContent += `<p class="${currentFormat}">${fixedLine}</p>`;
  }
  return formattedContent
}

export const autoFormatScript = (content, documentType) => {
  const lines = content?.split('\n');

  let newContent = '';
  if (documentType === 'Treatment') {
    if (lines) {
      lines.forEach((line) => {
        const trimmedLine = line.trim();
        if (trimmedLine === '') {
          return
        }
        newContent += `<p class="treatmentPara">${trimmedLine}</p>`;
      })
    }
    return newContent;
  }


  let formattedContent = '';
  let previousFormat = '';
  if (lines) {
    lines.forEach((line) => {
      const trimmedLine = line.trim();
      if (trimmedLine === '') {
        return;
      }

      let currentFormat = '';

      if (documentType === 'Film Script' || documentType === 'TV Script') {
        currentFormat = calculateConfidenceScores(line, previousFormat);
        const fixedLine = fixBeforeFormat(currentFormat, trimmedLine, previousFormat)
        formattedContent = updateFormattedContent(formattedContent, currentFormat, fixedLine, previousFormat)

        previousFormat = currentFormat;
      } else {
        formattedContent += `<p class="treatmentPara">${trimmedLine}</p>`;
      }
    });
  }


  return formattedContent;
};

export const handleParenthesisChange = (editor) => {
  if (editor) {
    const selectedNode = editor.selection.getNode()

    if (selectedNode.classList.contains('parentheses')) {
      if (selectedNode.firstChild && selectedNode.firstChild.nodeName === 'BR') {
        const zeroWidthSpace = '\u200B';
        editor.dom.setHTML(selectedNode, zeroWidthSpace)
      }
    }
  }
}

export const getParentPElement = (childNode) => {
  let node = childNode

  while (node && node.nodeName.toLowerCase() !== 'p' && node.nodeName.toLowerCase() !== 'body') {
    node = node.parentNode;
  }

  return node && node.nodeName.toLowerCase() === 'p' ? node : childNode;
}

export const contentStyle = `

          body {
           font-family:  Courier, monospace;
           font-size: 12pt;
           sans-serif; padding: 0 ;
           margin: 5px; outline: none;
           padding: 0 16px;
          }

          body[data-show-notes]{
            
          }
          
        
          body[data-show-notes] p[data-note-list]:focus{
          border: solid 1px black
          }

        body[data-show-notes] p[data-note-list]::after {
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          line-height: 1px; 
          font-size: 11pt;
          content: "\\f08d";
          color: #292929;
          rotate: 45deg;
          position: relative;
          display: inline-block;
          left: 20px;
         }

        body[data-show-notes] p[data-active-note]{
          background: #e1f3fb
        }

        body[data-hide-placeholder]:not(.mce-visualblocks)::before {
  display: none;
}

          .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
            margin-left:18px;
            margin-top:0px;
          }

          .pageEnd { width:calc(100% + 10px);
            height:100px;
            background: #EAEEF2;
            margin-left: -5px;
          }
          
           .sceneHeadings::before {
              content: attr(data-scene-index);
              margin-left: -20px;
              margin-right: 12px;
            }

          .tox-tinymce{
            border-radius:0;
            }

          .coldOpening { 
            margin-left: auto;
            margin-right: auto;
            max-width: 40%;
            text-transform: uppercase;
            text-decoration: underline;
            text-align: center;
            margin-bottom: 90px;
            font-weight: bold;
            color: #555;
          }

          .sceneHeadings {
            font-weight: 900; 
            text-transform: uppercase; 
            color: #a52a2a; 
          }

          .sceneHeadings + .action{
            padding-top:0px;
          }
          .action {
            padding-top:16px;
            margin: 0;
            color: #555;
          }
          .character { 
            margin: 0;
            font-weight: bold; 
            text-align: center;
            padding-left: 15%;
            padding-right: 15%;
            text-transform: uppercase;
            color: #cd3333;
            margin-top: 1rem !important;
          }
          .extra-character,.non-speaking-character{
            display: none;
          }
          
          .parentheses {
            
            margin:0;
            text-align: center;
            padding-left: 15%;
            padding-right: 15%;
            font-style: italic;
            color: #f5785a;
            margin-top: 0;
            margin-bottom: 0; }

          .parentheses::before {
            content: "(";
          }
          .parentheses::after {
            content: ")";
          }
            .dialogue { 
            text-align: center;
            padding-left: 15%;
            padding-right: 15%;
            margin-bottom: 0;
            margin-top: 0;
            color: #555;
             }

          .custom-pagebreak {
            margin:0;
            padding:0;
            user-select: none;
            pointer-events: none;
            border: none;
            height: 40px;
            width: calc(100% + 40px);
            margin-left: -20px;
            background: #f5f5f5;
            position: relative;
          }

          .custom-pagebreak::after {
            content: attr(data-page);
           
            position: absolute;
            bottom: -70px;
            right: 50px;
            color: #888;
          }
          .custom-pagebreak::before {
            content: "CONTINUED.";
            
            position: absolute;
            bottom: -70px;
            left: 50px;
            color: #888;
          }
          
          .newAct { font-weight: bold; color: #00008B; }
          .endAct { font-weight: bold; color: #8B0000; }

          .transition { 
          text-transform: uppercase;
          text-align: right;
           color: #555; }

          .flashBack { 
            color: #555;
            text-transform: uppercase;
          }
          .montage { 
            color: #555;
            text-transform: uppercase;
          }
          .titlePage { font-weight: bold; text-align: center; color: #000080; }
          .genre { font-style: italic; color: #20B2AA; }
          .logline { font-style: italic; margin: 1em 0; color: #20B2AA; }
          .synopsis { margin: 1em 0; color: #4682B4; }
          .treatmentHeader { font-weight: bold; color: #1E90FF; text-transform: uppercase; }

          .treatmentPara {
            margin: 0px;
            margin-top: 16px;
             color: #585858 ; 
            }

@media screen and (min-width: 800px) {
             p{
              padding-left: 133.6px;
              padding-right: 95.5px;
              }
.custom-pagebreak {
            margin:0;
            padding:0;
            user-select: none;
            pointer-events: none;
            border: none;
            height: 40px;
            width: calc(100% + 32px);
            margin-left: -16px;
            background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
            position: relative;
          }

          body[data-show-notes] p[data-note-id]::after {
          right: 2%;
}
            .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
              margin-left:133.6px;
              margin-top:18px;
            }
             

               .custom-pagebreak {
            margin:0;
            padding:0;
            user-select: none;
            pointer-events: none;
            border: none;
            border-left: solid 16px white;
            border-right: solid 16px white;
            margin-top: 25mm;
            margin-bottom: 25mm;
            height: 40px;
            width: calc(100% - 1px);
            margin-left: -15px;
            background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
            position: relative;
          }
            
            body{
              width: 794.993px;
              margin: 20px auto;
              margin-bottom: 0;
              padding:0;
              padding-top: 25mm;  
              background: white;
              z-index: 1;
              border: 1px solid #dedede;
              box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
            }
            .mce-pagebreak{
            user-selects:none;
              border: none;
              border-left: solid 4mm white;
              border-right: solid 4mm white;
              margin-top: 25mm;
              margin-bottom: 25mm;
              height: 100px;
              width: 210mm;
              margin-left: -29mm;
              background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
              box-shadow: 50px 50px white;
            }

            .sceneHeadings::before {
                content: attr(data-scene-index);
                margin-left: -50px;
                margin-right: 42px;
            }

            .coldOpening { 
            text-decoration: underline;
            text-align: center;
            margin-bottom: 90px;
            font-weight: bold;
            color: #555;
          }

          .sceneHeadings {
            font-weight: 900; 
            text-transform: uppercase; 
            color: #a52a2a; 
          }

          .action {
            margin: 0;
            color: #555;
          }
          .character {
             text-align: center;
            padding: 0;
            width: 266.484px;
            font-weight: bold;
            padding-left: 43.556px;
            padding-right: 47.7px;
            margin-left: 222.594px;
            margin-right: auto;
            text-transform: uppercase;
            color: #cd3333;
            line-height:1.1em;
          }
            
          .parentheses {
          text-align: start;
            width: 41.5%;
            margin-left: 28%;
            padding-left: 9%;
            font-style: italic;
            color: #f5785a;
            margin-top: 0;
            line-height:1.1em;
          }

          .parentheses::before {
            content: "(";
          }
          .parentheses::after {
            content: ")";
          }
          .dialogue { 
          text-align: start;
          margin: 0;
          padding: 0;
            width: 45%;
            margin-left: 28%;
            margin-top: 0;
            color: #555;
            line-height:1.1em;
             }
          .newAct { font-weight: bold; color: #00008B; }
          .endAct { font-weight: bold; color: #8B0000; }

          .transition { 
          text-align: right;
           color: #555; }

          .flashBack { 
            color: #555;
            text-transform: uppercase;
          }
          .montage { 
            color: #555;
            text-transform: uppercase;
          }
          .titlePage { font-weight: bold; text-align: center; color: #000080; }
          .genre { font-style: italic; color: #20B2AA; }
          .logline { font-style: italic; margin: 1em 0; color: #20B2AA; }
          .synopsis { margin: 1em 0; color: #4682B4; }
          .treatmentHeader { font-weight: bold; color: #1E90FF; text-transform: uppercase; }
          }
        `
// const backupStyle  = `

// body {
//  font-family:  Courier, monospace;
//  font-size: 12pt;
//  sans-serif; padding: 0 ;
//  margin: 5px; outline: none;
//  padding: 0 16px;
// }

// body[data-show-notes]{
  
// }


// body[data-show-notes] p[data-note-list]:focus{
// border: solid 1px black
// }

// body[data-show-notes] p[data-note-list]::after {
// font-family: "Font Awesome 6 Free";
// font-weight: 900;
// line-height: 1px; 
// font-size: 11pt;
// content: "\\f08d";
// color: #292929;
// rotate: 45deg;
// position: relative;
// display: inline-block;
// left: 20px;
// }

// body[data-show-notes] p[data-active-note]{
// background: #e1f3fb
// }

// body[data-hide-placeholder]:not(.mce-visualblocks)::before {
// display: none;
// }

// .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
//   margin-left:18px;
//   margin-top:0px;
// }

// .pageEnd { width:calc(100% + 10px);
//   height:100px;
//   background: #EAEEF2;
//   margin-left: -5px;
// }

//  .sceneHeadings::before {
//     content: attr(data-scene-index);
//     margin-left: -20px;
//     margin-right: 12px;
//   }

// .tox-tinymce{
//   border-radius:0;
//   }

// .coldOpening { 
//   margin-left: auto;
//   margin-right: auto;
//   max-width: 40%;
//   text-transform: uppercase;
//   text-decoration: underline;
//   text-align: center;
//   margin-bottom: 90px;
//   font-weight: bold;
//   color: #555;
// }

// .sceneHeadings {
//   font-weight: 900; 
//   text-transform: uppercase; 
//   color: #a52a2a; 
// }

// .sceneHeadings + .action{
//   padding-top:0px;
// }
// .action {
//   padding-top:16px;
//   margin: 0;
//   color: #555;
// }
// .character { 
//   margin: 0;
//   font-weight: bold; 
//   text-align: center;
//   padding-left: 15%;
//   padding-right: 15%;
//   text-transform: uppercase;
//   color: #cd3333;
//   margin-top: 1rem !important;
// }
// .extra-character,.non-speaking-character{
//   display: none;
// }

// .parentheses {
  
//   margin:0;
//   text-align: center;
//   padding-left: 15%;
//   padding-right: 15%;
//   font-style: italic;
//   color: #f5785a;
//   margin-top: 0;
//   margin-bottom: 0; }

// .parentheses::before {
//   content: "(";
// }
// .parentheses::after {
//   content: ")";
// }
//   .dialogue { 
//   text-align: center;
//   padding-left: 15%;
//   padding-right: 15%;
//   margin-bottom: 0;
//   margin-top: 0;
//   color: #555;
//    }

// .custom-pagebreak {
//   margin:0;
//   padding:0;
//   user-select: none;
//   pointer-events: none;
//   border: none;
//   height: 40px;
//   width: calc(100% + 40px);
//   margin-left: -20px;
//   background: #f5f5f5;
//   position: relative;
// }

// .custom-pagebreak::after {
//   content: attr(data-page);
 
//   position: absolute;
//   bottom: -70px;
//   right: 50px;
//   color: #888;
// }
// .custom-pagebreak::before {
//   content: "CONTINUED.";
  
//   position: absolute;
//   bottom: -70px;
//   left: 50px;
//   color: #888;
// }

// .newAct { font-weight: bold; color: #00008B; }
// .endAct { font-weight: bold; color: #8B0000; }

// .transition { 
// text-transform: uppercase;
// text-align: right;
//  color: #555; }

// .flashBack { 
//   color: #555;
//   text-transform: uppercase;
// }
// .montage { 
//   color: #555;
//   text-transform: uppercase;
// }
// .titlePage { font-weight: bold; text-align: center; color: #000080; }
// .genre { font-style: italic; color: #20B2AA; }
// .logline { font-style: italic; margin: 1em 0; color: #20B2AA; }
// .synopsis { margin: 1em 0; color: #4682B4; }
// .treatmentHeader { font-weight: bold; color: #1E90FF; text-transform: uppercase; }

// .treatmentPara {
//   margin: 0px;
//   margin-top: 16px;
//    color: #585858 ; 
//   }

// @media screen and (min-width: 800px) {
//    p{
//     padding-left: 133.6px;
//     padding-right: 95.5px;
//     }
// .custom-pagebreak {
//   margin:0;
//   padding:0;
//   user-select: none;
//   pointer-events: none;
//   border: none;
//   height: 40px;
//   width: calc(100% + 32px);
//   margin-left: -16px;
//   background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
//   position: relative;
// }

// body[data-show-notes] p[data-note-id]::after {
// right: 2%;
// }
//   .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
//     margin-left:133.6px;
//     margin-top:18px;
//   }
   

//      .custom-pagebreak {
//   margin:0;
//   padding:0;
//   user-select: none;
//   pointer-events: none;
//   border: none;
//   border-left: solid 16px white;
//   border-right: solid 16px white;
//   margin-top: 25mm;
//   margin-bottom: 25mm;
//   height: 40px;
//   width: calc(100% - 1px);
//   margin-left: -15px;
//   background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
//   position: relative;
// }
  
//   body{
//     width: 794.993px;
//     margin: 20px auto;
//     margin-bottom: 0;
//     padding:0;
//     padding-top: 25mm;  
//     background: white;
//     z-index: 1;
//     border: 1px solid #dedede;
//     box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
//   }
//   .mce-pagebreak{
//   user-selects:none;
//     border: none;
//     border-left: solid 4mm white;
//     border-right: solid 4mm white;
//     margin-top: 25mm;
//     margin-bottom: 25mm;
//     height: 100px;
//     width: 210mm;
//     margin-left: -29mm;
//     background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
//     box-shadow: 50px 50px white;
//   }

//   .sceneHeadings::before {
//       content: attr(data-scene-index);
//       margin-left: -50px;
//       margin-right: 42px;
//   }

//   .coldOpening { 
//   text-decoration: underline;
//   text-align: center;
//   margin-bottom: 90px;
//   font-weight: bold;
//   color: #555;
// }

// .sceneHeadings {
//   font-weight: 900; 
//   text-transform: uppercase; 
//   color: #a52a2a; 
// }

// .action {
//   margin: 0;
//   color: #555;
// }
// .character {
//    text-align: start;
//   padding: 0;
//   width: 33%;
//   font-weight: bold;
//   padding-left: 12%;
//   margin-left: 28%;
//   text-transform: uppercase;
//   color: #cd3333;
//   line-height:1.1em;
// }
  
// .parentheses {
// text-align: start;
//   width: 41.5%;
//   margin-left: 28%;
//   padding-left: 9%;
//   font-style: italic;
//   color: #f5785a;
//   margin-top: 0;
//   line-height:1.1em;
// }

// .parentheses::before {
//   content: "(";
// }
// .parentheses::after {
//   content: ")";
// }
// .dialogue { 
// text-align: start;
// margin: 0;
// padding: 0;
//   width: 45%;
//   margin-left: 28%;
//   margin-top: 0;
//   color: #555;
//   line-height:1.1em;
//    }
// .newAct { font-weight: bold; color: #00008B; }
// .endAct { font-weight: bold; color: #8B0000; }

// .transition { 
// text-align: right;
//  color: #555; }

// .flashBack { 
//   color: #555;
//   text-transform: uppercase;
// }
// .montage { 
//   color: #555;
//   text-transform: uppercase;
// }
// .titlePage { font-weight: bold; text-align: center; color: #000080; }
// .genre { font-style: italic; color: #20B2AA; }
// .logline { font-style: italic; margin: 1em 0; color: #20B2AA; }
// .synopsis { margin: 1em 0; color: #4682B4; }
// .treatmentHeader { font-weight: bold; color: #1E90FF; text-transform: uppercase; }
// }
// `
