export const pageTotalHeight = 1056
export const PADDING = 94.4882
export const PAGE_HEIGHT = pageTotalHeight - (2 * PADDING);
const PAGEBREAK_HEIGHT = 40;

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const getNodeHeight = (node) => {
  const rect = node.getBoundingClientRect();
  const styles = window.getComputedStyle(node);

  const marginTop = parseFloat(styles.marginTop);
  const marginBottom = parseFloat(styles.marginBottom);
  const paddingTop = parseFloat(styles.paddingTop);
  const paddingBottom = parseFloat(styles.paddingBottom);
  const height = parseFloat(styles.height);

  const totalHeight = height + marginTop + marginBottom + paddingBottom + paddingTop;
  // console.log('getNodeHeight',node.classList[0],totalHeight)
  return totalHeight;
}

export const getTotalHeight = (editor) => {
  const body = editor.getBody()
  let totalHeight = 0
  body.childNodes.forEach(currentNode => {

    if (currentNode && !currentNode.classList.contains('custom-pagebreak')) {

      const currentNodeHeight = getNodeHeight(currentNode)

      totalHeight = totalHeight + currentNodeHeight
    }
  })
  return totalHeight
}


export const autoPaginate = (editor, zoom = 100) => {

  if (!editor) return;
  const range = editor.selection.getRng();

  const body = editor.getBody();

  // Remove existing page breaks
  const existingPageBreaks = body.querySelectorAll('.custom-pagebreak');
  existingPageBreaks.forEach(node => node.remove());

  // Get updated list of content nodes
  const contentNodes = Array.from(body.childNodes);
  let currentPageHeight = 0;
  let pageNumber = 1;

  contentNodes.forEach((node, index) => {
    if (node.classList && node.classList.contains('custom-pagebreak')) {
      return; // Skip any remaining pagebreaks (shouldn't happen, but just in case)
    }

    const nodeHeight = getNodeHeight(node);
    if (currentPageHeight + nodeHeight > PAGE_HEIGHT) {
      // Insert page break before this node
      if ((node.classList[0] === 'dialogue' || node.classList[0] === 'parentheses') && 
      (node.previousElementSibling.classList[0]==='character' || node.previousElementSibling.previousElementSibling.classList[0]==='character' )) {
        let previousNode = node.previousElementSibling
        let newHeight = getNodeHeight(previousNode)
        let count = 0
        while (previousNode.classList[0] !== 'character' && count < 3) {
          previousNode = previousNode.previousElementSibling;
          newHeight += getNodeHeight(previousNode)
          count++
        }

        currentPageHeight -= newHeight

        const pageBreak = editor.dom.create('div', {
          class: 'custom-pagebreak',
          contenteditable: 'false',
          style: `margin-top:${pageTotalHeight - (currentPageHeight + PADDING)}px; margin-bottom:${PADDING}px;`,
          'data-page': `${pageNumber + 1}.`
        }, '<div style="page-break-after: always;"></div>');

        body.insertBefore(pageBreak, previousNode);

        currentPageHeight = newHeight + getNodeHeight(node);
      }
      else if (node.classList[0] === 'action' && node.previousElementSibling?.classList[0] === 'sceneHeadings') {
        let previousNode = node.previousElementSibling
        let newHeight = getNodeHeight(previousNode)

        currentPageHeight -= newHeight

        const pageBreak = editor.dom.create('div', {
          class: 'custom-pagebreak',
          contenteditable: 'false',
          style: `margin-top:${pageTotalHeight - (currentPageHeight + PADDING)}px; margin-bottom:${PADDING}px;`,
          'data-page': `${pageNumber + 1}.`
        }, '<div style="page-break-after: always;"></div>');

        body.insertBefore(pageBreak, previousNode);

        currentPageHeight = newHeight + getNodeHeight(node);
      }
      else {
        const pageBreak = editor.dom.create('div', {
          class: 'custom-pagebreak',
          contenteditable: 'false',
          style: `margin-top:${pageTotalHeight - (currentPageHeight + PADDING)}px; margin-bottom:${PADDING}px;`,
          'data-page': `${pageNumber + 1}.`
        }, '<div style="page-break-after: always;"></div>');

        body.insertBefore(pageBreak, node);

        currentPageHeight = nodeHeight;
      }

      pageNumber++;
    } else {
      currentPageHeight += nodeHeight;
    }
  });

  // Restore selection
  editor.selection.setRng(range);

  // Set page height
  const numberOfPages = pageNumber;
  const newPageHeight = (pageTotalHeight * numberOfPages) + (PAGEBREAK_HEIGHT * (numberOfPages - 1)) - PADDING;
  editor.getBody().style.height = `${newPageHeight}px`;
};

export const debounceAutoPaginate = debounce(autoPaginate, 100);

export const onPageBreakSelect = (editor) => {
  const node = editor.selection.getNode();
  const range = editor.selection.getRng();

  // Check if the current node or its parent is a custom pagebreak
  // If it is, collapse the selection to the start of the next node
  const nextNode = node.nextSibling
  if (nextNode) {
    range.setStartBefore(nextNode);
    range.collapse(true);
    editor.selection.setRng(range);
  }
}