import React, { useEffect, useState } from 'react'
import { getTitle, timeColors } from '../Editor/utils/sceneutils';
import ElementDetailsSlider from './ElementsDetailSlider';
import { GoDotFill } from "react-icons/go";
import Dropdown from '../../Common/DropDown';
import { MdAdd, MdExpandMore } from 'react-icons/md';
import { Collapse, ConfigProvider } from 'antd';
import CharacterButton from './CharacterButton';
import { toast } from 'react-toastify';
import { getAccessToken } from '../../../services/authService';
import LoaderSpin from '../../Common/LoaderSpin';
import AddMenu from './AddMenu';
import AddCategory from './AddCategory';
import AddElement from './AddElement';
import { getNameWithoutbrackets } from '../Editor/utils/characterUtils';
import EmptyPage from '../Editor/sidepanel/EmptyPage';

const ElementsTab = ({ setSceneElementList, setUpdateData, sceneIndex, pNodes, scriptData, searchVal, permissionToEdit }) => {
    const [activeCategory, setActiveCategory] = useState(null)
    const [activeElement, setActiveElement] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [openCategoryModal, setOpenCategoryModal] = useState(false)
    const [openElementModal, setOpenElementModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)

    let sceneCount = -1
    const sceneElements = []
    const allElements = []
    const defaultCategories = []

    const getPNodeArrtibute = (node, attribute) => {
        return node.attrs.find(attr => attr.name === attribute)?.value
    }

    pNodes.forEach(node => {
        const nodeClass = getPNodeArrtibute(node, 'class')
        if (nodeClass === 'sceneHeadings') {
            sceneCount = sceneCount + 1

            const categoryList = JSON.parse(getPNodeArrtibute(node, 'data-category-list') || 'null') || []
            const elementList = JSON.parse(getPNodeArrtibute(node, 'data-element-list') || 'null') || []

            sceneElements[sceneCount] = { categoryList, elementList }
            allElements.push(...elementList)
            defaultCategories.push(...categoryList.filter(category => category.isDefault === true).map(cat => {
                return { ...cat, scene: sceneElements.length }
            }))
        }
    });



    useEffect(() => { setSceneElementList(sceneElements) }, [])

    const handleCategoryClick = (category) => {
        setActiveCategory(category)
    }
    const handleElementClick = (elem) => {
        setActiveElement(elem)
    }

    const { Panel } = Collapse;

    const customTheme = {
        components: {
            Collapse: {
                contentPadding: 0,
            },
        },
    };
    const categoryList = [...sceneElements[sceneIndex]?.categoryList
        , ...defaultCategories.filter(category => category.scene - 1 !== sceneIndex)]
    const filteredCateGoryList = categoryList.filter(eleme => eleme.name.toUpperCase().includes(searchVal.toUpperCase()))
    const filteredElementList = sceneElements[sceneIndex]?.elementList?.slice().filter(eleme => eleme.name.toUpperCase().includes(searchVal.toUpperCase())) || []

    console.log("defaultc", filteredCateGoryList)


    return (
        <>
            {openAddModal &&
                <AddMenu
                    isOpen={openAddModal}
                    setIsOpen={setOpenAddModal}
                    setOpenAddCategory={setOpenCategoryModal}
                    setOpenAddElement={setOpenElementModal}
                    sceneIndex={sceneIndex}
                />}

            {(openCategoryModal || activeCategory) &&
                <AddCategory
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                    sceneIndex={sceneIndex}
                    categoryList={categoryList}
                    isOpen={openCategoryModal}
                    setIsOpen={setOpenCategoryModal}
                    pNodes={pNodes}
                    setUpdateData={setUpdateData}
                    permissionToEdit={permissionToEdit}
                />}

            {openElementModal &&
                <AddElement
                    sceneIndex={sceneIndex}
                    categoryList={categoryList}
                    elementList={sceneElements[sceneIndex].elementList}
                    setIsOpen={setOpenElementModal}
                    pNodes={pNodes}
                    setUpdateData={setUpdateData}
                />}

            {activeElement ?
                <ElementDetailsSlider
                    setUpdateData={setUpdateData}
                    activeElement={activeElement}
                    categoryList={categoryList}
                    elementList={sceneElements[sceneIndex].elementList}
                    sceneElements={sceneElements}
                    index={sceneIndex}
                    setActiveElement={setActiveElement}
                    pNodes={pNodes}
                    permissionToEdit={permissionToEdit}
                />
                :
                <>
                    {isLoading ?
                        <div className='flex justify-center py-20'>
                            <LoaderSpin size={40} />
                        </div>
                        :
                        <div className='mt-4 '>

                            {sceneElements.length == 0 ?
                                <EmptyPage
                                    title={"You do not have any scenes to show characers"}
                                    subtitle={"Create a scene with characters to see here"} />
                                :
                                <>
                                    {searchVal && searchVal !== "" ? <div>
                                        {filteredCateGoryList.length == 0 && filteredElementList.length == 0 ?
                                            <EmptyPage title={"No Categories or elements found"} subtitle={"Try searching with a different name"} />
                                            :

                                            <ConfigProvider theme={customTheme}>
                                                <Collapse
                                                    bordered={false}
                                                    defaultActiveKey={['1', '2']}
                                                    expandIconPosition={'end'}
                                                >
                                                    <Panel header="Catrgories" key="1" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                        <>
                                                            {categoryList.length > 0 ? (
                                                                <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                    {filteredCateGoryList.map((category, index) =>
                                                                        <CharacterButton
                                                                            onClick={() => { handleCategoryClick(category) }}
                                                                            character={category}
                                                                        />)}
                                                                </div>
                                                            ) : (
                                                                <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                    No Categories
                                                                </div>
                                                            )}
                                                        </>
                                                    </Panel>
                                                    <Panel header="Elements" key="2" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                        <>
                                                            {sceneElements[sceneIndex]?.elementList?.length > 0 ? (
                                                                <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                    {filteredElementList.map((element, index) =>
                                                                        <CharacterButton
                                                                            onClick={() => { handleElementClick(element) }}
                                                                            character={{ ...element, color: sceneElements[sceneIndex].categoryList.find(category => category.name === element.category)?.color }}
                                                                        />)}
                                                                </div>
                                                            ) : (
                                                                <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                    No Elements
                                                                </div>
                                                            )}
                                                        </>
                                                    </Panel>

                                                </Collapse>
                                            </ConfigProvider>
                                        }

                                    </div> :
                                        <ConfigProvider theme={customTheme}>
                                            <Collapse
                                                bordered={false}
                                                defaultActiveKey={['1', '2']}
                                                expandIconPosition={'end'}
                                            >
                                                <Panel header="Catrgories" key="1" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                    <>
                                                        {categoryList.length > 0 ? (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                {categoryList.map((category, index) =>
                                                                    <CharacterButton
                                                                        
                                                                        onClick={() => { handleCategoryClick(category) }}
                                                                        character={category}
                                                                    />)}
                                                            </div>
                                                        ) : (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                No Categories
                                                            </div>
                                                        )}
                                                    </>
                                                </Panel>
                                                <Panel header="Elements" key="2" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                    <>
                                                        {sceneElements[sceneIndex]?.elementList?.length > 0 ? (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                {sceneElements[sceneIndex]?.elementList?.map((element, index) =>
                                                                    <CharacterButton
                                                                        
                                                                        onClick={() => { handleElementClick(element) }}
                                                                        character={{ ...element, color: categoryList.find(category => category.name === element.category)?.color }}
                                                                    />)}
                                                            </div>
                                                        ) : (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                No Elements
                                                            </div>
                                                        )}
                                                    </>
                                                </Panel>

                                            </Collapse>
                                        </ConfigProvider>}
                                </>}

                            {permissionToEdit && sceneElements.length > 0 && <button onClick={() => { setOpenAddModal(true) }} className='cursor-pointer size-[56px] rounded-full flex justify-center items-center fixed bottom-4 right-4 bg-[#0099FF]'><MdAdd size={24} color='white' /></button>}
                        </div>
                    }
                </>
            }

        </>
    )
}

export default ElementsTab