import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  MdPerson, MdLogout,
  MdLock,
  MdNotificationsActive,
  MdAttachMoney,
  MdContactSupport,
  MdDescription,
  MdLanguage
} from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import Dropdown from "../Common/DropDown";
import { updateUser } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/morphosys-logo.png";
import ProfileModals from "../Modals/ProfileModals";
import CreateScriptMenu from "./CreateScriptMenu";
import { setConnectionErrorOpen, setCreateSciptOpen, setIsTranslateOpen } from "../../store/navSlice";
import ErrorPopUp from "../Common/ErrorPopUp";
import { logOutAsync } from "../../store/asyncLogout";
import { Modal } from "antd";
import GoogleTranslate from "../../utils/Translate";

const NavBar = () => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation()
  const dispatch = useDispatch();
  const [currentPath, setCurrentPath] = useState(location.pathname)
  const [showModal, setShowModal] = useState('')
  const isCreateOpen = useSelector((state) => state.nav.isCreateScriptOpen)
  const [showBottomNav, setShowBottomNav] = useState(true)


  const setIsCreateOpen = (val) => { dispatch(setCreateSciptOpen(val)) }

  const navigate = useNavigate()

  useEffect(() => {
    setShowBottomNav(location.pathname.endsWith('/scripts') || location.pathname.includes('/myprofile'))

  }, [location])

  const getUserData = async () => {
    const userId =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?._id;

    try {
      if (!userId) {
        return
      }
      const res = await fetch(
        `${process.env.REACT_APP_DEV_API}/user/${userId}`
      );
      if (!res.ok) {
        throw new Error('bad response')
      };
      const body = await res.json();
      dispatch(updateUser(body.data.user));

    } catch (err) {
      if (err.message === 'Failed to fetch' || err.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      console.error(err)
    }
  };

  const handleLogoClick = () => {
    if (window.location.pathname === '/scripts') {
      navigate(0)
    }
    else {
      navigate('/scripts')
    }
  }

  useEffect(() => {
    setCurrentPath(window.location.pathname)
  }, [window.location.pathname])

  useEffect(() => {
    if (!user?.photo) getUserData();
  }, []);

  const BottomNavItem = ({ Icon, title, path }) => {
    return (
      <Link to={path} className={`flex items-center flex-col`}>
        <Icon size={25} color={path === currentPath ? "#0995F2" : "#C4C4C4"} />
        <label
          style={{ color: path === currentPath ? "#0995F2" : "#969696" }}
          className={` text-xs`}
        >
          {title}
        </label>
      </Link>
    );
  };

  const BottomNav = () => {
    return (
      <>
        <div className="h-16 w-full flex justify-evenly lg:hidden items-center ">
          <BottomNavItem
            Icon={MdDescription}
            title={"Script"}
            path={"/scripts"}
          />
          <button
            onClick={() => { setIsCreateOpen(true) }}
            className="relative flex justify-center w-[4.5rem] h-[4.5rem]"
          >
            <div className=" flex items-center justify-center absolute top-0 w-14 h-14 translate-y-[-20%] bg-[#0099FF] rounded-full z-10">
              <FaPlus color="white" size={20} />
            </div>
            <div className=" absolute top-0 w-[4.5rem] h-[4.5rem] translate-y-[-20%] bg-white rounded-full"></div>
          </button>
          <BottomNavItem
            Icon={MdPerson}
            title={"Profile"}
            path={"/myprofile"}
          />
        </div>
        <div className=" w-[50%] mx-auto bg-[#C4C4C4] mb-2 lg:hidden h-[0.3rem] rounded-full" />
      </>
    );
  };

  const TopNav = () => {

    const ProfileMenu = () =>
      <Dropdown
        alignment="right"
        showArrow={true}
        disabled={showModal!==''}
        trigger={<button className=" flex rounded-full size-12 max-h-[6vh] max-w-[6vh]">
          {user?.photo ?
            <img src={user?.photo} className=" shadow-[0_3px_8px_rgba(0,0,0,0.24)] bg-white rounded-full size-full object-cover " />
            : <div className="h-full shadow-[0_3px_8px_rgba(0,0,0,0.24)] font-semibold text-lg w-full flex justify-center items-center text-center bg-[#D41E79] rounded-full text-white">
              {user?.name?.charAt(0)?.toUpperCase()}
            </div>}
        </button>}
      >
        <div className="p-3 flex flex-col text-left">
          <button onClick={() => { setShowModal("profileinfo") }} className="text-start text-sm hover:bg-zinc-100 p-2 rounded text-[#727272] font-bold flex items-center justify-start gap-2 w-full">
            <MdPerson className="size-5" />
            Profile Information
          </button>
          <button onClick={() => { setShowModal("changepass") }} disabled={user?.loginType !== 'EMAIL'} className="text-start text-sm hover:bg-zinc-100 p-2 rounded text-[#727272] font-bold flex items-center justify-start gap-2 w-full disabled:opacity-50 disabled:cursor-not-allowed">
            <MdLock className="size-5" />
            Password and Security
          </button>
          <button onClick={() => { setShowModal("") }} className="text-start text-sm text-[#727272] hover:bg-zinc-100 p-2 rounded flex font-bold items-center justify-start gap-2 w-full opacity-50 cursor-not-allowed">{/* TODO: remove "opacity-50 cursor-not-allowed" after notification is implemented */}
            <MdNotificationsActive className="size-5" />
            Notification
          </button>
          <button onClick={() => { navigate('myprofile/subscription') }} className="text-[#727272] text-sm text-start hover:bg-zinc-100 p-2 rounded font-bold flex items-center justify-start gap-2 w-full">
            <MdAttachMoney className="size-5" />
            Subscription
          </button>
          <button onClick={() => { setShowModal("") }} className="text-start text-sm text-[#727272] hover:bg-zinc-100 p-2 rounded font-bold flex items-center justify-start gap-2 w-full opacity-50 cursor-not-allowed">{/* TODO: remove "opacity-50 cursor-not-allowed" after notification is implemented */}
            <MdContactSupport className="size-5" />
            Support
          </button>
          <button onClick={() => { dispatch(setIsTranslateOpen(true)) }} className="text-start text-sm text-[#727272] hover:bg-zinc-100 p-2 rounded font-bold flex items-center justify-start gap-2 w-full ">{/* TODO: remove "opacity-50 cursor-not-allowed" after notification is implemented */}
            <MdLanguage className="size-5" />
            Language
          </button>
          <button className="text-start text-[#727272] text-sm hover:bg-zinc-100 p-2 rounded flex items-center justify-start font-bold gap-2 w-full"
            onClick={() => {
              dispatch(logOutAsync())
              localStorage.removeItem("user");
              localStorage.removeItem("session");
              navigate('/login')
            }}
          >
            <MdLogout className="size-5 " />
            Log Out
          </button>
        </div>
      </Dropdown>

    return (
      <div className="  h-[6.9vh] max-lg:hidden"
      >
        <header className="bg-[#FFA300] w-full max-lg:hidden fixed header top-0 h-[7vh]">
          <div className="w-full h-full relative ">

            <div className="relative w-full h-full overflow-hidden">
              <div className="top-triangle left-[1%] w-[3%] h-[90%] absolute z-[4] rotate-[-25deg] top-[-10.5%]" />
              <div className="h-[999px] w-[999px] left-[50%] top-[-2%] dark-blue-circle-bg rounded-full z-[2] absolute" />
            </div>
            <div className="absolute top-0 flex z-[15] w-full h-full items-center justify-between px-4">
              <button onClick={handleLogoClick}>
                <img className="h-16 max-h-[7vh]" src={logo} />
              </button>

              <ProfileMenu />
            </div>
          </div>
        </header>
      </div>
    );
  };

  return (
    <>
      <nav className="flex fixed top-0 w-full flex-col h-[100svh] z-[80] lg:flex-col-reverse">
        <div className="grow overflow-y-auto"> <Outlet /></div>
        <div className="bg-white z-[80] max-lg:drop-shadow-[0_10px_8px_rgba(0,0,0,0.2)] border-b">
          {showBottomNav && <BottomNav />}
          <TopNav />
          {isCreateOpen && <CreateScriptMenu isCreateOpen={isCreateOpen} setIsCreateOpen={setIsCreateOpen} />}
          <div className="">
            <ProfileModals showModal={showModal} setShowModal={setShowModal} />
          </div>

        </div>


      </nav>
    </>
  );
};

export default NavBar;
