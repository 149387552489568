export const getNameWithoutbrackets = (name) => {
  const newName = name?.replace('(V.O)', '')
    .replace('(O.C)', '')
    .replace('(O.S)', '')
    .replace("(CONT'D)", '')
    .replace("(CONT’D)",'')
    .trim()
    
    return newName || ''
}

export const getCharacter = (editor) => {
  if (editor) {
    const content = editor.getContent({ format: 'raw' });
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const allCharacters = [];
    const sceneCharacters = {};
    const selectedNode = editor.selection?.getNode();
    let lastCharacterIndex = -1;
    let sceneCount = -1;

    const traverseNodes = (node, index = 0) => {
      if (node.nodeType === 1) {
        if (sceneCharacters[sceneCount] && node.classList.contains('character') && node.textContent !== '') {
          const character = node.textContent.toUpperCase()
          // CHECKING FOR DIALOGUE CHARACTERS
          if (!sceneCharacters[sceneCount]?.dialogue?.find(char => getNameWithoutbrackets(char) === getNameWithoutbrackets(character))) {
            if (!character.trim().includes('(V.O)')) {
              sceneCharacters[sceneCount].dialogue.push(getNameWithoutbrackets(character))
            }

          }
          // CHECKING FOR VO CHARACTERS
          if (!sceneCharacters[sceneCount]?.voice?.find(char => getNameWithoutbrackets(char) === getNameWithoutbrackets(character))) {
            if (character.trim().includes('(V.O)')) {
              sceneCharacters[sceneCount].voice.push(getNameWithoutbrackets(character))
            }
          }

          if (!allCharacters.find(char => getNameWithoutbrackets(char) === getNameWithoutbrackets(character))) {
            allCharacters.push(getNameWithoutbrackets(character))
          }
        }

        if (node.classList.contains('sceneHeadings')) {
          const nonSpeakingList = JSON.parse(node.getAttribute('data-non-speak-character')) || []
          const extrasList = JSON.parse(node.getAttribute('data-extras-character')) || []

          sceneCount = sceneCount + 1
          sceneCharacters[sceneCount] = { dialogue: [], voice: [], nonSpeaking: nonSpeakingList, extra: extrasList }
        }

      }
      if (node.isEqualNode(selectedNode)) {
        lastCharacterIndex = allCharacters.length - 1;
      }
      node.childNodes.forEach((childNode, childIndex) =>
        traverseNodes(childNode, index + childIndex + 1)
      );
    };
    traverseNodes(doc.body);

    if (lastCharacterIndex >= 0) {
      return { index: lastCharacterIndex + 1, allCharacters: allCharacters, sceneCharacters };
    } else {
      return { index: null, allCharacters: allCharacters, sceneCharacters }
    }
  }
}

export const checkforCharContinued = (editor) =>{
  if(editor){
    const body = editor.getBody()
    let previousCharacter = null

    body.childNodes.forEach((node,index)=>{
      const name = node.textContent?.toUpperCase() || ''
      
      if(node.classList[0] === 'character' && name!== "" && !name.includes("(CONT'D)") && !name.includes("(CONT’D)")){
        if (previousCharacter && getNameWithoutbrackets( previousCharacter.textContent.toUpperCase() ) === getNameWithoutbrackets(name)) {
          setTimeout(() => {
            const text = name.replace("(CONT'D)","").trim() + " (CONT'D)";
            editor.dom.setHTML(node, text);
          }, 0);
        }
        previousCharacter = node
      }
      if(node.classList[0] === 'sceneHeadings'){
        previousCharacter = null
      }
    })
  }
}

export const getCharacterStats = (editor, name) => {
  
  if (editor) {
    const characterName = name.toUpperCase()
    const content = editor.getContent({ format: 'raw' });
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const sceneList = [];
    const characterStats = { scenes: 0, dialogue: 0, nonSpeaking: 0, voice: 0 }
    let sceneCount = -1;

    const traverseNodes = (node, index = 0) => {
      if (node.nodeType === 1) {
        const charNodeName = node.textContent.toUpperCase()
        if (sceneCount >= 0 && node.classList.contains('character') &&
          getNameWithoutbrackets(charNodeName) === getNameWithoutbrackets(characterName)) {

          if (!sceneList.includes(sceneCount)) {
            characterStats.scenes++
            sceneList.push(sceneCount)
          }
          if (!charNodeName.includes('(V.O)')) {
            characterStats.dialogue++
          }
          else {
            characterStats.voice++
          }
        }
        else


          if (node.classList.contains('sceneHeadings')) {
            const nonSpeakingList = JSON.parse(node.getAttribute('data-non-speak-character')) || []

            sceneCount = sceneCount + 1
            nonSpeakingList.forEach((char) => {
              if (char === characterName) {
                characterStats.nonSpeaking++
                if (!sceneList.includes(sceneCount)) {
                  characterStats.scenes++
                  sceneList.push(sceneCount)
                }
              }
            })
          }

      }
      node.childNodes.forEach((childNode, childIndex) =>
        traverseNodes(childNode, index + childIndex + 1)
      );
    };
    traverseNodes(doc.body);

    return characterStats
  }

}

const findNodesWithClassAndText = (node, className, text) => {
  let foundNodes = [];

  if (node.className && node.className.includes(className) && getNameWithoutbrackets(node.textContent).toUpperCase() === text) {
    foundNodes.push(node);
  }

  for (let i = 0; i < node.childNodes.length; i++) {
    foundNodes = foundNodes.concat(findNodesWithClassAndText(node.childNodes[i], className, text));
  }

  return foundNodes;
};

export const findAndSetAttributeOnCharacter = (editor, name, attribute, value) => {
  if (editor) {

    const contentNode = editor.getBody();
    const foundNodes = findNodesWithClassAndText(contentNode, 'character', name);

    if (foundNodes.length > 0) {
      foundNodes.forEach(node => {
        // Set the attribute here. For example:
        node.setAttribute(attribute, value);
      });
    }
  }
};

export const findAndUpdateTextOnCharacter = (editor, name, text) => {

  if (editor) {
    const contentNode = editor.getBody();
    const foundNodes = findNodesWithClassAndText(contentNode, 'character', name);

    if (foundNodes.length > 0) {
      foundNodes.forEach(node => {
        // Set the attribute here. For example:
        node.textContent = text;
      });
    }

  }
}

export const goToCharacter = (index, editor) => {
  if (editor) {

    const sceneHeadings = editor.dom.select('.character');
    if (index >= 0 && index < sceneHeadings.length) {
      const targetNode = sceneHeadings[index];

      // Scroll the node into view
      targetNode.scrollIntoView({ behavior: 'smooth' });

      // Set the cursor to the start of the node
      const newRange = editor.dom.createRng();
      newRange.setStart(targetNode, 0);
      newRange.setEnd(targetNode, 0);
      editor.selection.setRng(newRange);

      // Focus the editor
      editor.focus();
    }
  }
}

export const blockCharacterDialogue = (editor, name, color) => {
  if (editor) {
    const contentNode = editor.getBody();
    const foundNodes = findNodesWithClassAndText(contentNode, 'character', name);

    if (foundNodes.length > 0) {
      foundNodes.forEach(node => {
        // Set the attribute here. For example:
        node.style.background = color;

        let nextNode = node.nextSibling;

        while (nextNode && (nextNode.nodeType === 3 || !nextNode.textContent.trim())) {
          nextNode = nextNode.nextSibling;
        }

        // Check if the next node has the 'dialogue' class
        while (nextNode && nextNode.nodeType === 1 && (nextNode.classList.contains('dialogue') || nextNode.classList.contains('parentheses'))) {
          // Apply the same background color to the dialogue node
          nextNode.style.background = color;
          nextNode = nextNode.nextSibling;
        }

      });
    }
  }
}

export const addNonSpeakingCharacter = (editor, name, index) => {
  const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]');
  if (selectedNodes.length > 0) {
    const selectedNode = selectedNodes[0]
    const nonSpeakingList = JSON.parse(selectedNode.getAttribute('data-non-speak-character')) || []
    if (nonSpeakingList.includes(name)) {
      return
    }
    nonSpeakingList.push(name)
    selectedNode.setAttribute('data-non-speak-character', JSON.stringify(nonSpeakingList))
    editor.fire('Change');
  }
}

export const addExtrasCharacter = (editor, name, index) => {
  const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]');
  if (selectedNodes.length > 0) {
    const selectedNode = selectedNodes[0]
    const extrasList = JSON.parse(selectedNode.getAttribute('data-extras-character')) || []
    if (extrasList.includes(name)) {
      return
    }
    extrasList.push(name)
    selectedNode.setAttribute('data-extras-character', JSON.stringify(extrasList))
    editor.fire('Change');
  }
}

export const deleteNonSpeakingCharacter = (editor, name, index) => {
  const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]');
  if (selectedNodes.length > 0) {
    const selectedNode = selectedNodes[0]
    const nonSpeakingList = JSON.parse(selectedNode.getAttribute('data-non-speak-character')) || []
    const newNonSpeakingList = nonSpeakingList.filter(char => char !== name)

    selectedNode.setAttribute('data-non-speak-character', JSON.stringify(newNonSpeakingList))
    // editor.fire('Change');
  }
}

export const deleteExtrasCharacter = (editor, name, index) => {
  const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index + 1) + '"]');
  if (selectedNodes.length > 0) {
    const selectedNode = selectedNodes[0]
    const extrasList = JSON.parse(selectedNode.getAttribute('data-extras-character')) || []
    const newExtrasList = extrasList.filter(char => char !== name)

    selectedNode.setAttribute('data-extras-character', JSON.stringify(newExtrasList))
    editor.fire('Change');
  }
}
