import React, { useState, useRef, useEffect } from 'react';
import EditorComponent from './utils/EditorComponent';
import { autoFormatScript, getScenes } from './utils/formatUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { getAccessToken } from '../../../services/authService';
import ScreenWritingPopUp from './popupSliders/screenWritingPopUp';
import LoaderSpin from '../../Common/LoaderSpin';
import { useDispatch, useSelector } from 'react-redux';
import { setConnectionErrorOpen } from '../../../store/navSlice';
import { MdArrowBackIos, MdFindReplace, MdOutlineDescription, MdSearch } from 'react-icons/md';
import ToolBar from './ToolBar';
import FormatBar from './FormatBar';
import SidePanel from './sidepanel';
import DesktopMenuBar from './DesktopMenuBar';
import { replaceAlexContent, setCharacters, setCurrentDocumentType, setScenes, setEditor, setNotes } from '../../../store/scriptSlice';
import { Tooltip } from 'antd';
import PageTitleModal from './modals/PageTitleModal';
import PageTitleModalTV from './modals/PageTitleModalTV';
import { toast } from 'react-toastify';
import SaveAsModal from '../modals/SaveAsModal';
import { editFindAndReplace } from './utils/scriptUtils';

const ScriptEditor = () => {
  const [scriptData, setScriptData] = useState(null)
  const scenes = useSelector(state => state.script.scenes)
  const [currentScene, setCurrentScene] = useState(null)
  const [isPageTitleOpen, setIsPageTitleOpen] = useState(false)
  const [editorState, setEditorState] = useState(null)
  const [showScriptLoading, setShowScriptLoading] = useState(false)

  const formatClasses = {
    "Film Script": [
      "sceneHeadings",
      "action",
      "character",
      "dialogue",
      "parentheses",
      "transition",
      "flashBack",
      "montage",
    ],
    "TV Script": [
      "coldOpening",
      "newAct",
      "sceneHeadings",
      "action",
      "character",
      "dialogue",
      "parentheses",
      "transition",
      "endAct",
      "flashBack",
    ],
    Treatment: ["tratmentPara"],
  };

  const getSctiptType = (type) => {
    return type === "FILM"
      ? "Film Script"
      : type === "TV"
        ? "TV Script"
        : type === "TREATMENT"
          ? "Treatment"
          : "";
  };
  const [loading, setLoading] = useState(1);
  const [documentType, setDocumentType] = useState("");
  const [currentFormat, setCurrentFormat] = useState("");
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const editorRef = useRef(null);
  const documentTypeRef = useRef(documentType); // Ref to keep track of documentType
  const replaceAlexContent = useSelector(
    (state) => state.script.replaceAlexContent
  );
  const notes = useSelector((state) => state.script.notes);

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();



  const fetchScriptData = async () => {
    setLoading(1);
    const token = getAccessToken();
    const url = `${process.env.REACT_APP_DEV_API}/document/${id}`;
    try {
      if (!token) {
        throw new Error("A valid session not found");
      }
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        throw new Error("Error getting response");
      }
      const body = await res.json();
      const data = body.data;

      setDocumentType(getSctiptType(data.type))
      dispatch(setCurrentDocumentType(data.type))
      setScriptData(data)
      setIsPopUpOpen(data.content === '</p>')
      setLoading(0)
    } catch (error) {
      setLoading(2)
      toast.error('Failed to load script')
      console.error(error)
    }
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //   if(editorRef.current){
  //     console.log("setting notes")
  //   getAllNotes();
  //   }{
  //     console.log("no editor")
  //   }
  //   }, 1000);
  // }, [editorRef.current]);

  useEffect(() => {
    if (editorRef.current) {
      const formattedContent = autoFormatScript(
        replaceAlexContent,
        documentTypeRef.current
      );
      editorRef.current.execCommand("mceSetContent", false, formattedContent);
    }
  }, [replaceAlexContent]);

  useEffect(() => {
    fetchScriptData().then(() => {
      setTimeout(() => {
        if (editorRef.current) {
          const editor = editorRef.current;
          dispatch(setEditor(editor));
        }
      }, 3000);
    });
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current;
      dispatch(setEditor(editor))
    } {
      console.log("no editor")
    }
  }, [editorRef.current])

  useEffect(() => {
    if (editorRef.current) {
      const formattedContent = autoFormatScript(replaceAlexContent, documentTypeRef.current)
      editorRef.current.execCommand('mceSetContent', false, formattedContent)
    }
  }, [replaceAlexContent])

  useEffect(() => {
    fetchScriptData()
    dispatch(setScenes([]))
    dispatch(setCharacters({}))
  }, [])

  useEffect(() => {
    documentTypeRef.current = documentType;
  }, [documentType]);

  const handleTextTypeChange = (type) => {
    setCurrentFormat(type);
  };

  const handleZoomChange = (event) => {
    setZoomLevel(event.target.value);
  };

  const autoFormatEditorContent = () => {
    if (editorRef.current) {
      const editor = editorRef.current;
      const content = editor.getContent({ format: "text" });
      const formattedContent = autoFormatScript(
        content,
        documentTypeRef.current
      );
      editor.setContent(formattedContent);
    }
  };

  let observer = null;

  const openMobileSearch = () => {
    const editor = editorRef.current;
  
    if (editor) {
      editor.focus();
      editor.execCommand("SearchReplace");
      
      // Cache DOM queries
      const bodycontent = document.querySelector(".tox-dialog__body-content");
      const body = document.querySelector(".tox-dialog");
      const findbutton = document.querySelector(".tox-dialog__footer-end");
      const settingsbutton = document.querySelector(".tox-dialog__footer-start");
  
      const hideFindResponsive = () => {
        bodycontent?.setAttribute("style", "padding-bottom:0; padding-top:0;");
        findbutton?.setAttribute("style", "margin-left:-8px;");
        
        if (body) {
          body.style.position = "fixed";
          body.style.top = "25%";
          body.style.left = "50%";
          body.style.transform = "translate(-50%, -25%) scale(0.7)";
          body.style.margin = "0";
          body.style.maxWidth = "100svw";
        }
        
        if (settingsbutton) {
          settingsbutton.style.marginLeft = "-10px";
        }
        
        editFindAndReplace();
      };
  
      const handleSettingsClick = () => {
        console.log('settingClick');
        setTimeout(() => {
          const settingOptionsContainer = document.querySelector('.tox-collection__group');
          if (settingOptionsContainer) {
            settingOptionsContainer.childNodes.forEach(node => {
              node.style.background = 'white';
              node.style.color = 'black';
            });
          }
        }, 0);
      };
  
      // Handle find button clicks
      findbutton?.addEventListener("click", () => {
        setTimeout(hideFindResponsive, 0);
      });
  
      // Enhanced settings button interaction handling
      if (settingsbutton) {
        // Handle both click and touch events
        const settingsHandler = (e) => {
          e.preventDefault();
          handleSettingsClick();
        };
  
        // Add event listeners to the entire settings button, not just firstChild
        settingsbutton.addEventListener("click", settingsHandler);
        settingsbutton.addEventListener("touchend", settingsHandler);
        
        // Optional: Add touch feedback
        settingsbutton.addEventListener("touchstart", (e) => {
          e.preventDefault();
          settingsbutton.style.opacity = "0.7";
        });
        
        settingsbutton.addEventListener("touchend", () => {
          settingsbutton.style.opacity = "1";
        });
      }
  
      hideFindResponsive();
    }
  };

  const isEditorFocus = useSelector(state => state.script.isEditorFocus)

  useEffect(() => {
    if (!editorState)
      return

    const body = editorState.getBody()
    if (['character', 'coldOpening', 'dialogue', 'parentheses', 'transition'].includes(currentFormat) && isEditorFocus){
      body.setAttribute('data-hide-placeholder', '')
    }
      

    else
      body.removeAttribute('data-hide-placeholder')

  }, [currentFormat, editorState, isEditorFocus])

  return (
    <>
      {isPageTitleOpen && scriptData?.type && <>
        {scriptData.type === 'TV' ?
          <PageTitleModalTV scriptData={scriptData} setIsPageTitleOpen={setIsPageTitleOpen} />
          :
          <PageTitleModal scriptData={scriptData} setIsPageTitleOpen={setIsPageTitleOpen} />}
      </>}
      {showScriptLoading && <div className='fixed gap-2 bg-[#f3f3f3] top-[50svh] translate-x-[-50%] z-[80] left-[50vw] p-4 rounded shadow flex items-center'>
        <LoaderSpin />
        <p className='text-[#868686]'>
          Loading script
        </p>
      </div>}
      <div className=" bg-white flex flex-col h-full lg:bg-[#fbfbfb]">
        <nav className='lg:hidden p-4 flex border-b border-[#0000001A] justify-between items-baseline'>
          <div className='flex gap-1'>
            <button className='h-fit pt-1' onClick={() => { navigate(`/scripts/${id}`) }}>
              <MdArrowBackIos size={22} color='#292929' />
            </button>
            <div className=''>
              <h1 className='text-[#292929] font-bold text-[20px]'>{documentType}</h1>
              <h2 className='text-[#868686] text-[15px] truncate max-w-[calc(100svw-70px)]'>{scriptData?.title}</h2>
            </div>
          </div>
          <div>
          </div>
          <button className='pt-1'>
            <MdFindReplace size={28} color='#AAAAAA' onClick={openMobileSearch} />
          </button>
        </nav>
        <nav className='max-lg:hidden bg-white shadow p-4 py-3  flex flex-col border-b border-[#0000001A] gap-4'>

          <div className='flex w-full relative  items-center justify-start'>
            <div className='z-[80] basis-[50%]'>
              <DesktopMenuBar setShowScriptLoading={setShowScriptLoading} documentType={documentType} editor={editorState} scriptData={scriptData} />
            </div>
            <h2 className=' absolute left-[50%] truncate max-w-[500px] translate-x-[-50%] text-[#585858] font-bold text-[18px] px-4 py-1 w-fit h-fit'>
              {scriptData?.title}
            </h2>
            <div />
          </div>

        </nav>

        {loading === 0 && <>
          <div className='overflow-y-auto grow flex gap-3 justify-center '>
            <SidePanel scriptData={scriptData} editor={editorRef.current} />
            <div className='max-lg:w-full h-[calc(100%-12px)] max-lg:h-full grow w-fit max-w-[8.5in] lg:max-w-[11in] max-lg:mx-auto shadow-xl lg:p-3 lg:mt-3 border rounded-xl flex flex-col'>

              <div className='max-lg:hidden flex gap-2 items-center mb-3'>

                {scriptData?.type !== 'TREATMENT' && <ToolBar
                  scenes={scenes}
                  currentScene={currentScene}
                  editor={editorRef?.current}
                  documentType={documentType}
                  formatClasses={formatClasses}
                  setCurrentFormat={setCurrentFormat}
                  currentFormat={currentFormat} />
                }
                <FormatBar zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} editor={editorRef?.current} />
                <Tooltip placement={'bottom'} title='Page title'>

                  <button className='hover:bg-[#ececec] active:text-[#0099FF] -ms-4 text-[#777777] p-1 rounded' onClick={() => { setIsPageTitleOpen(true) }}><MdOutlineDescription size={20} /></button>
                </Tooltip>

              </div>
              <div className='max-w-[8.5in] lg:max-w-full lg:h-[calc(100%)] w-full grow mx-auto h-[11in] lg:border relative'>
                <EditorComponent
                  editorRef={editorRef}
                  formatClasses={formatClasses}
                  content={scriptData.content}
                  documentType={documentType}
                  setCurrentFormat={setCurrentFormat}
                  documentTypeRef={documentTypeRef}
                  scenes={scenes}
                  scriptData={scriptData}
                  setEditorState={setEditorState}
                  zoomVal={zoomLevel}
                />
              </div>
            </div>

          </div>

          <div className='lg:hidden'>
            {scriptData?.type !== 'TREATMENT' && <ToolBar scenes={scenes} editor={editorRef.current} documentType={documentType} formatClasses={formatClasses} setCurrentFormat={setCurrentFormat} currentFormat={currentFormat} />}
          </div>
        </>
        }
        {loading === 1 && <div className='mt-[20%] flex justify-center'><LoaderSpin size={30} /> </div>}
        {isPopUpOpen && <ScreenWritingPopUp isOpen={isPopUpOpen} setIsOpen={setIsPopUpOpen} editor={editorState} documentType={documentType} />}
      </div>
    </>
  );
};

export default ScriptEditor;
