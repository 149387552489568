import React, { useState } from "react";
import { Input, Select, Button } from "antd";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { setAlexContent } from "../../../../../store/scriptSlice";
import { useDispatch } from "react-redux";
import { getAccessToken } from "../../../../../services/authService";
import { useSelector } from "react-redux";
import { autoFormatScript } from "../../utils/formatUtils";
import Dropdown from "../../../../Common/DropDown";
import { MdExpandMore } from "react-icons/md";

const AlexAiModal = ({ openModal, setOpen, editor }) => {
  const [step, setStep] = useState(1);
  const [storyIdea, setStoryIdea] = useState("");
  const [characterCount, setCharacterCount] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generatedScript, setGeneratedScript] = useState("");
  const [confirmReplace, setConfirmReplace] = useState(false);
  const dispatch = useDispatch();
  const documentType = useSelector(state => state.script.currentDocumentType)
  const [duplicateErrors, setDuplicateErrors] = useState({});
  const [scriptType, setScriptType] = useState("FILM")

  const scriptTypeList = [
    { name: 'Film', key: 'FILM' },
    { name: 'TV', key: 'TV' },
    { name: 'Treatment', key: 'TREATMENT' },
  ]

  const handleCharacterCountChange = (e) => {
    const count = parseInt(e.target.value);
    setCharacterCount(count);
    setCharacters(
      Array.from({ length: count }, () => ({ name: "", gender: null, age: "" }))
    );
  };

  const handleCharacterChange = (index, field, value) => {
    if (field === "name") {
      // Clear existing errors first
      setDuplicateErrors({});

      // Find all duplicate names
      const duplicates = characters.reduce((acc, char, i) => {
        if (i === index) {
          // Use the new value for the current character being changed
          if (characters.some((c, idx) => idx !== index && c.name.toLowerCase() === value.toLowerCase() && value !== "")) {
            acc[index] = true;
            // Find the other character with the same name
            characters.forEach((c, idx) => {
              if (idx !== index && c.name.toLowerCase() === value.toLowerCase()) {
                acc[idx] = true;
              }
            });
          }
        } else {
          // Check existing characters against the current value
          if (value.toLowerCase() === char.name.toLowerCase() && value !== "") {
            acc[i] = true;
            acc[index] = true;
          }
        }
        return acc;
      }, {});

      if (Object.keys(duplicates).length > 0) {
        setDuplicateErrors(duplicates);
      }
    }

    setCharacters((prevCharacters) =>
      prevCharacters.map((char, i) =>
        i === index ? { ...char, [field]: value } : char
      )
    );
  };

  const isFormValid = () => {
    return (
      storyIdea && Object.keys(duplicateErrors).length === 0 
    );
  };

  const generateScript = async () => {

    setLoading(true);

    const token = getAccessToken();
    const url = `${process.env.REACT_APP_DEV_API}/generate-ai-story`;
    const storyData = {
      storyType: scriptType == "TREATMENT" ? "TREATMENT" : "SCREENPLAY",
      ...(characterCount && { numberOfCharacters: characterCount }),
      characters: characters.map((char,index) => {
        const characterData = {};
        if (char.name) characterData.name = char.name;
        else characterData.name = `Character ${index+1}`
        if (char.gender) characterData.gender = char.gender.toUpperCase();
        if (char.age) characterData.age = parseInt(char.age);
        return characterData;
      }),
      storyIdea: storyIdea || undefined,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(storyData),
      });

      if (response.status !== 201) {
        const data = await response.json()
        toast.error(data.message);
        setLoading(false)
        return
      }

      const data = await response.json()
      setGeneratedScript(data.data.content.content);
      setLoading(false);
      setStep(2);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error generating script,Please try again.");
      setLoading(false)
    }

  };

  const documentTypeLookup = {
    "TREATMENT": "Treatment",
    "FILM": "Film Script",
    "TV": "TV Script"
  }

  const handleReplaceInEditor = async () => {
    const formattedScript = autoFormatScript(generatedScript, documentTypeLookup[documentType])
    editor.setContent(formattedScript)

    setConfirmReplace(false)
    // setOpen(false)

  };

  return (
    <div className="h-full  py-4">
      <div className="h-full overflow-y-auto text-[12px] text-[#868686] bg-[#f3f4f6] flex flex-col rounded ">
        <h2 className="text-[14px] text-[#292929] font-bold py-2 ps-4">Alex AI</h2>
        <hr></hr>
        {step === 1 && (
          <div className="overflow-y-auto grow max-h-[calc(100vh-250px)] p-4">
            <div className="relative mb-4 mt-2">
              <label className="block mb-1 ">Script Type <label className=" text-red-500">*</label></label>
              <Dropdown close={scriptType} className={'z-[90] w-full'} alignment='left' trigger={
                <button className="bg-white hover:bg-[#efefef] w-full  text-[14px] text-start rounded flex items-center justify-between border text-[#868686] p-3 py-2 ">
                  {scriptTypeList.find(type => type.key === scriptType)?.name}
                  <MdExpandMore size={18} color="#CACACA" />
                </button>}>
                <div className=' flex flex-col w-[100px] max-h-[250px] z-[90] overflow-y-auto'>
                  {scriptTypeList.map(({ name, key }, index) =>
                    <button
                      style={{ color: key === scriptType ? '#0099ff' : '', background: key === scriptType ? '#F0F9FF' : '' }}
                      className='border-b py-2 text-start text-[#868686] text-[13px] px-2 hover:bg-[#e2e2e2]'
                      onClick={() => {
                        setScriptType(key)
                      }}>
                      {name}
                    </button>
                  )}
                </div>
              </Dropdown>
            </div>
            <div className="mb-4 ">
              <label className="block mb-1 ">Story Idea <label className=" text-red-500">*</label></label>
              <textarea
                disabled={loading}
                className="w-full text-black border rounded p-2"
                rows="3"
                value={storyIdea}
                onChange={(e) => setStoryIdea(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 ">Number of Characters</label>
              <input
                type="number"
                disabled={loading}
                min="1"
                className="w-full text-[12px] text-black border rounded p-2"
                value={characterCount}
                onChange={handleCharacterCountChange}
                onKeyPress={(e) => {
                  if (e.key === '-' || e.key === 'e' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            {characters.map((char, index) => (
              <div key={index} className="mb-4">
                <h4 className=" mb-1">Character {index + 1}</h4>
                <Input
                  type="text"
                  size="large"
                  disabled={loading}
                  placeholder="Character Name"
                  className="w-full text-[12px] border rounded p-2 mb-2"
                  value={char.name}
                  onChange={(e) =>
                    handleCharacterChange(index, "name", e.target.value)
                  }
                />
                {duplicateErrors[index] && ( // Add this error message
                  <div className="text-red-500 text-sm mb-2">
                    This name is already in use
                  </div>
                )}
                <div className="flex mb-2">
                  <Select
                    size="medium"
                    disabled={loading}
                    placeholder={'Gender'}
                    className="rounded h-[40px] text-[12px] w-[50%] mr-2"
                    value={char.gender}
                    onChange={(value) =>
                      handleCharacterChange(index, "gender", value)
                    }
                  >
                    <Select.Option value="Male">Male</Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                    <Select.Option value="Others">Others</Select.Option>
                  </Select>
                  <Input
                    min={"1"}
                    size="large"
                    type="number"
                    placeholder="Age"
                    disabled={loading}
                    className="border text-[12px] rounded w-[50%]"
                    value={char.age}
                    onChange={(e) =>
                      handleCharacterChange(index, "age", e.target.value)
                    }
                    onKeyPress={(e) => {
                      if (e.key === '-' || e.key === 'e' || e.key === '+') {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="mt-8">
              <Button
                type="primary"
                size="large"
                className={`w-full text-white py-2 rounded-md h-11  ${!isFormValid() ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                loading={loading}
                onClick={generateScript}
                disabled={!isFormValid()}
              >
                {loading ? "Generating..." : "Generate Script"}
              </Button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div
            className="flex flex-col max-h-[calc(100vh-280px)] p-4"

          >
            <h3 className="text-[13px] flex flex-col font-semibold mb-2">
              Script Preview
            </h3>
            <div className="flex-1 overflow-auto">
              <pre className="bg-white p-4 rounded mb-4 flex-1 text-wrap overflow-y-auto">
                {generatedScript}
              </pre>
            </div>
            <div className="flex flex-col gap-2 self-end mt-4 w-full">
              <Button
                type="primary"
                className="text-white text-[12px] py-2 rounded-lg h-11"
                onClick={() => {
                  navigator.clipboard.writeText(generatedScript);
                  toast.success("Script copied to clipboard");
                }}
              >
                Copy Script
              </Button>
              <Button
                type=""
                className="w-full py-2 text-[12px] h-11 rounded-lg"
                onClick={() => setConfirmReplace(true)}
              >
                Replace in Editor
              </Button>
            </div>
          </div>
        )}
        {
          <Modal
            open={confirmReplace}
            className="text-center"
            title="Require Confirmation"
            onOk={() => { }}
            onCancel={() => {
              setConfirmReplace(false);
            }}
            footer={(_, { OkBtn, CancelBtn }) => (
              <div className="flex justify-center gap-2">
                <Button
                  onClick={() => {
                    setConfirmReplace(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={handleReplaceInEditor}>
                  Confirm
                </Button>
              </div>
            )}
          >
            <div className="my-8 mx-4">
              <p className="text-center">
                Are you sure you want to replace the content of the editor with
                the Alex AI-generated content? This action cannot be undone.
              </p>
            </div>
          </Modal>
        }
      </div>
    </div>
  );
};

export default AlexAiModal;
