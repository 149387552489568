import React, { useRef, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Editor } from "@tinymce/tinymce-react";
import {
    getParentPElement,
    getNextFormat,
    autoFormatScript,
    contentStyle,
    removeAlignment,
    handleParenthesisChange,
} from "../utils/formatUtils";
import CharacterMenu from "./CharacterMenu";
import TransitionMenu from "./TransitionMenu";
import { checkforCharContinued, getCharacter } from "./characterUtils";
import { AutosuggestPlugin } from "./pulgins/autoSuggestPlugin";
import { getAccessToken } from "../../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { setEditorFocus } from "../../../../store/scriptSlice";
import { setScreenIndexes } from "./sceneutils";
import { saveContent } from "./scriptUtils";
import {
    addPageBreak,
    autoPaginate,
    debounceAutoPaginate,
    getTotalHeight,
    onPageBreakSelect,
} from "./pageUtils";
import CharacterSugggest from "./AutoSuggestCharacter";
import SceneSugggest from "./AutoSuggestScene";

const EditorComponent = ({
    scriptData,
    zoomVal,
    isDisabled,
    setEditorState,
    editorRef,
    documentType,
    formatClasses,
    setCurrentFormat,
    documentTypeRef,
}) => {
    const [characteMenuY, setCharacterMenuY] = useState(null);
    const scenes = useSelector((state) => state.script.scenes.allScenes);
    const [transitionMenuY, setTransitionMenuY] = useState(null);
    const [characters, setCharacters] = useState(null);
    const [autoSuggestCharacter, setAutoSuggestCharacter] = useState(null);
    const [autoSuggestScene, setAutoSuggestScene] = useState(null);
    const previousContent = useRef(scriptData?.content);
    const totalHeightRef = useRef(0);
    const suggestionsRef = useRef([""]);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [isUserAuthorizedToMakeChanges, setIsUserAuthorizedToMakeChanges] =
        useState(false);

    const getUserDocumentPermission = async () => {
        try {
            const accessToken = getAccessToken();
            const response = await fetch(
                `${process.env.REACT_APP_DEV_API}/document/${scriptData._id}/permission`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch user document permission");
            }

            const data = await response.json();
            return data.data.permission;
        } catch (error) {
            console.error("Error fetching user document permission:", error);
            return null;
        }
    };

    useEffect(() => {
        setLoading(true);
        const fetchUserPermission = async () => {
            const user = JSON.parse(localStorage.getItem("user"));
            let isUserOwner = scriptData?.userId === user?._id;
            if (!isUserOwner) {
                const permission = await getUserDocumentPermission();
                if (
                    !!permission.SCRIPT &&
                    permission.SCRIPT.includes("WRITE")
                ) {
                    setIsUserAuthorizedToMakeChanges(true);
                } else {
                    setIsUserAuthorizedToMakeChanges(false);
                }
            } else {
                setIsUserAuthorizedToMakeChanges(true);
            }
            setLoading(false);
        };

        fetchUserPermission();
    }, [scriptData.userId]);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.mode.set(
                isUserAuthorizedToMakeChanges ? "design" : "readonly"
            );
        }
    }, [isUserAuthorizedToMakeChanges]);

    const saveTimeOut = useRef(null);

    const debounceSave = () => {
        const content = editorRef.current?.getContent()
        clearTimeout(saveTimeOut.current);
        saveTimeOut.current = setTimeout(() => {
            if (previousContent.current !== editorRef.current?.getContent()) {
                previousContent.current = editorRef.current?.getContent();
                saveContent(
                    editorRef.current,
                    scriptData,
                    () => { },
                    scenes?.length,
                    content
                );
            }
        }, 2000);
    };
    const updateFirstLine = (selectedNode) => {
        if (documentType === "Film Script") {
            setCurrentFormat("sceneHeadings");
            editorRef.current.dom.setAttrib(
                selectedNode,
                "class",
                "sceneHeadings"
            );
        } else if (documentType === "TV Script") {
            setCurrentFormat("coldOpening");
            editorRef.current.dom.setAttrib(
                selectedNode,
                "class",
                "coldOpening"
            );
        } else if (documentType === "Treatment") {
            setCurrentFormat("treatmentPara");
            editorRef.current.dom.setAttrib(
                selectedNode,
                "class",
                "treatmentPara"
            );
        }
    };
    const handleEditorChange = (content) => {
        if (!isUserAuthorizedToMakeChanges) return;

        debounceSave();
        const trimmedContent = content.trim();
        const lines = trimmedContent.split("\n");
        const body = editorRef.current?.getBody();
        const firstChild = body.firstChild;
        const selectedNode = editorRef.current?.selection?.getNode();



        if (lines.length === 1 && lines[0] !== "") {
            if (firstChild.classList.length === 0) {
                updateFirstLine(selectedNode);
            }
        }
        if (selectedNode.classList.contains("sceneHeadings")) {
            setScreenIndexes(editorRef.current);
            const rect = selectedNode.getBoundingClientRect();
            setAutoSuggestScene({
                top: rect.top + 20,
                text: selectedNode.textContent,
            });
        }

        if (selectedNode.classList.contains("character")) {
            const rect = selectedNode.getBoundingClientRect();
            setAutoSuggestCharacter({
                top: rect.top + 20,
                text: selectedNode.textContent,
            });
        } else {
            setAutoSuggestCharacter(null);
        }
        const currentHeight = getTotalHeight(editorRef.current);
        if (totalHeightRef.current !== currentHeight) {
            if (selectedNode && selectedNode.tagName === 'P') {
                selectedNode.scrollIntoView({ block: 'center' });
            }
            
            totalHeightRef.current = currentHeight;
            debounceAutoPaginate(editorRef.current, zoomVal);
        }
        handleParenthesisChange(editorRef.current);
    };

    const handleEnterKey = (event) => {
        if (editorRef.current) {
            setTimeout(() => {
                checkforCharContinued(editorRef.current);
            }, 0);
            if (autoSuggestCharacter) {
                event.preventDefault();
                return;
            }
            const editor = editorRef.current;

            const selectedNode = getParentPElement(editor.selection?.getNode())

            // if (selectedNode.classList.contains('character')) {

            //   const text = editor.selection.getNode().textContent.replace('@', '')
            //   editor.dom.setHTML(selectedNode, text)

            //   editor.selection.select(selectedNode, true);
            //   editor.selection.collapse(false);

            // }

            const currentLineFormat = selectedNode.getAttribute("class");
            const nextFormat = getNextFormat(
                documentTypeRef.current,
                currentLineFormat
            );
            

            if (nextFormat) {
                event.preventDefault();
                if (!selectedNode.textContent.trim()) {
                    editor.dom.setAttrib(selectedNode, "class", nextFormat);
                    setCurrentFormat(nextFormat);
                } else {
                    editor.execCommand("InsertParagraph");
                    const newNode = getParentPElement(editor.selection?.getNode())
                    while (newNode.attributes.length > 0) {
                        newNode.removeAttribute(newNode.attributes[0].name);
                    }
                    editor.dom.setAttrib(newNode, "class", nextFormat);
                    editor.selection.setCursorLocation(newNode, 0);
                    setCurrentFormat(nextFormat);
                }
            }

            // if (editor.selection?.getNode().classList.contains('character')) {
            //   const text = editor.selection?.getNode().textContent.replace('@', '')
            //   if (suggestionsRef.current.some(character => character.includes(text))) {
            //     editorRef.current.execCommand('mceInsertContent', false, '@');
            //   }
            // }

            removeAlignment(editor);
        }
    };

    const handleUpKey = (e) => {
        if (editorRef.current) {
            const selectedNode = editorRef.current.selection.getNode();
            const previousSibling = selectedNode.previousSibling;

            // First, check if previousSibling exists
            if (previousSibling && previousSibling.classList && previousSibling.classList.contains("custom-pagebreak")) {
                e.preventDefault();
                // Get the position before the page break
                const prevElement = previousSibling.previousElementSibling.previousElementSibling;
                if (prevElement) {
                    // Move cursor to the end of the previous element
                    editorRef.current.selection.select(prevElement);
                    editorRef.current.selection.collapse(false); // false means collapse to end
                }
            }
        }
    }

    const handleDownKey = (e) => {
        if (editorRef.current) {
            const selectedNode = editorRef.current.selection.getNode();
            const nextSibling = selectedNode.nextSibling;

            // First, check if previousSibling exists
            if (nextSibling && nextSibling.classList && nextSibling.classList.contains("custom-pagebreak")) {
                e.preventDefault();
                // Get the position before the page break
                const siblingAfterPageBreak = nextSibling.nextElementSibling.nextElementSibling;
                if (siblingAfterPageBreak) {
                    // Move cursor to the end of the previous element
                    editorRef.current.selection.select(siblingAfterPageBreak);
                    editorRef.current.selection.collapse(true); // false means collapse to end
                }
            }
        }
    }

    const handleNodeChange = (event) => {
        const selectedNode = event.element;
        const parentNode = getParentPElement( selectedNode);
        const selectedFormat = formatClasses[documentType].find((format) =>
            parentNode.classList.contains(format)
        );

        if (selectedFormat) {
            setCurrentFormat(selectedFormat);
        } else {
            setCurrentFormat("");
        }
        setCharacters(getCharacter(editorRef.current).allCharacters);

        if (selectedNode.classList[0] === undefined) {
            event.preventDefault()
            const siblingBeforePageBreak = selectedNode.previousElementSibling;
            if (siblingBeforePageBreak) {
                // Move cursor to the end of the previous element
                editorRef.current.selection.select(siblingBeforePageBreak);
                editorRef.current.selection.collapse(true); // false means collapse to end
            }
        }
    };

    const handlePaste = (event) => {
        if (!isUserAuthorizedToMakeChanges) {
            event.preventDefault();
            return;
        }
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("Text");
        const formattedData = autoFormatScript(
            pastedData,
            documentTypeRef.current
        );

        if (editorRef.current) {
            editorRef.current.execCommand(
                "mceInsertContent",
                false,
                formattedData
            );
        }
        setScreenIndexes(editorRef.current);
    };

    const handleDoubleClick = (e) => {
        const selectedNode = getParentPElement(editorRef.current.selection?.getNode())
        const selectedClass = selectedNode?.classList[0];
        if (selectedNode && selectedClass === "character") {
            editorRef.current.selection.collapse(false);
            const rect = selectedNode.getBoundingClientRect();
            setCharacterMenuY(rect.top + 20);
        } else if(window.innerWidth<1024) {
            
            const rect = selectedNode.getBoundingClientRect();
            editorRef.current.selection.collapse(false);
            setTransitionMenuY(rect.top);
        }
    };
    const handleSelectionChange = (e) => {
        const selectedNode = editorRef.current.selection?.getNode();

        if (selectedNode.classList[0] === "transition") {
            if (selectedNode.innerHTML === "FADE IN:") {
                editorRef.current.dom.setAttrib(
                    selectedNode,
                    "style",
                    "text-align: left;"
                );
            } else if (
                selectedNode.innerHTML === "FADE OUT:" ||
                selectedNode.innerHTML === "FADE TO BLACK:"
            ) {
                editorRef.current.dom.setAttrib(
                    selectedNode,
                    "style",
                    "text-align: right;"
                );
            }
        }
    };
    const handleInit = (evt, editor) => {

        const editorDocument = editor.getDoc();
        editorDocument.addEventListener('mousedown', (e) => {
            if (e.target.tagName.toLowerCase() === 'html') {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            if (e.target.tagName.toLowerCase() === 'body') {
                // e.preventDefault();
                e.stopPropagation();
                return false;
            }
        }, true);
    };

    useEffect(() => {
        if (characters) {
            suggestionsRef.current = characters;
        }
    }, [characters]);

    useEffect(() => {
        autoPaginate(editorRef.current, zoomVal);
    }, [zoomVal]);

    return (
        <>
            {transitionMenuY && (
                <TransitionMenu
                    editor={editorRef.current}
                    transitionMenuY={transitionMenuY}
                    setTransitionMenuY={setTransitionMenuY}
                />
            )}
            {characteMenuY && (
                <CharacterMenu
                    editor={editorRef.current}
                    characterMenuY={characteMenuY}
                    setCharacterMenuY={setCharacterMenuY}
                />
            )}
            {autoSuggestCharacter && (
                <CharacterSugggest
                    editor={editorRef.current}
                    characterMenuY={autoSuggestCharacter}
                    setCharacterMenuY={setAutoSuggestCharacter}
                    characterList={characters || []}
                />
            )}
            
            {!loading && (
                <>
                {autoSuggestScene && (
                <SceneSugggest
                    editor={editorRef.current}
                    sceneMenuY={autoSuggestScene}
                    setSceneMenuY={setAutoSuggestScene}
                />
            )}
                <Editor

                    apiKey="x6bkrnudzvmfui0j7prr8s1ghxe3vkpkytpmpf04oh555ext"
                    disabled={!isUserAuthorizedToMakeChanges || isDisabled}
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setEditorState(editor);
                        autoPaginate(editorRef.current, 100);
                        handleInit(evt, editor)
                    }}
                    initialValue={scriptData?.content}
                    style={{
                        borderRadius: "0", // Remove border-radius from the editor's container
                    }}
                    init={{
                        content_css: [
                            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css'  // Add Font Awesome CSS
                        ],
                        placeholder: "Start typing your script...",
                        height: "100%",
                        width: "100%",
                        resize: false,
                        menubar: false,
                        branding: false,
                        highlight_on_focus: false,
                        plugins: [
                            "insertreactcomponent",
                            "advlist autolink lists link image charmap print preview anchor",
                            "visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                            "pagebreak",
                            "searchreplace",
                        ],
                        toolbar: false,
                        statusbar: false,

                        style_formats: {
                            coldOpening: {
                                block: "span",
                                classes: "coldOpening",
                            },
                            sceneHeadings: {
                                block: "p",
                                classes: "sceneHeadings",
                            },
                            action: { block: "p", classes: "action" },
                            character: { block: "p", classes: "character" },
                            parentheses: {
                                inline: "span",
                                classes: "parentheses",
                            },
                            dialogue: { block: "p", classes: "dialogue" },
                            newAct: { block: "p", classes: "newAct" },
                            endAct: { block: "p", classes: "endAct" },
                            transition: { block: "p", classes: "transition" },
                            flashBack: { block: "p", classes: "flashBack" },
                            montage: { block: "p", classes: "montage" },
                            titlePage: { block: "p", classes: "titlePage" },
                            genre: { block: "p", classes: "genre" },
                            logline: { block: "p", classes: "logline" },
                            synopsis: { block: "p", classes: "synopsis" },
                            treatmentHeader: {
                                block: "p",
                                classes: "treatmentHeader",
                            },
                        },
                        content_style: contentStyle,
                        setup: (editor) => {
                            editor.on("keydown", (e) => {
                                if (e.key === "Enter") {
                                    handleEnterKey(e);
                                } else if (e.key === 'ArrowUp') {
                                    handleUpKey(e);
                                } else if (e.key === 'ArrowDown') {
                                    handleDownKey(e)
                                }
                            });
                            editor.on("NodeChange", handleNodeChange);
                            // editor.on('Paste', handlePaste);
                            editor.on("dblclick", handleDoubleClick);
                        },
                    }}
                    onFocus={() => {
                        dispatch(setEditorFocus(true));
                    }}
                    onBlur={() => {
                        dispatch(setEditorFocus(false));
                    }}
                    onEditorChange={handleEditorChange}
                    onSelectionChange={handleSelectionChange}
                    onPaste={handlePaste}
                    onKeyDown={(e) => {
                        if (!isUserAuthorizedToMakeChanges) {
                            e.preventDefault(); // Prevent editing
                        }
                    }}
                    onSetContent={() => {
                        setScreenIndexes(editorRef.current);
                    }}

                />
                </>
            )}
            <style>
                {`
          .tox-editor-container, 
          .tox, 
          .tox-editor-container iframe {
            border: 0 ;
            border-radius: 0 !important; /* Remove border-radius from TinyMCE editor's container and iframe */
          }
        `}
            </style>
        </>
    );
};

export default EditorComponent;
