import { useEffect, useRef, useState } from "react"
import BackButton from "../BackButton"
import { goToScene } from "../../utils/formatUtils"
import { MdArrowBack, MdArrowBackIos, MdExpandMore } from "react-icons/md"
import { blockCharacterDialogue, findAndSetAttributeOnCharacter, findAndUpdateTextOnCharacter, getCharacter, getCharacterStats } from "../../utils/characterUtils"
import EmptyPage from "../EmptyPage"
import { useSelector } from "react-redux"
import Dropdown from "../../../../Common/DropDown"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import { getAccessToken } from "../../../../../services/authService"
import LoaderSpin from "../../../../Common/LoaderSpin"
import { Checkbox, ColorPicker } from "antd"

const CharactrDetails = ({ setUpdateList, characterList, setOpenedCharacter, editor, activeCharacterName, permissionToEdit }) => {
    const character = characterList.find(char => char.name === activeCharacterName)
    const [characterState, setCharacterState] = useState(character || {})
    const [isBlockDialogue, setIsBlockDialogue] = useState(false)
    const colorInputRef = useRef(null)
    const [isLoading, setLoading] = useState(false)
    const characterId = character?._id
    const { id } = useParams()

    const characterStats = getCharacterStats(editor, activeCharacterName)

    const updateCharacter = async () => {
        setLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/character/${characterId}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const { name, color, age, gender, type, ethnicity, occupation, orientation, disability, description } = characterState
            const data = { color, type, age: age === '' ? 0 : age, gender, ethnicity, occupation, orientation, disability, description }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: 'PUT',
                body: JSON.stringify(data)
            })

            const body = await res.json()
            if (!res.ok) {
                throw new Error('Error getting response', body)
            }
            setLoading(false)
            toast.success("Character updated successfully")
        } catch (error) {
            setLoading(false)
            console.error(error)
            toast.error('Error updating the character')
        }
    }
    const createCharacter = async () => {
        setLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/character/`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const { name, color, age, gender, type, ethnicity, occupation, orientation, disability, description } = characterState
            const data = { documentId: id, name: activeCharacterName, color, age, gender, type, ethnicity, occupation, orientation, disability, description: description }

            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: 'POST',
                body: JSON.stringify(data)
            })

            const body = await res.json()
            if (!res.ok) {
                throw new Error('Error getting response', body)

            }
            setLoading(false)
            toast.success("Character updated successfully")
        } catch (error) {
            setLoading(false)
            console.error(error)
            toast.error('Error updating the character')
        }
    }
    const getCharacterFromList = (character) => {
        return characterList.find(char => char.name === character)
    }

    const typeOptions = [
        { label: 'Lead', value: 'LEAD' },
        { label: 'Supporting', value: 'SUPPORTING' },
        { label: 'Featured', value: 'FEATURED' },
        { label: 'Cast', value: 'CAST' },
    ]

    const genderOptions = [
        { label: 'Male', value: 'MALE' },
        { label: 'Female', value: 'FEMALE' },
        { label: 'Other', value: 'OTHER' }
    ]

    const handleColorChange = (color) => {
        setCharacterState(prev => { return { ...prev, color } })
    }

    const handledescriptionChange = (e) => {
        const description = e.target.value
        setCharacterState(prev => { return { ...prev, description } })
    }

    const handleAgeChange = (e) => {
        const age = e.target.value
        const ageRegex = /^\d+$/
        if(age==='' || ageRegex.test(age)){
            setCharacterState(prev => { return { ...prev, age } })
        }
        
        
    }

    const handleTypeChange = (type) => {
        setCharacterState(prev => { return { ...prev, type } })
    }

    const handleGenderChange = (gender) => {
        setCharacterState(prev => { return { ...prev, gender } })
    }

    const handleDisabilityChange = (e) => {
        const disability = e.target.value
        setCharacterState(prev => { return { ...prev, disability } })
    }

    const handleOccupationChange = (e) => {
        const occupation = e.target.value
        setCharacterState(prev => { return { ...prev, occupation } })
    }

    const handleEthnicityChange = (e) => {
        const ethnicity = e.target.value
        setCharacterState(prev => { return { ...prev, ethnicity } })
    }


    const handleOrientationChange = (e) => {
        const orientation = e.target.value
        setCharacterState(prev => { return { ...prev, orientation } })
    }

    const handleSave = async () => {
        if (characterList.find(character => character.name === activeCharacterName)) {
            await updateCharacter()
        }
        else {
            await createCharacter()
        }

        setUpdateList(prev => !prev)
        setOpenedCharacter(null)
    }

    useEffect(()=>{
        if (isBlockDialogue) {
            blockCharacterDialogue(editor, activeCharacterName, characterState.color)
        }
        else {
            blockCharacterDialogue(editor, activeCharacterName, 'none')
        }

        return ()=>{blockCharacterDialogue(editor, activeCharacterName, 'none')}
    },[isBlockDialogue])

    return <div className="h-full">
        <div className="flex items-center border-b pb-2 overflow-hidden">
            <button onClick={() => { setOpenedCharacter(null) }}
                className="hover:text-[#636363] flex justify-center p-1 items-center">
                <MdArrowBackIos size={15} />
            </button>

            <h2 className="text-[13px] truncate">{character?.name || activeCharacterName}</h2>
        </div>
        <div className="overflow-y-auto text-[12px] h-[calc(100%-20px)] p-2">
            <div className="grid grid-cols-2 gap-2  mb-2">

                <div className="flex gap-2 items-center">
                    <ColorPicker disabled={!permissionToEdit} value={characterState.color || '#d9dee3'} size="medium" onChange={(_, hex) => { handleColorChange(hex) }} />
                    <p className=" text-[12px] text-[#868686]">Color</p>

                </div>

                <div className="flex items-center gap-2">
                    <Checkbox disabled={!permissionToEdit} checked={isBlockDialogue} onChange={(e)=>{setIsBlockDialogue(e.target.checked)}} 
                    className="scale-125"/>

                    <p className=" ms-2 text-[12px]  text-[#868686]">Block Dialogue</p>

                </div>

            </div>

            <div class="grid grid-cols-2 text-[#868686] grid-rows-2 gap-2">
                <div class=" p-2 flex gap-2">
                    {characterStats.scenes} <p>Scenes</p>
                </div>
                <div class=" p-2 flex gap-2">
                    {characterStats.dialogue} <p>Dialogues</p>
                </div>
                <div class=" p-2 flex gap-2">
                    {characterStats.voice} <p>Voice Over</p>
                </div>
                <div class=" p-2 flex gap-2">
                    {characterStats.nonSpeaking} <p>Non-Speaking</p>
                </div>
            </div>


            {/* <label className="mb-2 text-[12px] text-[#868686]">Character Type</label> */}

            {permissionToEdit ? <Dropdown close={characterState.type} className={'w-full mt-2'} alignment="left" trigger={
                <button className="bg-white hover:bg-[#efefef] text-[12px] text-start rounded-lg flex items-center justify-between border px-2 w-full py-2">
                    {typeOptions.find(type => type.value === characterState.type)?.label || 'Character type'}
                    <MdExpandMore size={18} color="#CACACA" />
                </button>}>

                <div className='w-[200px] flex flex-col'>
                    {typeOptions.map((option) => (
                        <button
                            style={{
                                color: characterState.type === option.value ? '#0099ff' : '#868686',
                                background: characterState.type === option.value ? '#f0f9ff' : ''
                            }}
                            onClick={() => { handleTypeChange(option.value) }} className="w-full border-b hover:bg-[#e2e2e2] text-start text-[12px] px-3 py-2" key={option.value}>
                            {option.label}
                        </button>
                    ))}
                </div>
            </Dropdown>
            :
            <p>{characterState.type}</p>
            }

                <div className="mt-2">
                    <label className="mb-2 text-[12px] text-[#868686]">Age</label>
                    <input disabled={!permissionToEdit} value={characterState.age || ''} onChange={handleAgeChange} className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />
                </div>
                <div className="mb-2">
                    <label className="mb-2 text-[12px] text-[#868686]">Gender</label>
                    {permissionToEdit ? <Dropdown close={characterState.gender} className={'w-full'} alignment="left" trigger={
                        <button className="bg-white hover:bg-[#efefef] text-[12px] text-start rounded-lg flex items-center justify-between border  px-2 w-full py-2">
                            {genderOptions.find(gender => gender.value === characterState.gender)?.label || 'Select gender'}
                            <MdExpandMore size={18} color="#CACACA" />
                        </button>}>

                        <div className='w-[100px] flex flex-col'>
                            {genderOptions.map((option) => (
                                <button onClick={() => { handleGenderChange(option.value) }}
                                    style={{
                                        color: characterState.gender === option.value ? '#0099ff' : '#868686',
                                        background: characterState.gender === option.value ? '#f0f9ff' : ''
                                    }}
                                    className="w-full border-b text-[#868686] text-start text-[12px] px-3 py-2 hover:bg-[#e2e2e2]" key={option.value}>
                                    {option.label}
                                </button>
                            ))}
                        </div>

                    </Dropdown>
                    :
                    <p>{characterState.gender}</p>
                }


                </div>

            <label className="mb-2 text-[12px] text-[#868686]">Ethnicity</label>
            <input disabled={!permissionToEdit} value={characterState.ethnicity} onChange={handleEthnicityChange} className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

            <label className="mb-2 text-[12px] text-[#868686]">Occupation</label>
            <input disabled={!permissionToEdit} value={characterState.occupation} onChange={handleOccupationChange} className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

            <label className="mb-2 text-[12px] text-[#868686]">Orientation</label>
            <input disabled={!permissionToEdit} value={characterState.orientation} onChange={handleOrientationChange} className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />

            <label className="mb-2 text-[12px] text-[#868686]">Disability</label>
            <input disabled={!permissionToEdit} value={characterState.disability} onChange={handleDisabilityChange} className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />


            <label className="mb-2 text-[12px] text-[#868686]">Character description</label>
            <textarea disabled={!permissionToEdit} onChange={handledescriptionChange} value={characterState?.description} className="w-full h-[150px] mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />



            {permissionToEdit && <button onClick={handleSave} className="bg-[#0099ff] px-3 py-2 rounded-full text-white mt-4 font-medium w-full">
                {isLoading ? <LoaderSpin color={'white'} /> : 'Save'}
            </button>}

        </div>
    </div>
}

export default CharactrDetails;