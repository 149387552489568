import React, { useEffect, useState } from 'react'
import { MdExpandLess, MdExpandMore, MdMoreHoriz } from 'react-icons/md'
import { getScenes, goToScene, setScreenIndexes } from './utils/sceneutils'
import { formatText, handleParenthesisChange } from './utils/formatUtils'
import DropDown from '../../Common/DropDown'
import { setScenes } from '../../../store/scriptSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'antd'

const ToolBar = ({ scenes, currentScene, editor, currentFormat, documentType, setCurrentFormat, formatClasses }) => {

    const [isFormatMenuOpen, setIsFormatMenuOpen] = useState(false)
    const isEditorFocus = useSelector(state => state.script.isEditorFocus)
    const [showToolTip, setShowToolTip] = useState(false)
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)


    const dispatch = useDispatch()
    const handleNodeChange = (e) => {
        const sceneObject = getScenes(editor)
        dispatch(setScenes(sceneObject))
    }


    useEffect(() => {
        if (editor) {
            handleNodeChange()
            editor.on('NodeChange', handleNodeChange)
            return () => {
                editor.off('NodeChange', handleNodeChange)
            }
        }

    }, [editor])

    useEffect(() => {
        if (isEditorFocus) {
            setIsFormatMenuOpen(false)
        }
    }, [isEditorFocus])

    useEffect(() => {
        handleParenthesisChange(editor)
        if (currentFormat === 'sceneHeadings') setScreenIndexes(editor) 

    }, [currentFormat])

    const formats = [
        { key: 'coldOpening', letter: 'O', title: 'Opening' },
        { key: 'newAct', letter: 'A', title: 'New Act' },
        { key: 'sceneHeadings', letter: 'S', title: 'Scene' },
        { key: 'action', letter: 'A', title: 'Action' },
        { key: 'character', letter: 'C', title: 'Character' },
        { key: 'dialogue', letter: 'D', title: 'Dialogue' },
        { key: 'parentheses', letter: 'P', title: 'Paranthesis' },
        { key: 'transition', letter: 'T', title: 'Transition' },
        { key: 'endAct', letter: 'A', title: 'End Act' },
        { key: 'flashBack', letter: 'F', title: 'Flashback' },
        { key: 'montage', letter: 'M', title: 'Montage' },
        { key: 'titlePage', letter: 'T', title: 'Title Page' },
        { key: 'genre', letter: 'G', title: 'Genre' },
        { key: 'logline', letter: 'L', title: 'Log Line' },
        { key: 'treatmentHeader', letter: 'H', title: 'Header' },
    ]



    return (
        <>
            {isFormatMenuOpen && <div className='lg:hidden bg-white p-3 border-t-2 rounded-t-2xl h-fit'>
                <h3 className='text-center text-[#404040] font-bold text-[20px] mb-5'>Format menu</h3>
                <div className='flex gap-4 flex-wrap'>
                    {formats.slice().filter(({ key }) => formatClasses[documentType]?.includes(key)).map(({ key, letter, title }) =>
                        <button key={key} onClick={() => {
                            formatText(editor, key)
                            setCurrentFormat(key)
                            setIsFormatMenuOpen(false)
                            editor?.focus()
                        }
                        } className={`${currentFormat === key ? 'bg-[#F0F9FF] border-[#0099FF] text-[#0099FF]' : 'bg-white text-[#444444] border-[#E4E4E4]'} flex grow w-[34%] gap-5 py-2 px-3 border `}>
                            {letter} <label className='cursor-pointer'>{title}</label>
                        </button>)}
                    {formatClasses[documentType].length % 2 !== 0 && <div className='w-[34%] grow'></div>}
                </div>

            </div>}
            <div className='lg:hidden border-t-2 p-4 '>
                <div className=' flex justify-around '>
                    {formats.slice().filter(({ key }) => formatClasses[documentType]?.includes(key)).map(({ key, letter }, i) => i < 6 &&
                        <button onClick={() => {
                            formatText(editor, key)
                            setCurrentFormat(key)
                            editor?.focus()
                        }} className={`${currentFormat === key ? 'bg-[#F0F9FF] text-[#0099FF]' : 'bg-white text-[#AAAAAA]'} rounded-full size-10 flex items-center justify-center text-[18px]`}>{letter}</button>
                    )}
                    <button onClick={() => {
                        setIsFormatMenuOpen(prev => !prev)
                    }} className='rounded-full size-10 flex items-center justify-center text-[18px] text-[#AAAAAA]'>
                        <MdMoreHoriz size={28} color='#AAAAAA' />
                    </button>
                </div>
                <h3 className={` w-full text-center text-[15px] ${currentFormat === '' ? 'text-[#444444] ' : 'text-[#0099FF]'}`}>{currentFormat === `` ? 'Select Format' : formats.find(({ key }) => key === currentFormat).title}</h3>
                <div className='flex justify-between items-center'>
                    <label className='text-[16px] text-[#292929]'>Scene {scenes.index || 0}/{scenes?.allScenes?.length || 0}</label>
                    <div className='flex gap-1'>
                        <MdExpandMore size={28} color='#AAAAAA' className='cursor-pointer select-none' onClick={() => { goToScene(scenes.index || 0, editor) }} />
                        <MdExpandLess size={28} color='#AAAAAA' className='cursor-pointer select-none' onClick={() => { goToScene(scenes.index - 2 || 0, editor) }} />
                    </div>
                </div>
            </div>
            <div className='z-50 max-lg:hidden'>
                <DropDown
                    onOpen={() => {
                        setIsDropDownOpen(true)
                        setShowToolTip(false)
                    }}
                    onClose={() => { setIsDropDownOpen(false) }}
                    close={isEditorFocus}
                    trigger={
                        <Tooltip placement={'bottom'} title={showToolTip ? 'Script formats' : ''}>
                            <button onMouseOver={() => {
                                if (!isDropDownOpen)
                                    setShowToolTip(true)
                            }} className={`text-[#292929] text-[14px] hover:bg-[#efefef] bg-white rounded-lg flex justify-between w-36 gap-5 py-1 px-2 border-2 `}>
                                {formats.find(format => format.key === currentFormat)?.title || 'Select...'}
                                <MdExpandMore color='#CACACA' size={24} />
                            </button>
                        </Tooltip>
                    }>
                    <div className='rounded p-2'>
                        {formats.slice().filter(({ key }) => formatClasses[documentType]?.includes(key)).map(({ key, letter, title }) =>
                            <button key={key}
                                onClick={() => {
                                    formatText(editor, key)
                                    setCurrentFormat(key)
                                    setIsFormatMenuOpen(false)
                                    editor?.focus()
                                }}
                                className={`${currentFormat === key ? 'bg-[#F0F9FF]  border-[#0099FF] text-[#0099FF]' : 'bg-white text-[#444444]'} text-[13px] hover:bg-[#e2e2e2] flex justify-start grow min-w-fit w-full gap-5 py-2 px-3 border-b `}>

                                <label className='w-3'>{letter}</label>
                                <label className='cursor-pointer'>{title}</label>
                            </button>)}
                    </div>
                </DropDown>
            </div>
        </>
    )
}

export default ToolBar