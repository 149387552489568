import NotesModal from "./NotesModal"
import BackButton from "../BackButton"
import EmptyPage from "../EmptyPage"

const NotesPanel = ({ notes, getUsersPermission, setPanel }) => {
    return <div className='w-full h-full '>
        <BackButton setPanel={setPanel} />
        <div className='w-full h-[calc(100%-30px)] flex flex-col shadow-sm'>
            {!notes.length &&
                <div className="max-h-[500px]">
                    <EmptyPage title={'You have not added notes'} subtitle={'Add some notes to see here'} />
                </div>}
            <NotesModal permissionToEdit={getUsersPermission()} />

        </div>
    </div>
}
export default NotesPanel