import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAccessToken } from '../../../services/authService'
import { parse } from 'parse5'
import { MdAdb, MdAdd, MdArrowBackIos, MdClose, MdSearch } from 'react-icons/md'
import LoaderSpin from '../../Common/LoaderSpin'
import ScenesTab from '../Scenes/ScenesTab'
import ScriptTab from '../Scenes/ScriptTab'
import SceneCardsTab from './SceneCardsTab'
import { parseToBody } from '../Editor/utils/scriptUtils'
import { getTitle, timeColors } from '../Editor/utils/sceneutils'

const SceneCards = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [isScriptTabActive, setIsScriptTabActive] = useState(true)
    const [searchVal, SetSearchVal] = useState('')
    const [isSearchOpen, setIsSearchOpen] = useState(false)

    const [loading, setLoading] = useState(1)
    const [scriptData, setScriptData] = useState({})
    const [updateData, setUpdateData] = useState(false)
    const [addClickUpdate, setAddClickUpdate] = useState(false)
    const searchRef = useRef(null)

    const fetchScript = async () => {
        setLoading(1)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const body = await res.json()
            setScriptData(body.data)

            setLoading(0)
        } catch (error) {
            setLoading(2)
            console.error(error)
        }
    }

    const { content } = scriptData

    const body = parseToBody(content)
    const htmlObject = parse(content || '')

    function findPNodes(obj) {
        let pNodes = [];

        function traverse(node) {
            if (typeof node !== 'object' || node === null) {
                return;
            }

            if (node.nodeName === 'p') {
                pNodes.push(node);
            }

            if (Array.isArray(node.childNodes)) {
                node.childNodes.forEach(traverse);
            }

            // Check other properties that might contain nested nodes
            ['0', '1', '2'].forEach(key => {
                if (node[key] && typeof node[key] === 'object') {
                    traverse(node[key]);
                }
            });
        }

        traverse(obj);
        return pNodes;
    }

    const allSceneCards = []

    const pNodes = findPNodes(htmlObject) || []

    body.childNodes.forEach(node=>{

        if (node.nodeType === 1) {
            if (node.classList.contains('sceneHeadings')) {
                const { time } = getTitle(node.textContent)
                allSceneCards.push({
                    title: node.textContent,
                    color: node.getAttribute('data-color') || timeColors[time],
                    action: ''
                })
            }

            if (node.classList.contains('action') && allSceneCards[allSceneCards.length - 1]) {
                allSceneCards[allSceneCards.length - 1].action += node.textContent + '\n'
            }
        }
    })

    useEffect(() => {
        searchRef.current?.focus()
    }, [searchRef.current, isSearchOpen])

    useEffect(() => {
        fetchScript()
    }, [updateData])

    return (
        <div className=" bg-white flex flex-col h-full overflow-y-hidden">
            <nav className=' p-4 gap-4 min-h-[72px] max-h-[72px] flex border-b border-[#0000001A] justify-between items-center'>
                {isSearchOpen ?
                    <>
                        <div className='bg-white border grow border-[#CACACA] gap-2 rounded-lg p-3 flex items-center'>
                            <MdSearch size={24} color='#AAAAAA' />
                            <input ref={searchRef} placeholder={`Find scene card`}
                                className='outline-none grow focus:border-none'
                                value={searchVal}
                                onChange={(e) => { SetSearchVal(e.target.value) }} />

                        </div>
                        <MdClose size={28} color='#868686' onClick={() => {
                            setIsSearchOpen(false)
                            SetSearchVal('')
                        }} />
                    </>
                    :
                    <><div className='flex gap-1'>
                        <button className='h-fit pt-1' onClick={() => { navigate(`/scripts/${id}`) }}>
                            <MdArrowBackIos size={22} color='#292929' />
                        </button>
                        <div className=''>
                            <h1 className='text-[#292929] font-bold text-[20px]'>Scene Cards</h1>
                        </div>
                    </div>

                        <div className='flex items-center gap-2'>
                            <button className='pt-1'>
                                <MdSearch size={28} color='#AAAAAA' onClick={() => {
                                    setIsSearchOpen(true)
                                }} />
                            </button>
                            <button className='rounded-full flex items-center justify-center bg-[#0099ff] size-[36px]'>
                                <MdAdd size={28} color='white' onClick={() => {
                                    setAddClickUpdate(prev => !prev)
                                }} />
                            </button>
                        </div>
                    </>}
            </nav>

            <div className='h-full overflow-hidden'>
                {loading === 1 && <div className='h-full flex items-center justify-center'><LoaderSpin size={40} /></div>}
                {loading === 0 && <div className='overflow-y-hidden'>
                    <div className='overflow-y-auto h-[calc(100svh-73px)] pb-10 '>
                        <SceneCardsTab
                            allSceneCards={allSceneCards}
                            searchVal={searchVal}
                            scriptData={scriptData}
                            pNodes={pNodes}
                            body={body}
                            setUpdateData={setUpdateData}
                            addClickUpdate={addClickUpdate}
                            setAddClickUpdate={setAddClickUpdate} />
                    </div>
                </div>}
            </div>

        </div>
    )
}

export default SceneCards