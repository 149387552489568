import { useEffect, useRef, useState } from "react"
import { getTitle, timeColors } from "../Editor/utils/sceneutils";
import { serialize } from "parse5";
import { getAccessToken } from "../../../services/authService";
import { toast } from 'react-toastify'
import LoaderSpin from "../../Common/LoaderSpin";
import SuggestionDropDown from "../Editor/sidepanel/SuggestionDropDown";
import { ColorPicker } from "antd";
import { bodyToString } from "../Editor/utils/scriptUtils";
import { updateContent } from "../ScriptInfo/functions";

const SceneDetailsSlider = ({ setUpdateData, activeScene, setActiveScene, index, scriptData, body, permissionToEdit }) => {

    const [sceneState, setSceneState] = useState(activeScene)
    const [isVisible, setIsVisible] = useState(false)
    const [titleSpit, setTitleSplit] = useState(getTitle(activeScene.title))
    const [isloading, setIsLoading] = useState(false)

    const [introSuggestionOpen, setIntroSuggestionOpen] = useState(false)
    const [timeSuggestionOpen, setTimeSuggestionOpen] = useState(false)

    const menuRef = useRef(null)
    const timeOftheDayList = ['DAY', 'NIGHT', 'MORNING', 'AFTERNOON', 'SUNRISE', 'SUNSET', 'DUSK', 'LATER', 'EVENING']
    const introList = ['INT', 'EXT', 'I/E']

    const close = () => {
        setIsVisible(false)
        setTimeout(() => {
            setActiveScene(false)
        }, 300);
    }

    const handleColorChange = (color) => {

        setSceneState(prev => { return { ...prev, color } })
    }

    const handleTitleChange = (e) => {
        const title = e.target.value
        setTitleSplit(prev => { return { ...prev, title } })
    }

    const setIntro = (intro) => {
        setTitleSplit(prev => { return { ...prev, intro } })
    }

    const handleIntroChange = (e) => {
        const intro = e.target.value
        setIntro(intro)
        setIntroSuggestionOpen(true)
    }

    const setTime = (time) => {
        setTitleSplit(prev => { return { ...prev, time } })
    }
    const handleTimeChange = (e) => {
        const time = e.target.value
        setTime(time)
        setTimeSuggestionOpen(true)
    }


    const handleSummaryChange = (e) => {
        const summary = e.target.value
        setSceneState(prev => { return { ...prev, summary } })
    }

    const handleSave = async () => {
        setIsLoading(true)
        const { title, time, intro } = titleSpit
        const currentScene = body.querySelector('p.sceneHeadings[data-scene-index="' + (index) + '"]')

        if (currentScene) {
            currentScene.setAttribute('data-color', sceneState.color || '')
            currentScene.setAttribute('data-summary', sceneState.summary || '')
            currentScene.textContent = `${intro ? intro + '. ' : ''}${title}${time ? ' - ' + time : ''}`
        }

        const onComplete = ()=>{
            toast.success('Scene Updated')
            setUpdateData(prev => !prev)
        }

        const onError = ()=>{
            toast.error('Scene Update failed')
        }

        const content = bodyToString(body)
        await updateContent(scriptData,content,onComplete, onError)
        setIsLoading(false)
    }



    useEffect(() => {
        setIsVisible(activeScene)
    }, [activeScene])


    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} transition-all duration-200 bottom-0 flex items-end z-20 absolute  w-[100vw] h-[100svh] `}>
            <div ref={menuRef}
                className={`${isVisible ? 'translate-y-0' : 'translate-y-full'} transition-all duration-300 w-full mx-auto  lg:max-h-[calc(100svh-65px)] rounded-t-2xl bg-white shadow pb-2 z-[100]`}>
                <div className="flex justify-between p-4 bg-[#F5F5F5] rounded-t-2xl border-b-2 border-[#F5F5F5]">
                    <button className="text-[#007AFF] font-medium text-[17px] w-[50px]" onClick={close}>Cancel</button>
                    <h2 className="text-[17px] font-bold">Scene</h2>
                    <button className="text-[#007AFF] text-[17px] font-medium w-[50px]" onClick={handleSave}>{isloading ? <LoaderSpin size={17} /> : 'Save'}</button>
                </div>
                <div className="p-4 overflow-y-auto h-[calc(95vh-125px)]">
                    <h1 className="font-bold text-[18px]">Scene Details</h1>
                    <div className="">


                        <div className="flex mt-4 gap-4 ">
                            <div className=" basis-[50%]">
                                <label className="mb-2 text-[14px] text-[#868686]">Scene #</label>
                                <div className="w-full border border-[#CACACA] p-3 text[14px] rounded-lg" >{index}</div>
                            </div>
                            <div className="basis-[50%]" >
                                <label className="mb-2 text-[14px] text-[#868686]">Intro</label>
                                <div className="relative z-10">
                                    <input disabled={!permissionToEdit} onChange={handleIntroChange} onFocus={() => { setIntroSuggestionOpen(true) }} value={titleSpit?.intro} className="w-full uppercase mb-2 border border-[#CACACA] p-3 text-[14px] rounded-lg" />
                                    <SuggestionDropDown suggestions={introList} value={titleSpit?.intro} isOpen={introSuggestionOpen} setIsOpen={setIntroSuggestionOpen} setText={setIntro} />
                                </div>
                            </div>
                        </div>
                        <label className="mb-2 text-[14px] text-[#868686]">Heading</label>
                        <input disabled={!permissionToEdit} value={titleSpit?.title} onChange={handleTitleChange} className="w-full uppercase mb-4  border border-[#CACACA] p-3 text[14px] rounded-lg" />

                        <label className="mb-2 text-[14px] text-[#868686]">Time of day</label>
                        <div className="relative z-10">
                            <input disabled={!permissionToEdit} onChange={handleTimeChange} onFocus={() => { setTimeSuggestionOpen(true) }} value={titleSpit?.time} className="w-full uppercase mb-2 border border-[#CACACA] p-3 text-[14px] rounded-lg" />
                            <SuggestionDropDown suggestions={timeOftheDayList} value={titleSpit?.time} isOpen={timeSuggestionOpen} setIsOpen={setTimeSuggestionOpen} setText={setTime} />
                        </div>

                        <div className="flex mt-4 mb-4 gap-4 items-center">

                            <div className="basis-[50%] grow text-[14px] text-[#868686] border border-[#CACACA] rounded-lg text-start px-3 py-[5px]">
                                <div
                                    className="flex items-center">
                                    <div className="flex gap-2 items-center">
                                        <ColorPicker className="z-0" disabled={!permissionToEdit} value={sceneState.color || timeColors[titleSpit.time] || '#d9dee3'} size="medium" onChange={(_, hex) => { handleColorChange(hex) }} />
                                        <p className="  text-[#868686]">Color</p>
                                    </div>

                                </div>
                            </div>

                            <button disabled={!permissionToEdit} onClick={() => { setSceneState(prev => { return { ...prev, color: '' } }) }}
                                className="basis-[50%] grow text-[14px] text-[#868686] border border-[#CACACA] rounded-lg text-start px-3 py-[10px]">
                                Default Color
                            </button>

                        </div>

                        <label className="mb-2 text-[14px] text-[#868686]">Scene summary</label>
                        <textarea disabled={!permissionToEdit} onChange={handleSummaryChange} value={sceneState?.summary} className="w-full h-[200px]  border border-[#CACACA] p-3 text[14px] rounded-lg" />


                    </div>
                </div>
            </div>
        </div>)
}

export default SceneDetailsSlider