import React from 'react'
import { MdDelete } from 'react-icons/md'

const CharacterButton = ({ onClick, color, name, isDelete = false, onDelete }) => {
  return (
    <button
      onClick={onClick}
      className="group flex rounded-lg border hover:shadow hover:bg-[#f3f3f3] text-start font-medium bg-white text-[#868686] w-full">
      <div style={{ background: color || '#d9dee3' }} className="h-full w-2 rounded-s-lg "></div>
      <div className='flex items-center justify-between grow px-3'>
        <div className="flex text-[12px] max-w-[200px] gap-4 py-2">
          <p className='w-full text-ellipsis break-all'>{name}</p>
        </div>
        {isDelete && <button onClick={(e) => {
          e.stopPropagation()
          onDelete()
        }} className=' hover:text-[#868686] invisible text-[#cacaca] group-hover:visible '>
          <MdDelete color='' size={20} />
        </button>}
      </div>
    </button>
  )
}

export default CharacterButton