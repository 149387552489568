import { useState } from "react"

import { getAccessToken } from "../../../services/authService";

import { toast } from 'react-toastify'
import LoaderSpin from "../../Common/LoaderSpin";
import { MdArrowBackIos, MdExpandMore } from "react-icons/md";
import Dropdown from "../../Common/DropDown";
import { useParams } from "react-router-dom";
import { getNameWithoutbrackets } from "../Editor/utils/characterUtils";
import { Checkbox, ColorPicker } from "antd";

const CharacterDetailsSlider = ({ setUpdateData, characterList, activeCharacter, setActiveCharacter, body, character, permissionToEdit, blockDialogueList, setBlockDialogueList }) => {
    const [characterState, setCharacterState] = useState(character)
    const [isLoading, setIsLoading] = useState(false)
    const [openedTab, setOpenedTab] = useState('DETAILS')
    const [isBlocked, setIsBlocked] = useState(blockDialogueList.find(char=> char.name === activeCharacter))

    const characterId = character?._id
    const { id } = useParams()

    const typeOptions = [
        { label: 'Lead', value: 'LEAD' },
        { label: 'Supporting', value: 'SUPPORTING' },
        { label: 'Featured', value: 'FEATURED' },
        { label: 'Cast', value: 'CAST' },
    ]

    const genderOptions = [
        { label: 'Male', value: 'MALE' },
        { label: 'Female', value: 'FEMALE' },
        { label: 'Other', value: 'OTHER' },
    ]

    let sceneCount = -1
    const sceneList = [];
    const characterStats = { scenes: 0, dialogue: 0, nonSpeaking: 0, voice: 0 }
    const characterName = activeCharacter?.toUpperCase()


    // get character stats
    const traverseNodes = (node, index = 0) => {
        if (node.nodeType === 1) {
            const charNodeName = node.textContent.toUpperCase()
            if (sceneCount >= 0 && node.classList.contains('character') &&
                getNameWithoutbrackets(charNodeName) === getNameWithoutbrackets(characterName)) {

                if (!sceneList.includes(sceneCount)) {
                    characterStats.scenes++
                    sceneList.push(sceneCount)
                }
                if (!charNodeName.includes('(V.O)')) {
                    characterStats.dialogue++
                }
                else {
                    characterStats.voice++
                }
            }
            else


                if (node.classList.contains('sceneHeadings')) {
                    const nonSpeakingList = JSON.parse(node.getAttribute('data-non-speak-character')) || []

                    sceneCount = sceneCount + 1
                    nonSpeakingList.forEach((char) => {
                        if (char === characterName) {
                            characterStats.nonSpeaking++
                            if (!sceneList.includes(sceneCount)) {
                                characterStats.scenes++
                                sceneList.push(sceneCount)
                            }
                        }
                    })
                }

        }
        node.childNodes.forEach((childNode, childIndex) =>
            traverseNodes(childNode, index + childIndex + 1)
        );
    };
    traverseNodes(body);

    const updateCharacter = async () => {
        setIsLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/character/${characterId}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const { name, color, age, gender, type, ethnicity, occupation, orientation, disability, description } = characterState
            const data = { color, type, age: age === '' ? 0 : age, gender, ethnicity, occupation, orientation, disability, description };
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: 'PUT',
                body: JSON.stringify(data)
            })

            const body = await res.json()
            if (!res.ok) {
                throw new Error('Error getting response', body)
            }
            toast.success("Character updated successfully")
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            toast.error('Error updating the character')
        }
    }
    const createCharacter = async () => {
        setIsLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/character/`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const { name, color, age, gender, ethnicity, occupation, type, orientation, disability, description } = characterState
            const data = { documentId: id, name: activeCharacter, type, color, age, gender, ethnicity, occupation, orientation, disability, description: description }

            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: 'POST',
                body: JSON.stringify(data)
            })

            const body = await res.json()
            if (!res.ok) {
                throw new Error('Error getting response', body)

            }
            toast.success("Character updated successfully")
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            toast.error('Error updating the character')
        }
    }

    const handleColorChange = (color) => {

        setCharacterState(prev => { return { ...prev, color } })
    }

    const handledescriptionChange = (e) => {
        const description = e.target.value
        setCharacterState(prev => { return { ...prev, description } })
    }

    const handleAgeChange = (e) => {
        const age = e.target.value
        const ageRegex = /^\d+$/
        if (age === '' || ageRegex.test(age)) {
            setCharacterState(prev => { return { ...prev, age } })
        }
    }

    const handleTypeChange = (type) => {
        setCharacterState(prev => { return { ...prev, type } })
    }

    const handleGenderChange = (gender) => {
        setCharacterState(prev => { return { ...prev, gender } })
    }

    const handleDisabilityChange = (e) => {
        const disability = e.target.value
        setCharacterState(prev => { return { ...prev, disability } })
    }

    const handleOccupationChange = (e) => {
        const occupation = e.target.value
        setCharacterState(prev => { return { ...prev, occupation } })
    }

    const handleEthnicityChange = (e) => {
        const ethnicity = e.target.value
        setCharacterState(prev => { return { ...prev, ethnicity } })
    }

    const handleIsBlockDialogue = (isBlockDialogue) => {
        setIsBlocked(isBlockDialogue)
    }

    const handleOrientationChange = (e) => {
        const orientation = e.target.value
        setCharacterState(prev => { return { ...prev, orientation } })
    }
    const handleSave = async () => {

        if (characterList.find(character => character.name === activeCharacter)) {
            await updateCharacter()
        }
        else {
            await createCharacter()
        }
        

        await setUpdateData(prev => !prev)
        
        console.log('activeCharacter',activeCharacter,blockDialogueList)

        if(isBlocked && !blockDialogueList.find(char=> char.name === activeCharacter)){ 
            setBlockDialogueList(prev=>{
                const newList = [...prev]
                newList.push({name:activeCharacter, color:characterState.color})
                return newList
            })
        }
        else if(!isBlocked && blockDialogueList.find(char=> char.name === activeCharacter)){
            setBlockDialogueList(prev=>{
                const newList = [...prev].filter(char => char.name!==activeCharacter)
                return newList
            })
        }
        else if (isBlocked && blockDialogueList.find(char=> char.name === activeCharacter)){
            setBlockDialogueList(prev=>{
                const newList = [...prev]
                newList.find(char=> char.name === activeCharacter).color = characterState.color
                return newList
            })
        }

        setActiveCharacter(null)

    }


    return (
        <div className="fixed bg-white top-0 left-0 h-[100svh] w-[100svw]" >

            <div className="overflow-hidden border-b p-4">
                <div className="flex pb-0 justify-between items-center ">
                    <div className="flex items-center  ">
                        <button onClick={() => { setActiveCharacter(null) }} className="w-fit">
                            <MdArrowBackIos size={24} />
                        </button>
                        <h2 className="text-[20px] font-bold">Character</h2>
                    </div>
                    <button className="text-[#007AFF] text-[15px] font-medium" onClick={handleSave}>{isLoading ? <LoaderSpin size={17} /> : 'Save'}</button>
                </div>
                <div className='overflow-y-hidden w-full mt-5 flex p-1 gap-1 rounded-[9px] bg-[#EFEFEF]'>
                    <button className={` grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${openedTab === 'DETAILS' && 'bg-white shadow-md'}`}
                        onClick={() => { setOpenedTab('DETAILS') }}>
                        Details
                    </button>
                    <button className={`grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${openedTab === 'ABOUT' && 'bg-white shadow-md'}`}
                        onClick={() => { setOpenedTab('ABOUT') }}>
                        About
                    </button>

                </div>
            </div>

            {openedTab === 'DETAILS' && <div className="overflow-y-auto text-[14px] h-[calc(100svh-119px)] p-4">
                <label className=" text-[14px] text-[#868686]">Character Name</label>
                <div className="w-full mt-2 mb-4 border border-[#CACACA] p-3 text-[14px] rounded-lg">
                    {activeCharacter}
                </div>

                <label className=" text-[14px] text-[#868686]">Character Type</label>

                <Dropdown disabled={!permissionToEdit} close={characterState?.type} className={'w-full mt-2 mb-4 relative'} alignment="left" trigger={
                    <button disabled={!permissionToEdit} className="bg-white hover:bg-[#efefef] text-[14px] text-start rounded-lg flex items-center justify-between border text-[#292929] p-3 w-full ">
                        {typeOptions.find(type => type.value === characterState?.type)?.label || 'Character type'}
                        <MdExpandMore size={18} color="#CACACA" />
                    </button>}>

                    <div className='w-[200px] flex flex-col'>
                        {typeOptions.map((option) => (
                            <button
                                disabled={!permissionToEdit}
                                style={{
                                    color: characterState?.type === option.value ? '#0099ff' : '#868686',
                                    background: characterState?.type === option.value ? '#f0f9ff' : ''
                                }}
                                onClick={() => { handleTypeChange(option.value) }} className="w-full border-b hover:bg-[#e2e2e2] text-start text-[14px] px-3 py-2" key={option.value}>
                                {option.label}
                            </button>
                        ))}
                    </div>

                </Dropdown>

                <div className="flex mt-2 mb-2 gap-4">
                    <div className="basis-[50%]">
                        <label className=" text-[14px] text-[#868686] ">Age</label>
                        <input disabled={!permissionToEdit} value={characterState?.age || ''} onChange={handleAgeChange} className="w-full mt-2 mb-4 border border-[#CACACA] p-3 text-[14px] rounded-lg" />
                    </div>
                    <div className="basis-[50%]">
                        <label className="mb-2 text-[14px] text-[#868686]">Gender</label>
                        <Dropdown disabled={!permissionToEdit} close={characterState?.gender} className={'w-full mt-2 mb-4'} alignment="left" trigger={
                            <button disabled={!permissionToEdit} className="bg-white hover:bg-[#efefef] text-[14px] text-start rounded-lg flex items-center justify-between border text-[#292929] p-3 w-full ">
                                {genderOptions.find(gender => gender.value === characterState?.gender)?.label || 'Gender'}
                                <MdExpandMore size={18} color="#CACACA" />
                            </button>}>

                            <div className='w-[100px] flex flex-col'>
                                {genderOptions.map((option) => (
                                    <button disabled={!permissionToEdit} onClick={() => { handleGenderChange(option.value) }}
                                        style={{
                                            color: characterState?.gender === option.value ? '#0099ff' : '#868686',
                                            background: characterState?.gender === option.value ? '#f0f9ff' : ''
                                        }}
                                        className="w-full border-b text-[#868686] text-start text-[14px] px-3 py-2 hover:bg-[#e2e2e2]" key={option.value}>
                                        {option.label}
                                    </button>
                                ))}
                            </div>

                        </Dropdown>


                    </div>

                </div>
                <label className="mb-2 text-[14px] text-[#868686]">Character color</label>
                <div className="flex gap-4">
                    <div className="grow basis-[40%]">
                        <div className="flex gap-4 w-full items-center mb-4 mt-2 z-0">
                            <div
                                className="flex grow justify-between border p-3 h-[45px] basis-[40%]  border-[#CACACA] items-center rounded-lg">
                                <div className="flex gap-2 items-center">
                                    <ColorPicker disabled={!permissionToEdit} className="z-0" value={characterState?.color || '#d9dee3'} size="medium" onChange={(_, hex) => { handleColorChange(hex) }} />
                                    <p className="  text-[#868686]">Color</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="grow basis-[40%]">
                        <div className="flex gap-4 w-full items-center mb-4 mt-2 z-0">
                            <div
                                className="flex grow justify-between border p-3 h-[45px] basis-[40%]  border-[#CACACA] items-center rounded-lg">
                                <div className="flex gap-2 items-center">
                                    <Checkbox checked={isBlocked} onChange={(e)=>handleIsBlockDialogue(e.target.checked)} className="z-0"  size="large"  />
                                    <p className="  text-[#868686]">Block dailogue</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <label className="mb-2 text-[14px] text-[#868686]">Ethnicity</label>
                <input disabled={!permissionToEdit} value={characterState?.ethnicity} onChange={handleEthnicityChange} className="w-full mt-2 mb-4 border border-[#CACACA] p-3 text-[14px] rounded-lg" />

                <label className="mb-2 text-[14px] text-[#868686]">Occupation</label>
                <input disabled={!permissionToEdit} value={characterState?.occupation} onChange={handleOccupationChange} className="w-full mt-2 mb-4 border border-[#CACACA] p-3 text-[14px] rounded-lg" />

                <label className="mb-2 text-[14px] text-[#868686]">Orientation</label>
                <input disabled={!permissionToEdit} value={characterState?.orientation} onChange={handleOrientationChange} className="w-full mt-2 mb-4 border border-[#CACACA] p-3 text-[14px] rounded-lg" />

                <label className="mb-2 text-[14px] text-[#868686]">Disability</label>
                <input disabled={!permissionToEdit} value={characterState?.disability} onChange={handleDisabilityChange} className="w-full mt-2 mb-4 border border-[#CACACA] p-3 text-[14px] rounded-lg" />


                <label className="mb-2 text-[14px] text-[#868686]">Character description</label>
                <textarea disabled={!permissionToEdit} onChange={handledescriptionChange} value={characterState?.description} className="w-full h-[150px] mt-2 mb-4 border border-[#CACACA] p-3 py-2 text-[14px] rounded-lg" />

                <button disabled={!permissionToEdit} onClick={handleSave} className="bg-[#0099ff] p-3 rounded-full text-white mt-2 mb-4 font-medium w-full">
                    {isLoading ? <LoaderSpin color={'white'} /> : 'Save'}

                </button>

            </div>}

            {openedTab === 'ABOUT' && <div className="overflow-y-auto text-[14px] h-[calc(100svh-119px)]">
                <div className="px-4 py-2  flex items-center justify-between border-t">
                    <p className="text-[16px] font-medium text-[#292929]">
                        Scenes
                    </p>
                    <p className="text-[15px] text-[#868686]">
                        {characterStats.scenes}
                    </p>
                </div>

                <div className="px-4 py-2  flex items-center justify-between border-t">
                    <p className="text-[16px] font-medium text-[#292929]">
                        Dialogue
                    </p>
                    <p className="text-[15px] text-[#868686]">
                        {characterStats.dialogue}
                    </p>
                </div>

                <div className="px-4 py-2  flex items-center justify-between border-t">
                    <p className="text-[16px] font-medium text-[#292929]">
                        Non-Speaking
                    </p>
                    <p className="text-[15px] text-[#868686]">
                        {characterStats.nonSpeaking}
                    </p>
                </div>

                <div className="px-4 py-2  flex items-center justify-between border-t">
                    <p className="text-[16px] font-medium text-[#292929]">
                        Voice Over
                    </p>
                    <p className="text-[15px] text-[#868686]">
                        {characterStats.voice}
                    </p>
                </div>
            </div>}
        </div>)
}

export default CharacterDetailsSlider