import React from "react";

const CustomButton = ({
  type = "default",
  customClassName = "",
  children,
  handleClick,
  loading = false,
  disabled = false,
}) => {
  if (type === "light")
    return (
      <>
        <div className={`w-[343px] h-14 justify-start items-start inline-flex ${customClassName}`}>
          {disabled ? (
            <div className="grow shrink basis-0 h-14 p-4 bg-neutral-100 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
              <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                {children}
              </div>
            </div>
          ) : loading ? (
            <div className="grow shrink basis-0 h-14 p-4 bg-slate-50 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-not-allowed">
              <div className="text-sky-500 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                <div className="w-8 h-8 border-4 border-neutral-300 border-t-transparent border-solid rounded-full animate-spin"></div>
              </div>
            </div>
          ) : (
            <div
              className="grow shrink basis-0 h-14 p-4 bg-slate-50 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
              onClick={handleClick}
            >
              <div className="text-sky-500 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                {children}
              </div>
            </div>
          )}
        </div>
      </>
    );

  if (type === "light2") {
    return (
      <div className={`w-[343px] h-14 justify-start items-start inline-flex ${customClassName}`}>
        <div
          className="grow shrink basis-0 h-14 p-4 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
          onClick={handleClick}
        >
          <div className="text-sky-500 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            {children}
          </div>
        </div>
      </div>
    );
  }
  if (type === "extraLight") {
    return (
      <div className={`w-[343px] h-14 justify-start items-start inline-flex ${customClassName}`}>
        {disabled ? (
          <div className="grow shrink basis-0 h-14 p-4 bg-neutral-100 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
            <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
              {children}
            </div>
          </div>
        ) : (
          <div
            className="grow shrink basis-0 h-14 p-4 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
            onClick={handleClick}
          >
            <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
              {children}
            </div>
          </div>
        )}
      </div>
    );
  }
  if (type === "social") {
    return (
      <div
        className={`w-[343px] h-14 p-4 bg-white rounded-[32px] border border-neutral-200 justify-center items-center gap-2 inline-flex hover:bg-stone-100 cursor-pointer ${customClassName}`}
        onClick={handleClick}
      >
        <div className="text-zinc-600 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight flex flex-row gap-2">
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className={`w-[343px] h-14 justify-start items-start inline-flex ${customClassName}`}>
      {disabled ? (
        <div className="grow shrink basis-0 h-14 p-4 bg-neutral-100 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
          <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            {children}
          </div>
        </div>
      ) : loading ? (
        <div className="grow shrink basis-0 h-14 p-4 bg-sky-500 rounded-[32px] justify-center items-center gap-2 flex cursor-not-allowed">
          <div className="text-white text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            <div className="w-8 h-8 border-4 border-sky-600 border-t-transparent border-solid rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div
          className="grow shrink basis-0 h-14 p-4 bg-sky-500 rounded-[32px] justify-center items-center gap-2 flex hover:bg-sky-400 cursor-pointer"
          onClick={handleClick}
        >
          <div className="text-white text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomButton;
