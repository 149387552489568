import React, { useEffect, useState } from 'react'
import './script.css'
import EmptyPage from '../Editor/sidepanel/EmptyPage';

const ScriptTab = ({content,sceneIndex}) => {
  // const [isScriptEmpty,setIsScriptEmpty] = useState(false)
  function isHtmlEmpty(html) {
    if (!html) return true;
    
    // Convert common HTML entities to characters
    const decodedHtml = html
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
    
    // Remove all HTML tags
    const textContent = decodedHtml.replace(/<[^>]*>/g, '');
    
    // Check if remaining content is just whitespace
    return !textContent.trim();
}

// Test cases
const tests = [
    { input: '', expected: true },
    { input: null, expected: true },
    { input: undefined, expected: true },
    { input: '   ', expected: true },
    { input: '&nbsp;&nbsp;', expected: true },
    { input: '<p></p>', expected: true },
    { input: '<p>  </p>', expected: true },
    { input: '<div><span></span></div>', expected: true },
    { input: '<div>&nbsp;</div>', expected: true },
    { input: 'Hello', expected: false },
    { input: '<p>Hello</p>', expected: false },
    { input: '&nbsp;Hi&nbsp;', expected: false },
    { input: '<div><span>Text</span></div>', expected: false }
];

// Run tests
tests.forEach((test, index) => {
    const result = isHtmlEmpty(test.input);
    console.log(
        `Test ${index + 1}: ${result === test.expected ? 'PASS' : 'FAIL'} `,
        `Input: "${test.input}", `,
        `Expected: ${test.expected}, `,
        `Got: ${result}`
    );
});

const isEmpty = isHtmlEmpty(content)

useEffect(() => {
  const sceneNode = document.querySelector('p.sceneHeadings[data-scene-index="' + (sceneIndex) + '"]')
  if (sceneNode) sceneNode.scrollIntoView()
}, [sceneIndex])

  return (
    <div className='py-4 max-w-[100svw] overflow-x-hidden'>
      {isEmpty?
      <EmptyPage title={"Script is empty"} />
      :<div className='scriptHolder' dangerouslySetInnerHTML={{__html:content}}></div>}
    </div>
  )
}

export default ScriptTab