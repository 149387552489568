import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import Login from "./components/Login";
import SplashScreen from "./components/SplashScreen";
import Register from "./components/Register";
import Home from "./components/Home";
import ForgotPassword from "./components/ForgotPassword";
import englishFlag from "./assets/english.svg";
import morphosys from "./assets/morphosys.png";
import WelcomeScreens from "./components/WelcomeScreens";

import logoWhiteBg from "./assets/morphosys-logo.png";
import Dashboard from "./components/Dashboard";

import images1 from "./assets/image 1.png";
import images3 from "./assets/image 3.png";
import images5 from "./assets/image 5.png";
import avatar from "./assets/profile_avatar.png";
import imageSrc from './assets/backgrounds/connection_error.webp'

import emailsImg from "./assets/email-transparent-bg.png";
import resetSuccess from "./assets/resetSuccess.png";
import { useEffect, useRef, useState } from "react";
import ProtectedRoute from "./utils/ProtectedRoute";
import DesktopDashboard from "./components/DesktopDashboard";
import { logout } from "./store/authSlice";
import PublicRoute from "./utils/PublicRoute";
import MyProfile from "./components/MyProfile";
import ProfileInfo from "./components/MyProfile/ProfileInfo";
import ChangePassword from "./components/MyProfile/ChangePassword";
import Scripts from "./components/scripts";
import CreateScript from "./components/scripts/CreateScripts.js";
import NavBar from "./components/NavBar/index.js";
import Editor from "./components/scripts/Editor/index.js";
import Invite from "./components/scripts/Invite/index.js";
import AcceptInvite from "./components/scripts/Invite/AcceptInvite.js";
import ResponsiveReRoute from "./utils/ResponsiveReRoute.js";
import ScriptInfo from "./components/scripts/ScriptInfo/index.js";
import { setConnectionErrorOpen, setIsTranslateOpen } from "./store/navSlice.js";
import ErrorPopUp from "./components/Common/ErrorPopUp.js";
import Subscription from "./components/MyProfile/Subscription/index.js";
import Scenes from "./components/scripts/Scenes/index.js";
import AlexAI from "./components/scripts/Alex/index.js"
import Characters from "./components/scripts/Characters/index.js";
import Notes from "./components/scripts/Notes/notes.js"
import CategoriesAndElements from "./components/scripts/CategoriesAndElements/index.js";
import SceneCards from "./components/scripts/SceneCards/index.js";
import Revisions from "./components/scripts/Revisions/index.js";
import GoogleCallback from "./utils/GoogleCallback.js";
import { Modal } from "antd";
import GoogleTranslate from "./utils/Translate.js";
import LanguageSelector from "./utils/LanguageSelector.js";

function App() {
  const dispatch = useDispatch();
  const counterReducer = useSelector((state) => state.counter);

  let session =
    useSelector((state) => state?.auth?.session) ||
    JSON.parse(localStorage.getItem("session"));

  const [notebookImg, setNotebookImg] = useState(null);
  const [writingImg, setWritingImg] = useState(null);
  const [chattingImg, setChattingImg] = useState(null);
  const [emailImg, setEmailImg] = useState(null);
  const [lockImg, setLockImg] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [connectionImg, setConnectionImg] = useState(null)
  const isConncetionErrorOpen = useSelector((state) => state.nav.isConnectionErrorOpen)

  const isTranslateOpen = useSelector((state) => state.nav.isTranslateOpen)

  const dropdownRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    if (dropdownRef.current) {
      document.addEventListener("mousedown", checkIfClickedOutside);
    }

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdownRef, showMenu]);

  useEffect(() => {
    // Preload the images
    const img = new Image();
    img.src = images1;
    img.onload = () => setNotebookImg(images1);

    const img0 = new Image();
    img0.src = imageSrc;
    img0.onload = () => setConnectionImg(imageSrc);

    const img1 = new Image();
    img1.src = images5;
    img1.onload = () => setWritingImg(images5);

    const img2 = new Image();
    img2.src = images3;
    img2.onload = () => setChattingImg(images3);

    const img3 = new Image();
    img3.src = emailsImg;
    img3.onload = () => setEmailImg(emailsImg);

    const img4 = new Image();
    img4.src = resetSuccess;
    img4.onload = () => setLockImg(resetSuccess);
  }, []);

  useEffect(() => {
    // Initial check
    if (navigator.onLine) {
      dispatch(setConnectionErrorOpen(false));
    } else {
      dispatch(setConnectionErrorOpen(true));
    }

    // Event listeners for online and offline events
    const handleOnline = () => dispatch(setConnectionErrorOpen(false));
    const handleOffline = () => dispatch(setConnectionErrorOpen(true));

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <div className="">
        <div className="hidden">
        <GoogleTranslate />
        </div>
      <Modal open={isTranslateOpen} onCancel={() => {
          dispatch(setIsTranslateOpen(false))
        }} footer={false} height={500} title={"Select Language"}>
          <div className="h-[50px] skiptranslate">
            <LanguageSelector/>
          </div>
        </Modal>
        {isConncetionErrorOpen && <ErrorPopUp isOpen={isConncetionErrorOpen} imageSrc={connectionImg} />}
        <Routes>
          <Route element={<NavBar />}>
            <Route element={<ProtectedRoute />}>
              <Route path="/scripts" element={<Scripts />} />
              <Route path="/editor/:id" element={<Editor />} />
              <Route path="/myprofile/subscription" element={<Subscription />} />
              <Route path="/invite/:id" element={<AcceptInvite />} />
            </Route>
          </Route>
          <Route element={<ResponsiveReRoute isLargeRoute={false} />}>
            <Route element={<NavBar />}>
              <Route element={<ProtectedRoute />}>
                <Route path="/myprofile" element={<MyProfile />} />
                <Route path="/myprofile/profileinfo" element={<ProfileInfo />} />
                <Route path="/myprofile/changepass" element={<ChangePassword />} />
                <Route path="/scripts/:id/alex" element={<AlexAI />} />
                <Route path="/scripts/create/:type" element={<CreateScript />} />
                <Route path="/scripts/invite/:id" element={<Invite />} />
                <Route path="/scripts/:id" element={<ScriptInfo />} />
                <Route path="/scripts/:id/alex" element={<AlexAI />} />
                <Route path="/scripts/:id/elements" element={<CategoriesAndElements />} />
                <Route path="/scripts/:id/scenes" element={<Scenes />} />
                <Route path="/scripts/:id/scenecards" element={<SceneCards />} />
                <Route path="/scripts/:id/characters" element={<Characters />} />
                <Route path="/scripts/:id/revisions" element={<Revisions />} />
                <Route path="/scripts/:id/notes" element={<Notes />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>


      <Routes>


        <Route
          element={
            <div className="App h-[96vh]  lg:h-[100vh] flex flex-col justify-center items-center">
              <header className="bg-[#FFA300] w-full max-lg:hidden fixed header top-0 h-[7vh]">
                <div className="relative w-full h-full overflow-hidden">
                  <div className="top-triangle left-[1%] w-[3%] h-[90%] absolute z-[4] rotate-[-25deg] top-[-10.5%]"></div>
                  <div className="h-[999px] w-[999px] left-[50%] top-[-2%] dark-blue-circle-bg rounded-full z-[2] absolute" />

                  <div className="flex flex-row z-[15] w-full h-full gap-[19px] items-center justify-between font-logo p-4 relative">
                    <img className="h-16 max-h-[7vh]" src={logoWhiteBg} />

                  </div>
                </div>
              </header>
              <div
                className={`h-full w-full lg:max-h-[760px] lg:max-w-[500px] lg:m-auto lg:rounded-xl  mobile-modal relative bg-[#FFA300] overflow-hidden`}
              >
                <div className="top-triangle w-[42%] h-[19%] absolute z-[4] rotate-[-25deg] top-[-10.5%]"></div>
                <div className="h-[999px] w-[999px] left-[-13%] top-[-2%] dark-blue-circle-bg rounded-full z-[2] absolute" />
                <Outlet />
              </div>
            </div>
          }
        >
          <Route path="/register" element={<Register emailImg={emailImg} />} />
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<SplashScreen />} />
            <Route
              path="/welcome"
              element={
                <WelcomeScreens
                  notebookImg={notebookImg}
                  writingImg={writingImg}
                  chattingImg={chattingImg}
                />
              }
            />
            <Route path="/login" element={<Login />} />

            <Route
              path="/forgot-password"
              element={<ForgotPassword emailImg={emailImg} lockImg={lockImg} />}
            />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Route>
        <Route path="/auth/callback" element={<GoogleCallback />} />
      </Routes>
    </>
  );
}

export default App;
