import React, { useState } from "react";
import CustomButton from "../CustomButton";
import { useNavigate } from "react-router-dom";

import image1 from "../../assets/image 1.png";
import image3 from "../../assets/image 3.png";
import image5 from "../../assets/image 5.png";

import morphosys from "../../assets/morphosys.png";

const WelcomeScreens = ({ notebookImg, writingImg, chattingImg }) => {
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const handleNext = () => {
    setPage(page + 1);
  };

  const handleSkip = () => {
    // navigate("/login");
    setPage(2);
  };
  return (
    <>
      <div className=" flex flex-col justify-between h-full ">
        <div className="z-20  w-full p-[18px] lg:py-[10px] flex-row flex justify-center items-center mx-auto ">
          <div className="flex flex-col items-center lg:gap-4 gap-[20px]">
            <div className="text-white text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
              Morphosys
            </div>
            {page === 0 ? (
              <div>
                <img className="xl:w-[278px] xl:h-[unset] lg:h-[150px] lg:w-[unset] w-[278px]" src={notebookImg} />
              </div>
            ) : page === 1 ? (
              <img className="xl:w-[278px] xl:h-[unset] lg:h-[150px] lg:w-[unset] w-[278px]" src={writingImg} />
            ) : (
              <img className="xl:w-[278px] xl:h-[unset] lg:h-[150px] lg:w-[unset] w-[278px]" src={chattingImg} />
            )}
          </div>
        </div>
        <div
          className={`lg:min-h-[50%] lg:h-fit h-[50%] rounded-tl-3xl rounded-tr-3xl  w-full bg-white  z-20 lg:rounded-xl overflow-y-auto`}
        >
          {page === 0 && (
            <div className="flex gap-4 flex-col items-center h-full justify-evenly pt-[20px] lg:py-2 pb-[18px] px-4 ">
              <div className="w-[343px] lg:text-xl text-center text-zinc-800 text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                Create your script with industry standard formatting
              </div>
              <div className="w-[343px] text-center text-neutral-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                Alex your AI Co-Pilot is here to assist you with your
                creativity.
              </div>
              <div className="w-[54px] h-2.5 justify-start items-start gap-3 inline-flex">
                <div className="w-2.5 h-2.5 bg-sky-500 rounded-full" />
                <div className="w-2.5 h-2.5 bg-stone-300 rounded-full" />
                <div className="w-2.5 h-2.5 bg-stone-300 rounded-full" />
              </div>
              <div className="w-[343px] h-14 justify-start items-start inline-flex">
                <div
                  className="grow shrink basis-0 h-14 p-4 bg-slate-50 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
                  onClick={handleNext}
                >
                  <div className="text-sky-500 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                    Go to Next
                  </div>
                </div>
              </div>
              <div className="w-[343px] h-14 justify-start items-start inline-flex">
                <div
                  className="grow shrink basis-0 h-14 p-4 rounded-[32px] justify-center items-center gap-2 flex hover:bg-slate-100 cursor-pointer"
                  onClick={handleSkip}
                >
                  <div className="text-neutral-400 text-base font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                    Skip
                  </div>
                </div>
              </div>
            </div>
          )}
          {page === 1 && (
            <div className="flex gap-4 flex-col items-center h-full justify-evenly pt-[20px] pb-[18px] px-4 ">
              <div className="w-[343px] text-center text-zinc-800 text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                Writing. Take notes. <br />
                Give feedback
              </div>
              <div className="w-[343px] text-center text-neutral-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                You can provide notes and feedback easily.
              </div>
              <div className="w-[54px] h-2.5 justify-start items-start gap-3 inline-flex">
                <div className="w-2.5 h-2.5 bg-stone-300 rounded-full" />
                <div className="w-2.5 h-2.5 bg-sky-500 rounded-full" />
                <div className="w-2.5 h-2.5 bg-stone-300 rounded-full" />
              </div>
              <CustomButton type="light" handleClick={handleNext}>
                Go to Next
              </CustomButton>
              <CustomButton type="light" handleClick={handleSkip}>
                Skip
              </CustomButton>
            </div>
          )}
          {page === 2 && (
            <div className="flex gap-4 lg:h-fit xl:py-[unset] lg:py-2 flex-col items-center h-full justify-evenly pt-[20px] pb-[18px] px-4 ">
              <div className="w-[343px] text-center text-zinc-800 text-2xl font-bold font-['Eudoxus Sans'] leading-loose">
                Create your Script and Collaborate with your Team
              </div>
              <div className="w-[343px] text-center text-neutral-500 text-base font-normal font-['Eudoxus Sans'] leading-normal tracking-tight">
                How great is this? Yes, you can collaborate in real time, and on
                all devices.
              </div>
              <div className="w-[54px] h-2.5 justify-start items-start gap-3 inline-flex">
                <div className="w-2.5 h-2.5 bg-stone-300 rounded-full" />
                <div className="w-2.5 h-2.5 bg-stone-300 rounded-full" />
                <div className="w-2.5 h-2.5 bg-sky-500 rounded-full" />
              </div>
              <CustomButton
                handleClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </CustomButton>
              <CustomButton
                type="light"
                handleClick={() => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("session");
                  navigate("/register");
                }}
              >
                Sign Up
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WelcomeScreens;
