"use client";

import React, { useRef, useEffect } from 'react';

export function ClickOutsideListener({
  children,
  className = "",
  idsToExclude,
  style = null,
  onOutsideClick
}) {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      const target = event.target;
      let shouldCallOutsideClick = true;
      if (target?.id && idsToExclude.length > 0 && idsToExclude.includes(target.id)) shouldCallOutsideClick = false;
      if (shouldCallOutsideClick) onOutsideClick(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!!style) return <div className={className} ref={wrapperRef} style={{ ...style }}>{children}</div>;
  return <div className={className} ref={wrapperRef}>{children}</div>;
};