import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setNotes } from "../../../../../store/scriptSlice";
import { toast } from "react-toastify";
import { MdDelete, MdDeleteOutline, MdEdit } from "react-icons/md";
import { getParentPElement } from "../../utils/formatUtils";
const NotesModal = ({ permissionToEdit = false }) => {
    const [visible, setVisible] = useState(false);
    const notes = useSelector((state) => state.script.notes);
    const editorRef = useSelector((state) => state.script.editorRef);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedNoteId, setSelectedNoteId] = useState(null);
    const isEditorFocus = useSelector(state => state.script.isEditorFocus)
    const [editVisible, setEditVisible] = useState(false)
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
        setEditVisible(false)
    };

    const getCurrentTimeString = () => {
        const now = new Date();
        return `${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getDate()).padStart(2, '0')}/${String(now.getFullYear()).slice(-2)}`;
    };

    const handleSave = () => {
        console.log('selectedpele')
        if (editVisible) {
            handleEditSave()
            return
        }
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                const range = editorRef.selection.getRng();
                const selectedNode = getParentPElement(editorRef.selection.getNode())
                
                const editor = editorRef;
                editor.selection.setRng(range);
                editor.focus();
                const noteId = notes.reduce((acc, note) => Math.max(acc, note.noteId), 0) + 1
                const noteTime = getCurrentTimeString()
                const noteUser = JSON.parse(localStorage.getItem('user')).name

                const noteList = JSON.parse(selectedNode.getAttribute("data-note-list") || "null") || []

                noteList.push({ noteId, text: values.notes, user: noteUser, time: noteTime })

                selectedNode.setAttribute(`data-note-id-${noteId}`, true)
                selectedNode.setAttribute("data-note-list", JSON.stringify(noteList))

                range.collapse(false);
                editor.selection.setRng(range);
                editor.focus();

                editor.save();
                dispatch(
                    setNotes([...notes, { noteId, text: values.notes, time: noteTime, user: noteUser }])
                );

                scrollToNoteInIframe(noteId)
                setVisible(false);
                editor.fire("change")
            })
            .catch((info) => {
                console.error("Validate Failed:", info);
            });
    };

    const handleEditSave = () => {
        form
            .validateFields()
            .then((values) => {

                const editor = editorRef;
                const body = editor.getBody();

                const noteId = selectedNoteId

                const selectedNode = body.querySelector(
                    `p[data-note-id-${noteId}]`
                );
                const noteTime = getCurrentTimeString()
                const noteUser = JSON.parse(localStorage.getItem('user')).name
                const noteList = JSON.parse(selectedNode.getAttribute("data-note-list") || "null") || []

                const updateIndex = noteList.findIndex((note) => note.noteId === noteId)

                noteList[updateIndex].text = values.notes
                noteList[updateIndex].user = noteUser
                noteList[updateIndex].time = noteTime

                selectedNode.setAttribute("data-note-list", JSON.stringify(noteList))

                editor.focus();

                editor.save();


                scrollToNoteInIframe(noteId)
                setEditVisible(false);
                setVisible(false)
                editor.fire("change")
                form.resetFields();
            })
            .catch((info) => {
                console.error("Validate Failed:", info);
            });
    }

    const removePreviousActiveNotes = (body) => {
        body.childNodes.forEach(node => {
            if (node.getAttribute("data-active-note")) {
                node.removeAttribute("data-active-note")
            }
        });
    }

    const scrollToNoteInIframe = (noteId, sceneIndex) => {
        const editor = editorRef;

        if (!editor) {
            console.warn("Editor not found.");
            return;
        }


        const body = editor.getBody();

        const notePara = body.querySelector(
            `p[data-note-id-${noteId}]`
        );
        const scene = body.querySelector(
            `.sceneHeadings[data-scene-index='${sceneIndex}']`
        );


        if (notePara) {
            removePreviousActiveNotes(body)
            notePara.setAttribute('data-active-note', "true")
            if (scene) {
                scene.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            console.warn(`Note with ID ${noteId} not found.`);
        }

    };

    const handleAddNote = () => {
        const selectedContent =  editorRef.selection.getContent();
        if (selectedContent?.length === 0) {
            toast.error("Please select some text to add a note.");
            return;
        } else {
            showModal();
        }
    };

    const handleDeleteNote = (noteId) => {
        const editor = editorRef;
        const body = editor.getBody();
        const notePara = body.querySelector(
            `p[data-note-id-${noteId}]`
        );
        const range = editor.selection.getRng();


        if (notePara) {
            const noteList = JSON.parse(notePara.getAttribute('data-note-list') || "null") || []
            const newNoteList = noteList.filter(note => note.noteId !== noteId)

            notePara.setAttribute('data-note-list', JSON.stringify(newNoteList))
            notePara.removeAttribute(`data-note-id-${noteId}`)
            if (newNoteList.length === 0) {
                notePara.removeAttribute('data-note-list')
            }
        }

        const updatedNotes = notes.filter((note) => note.noteId !== noteId);
        dispatch(setNotes(updatedNotes));
        removePreviousActiveNotes(body)
        // Update the content of the editor
        editor.save();
        editor.fire("change")
        setDeleteModalVisible(false);
    };

    useEffect(() => {
        const body = editorRef.getBody()
        body.setAttribute("data-show-notes", "")
        return () => { body.removeAttribute("data-show-notes") }
    })

    useEffect(() => {
        if (editorRef && isEditorFocus) {
            removePreviousActiveNotes(editorRef.getBody())
        }

    }, [isEditorFocus])

    console.log('noteList', notes)

    return (
        <>
            {permissionToEdit &&
                <Button type="primary" className="w-full mt-4" onClick={handleAddNote}>
                    Add Note
                </Button>
            }
            <div className="grow max-h-[calc(100%-60px)] mt-2 px-3 pb-4 w-full overflow-auto">
                {notes.map((note, index) => (
                    <button
                        key={index}
                        className="mt-2 w-full focus:shadow-lg text-start h-fit group cursor-pointer relative border shadow-sm p-2 py-[2px] text-[12px] rounded-lg"
                        onClick={() => {
                            scrollToNoteInIframe(note.noteId, note.scene);
                        }}
                    >

                        <div className="flex justify-between text-[10px] text-[#868686] items-center">
                            <p>{note.user}</p>
                            <p>scene {note.scene}</p>

                        </div>
                        <div className=" flex justify-between items-center">
                            <pre className="text-[#292929] whitespace-pre-wrap break-words overflow-auto Eudoxus">{note.text}</pre>
                        </div>
                        <div className="flex justify-end text-[9px] text-[#868686] items-center">
                            <p>{note.time}</p>
                        </div>

                        <button
                            className="bg-white rounded-full text-red-400 transition-all size-7 shadow-lg border flex justify-center items-center absolute -bottom-2 -right-2 opacity-0 group-hover:opacity-100"
                            disabled={!permissionToEdit}
                            onClick={(e) => {
                                setSelectedNoteId(note.noteId);
                                setDeleteModalVisible(true);
                            }}
                        >
                            <MdDelete size={14} />
                        </button>
                        <button
                            className="bg-white rounded-full text-gray-400 transition-all size-7 shadow-lg border flex justify-center items-center absolute -top-2 -right-2 opacity-0 group-hover:opacity-100"
                            disabled={!permissionToEdit}
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedNoteId(note.noteId);
                                form.setFieldValue("notes", note.text)
                                setEditVisible(true)
                                setVisible(true)
                            }}
                        >
                            <MdEdit size={14} />
                        </button>


                    </button>
                ))}
            </div>

            <Modal
                title="Save Note"
                open={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave}>
                        Save
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" name="notes_form">
                    <Form.Item
                        name="notes"
                        label="Notes"
                        rules={[{ required: true, message: "Please input your note!" }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Delete Note"
                open={deleteModalVisible}
                onCancel={() => setDeleteModalVisible(false)}
                onOk={() => { handleDeleteNote(selectedNoteId) }}
            >
                <p>Are you sure you want to delete this note?</p>
            </Modal>
        </>
    );
};

export default NotesModal;
