import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

const CharacterMenu = ({ characterMenuY, setCharacterMenuY, editor }) => {
  const menuRef = useRef(null)
  const isEditorFocus = useSelector(state => state.script.isEditorFocus)

  const setCharacter = (type) => {
    var selection = editor.selection;
    var selectedNode = selection.getNode();
    var paragraphNode = editor.dom.getParent(selectedNode, 'p');
    let content = paragraphNode.innerHTML;
    content = content.replace(/ \(O\.C\)| \(O\.S\)| \(V\.O\)/g, '')
    if (paragraphNode) {
      editor.dom.setHTML(paragraphNode, content + type);
      editor.selection.select(paragraphNode, true);
      editor.selection.collapse(false);
    }
    editor.focus()
    editor.fire("change")

    setCharacterMenuY(null);
  }

  const handleClickOutside = (event) => { 
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setCharacterMenuY(null);
    }
  };

  useEffect(() => {
    const body = editor.getBody()
    editor.on('click', handleClickOutside)
    body.addEventListener('click', handleClickOutside)
    document.addEventListener('click', handleClickOutside)
    return () => {
      editor.off('click')
      document.removeEventListener('click', handleClickOutside)
      body.removeEventListener('click', handleClickOutside)
    };
  }, [menuRef]);


  return (
    <div style={{ top: characterMenuY + 'px', left: '50%' }} className={`translate-x-[-50%] mt-3 flex flex-col items-center w-full z-50 absolute`}>
      <div className='size-8 bg-[#292929] rotate-45 '></div>
      <div ref={menuRef} style={{ scrollbarWidth: 'none' }} className={`  bg-[#292929] overflow-x-scroll w-[calc(100%-8px)] max-w-[400px] rounded-lg text-white absolute`}>
        <div className={`transition-transform flex`}>
          <button onClick={() => { setCharacter(' (O.S)') }} className='grow text-center p-2 text-[15px] text-nowrap'>O.S (Off Screen)</button>
          <button onClick={() => { setCharacter(' (O.C)') }} className='grow text-center p-2 text-[15px] text-nowrap'>O.C (Off Camera)</button>
          <button onClick={() => { setCharacter(' (V.O)') }} className='grow text-center p-2 text-[15px] text-nowrap'>V.O (Voice Over)</button>
        </div>
      </div>

    </div>

  )
}

export default CharacterMenu