import { MdArrowBackIos } from "react-icons/md"

const BackButton = ({setPanel})=>{
    return  <button className='w-full text-sm border gap-2 items-center transition-all hover:shadow-lg p-2 h-fit rounded-lg flex'
              onClick={() => { setPanel('') }}>
                  <MdArrowBackIos size={14}/>
              Back to panel
      </button>
  }

export default BackButton