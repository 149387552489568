import { Button } from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import english from "../../assets/languages/english.svg";
import spanish from "../../assets/languages/spanish.svg";
import italian from "../../assets/languages/italian.svg";
import chinese from "../../assets/languages/chinese.svg";
import chezch from "../../assets/languages/chezch.svg";
import french from "../../assets/languages/french.svg";
import german from "../../assets/languages/german.svg";
import indonesian from "../../assets/languages/indonesian.svg";
import japanese from "../../assets/languages/japanese.svg";
import korean from "../../assets/languages/korean.svg";
import portugues from "../../assets/languages/portugues.svg";
import russian from "../../assets/languages/russian.svg";
import image1 from "../../assets/image 1.png";
import image3 from "../../assets/image 3.png";
import image5 from "../../assets/image 5.png";
import google from "../../assets/google.png";
import facebook from "../../assets/facebook.png";
import morphosys from "../../assets/morphosys.png";
import iconSmall from "../../assets/icon-small.svg";
import englishFlag from "../../assets/english.svg";
import { GoogleLogin } from "@react-oauth/google";
import FbLogin from "../../utils/FacebookLogin";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa6";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { login, logout } from "../../store/authSlice";
import { setConnectionErrorOpen } from "../../store/navSlice";
import { useLocation } from "react-router-dom";
import { handleCredentialResponse, handleFacebookLogin, handleGoogleSignIn } from "../../services/authService";

const Login = () => {
  const dispatch = useDispatch();
  const [loginPage, setLoginPage] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation()
  const from = state?.from

  const [errors, setErrors] = useState({ email: "", password: "" });
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailRegex.test(email) ? "" : "Invalid email format",
    }));
  };

  const validatePassword = (password) => {
    const passwordRegex = /^.{6,}$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: passwordRegex.test(password)
        ? ""
        : "Password must be 6 or more characters",
    }));
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };
  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const handleNext = () => {
    setLoginPage(loginPage + 1);
  };
  const handleSkip = () => {
    setLoginPage(3);
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/sign-in`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      const data = await response.json();

      if (data.status === "success") {
        if (data.data.user.isVerified === false) {
          //let the user login but take him to verify screen (step 2)
          toast.error('This account is not verified')
          setIsLoading(false);
          const user = data.data.user;
          const session = data.data.session;
          localStorage.setItem("user", JSON.stringify(user));
          // localStorage.setItem("session", JSON.stringify(data.data.session));
          navigate('/register', { state: { email, session } })
          return
          // send an otp to the user and verify it
        }
        setIsLoading(false);
        const user = data.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("session", JSON.stringify(data.data.session));
        toast.success("Login Successful");
        if (from?.pathname.includes('invite'))
          localStorage.setItem('invite_url', from?.pathname)
        dispatch(login(data.data));
        navigate("/scripts");
      } else {

        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error during login:", error);
      toast.error("An error occurred, please try again.");
    }
  };



  const handleExternalLogin = async (name, email, loginToken, loginType) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/social-sign-in`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            loginToken,
            loginType
          }),
        }
      );
      const data = await response.json();

      if (data.status === "success") {
        setIsLoading(false);
        const user = data.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("session", JSON.stringify(data.data.session));
        toast.success("Login Successful");
        if (from?.pathname.includes('invite'))
          localStorage.setItem('invite_url', from?.pathname)
        dispatch(login(data.data));
        navigate("/scripts");
      } else {
        setIsLoading(false);
        toast.error(data.message || "An error occurred");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error during login:", error);
      toast.error("An error occurred, please try again.");
    }
  }

  const handleFaceBookLoginClick = async () => {
    const userResponse = await handleFacebookLogin()
    if (userResponse) {
      const faceBookEmail = userResponse.email
      const facebookName = userResponse.name
      const faceBookToken = userResponse.accessToken
      handleExternalLogin(facebookName, faceBookEmail, faceBookToken, "FACEBOOK")
    }
  }

  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '828390832166658', // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v18.0' // Use latest version
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const googleUser = state?.googleUser

  useEffect(() => {
    if (googleUser) {
      handleExternalLogin(googleUser.name, googleUser.email, googleUser.accessToken, "GOOGLE")
    }
  }, [])

  return (
    <>
      <div className="flex flex-col justify-between h-full ">
        <div className="z-20  w-full p-[18px] flex-row flex justify-between items-center m-auto ">
          <div className="justify-start items-center gap-[10px] inline-flex  px-4 rounded-full cursor-pointer">
            <img src={iconSmall} className="w-[35px] h-[35px]" />
            <div className="text-white text-2xl font-medium font-logo leading-loose">
              Morphosys
            </div>
          </div>
        </div>

        <div
          className={`h-[86%] rounded-tl-3xl rounded-tr-3xl  w-full bg-white  z-20 lg:rounded-xl overflow-y-scroll no-scrollbar`}
        >
          {loginPage === 0 && (
            <div className="flex flex-col items-center h-full justify-between pt-[27px] pb-[18px]">
              <img className="h-12" src={morphosys} />
              <div className="w-[297px] text-center text-zinc-800 text-xl font-medium leading-7 tracking-tight ">
                Before continuing, please select your language
              </div>
              <div className="w-[326px] h-[344px] flex-col justify-start items-start gap-10 inline-flex ">
                <div className="justify-start items-start gap-[34px] inline-flex">
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full shadow border-2 border-sky-500"
                      src={english}
                    />
                    <div className="text-center text-sky-500 text-[15px] font-bold font-['Eudoxus Sans'] leading-normal tracking-tight">
                      English
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={spanish}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Spanish
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={italian}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Italian
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={german}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      German
                    </div>
                  </div>
                </div>
                <div className="justify-start items-start gap-[34px] inline-flex">
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={portugues}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Portugues
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={french}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      French
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={russian}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Russian
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={chinese}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Chinese
                    </div>
                  </div>
                </div>
                <div className="justify-start items-start gap-[34px] inline-flex">
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={indonesian}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Indonesian
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={korean}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Korean
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={japanese}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Japanese
                    </div>
                  </div>
                  <div className="w-14 flex-col justify-center items-center gap-2 inline-flex">
                    <img
                      className="w-14 h-14 rounded-full border-2 border-neutral-200"
                      src={chezch}
                    />
                    <div className="text-center text-neutral-500 text-[15px] font-medium font-['Eudoxus Sans'] leading-normal tracking-tight">
                      Chezch
                    </div>
                  </div>
                </div>
              </div>
              <CustomButton handleClick={handleNext}>Continue</CustomButton>
            </div>
          )}

          {loginPage === 4 && (
            <div className="flex flex-col gap-4 items-center h-full justify-evenly pt-[20px] pb-[18px] px-4 ">
              <div className="text-[#292929] text-2xl font-bold font-['Eudoxus Sans']">
                Welcome Back! <br />
                Login to your account.
              </div>
              <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex ">
                <div className="text-zinc-500 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                  Email
                </div>
                <div className="w-full flex flex-col">
                  <input
                    onChange={handleEmailChange}
                    value={email}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleLogin()
                      }
                    }}
                    type="email"
                    className="w-full pl-3 py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                    placeholder="Enter email address"
                    required
                    style={{
                      outline: !!errors.email ? "1px solid red" : "",
                    }}
                  />
                  {!!errors.email && (
                    <label className="text-[10px] text-red-600 text-start">
                      {errors.email}
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="w-[343px] h-[78px] flex-col justify-start items-start gap-2 inline-flex mb-2">
                  <div className="text-zinc-500 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight">
                    Password
                  </div>
                  <div className="w-full flex flex-col relative">
                    <input
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleLogin()
                        }
                      }}
                      type={showPassword ? "text" : "password"}
                      className="pl-3 w-full py-[13px] bg-white rounded-lg border border-stone-300 justify-start items-center inline-flex focus-visible:outline-stone-400 text-neutral-700 text-sm font-medium font-['Eudoxus Sans'] leading-snug tracking-tight"
                      placeholder="Enter your password"
                      required
                      style={{
                        outline: !!errors.password ? "1px solid red" : "",
                      }}
                    />

                    <button
                      type="button"
                      className="absolute p-2 right-[7px] top-[3.5px] focus:outline-none ml-2 flex items-center justify-center rounded-md"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <MdOutlineRemoveRedEye
                          color="#AAAAAA"
                          size={24}
                        />
                      ) :
                        <FaRegEyeSlash
                          color="#AAAAAA"
                          size={24}

                        />
                      }
                    </button>
                    {!!errors.password && (
                      <label className="text-[10px] text-red-600 text-start">
                        {errors.password}
                      </label>
                    )}
                  </div>
                </div>
                <div
                  className="w-[343px] text-right text-zinc-500 text-sm font-normal font-['Eudoxus Sans'] leading-snug tracking-tight cursor-pointer hover:text-zinc-700"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot Password?
                </div>
              </div>
              <div className="gap-7 flex flex-col items-center">
                <div className="gap-4 flex flex-col items-center">
                  <CustomButton
                    handleClick={handleLogin}
                    loading={isLoading}
                    disabled={!!errors.email || !!errors.password || email === "" || password === ""}
                  >
                    Login to my account
                  </CustomButton>
                  {/* <GoogleLogin
                    onSuccess={() => {
                      console.log("google login success");
                    }}
                    onError={() => {
                      console.log("google login fail");
                    }}
                  /> */}
                  <CustomButton
                    handleClick={handleGoogleSignIn}
                    type="social">

                    <img className="w-[23px] h-[23px]" src={google} />
                    Login with Google
                  </CustomButton>
                  <CustomButton
                    handleClick={handleFaceBookLoginClick}
                    type="social">
                    <img className="w-[23px] h-[23px]" src={facebook} />
                    Login with Facebook
                  </CustomButton>
                  {/* <FbLogin /> */}
                </div>
                <CustomButton
                  type="light2"
                  handleClick={() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("session");
                    navigate("/register");
                  }}
                >
                  Create new account
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
