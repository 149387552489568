import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { getTitle, timeColors } from '../Editor/utils/sceneutils';
import CharacterDetailsSlider from './CharacterDetailsSlider';
import { GoDotFill } from "react-icons/go";
import Dropdown from '../../Common/DropDown';
import { MdAdd, MdExpandMore } from 'react-icons/md';
import { Collapse, ConfigProvider } from 'antd';
import CharacterButton from './CharacterButton';
import { toast } from 'react-toastify';
import { getAccessToken } from '../../../services/authService';
import LoaderSpin from '../../Common/LoaderSpin';
import AddCharacterMenu from './AddCharacterMenu';
import AddNonSpeakingCharacter from './AddNonSpeakingCharacter';
import AddExtrasCharacter from './AddExtrasCharacter';
import { getNameWithoutbrackets } from '../Editor/utils/characterUtils';
import EmptyPage from '../Editor/sidepanel/EmptyPage';

const CharactersTab = ({ setSceneCharactersList, setUpdateData, sceneIndex, scriptData, searchVal,body, blockDialogueList, setBlockDialogueList }) => {
    const [activeCharacter, setActiveCharacter] = useState(null)
    const [activeIndex, setActiveIndex] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [openNonSpeakingModal, setOpenNonSpeakingModal] = useState(false)
    const [openExtrasModal, setOpenExtraModal] = useState(false)
    const [openAddCharacterModal, setOpenAddCharacterModal] = useState(false)

    const [characterList, setCharacterList] = useState([])
    const [updateList, setUpdateList] = useState(false)
    const [panelActiveKeys, setPanelActiveKeys] = useState(['1','2','3','4'])

    const fetchCharacterList = async () => {
        setIsLoading(true)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/character?documentId=${scriptData._id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })

            const body = await res.json()
            if (!res.ok) {
                throw new Error('Error getting response', body)
            }
            const data = body.data
            setCharacterList(data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            toast.error('Error fetching characters')
        }
    }



    useEffect(() => {
        fetchCharacterList()
    }, [updateList])

    const userData = JSON.parse(localStorage.getItem("user"));
    const [users, setUsers] = useState([]);
    const { id } = useParams();
    const [permissionToEdit, setPermissionToEdit] = useState(false);

    useEffect(() => {
        if (users.length === 0) fetchCollaborator();
    }, []);

    const fetchCollaborator = async () => {
        setIsLoading(true);
        const token = getAccessToken();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DEV_API}/documents/${id}/collaborators/list`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsLoading(false);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setUsers(data.data);
            const isAdmin = !data.data.find(user => user.email === userData.email);
            const allowedToEdit = isAdmin || data.data.find(user => user.email === userData.email)?.permission?.SCRIPT === "WRITE";
            setPermissionToEdit(allowedToEdit);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    let sceneCharacters = []
    let allCharacters = []
    let sceneCount = -1

    body.childNodes.forEach(node=>{
        if(node.nodeType ===1){
            const nodeClass = node.classList[0]
            if (nodeClass === 'sceneHeadings') {
                sceneCount = sceneCount + 1
    
                const nonSpeakingList = JSON.parse(node.getAttribute('data-non-speak-character') || 'null') || []
                const extrasList = JSON.parse(node.getAttribute('data-extras-character') || 'null') || []
    
                sceneCharacters[sceneCount] = { dialogue: [], voice: [], nonSpeaking: nonSpeakingList, extra: extrasList }
            }
            if (sceneCharacters[sceneCount] && nodeClass === 'character') {
                const characterName = node.textContent.toUpperCase() || ''
    
                if (!sceneCharacters[sceneCount]?.dialogue?.find(character => getNameWithoutbrackets(character) === getNameWithoutbrackets(characterName))) {
                    if (!characterName.trim().endsWith('(V.O)')) {
                        sceneCharacters[sceneCount].dialogue.push(getNameWithoutbrackets(characterName))
                    }
                }
    
                if (!sceneCharacters[sceneCount]?.voice?.find(character => getNameWithoutbrackets(character) === getNameWithoutbrackets(characterName))) {
                    if (characterName.trim().endsWith('(V.O)')) {
                        sceneCharacters[sceneCount].voice.push(getNameWithoutbrackets(characterName))
                    }
                }
    
                if (!allCharacters.find(character => getNameWithoutbrackets(character) === getNameWithoutbrackets(characterName))) {
                    allCharacters.push(getNameWithoutbrackets(characterName))
                }
            }
        }
    })

    useEffect(() => {
        setSceneCharactersList(sceneCharacters)
    }, [])

    const getCharacterFromList = (character) => {
        return characterList.find(char => char.name === character)
    }

    const handleCharacterClick = (character) => {
        setActiveCharacter(character)
    }

    const { Panel } = Collapse;

    const customTheme = {
        components: {
            Collapse: {
                contentPadding: 0,
            },
        },
    };

    const onChange = (key) => {
        setPanelActiveKeys(key)
    };

    const openNonSpeakingPanel = ()=>{
        setPanelActiveKeys(prev =>{
            const newKeys = [...prev]
            newKeys.push('2')
            return prev.includes('2')? prev: newKeys
        })
    }

    const searchList = allCharacters.slice().filter(char => char.toUpperCase().includes(searchVal.toUpperCase()))


    return (
        <>
            {openAddCharacterModal &&
                <AddCharacterMenu
                    isOpen={openAddCharacterModal}
                    setIsOpen={setOpenAddCharacterModal}
                    setOpenAddNonSpeak={setOpenNonSpeakingModal}
                    setOpenAddExtra={setOpenExtraModal}
                    sceneIndex={sceneIndex}
                />}

            {openNonSpeakingModal &&
                <AddNonSpeakingCharacter
                    openNonSpeakingPanel={openNonSpeakingPanel}
                    sceneIndex={sceneIndex}
                    isOpen={openNonSpeakingModal}
                    setIsOpen={setOpenNonSpeakingModal}
                    allCharaters={allCharacters}
                    characterList={characterList}
                    body={body}
                    scriptData={scriptData}
                    setUpdateData={setUpdateData}
                />}

            {openExtrasModal &&
                <AddExtrasCharacter
                    sceneIndex={sceneIndex}
                    isOpen={openExtrasModal}
                    setIsOpen={setOpenExtraModal}
                    body={body}
                    scriptData={scriptData}
                    setUpdateData={setUpdateData}
                />}

            {activeCharacter ?
                <CharacterDetailsSlider
                    setUpdateData={setUpdateList}
                    activeCharacter={activeCharacter?.toUpperCase()}
                    setActiveCharacter={setActiveCharacter}
                    index={activeIndex}
                    sceneCharacters={sceneCharacters}
                    body={body}
                    characterList={characterList}
                    character={getCharacterFromList(activeCharacter)}
                    permissionToEdit={permissionToEdit}
                    blockDialogueList={blockDialogueList}
                    setBlockDialogueList={setBlockDialogueList}
                />
                :
                <>
                    {isLoading ?
                        <div className='flex justify-center py-20'>
                            <LoaderSpin size={40} />
                        </div>
                        :
                        <div className='mt-4 '>

                            {sceneCharacters.length == 0 ?
                                <EmptyPage
                                    title={"You do not have any scenes to show characers"}
                                    subtitle={"Create a scene with characters to see here"} />
                                :
                                <>
                                    {searchVal && searchVal !== "" ? <div>
                                        {searchList.length === 0 ?
                                            <EmptyPage title={"Character not found"} subtitle={"Try searching for a different character"} /> :
                                            <>{searchList.map((character, index) =>
                                                <CharacterButton
                                                    onClick={() => { handleCharacterClick(character) }}
                                                    character={getCharacterFromList(character) || { name: character }}
                                                />)}</>}
                                    </div> :
                                        <ConfigProvider theme={customTheme}>
                                            <Collapse
                                                bordered={false}
                                                activeKey={panelActiveKeys}
                                                onChange={onChange}
                                                expandIconPosition={'end'}
                                            >
                                                <Panel  header="Dialogue" key="1" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                    <>
                                                        {sceneCharacters[sceneIndex]?.dialogue?.length > 0 ? (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                {sceneCharacters[sceneIndex]?.dialogue?.map((character, index) =>
                                                                    <CharacterButton
                                                                        onClick={() => { handleCharacterClick(character) }}
                                                                        character={getCharacterFromList(character) || { name: character }}
                                                                    />)}
                                                            </div>
                                                        ) : (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                No Characters
                                                            </div>
                                                        )}
                                                    </>
                                                </Panel>
                                                <Panel header="Non-Speaking" key="2" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                    <>
                                                        {sceneCharacters[sceneIndex]?.nonSpeaking?.length > 0 ? (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                {sceneCharacters[sceneIndex]?.nonSpeaking?.map((character, index) =>
                                                                    <CharacterButton
                                                                        onClick={() => { handleCharacterClick(character) }}
                                                                        character={getCharacterFromList(character) || { name: character }}
                                                                    />
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                No Characters
                                                            </div>
                                                        )}
                                                    </>
                                                </Panel>
                                                <Panel header="Voice Over" key="3" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                    <>
                                                        {sceneCharacters[sceneIndex]?.voice?.length > 0 ? (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                {sceneCharacters[sceneIndex]?.voice?.map((character, index) =>
                                                                    <CharacterButton
                                                                        onClick={() => { handleCharacterClick(character) }}
                                                                        character={getCharacterFromList(character) || { name: character }}
                                                                    />)}
                                                            </div>
                                                        ) : (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                No Characters
                                                            </div>
                                                        )}
                                                    </>
                                                </Panel>
                                                <Panel header="Extras" key="4" style={{ padding: 0, paddingLeft: 0, background: '#F6F6F6' }}>
                                                    <>
                                                        {sceneCharacters[sceneIndex]?.extra?.length > 0 ? (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col">
                                                                {sceneCharacters[sceneIndex]?.extra?.map((character, index) =>
                                                                    <CharacterButton
                                                                        onClick={() => { handleCharacterClick(character) }}
                                                                        character={getCharacterFromList(character) || { name: character }}
                                                                    />)}
                                                            </div>
                                                        ) : (
                                                            <div className=" bg-white  overflow-y-auto gap-[1px] flex flex-col p-2">
                                                                No Characters
                                                            </div>
                                                        )}
                                                    </>
                                                </Panel>
                                            </Collapse>
                                        </ConfigProvider>}
                                </>}

                            {sceneCharacters.length > 0 && <button disabled={!permissionToEdit} onClick={() => { setOpenAddCharacterModal(true) }} className='cursor-pointer size-[56px] rounded-full flex justify-center items-center fixed bottom-4 right-4 bg-[#0099FF]'><MdAdd size={24} color='white' /></button>}
                        </div>
                    }
                </>
            }

        </>
    )
}

export default CharactersTab