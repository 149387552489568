import React from 'react'
import ProfileInfo from '../../MyProfile/ProfileInfo'
import Modal from '../'
import ChangePassword from '../../MyProfile/ChangePassword'
import MyProfile from '../../MyProfile'
import Subscription from '../../MyProfile/Subscription'

const ProfileModals = ({showModal, setShowModal}) => {

    const closeModal = ()=>{
        setShowModal('')
    }

  return (
    <>
        {showModal === 'profileinfo' && <Modal closeModal={closeModal} closeOnClickOutside={false}><ProfileInfo close={closeModal}/></Modal>}
        {showModal === 'changepass' && <Modal closeModal={closeModal} closeOnClickOutside={false}><ChangePassword close={closeModal}/></Modal>}
        {showModal === 'myprofile' && <Modal closeModal={closeModal}><MyProfile/></Modal>}
        {showModal === 'subscription' && <Modal closeModal={closeModal} closeOnClickOutside={false}><Subscription close={closeModal}/></Modal>}
    </>
  )
}

export default ProfileModals