import { toast } from "react-toastify";

export const getElements = (editor) => {
    if (editor) {

        const content = editor.getContent({ format: 'raw' });
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');

        const sceneElements = [];
        const selectedNode = editor.selection?.getNode();
        const allElements = []
        const defaultCategories = []
        let lastSceneIndex = -1;

        const traverseNodes = (node, index = 0) => {
            if (node.nodeType === 1 && node.classList.contains('sceneHeadings')) {
                const categoryList = JSON.parse(node.getAttribute('data-category-list') || null) || []
                const elementList = JSON.parse(node.getAttribute('data-element-list') || null) || []
                sceneElements.push({
                    scene: node.textContent,
                    categoryList,
                    elementList
                })
                allElements.push(...elementList)
                defaultCategories.push(...categoryList.filter(category => category.isDefault === true).map(cat => {
                 return {...cat,scene:sceneElements.length}
                }))
            }
            if (node.isEqualNode(selectedNode)) {
                lastSceneIndex = sceneElements.length - 1;
            }
            node.childNodes.forEach((childNode, childIndex) =>
                traverseNodes(childNode, index + childIndex + 1)
            );
        };

        traverseNodes(doc.body);

        if (lastSceneIndex >= 0) {
            return { index: lastSceneIndex + 1, sceneElements, allElements, defaultCategories };
        } else {
            return { index: null, sceneElements, allElements, defaultCategories }
        }
    }
}

export const addCategory = (editor, categoryData, index) => {
    const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index+1) + '"]');
    if (selectedNodes.length > 0) {
      const selectedNode = selectedNodes[0]
      const categoryList = JSON.parse(selectedNode.getAttribute('data-category-list') || null) || []
      if(categoryList.find(category=> category.name === categoryData.name)){
        toast.error('Category already exists')
        return
      }
      categoryList.push(categoryData)
      selectedNode.setAttribute('data-category-list',JSON.stringify(categoryList))
      toast.success('Category added')
      editor.fire('Change');
    }
}


export const addElements = (editor, elementData, index) => {
    const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index+1) + '"]');
    if (selectedNodes.length > 0) {
      const selectedNode = selectedNodes[0]
      const elemntList = JSON.parse(selectedNode.getAttribute('data-element-list') || null) || []
      if(elemntList.find(element=> element.name === elementData.name)){
        toast.error('Element already exists')
        return
      }
      elemntList.push(elementData)
      selectedNode.setAttribute('data-element-list',JSON.stringify(elemntList))
      toast.success('Element added')
      editor.fire('Change');
    }
}

export const deleteCategory = (editor, categoryName, index) =>{
    const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index+1) + '"]');
    if (selectedNodes.length > 0) {
      const selectedNode = selectedNodes[0]
      const categoryList = JSON.parse(selectedNode.getAttribute('data-category-list') || null) || []
     
      const newList =  categoryList.filter(cat => cat.name !== categoryName)
      selectedNode.setAttribute('data-category-list',JSON.stringify(newList))
      toast.success('Category deleted')
      editor.fire('Change');
    }
}

export const deleteElement = (editor, elementName, index) =>{
    const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index+1) + '"]');
    if (selectedNodes.length > 0) {
      const selectedNode = selectedNodes[0]
      const elementList = JSON.parse(selectedNode.getAttribute('data-element-list') || null) || []
     
      const newList =  elementList.filter(elem => elem.name !== elementName)
      selectedNode.setAttribute('data-element-list',JSON.stringify(newList))
      toast.success('Element deleted')
      editor.fire('Change');
    }
}


export const editCategory = (editor, categoryData, categoryName, index) => {
    const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index+1) + '"]');
    if (selectedNodes.length > 0) {
      const selectedNode = selectedNodes[0]
      const categoryList = JSON.parse(selectedNode.getAttribute('data-category-list') || null) || []
     
      const updateIndex = categoryList.findIndex(cat => cat.name === categoryName)    
      categoryList[updateIndex] = categoryData
      selectedNode.setAttribute('data-category-list',JSON.stringify(categoryList))
      toast.success('Category updated')
      editor.fire('Change');
    }
}

export const editElement = (editor, elementData, elementName, index) => {
  const selectedNodes = editor.dom.select('p.sceneHeadings[data-scene-index="' + (index+1) + '"]');
  if (selectedNodes.length > 0) {
    const selectedNode = selectedNodes[0]
    const elementList = JSON.parse(selectedNode.getAttribute('data-element-list') || null) || []
   
    const updateIndex = elementList.findIndex(elem => elem.name === elementName)    
    elementList[updateIndex] = elementData
    selectedNode.setAttribute('data-element-list',JSON.stringify(elementList))
    toast.success('Element updated')
    editor.fire('Change');
  }
}