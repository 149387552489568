import React from 'react'
import { MdDelete } from 'react-icons/md'

const ElementButton = ({ onClick, color, name, isDelete = false, onDelete, isDisabled = false }) => {
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      className={`group flex rounded-lg border hover:shadow ${isDisabled ? 'cursor-not-allowed' : 'hover:bg-[#f3f3f3]'} text-start font-medium bg-white text-[#868686] w-full`}>
      <div style={{ background: color || '#d9dee3' }} className="h-full w-2 rounded-s-lg "></div>
      <div className='flex items-center justify-between grow px-3'>
        <div className="flex text-[12px] text-ellipsis max-w-[200px]  gap-4 py-2">
          {name}
        </div>
        {isDelete && <button onClick={(e) => {
          e.stopPropagation()
          onDelete()
        }} className=' hover:text-[#868686] invisible text-[#cacaca] group-hover:visible '>
          <MdDelete color='' size={20} />
        </button>}
      </div>
    </button>
  )
}

export default ElementButton