import React, { useEffect, useState } from 'react'
import { MdSearch, MdFilterList, MdAssistant, MdMicrowave, MdMovie, MdChevronRight, MdOutlineArticle, MdGroups, MdOutlineSentimentSatisfied, MdAmpStories, MdLayers, MdDownload, MdClose } from "react-icons/md";
import { FaHighlighter } from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import { getAccessToken } from '../../../services/authService';
import LoaderSpin from '../../Common/LoaderSpin';
import { toast } from 'react-toastify';
import SaveMenuPopUp from './SaveMenuPopUp';
import AiIcon from '../../../assets/icons/ai_icon.svg'
import AiIconDisabled from '../../../assets/icons/ai_icon_disabled.svg'
import ErrorPopUp from '../../Common/ErrorPopUp';
import DeleteMenuPopUp from './DeleteMenu';
import { useDispatch } from 'react-redux';
import { setConnectionErrorOpen } from '../../../store/navSlice';
import SubscriptionModal from '../Editor/sidepanel/SubscriptionModal';
import AlexAiAddOnModal from '../Editor/sidepanel/AlexAiAddOnModal';
import { fetchRevisions, getSctipStatistics } from './functions';

const ScriptOption = ({ title, isDisabled, subtitle, Icon, color, background, onClick, isTreatMentDisabled, openSubscriptionModal }) => {

    return (
        <div onClick={isDisabled ? openSubscriptionModal : isTreatMentDisabled ? () => { } : onClick} className='cursor-pointer flex p-4 border-b justify-between items-center'>
            <div className='flex gap-4'>
                <div className={`rounded-full flex items-center justify-center size-10`} style={{ background: isDisabled || isTreatMentDisabled ? '#F6F6F6' : background }}>
                    <Icon size={24} color={isDisabled || isTreatMentDisabled ? "#AAAAAA" : color} />
                </div>
                <div>
                    <p className='text-[16px] font-medium' style={{ color: isDisabled || isTreatMentDisabled ? '#868686' : '#292929' }}>{title}</p>
                    <p className='text-[13px] font-medium' style={{ color: isDisabled || isTreatMentDisabled ? '#AAAAAA' : '#757575' }}>{subtitle}</p>
                </div>
            </div>
            <MdChevronRight size={24} color={'#CACACA'} />
        </div>
    )
}

const ScriptInfo = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(1)
    const [scriptData, setScriptData] = useState({})
    const [isSaveOpen, setIsSaveOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const userData = JSON.parse(localStorage.getItem("user"))
    const planFeatures = userData.planFeatures;
    const subscription = userData.subscription;
    const [revisionLength, setRevisionLength] = useState(0)

    const [users, setUsers] = useState([]);
    const [permissionToEdit, setPermissionToEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isRevisionLoading, setIsRevisionLoading] = useState(true)
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false)
    const [isAlexAiAddOnModalOpen, setIsAlexAiAddOnModalOpen] = useState(false)
    const [scriptStatistics, setScriptStatistics] = useState({})

    const navigate = useNavigate()

    const fetchScript = async () => {
        setLoading(1)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const body = await res.json()
            setScriptData(body.data)
            setScriptStatistics( getSctipStatistics(body.data.content,id))
            setLoading(0);
            const isAdmin = body.data?.userId === userData?._id;
            if (isAdmin) setPermissionToEdit(true);
            else {
                const allowedToEdit = body.data.invitedCollaborators.find(user => user.userId._id === userData._id)?.permissions?.SCRIPT === "WRITE";
                setPermissionToEdit(allowedToEdit);
            }

            if (users.length === 0 && planFeatures?.canCollaborate) fetchCollaborator();
            else setIsLoading(false);
        } catch (error) {
            toast.error("Failed to fetch script")
            setLoading(2)
            setIsLoading(false);
            console.error(error)
        }
    }

    const updateRevisionLength = async()=>{
        setIsRevisionLoading(true)
        setRevisionLength(await fetchRevisions(id))
        setIsRevisionLoading(false)
    }

    const { type, title, invitedCollaborators, status, content, scenes } = scriptData

    const scriptType = type === 'FILM' ? 'Film'
        : type === 'TV' ? 'TV'
            : type === 'TREATMENT' ? 'Treatment' :
                ''
    const ScriptIcon = type === 'FILM' ? MdMovie
        : type === 'TV' ? MdMicrowave
            : type === 'TREATMENT' ? MdAssistant :
                ''

    const fetchCollaborator = async () => {
        setIsLoading(true);
        const token = getAccessToken();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DEV_API}/documents/${id}/collaborators/list`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsLoading(false);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            
            setUsers(data.data);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

  

    const openSubscriptionModal = () => {
        setIsSubscriptionModalOpen(true)
    }


    useEffect(() => {
        fetchScript()
        updateRevisionLength()
    }, []);

    return (
        <div className='overflow-y-hidden h-full'>
            <div className='p-4 pb-8 flex justify-between items-center border-b-2'>
                <h1 className='text-[20px] font-bold text-[#292929]'>Scripts</h1>
            </div>
            {loading === 0 && !isLoading && !isRevisionLoading && <div className=' overflow-y-auto h-[calc(100%-5rem)]'>
                <div>
                    <div className='relative p-6 bg-[#F6F6F6]'>
                        <button className='absolute top-4 right-4' onClick={() => { navigate('/scripts') }}>
                            <MdClose size={28} color='#868686' />
                        </button>
                        <div className=' max-w-[265px] mx-auto'>

                            <div className='mx-auto w-fit h-fit p-4 bg-[#E4E4E4] rounded-lg'>
                                <ScriptIcon size={28} color='#868686' />
                            </div>
                            <h2 className='mt-3 text-[20px] mx-auto px-1 font-bold text-[#292929] text-center break-all'>
                                {title}
                            </h2>
                            <i className='mt-2 block text-center font-thin text-[#757575]'>({scriptType} Script)</i>
                            <div className='mt-6 flex  items-center gap-2'>
                                <button onClick={() => { setIsDeleteOpen(true) }} className='w-[100px] font-medium text-[#AAAAAA]'>
                                    Delete
                                </button>
                                <button onClick={() => { setIsSaveOpen(true) }} className='rounded-full grow text-white text-center font-medium py-[10px] bg-[#D41E79] '>
                                    Save and exit
                                </button>

                            </div>
                        </div>

                    </div>
                    <div>
                        <ScriptOption onClick={() => { navigate(`/editor/${id}`) }} title={'Screenwriting'} subtitle={'Write your script.'}
                            Icon={MdOutlineArticle} color={'#292929'} background={'#F6F6F6'} isDisabled={false} openSubscriptionModal={openSubscriptionModal} />

                        <ScriptOption onClick={() => { navigate(`/scripts/invite/${id}`) }} title={'Invite'} subtitle={`${invitedCollaborators.length === 0 ? 'No' : invitedCollaborators.length} People`}
                            Icon={MdGroups} color={'#0099FF'} background={'#F0F9FF'} isDisabled={!planFeatures?.canCollaborate} openSubscriptionModal={openSubscriptionModal} />

                        <ScriptOption onClick={() => { navigate(`/scripts/${id}/scenes/`) }} title={'Scenes'} subtitle={`${!scriptStatistics.scenes ? 'No' : scriptStatistics.scenes} Scenes`}
                            Icon={MdMovie} isTreatMentDisabled={scriptData.type === "TREATMENT"} color={'#CC8C14'} background={'#FDF8EE'} isDisabled={!planFeatures?.canListScenes} openSubscriptionModal={openSubscriptionModal} />

                        <ScriptOption onClick={() => { navigate(`/scripts/${id}/characters/`) }} title={'Characters'} subtitle={`${!scriptStatistics.characters ? 'No' : scriptStatistics.characters} Characters`}
                            Icon={MdOutlineSentimentSatisfied} isTreatMentDisabled={scriptData.type === "TREATMENT"} color={'#48A966'} background={'#F2FFF6'} isDisabled={!planFeatures?.canUseCharacterBreakdown} openSubscriptionModal={openSubscriptionModal} />

                        <ScriptOption title={'Scene Cards'} subtitle={'Organize Scenes'}
                            Icon={MdAmpStories} onClick={() => { navigate(`/scripts/${id}/scenecards/`) }} isTreatMentDisabled={scriptData.type === "TREATMENT"} color={'#D41E79'} background={'#FFF5FA'} isDisabled={!planFeatures?.canUseSceneBreakdown} openSubscriptionModal={openSubscriptionModal} />

                        <ScriptOption onClick={() => { navigate(`/scripts/${id}/elements`) }} title={'Categories & Elements'} subtitle={`${!scriptStatistics.elements ? 'No' : scriptStatistics.elements} Elements`}
                            Icon={MdLayers} isTreatMentDisabled={scriptData.type === "TREATMENT"} color={'#CC8C14'} background={'#FDF8EE'} isDisabled={!planFeatures?.canUseCategoriesAndElements} openSubscriptionModal={openSubscriptionModal} />

                        <ScriptOption onClick={() => { navigate(`/scripts/${id}/revisions`) }} title={'Revisions'} subtitle={`${!revisionLength ? 'No' : revisionLength} Revisions`}
                            Icon={FaHighlighter} color={'#D41E79'} background={'#FFF5FA'} isDisabled={!planFeatures?.canApplyRevision} openSubscriptionModal={openSubscriptionModal} />

                        <ScriptOption onClick={() => { navigate(`/scripts/${id}/notes`) }} title={'Notes'} subtitle={`${!scriptStatistics.notes ? 'No' : scriptStatistics.notes} Notes`}
                            Icon={BsChatDotsFill} color={'#4eac6b'} background={'#f2fff6'} isDisabled={!planFeatures?.canApplyRevision} openSubscriptionModal={openSubscriptionModal} />


                        <div
                            onClick={() => {
                                if (!permissionToEdit) return;
                                if (planFeatures?.canUseAlexAI && subscription?.alexAIUsage < planFeatures.alexAIHitsLimit)
                                    navigate(`/scripts/${id}/alex`)
                                else
                                    setIsAlexAiAddOnModalOpen(true)
                            }}
                            className='cursor-pointer flex p-4 border-b justify-between items-center'
                        >
                            <div className='flex gap-4'>
                                <div className={`rounded-full flex items-center justify-center size-10`}>
                                    <img src={!planFeatures?.canUseAlexAI || !permissionToEdit || subscription?.alexAIUsage >= planFeatures.alexAIHitsLimit ? AiIconDisabled : AiIcon} />
                                </div>
                                <div>
                                    <p className='text-[16px] font-medium' style={{ color: !planFeatures?.canUseAlexAI || !permissionToEdit || subscription?.alexAIUsage >= planFeatures.alexAIHitsLimit ? '#868686' : '#dba612' }}>{'Alex AI'}</p>
                                    <p className='text-[13px] font-medium' style={{ color: !planFeatures?.canUseAlexAI || !permissionToEdit || subscription?.alexAIUsage >= planFeatures.alexAIHitsLimit ? '#AAAAAA' : '#757575' }}>{'Your AI Co-Pilot'}</p>
                                </div>
                            </div>
                            <MdChevronRight size={24} color={'#CACACA'} />
                        </div>

                    </div>
                </div>
            </div>}
            {(loading === 1 || isLoading || isRevisionLoading) && <div className='flex justify-center items-center h-[calc(100%-5rem)]'>
                <LoaderSpin size={30} />
            </div>}
            {isSaveOpen && <SaveMenuPopUp isSaveOpen={isSaveOpen} setIsSaveOpen={setIsSaveOpen} id={id} scriptData={scriptData} />}
            {isDeleteOpen && <DeleteMenuPopUp onDelete={() => { navigate('/scripts') }} isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} id={id} />}
            <SubscriptionModal isSubscriptionModalOpen={isSubscriptionModalOpen} setIsSubscriptionModalOpen={setIsSubscriptionModalOpen} />
            <AlexAiAddOnModal isAlexAiAddOnModalOpen={isAlexAiAddOnModalOpen} setIsAlexAiAddOnModalOpen={setIsAlexAiAddOnModalOpen} />
        </div>
    )
}

export default ScriptInfo