import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUser } from "../../store/authSlice";
import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { MdImage } from "react-icons/md";

const ProfileHeader = () => {
  const user = useSelector((state) => state.auth.user);
  const imageInputRef = useRef(null);
  const [isUploadingImg, setIsUploadingImg] = useState();

  const dispatch = useDispatch();

  const handleImageUpload = async (e) => {
    setIsUploadingImg(true);
    const file = e.target.files[0];
    if (!file) return
    const formData = new FormData();
    formData.append("photo", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/user/${user._id}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(response);
      }

      const data = await response.json();
      dispatch(updateUser(data.data));
      setIsUploadingImg(false);
    } catch (error) {
      setIsUploadingImg(false);
      toast.error("error uploading image");
      console.error("Error uploading image:", error);
      throw error;
    }
  };
  return (
    <div className=" justify-start gap-10 items-center max-lg:mb-[25px]">
      <div className="lg:border-2  relative max-lg:mt-7 size-[144px] p-2 bg-[#FFFFFF33] mx-auto rounded-full">
        {user?.photo ? <img
          src={user?.photo}
          className="h-full bg-white w-full object-cover rounded-full"
        /> :
          <div className="h-full font-semibold text-[48px] w-full flex justify-center items-center text-center bg-[#D41E79] rounded-full text-white">
            {user?.name?.charAt(0)?.toUpperCase()}
          </div>}

        <button
          className=" absolute lg:border-2 bg-white size-[28px] flex items-center justify-center bottom-[10px] right-[10px] rounded-full "
          onClick={() => {
            imageInputRef.current.click();
          }}
          disabled={isUploadingImg}
        >
          {isUploadingImg ? (
            <Spin indicator={
              <LoadingOutlined className=" size-[28px]" spin />
            } />
          ) : (
            <MdImage className=" size-[18px]" color="#9E9E9E" />
          )}
        </button>
        <input
          ref={imageInputRef}
          className="hidden"
          type="file"
          onInput={handleImageUpload}
          accept="image/*"
        />
      </div>
      <div className="lg:hidden mt-4  text-center text-white">
        <div>
          <p className="text-[24px] font-[700]">
            {user?.name || " "}
          </p>
        </div>
        <div className=" ">
          <p className=" font-[500]">{user?.email || " "}</p>
        </div>

      </div>
    </div>
  );
};

export default ProfileHeader;
