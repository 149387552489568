// constants/languages.js

export const LANGUAGES = {
    'af': 'Afrikaans',
    'sq': 'Albanian',
    'am': 'Amharic',
    'ar': 'Arabic',
    'hy': 'Armenian',
    'as': 'Assamese',
    'ay': 'Aymara',
    'az': 'Azerbaijani',
    'bm': 'Bambara',
    'eu': 'Basque',
    'be': 'Belarusian',
    'bn': 'Bengali',
    'bho': 'Bhojpuri',
    'bs': 'Bosnian',
    'bg': 'Bulgarian',
    'ca': 'Catalan',
    'ceb': 'Cebuano',
    'zh-CN': 'Chinese (Simplified)',
    'zh-TW': 'Chinese (Traditional)',
    'co': 'Corsican',
    'hr': 'Croatian',
    'cs': 'Czech',
    'da': 'Danish',
    'dv': 'Dhivehi',
    'doi': 'Dogri',
    'nl': 'Dutch',
    'en': 'English',
    'eo': 'Esperanto',
    'et': 'Estonian',
    'ee': 'Ewe',
    'fil': 'Filipino (Tagalog)',
    'fi': 'Finnish',
    'fr': 'French',
    'fy': 'Frisian',
    'gl': 'Galician',
    'ka': 'Georgian',
    'de': 'German',
    'el': 'Greek',
    'gn': 'Guarani',
    'gu': 'Gujarati',
    'ht': 'Haitian Creole',
    'ha': 'Hausa',
    'haw': 'Hawaiian',
    'he': 'Hebrew',
    'hi': 'Hindi',
    'hmn': 'Hmong',
    'hu': 'Hungarian',
    'is': 'Icelandic',
    'ig': 'Igbo',
    'ilo': 'Ilocano',
    'id': 'Indonesian',
    'ga': 'Irish',
    'it': 'Italian',
    'ja': 'Japanese',
    'jv': 'Javanese',
    'kn': 'Kannada',
    'kk': 'Kazakh',
    'km': 'Khmer',
    'rw': 'Kinyarwanda',
    'gom': 'Konkani',
    'ko': 'Korean',
    'kri': 'Krio',
    'ku': 'Kurdish',
    'ckb': 'Kurdish (Sorani)',
    'ky': 'Kyrgyz',
    'lo': 'Lao',
    'la': 'Latin',
    'lv': 'Latvian',
    'ln': 'Lingala',
    'lt': 'Lithuanian',
    'lg': 'Luganda',
    'lb': 'Luxembourgish',
    'mk': 'Macedonian',
    'mai': 'Maithili',
    'mg': 'Malagasy',
    'ms': 'Malay',
    'ml': 'Malayalam',
    'mt': 'Maltese',
    'mi': 'Maori',
    'mr': 'Marathi',
    'mni-Mtei': 'Meiteilon (Manipuri)',
    'lus': 'Mizo',
    'mn': 'Mongolian',
    'my': 'Myanmar (Burmese)',
    'ne': 'Nepali',
    'no': 'Norwegian',
    'ny': 'Nyanja (Chichewa)',
    'or': 'Odia (Oriya)',
    'om': 'Oromo',
    'ps': 'Pashto',
    'fa': 'Persian',
    'pl': 'Polish',
    'pt': 'Portuguese',
    'pa': 'Punjabi',
    'qu': 'Quechua',
    'ro': 'Romanian',
    'ru': 'Russian',
    'sm': 'Samoan',
    'sa': 'Sanskrit',
    'gd': 'Scots Gaelic',
    'nso': 'Sepedi',
    'sr': 'Serbian',
    'st': 'Sesotho',
    'sn': 'Shona',
    'sd': 'Sindhi',
    'si': 'Sinhala',
    'sk': 'Slovak',
    'sl': 'Slovenian',
    'so': 'Somali',
    'es': 'Spanish',
    'su': 'Sundanese',
    'sw': 'Swahili',
    'sv': 'Swedish',
    'tl': 'Tagalog (Filipino)',
    'tg': 'Tajik',
    'ta': 'Tamil',
    'tt': 'Tatar',
    'te': 'Telugu',
    'th': 'Thai',
    'ti': 'Tigrinya',
    'ts': 'Tsonga',
    'tr': 'Turkish',
    'tk': 'Turkmen',
    'tw': 'Twi (Akan)',
    'uk': 'Ukrainian',
    'ur': 'Urdu',
    'ug': 'Uyghur',
    'uz': 'Uzbek',
    'vi': 'Vietnamese',
    'cy': 'Welsh',
    'xh': 'Xhosa',
    'yi': 'Yiddish',
    'yo': 'Yoruba',
    'zu': 'Zulu'
  };

  export const languageCountriesCodes = {
    'af': 'ZA',  // Afrikaans - South Africa
    'sq': 'AL',  // Albanian - Albania
    'am': 'ET',  // Amharic - Ethiopia
    'ar': 'SA',  // Arabic - Saudi Arabia
    'hy': 'AM',  // Armenian - Armenia
    'as': 'IN',  // Assamese - India
    'az': 'AZ',  // Azerbaijani - Azerbaijan
    'bm': 'ML',  // Bambara - Mali
    'be': 'BY',  // Belarusian - Belarus
    'bn': 'IN',  // Bengali - India
    'bs': 'BA',  // Bosnian - Bosnia & Herzegovina
    'bg': 'BG',  // Bulgarian - Bulgaria
    'ca': 'ES',  // Catalan - Spain
    'ceb': 'PH',  // Cebuano - Philippines
    'zh-CN': 'CN', // Chinese (Simplified) - China
    'zh-TW': 'TW', // Chinese (Traditional) - Taiwan
    'co': 'FR',  // Corsican - France
    'hr': 'HR',  // Croatian - Croatia
    'cs': 'CZ',  // Czech - Czech Republic
    'da': 'DK',  // Danish - Denmark
    'dv': 'MV',  // Dhivehi - Maldives
    'nl': 'NL',  // Dutch - Netherlands
    'en': 'US',  // English - United States
    'et': 'EE',  // Estonian - Estonia
    'fi': 'FI',  // Finnish - Finland
    'fr': 'FR',  // French - France
    'de': 'DE',  // German - Germany
    'el': 'GR',  // Greek - Greece
    'gu': 'IN',  // Gujarati - India
    'hi': 'IN',  // Hindi - India
    'hu': 'HU',  // Hungarian - Hungary
    'is': 'IS',  // Icelandic - Iceland
    'ig': 'NG',  // Igbo - Nigeria
    'id': 'ID',  // Indonesian - Indonesia
    'it': 'IT',  // Italian - Italy
    'ja': 'JP',  // Japanese - Japan
    'jv': 'ID',  // Javanese - Indonesia
    'kn': 'IN',  // Kannada - India
    'kk': 'KZ',  // Kazakh - Kazakhstan
    'km': 'KH',  // Khmer - Cambodia
    'rw': 'RW',  // Kinyarwanda - Rwanda
    'ko': 'KR',  // Korean - South Korea
    'la': 'VA',  // Latin - Vatican City
    'lv': 'LV',  // Latvian - Latvia
    'lt': 'LT',  // Lithuanian - Lithuania
    'lg': 'UG',  // Luganda - Uganda
    'mk': 'MK',  // Macedonian - North Macedonia
    'ms': 'MY',  // Malay - Malaysia
    'ml': 'IN',  // Malayalam - India
    'mt': 'MT',  // Maltese - Malta
    'mr': 'IN',  // Marathi - India
    'mn': 'MN',  // Mongolian - Mongolia
    'my': 'MM',  // Myanmar (Burmese) - Myanmar
    'ne': 'NP',  // Nepali - Nepal
    'no': 'NO',  // Norwegian - Norway
    'pa': 'IN',  // Punjabi - India
    'pl': 'PL',  // Polish - Poland
    'pt': 'PT',  // Portuguese - Portugal
    'ro': 'RO',  // Romanian - Romania
    'ru': 'RU',  // Russian - Russia
    'sr': 'RS',  // Serbian - Serbia
    'sk': 'SK',  // Slovak - Slovakia
    'sl': 'SI',  // Slovenian - Slovenia
    'so': 'SO',  // Somali - Somalia
    'es': 'ES',  // Spanish - Spain
    'sw': 'KE',  // Swahili - Kenya
    'sv': 'SE',  // Swedish - Sweden
    'tl': 'PH',  // Tagalog (Filipino) - Philippines
    'ta': 'IN',  // Tamil - India
    'tr': 'TR',  // Turkish - Turkey
    'uk': 'UA',  // Ukrainian - Ukraine
    'ur': 'PK',  // Urdu - Pakistan
    'vi': 'VN',  // Vietnamese - Vietnam
    'cy': 'GB',  // Welsh - United Kingdom
    'xh': 'ZA',  // Xhosa - South Africa
    'yo': 'NG',  // Yoruba - Nigeria
    'zu': 'ZA'   // Zulu - South Africa
  };
  
  
  // Helper function to get language codes string
  export const getAllLanguageCodes = () => {
    return Object.keys(LANGUAGES).join(',');
  };
  
  // Helper function to get sorted language entries
  export const getSortedLanguages = () => {
    return Object.entries(LANGUAGES).sort((a, b) => a[1].localeCompare(b[1]));
  };