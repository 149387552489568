import Modal from '..'
import { MdAssistant, MdClose, MdMicrowave, MdMovie } from 'react-icons/md'

const CreateScriptModal = ({ setType, setShowModal }) => {

    const close = () => {
        setShowModal(false)
    }

    const buttonClassName = `h-[30%] bg-white hover:bg-gray-100 max-h-[10rem] transition-all hover:scale-110 rounded-xl shadow-lg justify-center
     w-full mx-auto text-[#292929] p-8 flex flex-col border flex-wrap gap-4 items-center text-start`


    return (
        <Modal closeOnClickOutside={false}>
            <div className='flex flex-col h-full '>
                <div className='flex justify-between'>
                    <div></div>
                    <h2 className="mt-4 mb-6 ms-2  text-center text-2xl text-[#292929] font-bold">Create new Script</h2>
                    <button onClick={close} className='me-2'>
                        <MdClose color='#868686' size={24} />
                    </button>
                </div>
                
                <div className='h-[80%] px-8 flex flex-col items-center gap-4 justify-evenly'>
                    <button className={buttonClassName}
                        onClick={() => {
                            setType('treatment')
                            close()
                        }}><MdAssistant size={40} color="#AAAAAA" />
                        <p className='text-lg font-bold text-[#AAAAAA]'>Treatment</p>
                    </button>

                    <button className={buttonClassName} onClick={() => {
                        setType('film')
                        close()
                    }}><MdMovie size={40} color="#AAAAAA" />
                        <p className='text-lg font-bold text-[#AAAAAA]'>Film Script</p>
                    </button>

                    <button className={buttonClassName} onClick={() => {
                        setType('tv')
                        close()
                    }}><MdMicrowave size={40} color="#AAAAAA" />
                        <p className='text-lg font-bold text-[#AAAAAA]'>TV Script</p>
                    </button>
                </div>
            </div>

        </Modal>
    )
}

export default CreateScriptModal