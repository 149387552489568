import { Modal } from 'antd'
import React from 'react'
import { MdArrowForward, MdArrowForwardIos } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const AlexAiAddOnModal = ({ setIsAlexAiAddOnModalOpen, isAlexAiAddOnModalOpen }) => {
    const navigate = useNavigate()
    const navigateToSubscription = () => {
        navigate('/myprofile/subscription')
    }
    const close = () => {
        setIsAlexAiAddOnModalOpen(false)
    }

    return (
        <Modal open={isAlexAiAddOnModalOpen} footer={null} onClose={close} onCancel={close}>

            <h1 className='font-bold text-[#292929] text-lg'>Buy Alex AI Add-On</h1>
            <h1 className='font-bold text-[#868686] mt-4'>You have reached the limit of your current plan for Alex AI</h1>
            <h2 className=' text-[#868686]'>Click below to buy the add-on</h2>
            <div className='flex mt-3 justify-between'>
                <button onClick={close} className='px-3 py-2 gap-2 border-2 flex justify-center items-center font-bold rounded-full text-[#868686] '>
                    Cancel
                </button>
                <button onClick={navigateToSubscription} className='px-3 py-2 gap-1 flex justify-center items-center font-bold rounded-full text-white bg-[#0099ff]'>
                    Buy Add-On
                    <MdArrowForwardIos size={16} />
                </button>
            </div>

        </Modal>
    )
}

export default AlexAiAddOnModal;