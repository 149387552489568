import React, { useEffect, useState } from 'react'
import { MdArrowBackIos, MdClose, MdExpandMore, MdSearch } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import RevisionTab from './RevisionTab.js'
import ScriptTab from '../Scenes/ScriptTab'
import LoaderSpin from '../../Common/LoaderSpin'
import { getAccessToken } from '../../../services/authService.js'
import { updateContent } from '../ScriptInfo/functions.js'
import { toast } from 'react-toastify'

const Revisions = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isScriptTabActive, setIsScriptTabActive] = useState(true)
    const [searchVal, SetSearchVal] = useState('')
    const [isSearchOpen, setIsSearchOpen] = useState(false)

    const [loading, setLoading] = useState(1)
    const [scriptData, setScriptData] = useState({})
    const [updateData, setUpdateData] = useState(false)
    const [content, setContent] = useState('')
    const [activeIndex, setActiveIndex] = useState(0)
    const [revisionList, setRevisionList] = useState([])

    const fetchScript = async () => {
        setLoading(1)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!res.ok) {
                throw new Error('Error getting response', res)
            }
            const body = await res.json()
            setScriptData(body.data)
            setContent(body.data.content)
            setLoading(0)
        } catch (error) {
            setLoading(2)
            console.error(error)
        }
    }

    const restoreRevision = async() => {
        if(revisionList[activeIndex]){
            const onComplete = ()=>{
                toast.success('Revision restored sucessfully')
            }
            const onError = ()=>{
                toast.error('Revision restore failed')
            }
            setLoading(1)
            await updateContent(scriptData,content,onComplete,onError)
            setLoading(0)
        }
     }


useEffect(() => {
    fetchScript()
}, [updateData])

return (
    <div className=" bg-white flex flex-col h-full overflow-y-hidden">
        <nav className='h-[72px] max-h-[72px] min-h-[72px] p-4 gap-4 flex border-b border-[#0000001A] justify-between items-center'>
            {isSearchOpen && !isScriptTabActive ?
                <>
                    <div className='bg-white border grow border-[#CACACA] gap-2 rounded-lg p-3 flex items-center'>
                        <MdSearch size={24} color='#AAAAAA' />
                        <input placeholder={`Search Revisions`}
                            className='outline-none grow focus:border-none'
                            value={searchVal}
                            onChange={(e) => { SetSearchVal(e.target.value) }} />

                    </div>
                    <MdClose size={28} color='#868686' onClick={() => {
                        setIsSearchOpen(false)
                        SetSearchVal('')
                    }} />
                </>
                :
                <>
                    <div className='flex gap-1 w-full'>
                        <button className='h-fit pt-1' onClick={() => { navigate(`/scripts/${id}`) }}>
                            <MdArrowBackIos size={22} color='#292929' />
                        </button>
                        <div className='flex items-center justify-between w-full'>
                            <h1 className='text-[#292929] font-bold text-[20px]'>Revisions</h1>
                            <button onClick={restoreRevision} disabled={activeIndex === 0 || loading!==0} className={`text-[14px] w-[146px] bg-[#0099ff] text-white border disabled:text-gray-500 disabled:bg-white px-2 py-1 rounded-lg`}>
                                {loading==1?
                                <LoaderSpin size={14}/>
                                :'Restore this version'}
                            </button>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center'>

                        {/* {!isScriptTabActive && <button className='pt-1'>
                        <MdSearch size={28} color='#AAAAAA' onClick={() => { setIsSearchOpen(true) }} />
                    </button>} */}
                    </div>
                </>}
        </nav>

        <div className='h-[calc] overflow-hidden z-[100]'>
            {loading === 1 && <div className='h-full py-20 flex items-center justify-center'><LoaderSpin size={40} /></div>}
            {loading === 0 && <div className='overflow-y-hidden'>
                <div className='overflow-y-hidden mt-5 mx-4 flex p-1 gap-1 rounded-[9px] bg-[#EFEFEF]'>
                    <button className={` grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${isScriptTabActive && 'bg-white shadow-md'}`}
                        onClick={() => { setIsScriptTabActive(true) }}>
                        Script
                    </button>
                    <button className={`grow text-[13px] p-1 text-[#292929] text-center rounded-[9px] ${!isScriptTabActive && 'bg-white shadow-md'}`}
                        onClick={() => { setIsScriptTabActive(false) }}>
                        Revisions
                    </button>
                </div>
                <div className='overflow-y-scroll h-[calc(100svh-100px)] pb-10 mt-2'>
                    {isScriptTabActive ? <ScriptTab content={content} /> :
                        <RevisionTab
                            setIsScriptTabActive={setIsScriptTabActive}
                            setUpdate={setUpdateData}
                            scriptData={scriptData}
                            setContent={setContent}
                            revisionList={revisionList}
                            setRevisionList={setRevisionList}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex} />}
                </div>
            </div>}
        </div>

    </div>
)
}

export default Revisions