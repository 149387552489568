import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const location = useLocation()
  let session =
    useSelector((state) => state?.auth?.session) ||
    JSON.parse(localStorage.getItem("session"));
  const user = JSON.parse(localStorage.getItem("user") || "{}")
  let defaultNavigationLink = "/login";
  
  if (window.location.pathname.includes("/invite/")) {
    const queryParams = new URLSearchParams(window.location.search);
    const newUser = queryParams.get('new-user');
    if (newUser === '0') defaultNavigationLink = "/register";
  }
  return (session && user?.isVerified) ? <Outlet /> : <Navigate to={defaultNavigationLink} state={{from : location}} />;
};

export default ProtectedRoute;
