export const exportContentStyle = `

          .pdf-container{
           font-family:  Courier, monospace;
           font-size: 12pt;
           }



            pdfupload p{
              letter-spacing: normal;
              line-height: normal;
              margin-top:16px;
              margin-bottom:16px;
              word-break: break-word;
              overflow-wrap: break-word;
              padding-left: 133.6px;
              padding-right: 95.5px;
              }
            

          .pageEnd { width:calc(100% + 10px);
            height:100px;
            background: #EAEEF2;
            margin-left: -5px;
          }
          
           .sceneHeadings::before {
              content: attr(data-scene-index);
              margin-left: -20px;
              margin-right: 12px;
            }

          .tox-tinymce{
            border-radius:0;
            }

          .coldOpening { 
            margin-left: auto;
            margin-right: auto;
            max-width: 40%;
            text-transform: uppercase;
            text-decoration: underline;
            text-align: center;
            margin-bottom: 90px;
            font-weight: bold;
            color: #555;
          }

          .sceneHeadings {
            font-weight: 900; 
            text-transform: uppercase; 
            color: #a52a2a; 
          }

          .sceneHeadings + .action{
            padding-top:0px;
          }
          .action {
            padding-top:16px;
            margin: 0;
            color: #555;
          }
          .character { 
            margin: 0;
            font-weight: bold; 
            text-align: center;
            padding-left: 15%;
            padding-right: 15%;
            text-transform: uppercase;
            color: #cd3333;
            margin-top: 1rem !important;
          }
          .extra-character,.non-speaking-character{
            display: none;
          }
          
          .parentheses {
            
            margin:0;
            text-align: center;
            padding-left: 15%;
            padding-right: 15%;
            font-style: italic;
            color: #f5785a;
            margin-top: 0;
            margin-bottom: 0; }

          .parentheses::before {
            content: "(";
          }
          .parentheses::after {
            content: ")";
          }
            .dialogue { 
            text-align: center;
            padding-left: 15%;
            padding-right: 15%;
            margin-bottom: 0;
            margin-top: 0;
            color: #555;
             }

          .custom-pagebreak {
            margin:0;
            padding:0;
            user-select: none;
            pointer-events: none;
            border: none;
            height: 40px;
            width: calc(100% + 40px);
            margin-left: -20px;
            background: #f5f5f5;
            position: relative;
          }

          .custom-pagebreak::after {
            content: attr(data-page);
           
            position: absolute;
            bottom: -70px;
            right: 50px;
            color: #888;
          }
          .custom-pagebreak::before {
            content: "CONTINUED.";
            
            position: absolute;
            bottom: -70px;
            left: 50px;
            color: #888;
          }
          
          .newAct { font-weight: bold; color: #00008B; }
          .endAct { font-weight: bold; color: #8B0000; }

          .transition { 
          text-transform: uppercase;
          text-align: right;
           color: #555; }

          .flashBack { 
            color: #555;
            text-transform: uppercase;
          }
          .montage { 
            color: #555;
            text-transform: uppercase;
          }
          .titlePage { font-weight: bold; text-align: center; color: #000080; }
          .genre { font-style: italic; color: #20B2AA; }
          .logline { font-style: italic; margin: 1em 0; color: #20B2AA; }
          .synopsis { margin: 1em 0; color: #4682B4; }
          .treatmentHeader { font-weight: bold; color: #1E90FF; text-transform: uppercase; }

          .treatmentPara {
            margin: 0px;
            margin-top: 16px;
             color: #585858 ; 
            }

.custom-pagebreak {
            margin:0;
            padding:0;
            user-select: none;
            pointer-events: none;
            border: none;
            height: 40px;
            width: calc(100% + 32px);
            margin-left: -16px;
            background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
            position: relative;
          }

          .pdf-container[data-show-notes] p[data-note-id]::after {
          right: 2%;
}
            .mce-content-.pdf-container[data-mce-placeholder]:not(.mce-visualblocks)::before{
              margin-left:133.6px;
              margin-top:18px;
            }
           

        .custom-pagebreak {
            margin:0;
            padding:0;
            page-break-before: always;
            user-select: none;
            pointer-events: none;
            border: none;
            border-left: solid 16px white;
            border-right: solid 16px white;
            margin-top: 25mm;
            margin-bottom: 25mm;
            height: 5px;
            width: calc(100% - 1px);
            margin-left: -15px;
            background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
            position: relative;
          }
            
            .pdf-container{
              z-index: 1;
            }
            .mce-pagebreak{
            user-selects:none;
              border: none;
              border-left: solid 4mm white;
              border-right: solid 4mm white;
              margin-top: 25mm;
              margin-bottom: 25mm;
              height: 100px;
              width: 210mm;
              margin-left: -29mm;
              background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0) 6%, rgba(255,255,255,0) 96%, rgba(0,0,0,0.15) 100%);
              box-shadow: 50px 50px white;
            }

            .sceneHeadings::before {
                content: attr(data-scene-index);
                margin-left: -50px;
                margin-right: 42px;
            }

            .coldOpening { 
            text-decoration: underline;
            text-align: center;
            margin-bottom: 90px;
            font-weight: bold;
            color: #555;
          }

          .sceneHeadings {
            font-weight: 900; 
            text-transform: uppercase; 
            color: #a52a2a; 
          }

          .action {
            margin: 0;
            color: #555;
          }
          .character {
            text-align: start;
            padding: 0;
            width: 357.73px;
            font-weight: bold;
            padding-left: 12%;
            margin-left: 28%;
            text-transform: uppercase;
            color: #cd3333;
            line-height:1.1em;
          }
            
          .parentheses {
          text-align: start;
            width: 520.69px;
            margin-left: 28%;
            padding-left: 9%;
            font-style: italic;
            color: #f5785a;
            margin-top: 0;
            line-height:1.1em;
          }

          .parentheses::before {
            content: "(";
          }
          .parentheses::after {
            content: ")";
          }
          .dialogue { 
          text-align: start;
          margin: 0;
          padding: 0;
            width: 357.73px;
            margin-left: 28%;
            margin-top: 0;
            color: #555;
            line-height:1.1em;
             }
          .newAct { font-weight: bold; color: #00008B; }
          .endAct { font-weight: bold; color: #8B0000; }

          .transition { 
          text-align: right;
           color: #555; }

          .flashBack { 
            color: #555;
            text-transform: uppercase;
          }
          .montage { 
            color: #555;
            text-transform: uppercase;
          }
          .titlePage { font-weight: bold; text-align: center; color: #000080; }
          .genre { font-style: italic; color: #20B2AA; }
          .logline { font-style: italic; margin: 1em 0; color: #20B2AA; }
          .synopsis { margin: 1em 0; color: #4682B4; }
          .treatmentHeader { font-weight: bold; color: #1E90FF; text-transform: uppercase; }
        `