import React, {useRef } from "react";

const OtpInput = ({ otp, setOtp }) => {
  const inputRefs = useRef([]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input box if the current one is filled
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text").slice(0, 6);
    if (/^[0-9]*$/.test(pasteData)) {
      const newOtp = pasteData
        .split("")
        .concat(new Array(6 - pasteData.length).fill(""));
      setOtp(newOtp);

      // Move focus to the appropriate input box
      for (let i = 0; i < 6; i++) {
        if (newOtp[i]) {
          inputRefs.current[i].focus();
        }
      }
    }
    event.preventDefault();
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
      {otp.map((value, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={value}
          onPaste={handlePaste}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          style={{
            width: "40px",
            height: "40px",
            textAlign: "center",
            fontSize: "20px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
