import React, { useState } from "react";
import { Drawer } from "antd";
import { Select, Checkbox } from "antd";
import CustomButton from "../CustomButton";

const FilterModal = ({ open, setOpen, docs, setDocs }) => {

    const [sortBy, setSortBy] = useState("recent");
    const [filters, setFilters] = useState({
        type: [],
        status: [],
    });

  const onClose = () => {
    setOpen(false);
  };

const applyFilters = (data, sortBy, filters) => {
    let filteredData = data;
    if (filters.type.length > 0) {
        filteredData = filteredData.filter((item) => filters.type.includes(item.type.toLowerCase()));
    }

    if (filters.status.length > 0) {
        filteredData = filteredData.filter((item) => filters.status.includes(item.status.toLowerCase()));
    }

    if (sortBy === "recent") {
        
        filteredData = filteredData.sort((a, b) => new Date(b.latestRevision.updatedAt) - new Date(a.latestRevision.updatedAt));
    } else if (sortBy === "size") {
        filteredData = filteredData.sort((a, b) => b.title.length - a.title.length);
    }
    setDocs(filteredData);
    setOpen(false);

    return filteredData;
};

  return (
    <Drawer title="Sort and Filter" onClose={onClose} open={open}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
        <p className="font-bold">SORT BY</p>
        <Select
          defaultValue="recent"
          style={{ width: "100%", marginBottom: 16 }}
          value={sortBy}
            onChange={(value) => setSortBy(value)}
          options={[
            { value: "recent", label: <span>Most Recent</span> },
            { value: "size", label: <span>Most Size</span> },
          ]}
        />
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold">FILTER BY</p>

          <div className="flex flex-col gap-3">
            <p className="text-[#3e3e52] font-semibold">Script Type</p>
            <Checkbox.Group 
            onChange={(checkedValues) =>{setFilters({ ...filters, type: checkedValues })}}
            className="flex flex-col border py-4 px-2 rounded-lg bg-gray-100 gap-2 sm:flex-row " style={{ width: "100%" }}>
              <Checkbox
            
              value="tv">TV Script</Checkbox>
              <Checkbox value="film">Film Script</Checkbox>
              <Checkbox value="treatment">Treatment</Checkbox>
            </Checkbox.Group>
          </div>

          <div className="flex flex-col gap-3 mt-4">
            <p className="text-[#3e3e52] font-semibold">Script Status</p>
            <Checkbox.Group
            onChange={(value) => setFilters({ ...filters, status: value })}
            className="flex flex-col border py-4 px-2 rounded-lg bg-gray-100 gap-2 sm:flex-row " style={{ width: "100%" }}>
              <Checkbox value="incomplete">In Progress</Checkbox>
              <Checkbox value="review">Review</Checkbox>
              <Checkbox value="complete">Complete</Checkbox>
            </Checkbox.Group>
          </div>
        </div>
        <div className="mt-4">
        <CustomButton
          type="primary"
          className="w-full h-11"
        //   handleClick={onClose}
          handleClick={()=> applyFilters(docs, sortBy, filters)}
          style={{ marginTop: 48 }}
        >
          Apply Filters
        </CustomButton>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterModal;
