// components/GoogleTranslateElement.js
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLanguageCodes } from '../constants/languages';
import { setSelectedLanguage } from '../store/navSlice';

const STORAGE_KEY = 'preferred_language';

const GoogleTranslateElement = () => {
  const isScriptLoaded = useRef(false);
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.nav.selectedLanguage);

  const changeLanguage = (languageCode) => {
    const selectElement = document.querySelector('.goog-te-combo');
    if (selectElement) {
      selectElement.value = languageCode;
      selectElement.dispatchEvent(new Event('change'));
      selectElement.dispatchEvent(new Event('goog-te-changed'));
      
      localStorage.setItem(STORAGE_KEY, languageCode);
      dispatch(setSelectedLanguage(languageCode));
    }
  };

  // Function to check if Google Translate is fully loaded
  const isGoogleTranslateLoaded = () => {
    return !!document.querySelector('.goog-te-combo');
  };

  // Function to initialize language with proper check
  const initializeLanguage = () => {
    const savedLanguage = localStorage.getItem(STORAGE_KEY) || selectedLanguage || 'en';
    
    // Poll for Google Translate widget
    const checkAndSetLanguage = () => {
      if (isGoogleTranslateLoaded()) {
        changeLanguage(savedLanguage);
        return true;
      }
      return false;
    };

    // Try immediately first
    if (!checkAndSetLanguage()) {
      // If not loaded yet, start polling with a maximum timeout
      let attempts = 0;
      const maxAttempts = 20; // 20 attempts * 100ms = 2 second maximum
      
      const pollInterval = setInterval(() => {
        attempts++;
        if (checkAndSetLanguage() || attempts >= maxAttempts) {
          clearInterval(pollInterval);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (isScriptLoaded.current) return;

    const addScript = () => {
      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);
      isScriptLoaded.current = true;
    };

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: getAllLanguageCodes(),
          layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        },
        'google_translate_element'
      );

      initializeLanguage();
    };

    addScript();
  }, []);

  // Effect to handle language changes from Google's widget
  useEffect(() => {
    if (!isGoogleTranslateLoaded()) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const selectElement = document.querySelector('.goog-te-combo');
          if (selectElement && !selectElement.dataset.eventBound) {
            selectElement.dataset.eventBound = 'true';
            
            selectElement.addEventListener('change', (e) => {
              const newLanguage = e.target.value;
              localStorage.setItem(STORAGE_KEY, newLanguage);
              dispatch(setSelectedLanguage(newLanguage));
            });
          }
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect();
  }, [dispatch]);

  return <div id="google_translate_element" />;
};

export default GoogleTranslateElement;