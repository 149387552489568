import React from 'react'
import emptyImage from '../../../../assets/backgrounds/empty_panel_background.webp'

const EmptyPage = ({title,subtitle}) => {
  return (
    <div className='h-full flex flex-col items-center'>
        <img className='w-[150px] mt-[100px]' src={emptyImage}></img>
        <p className='font-bold mt-8 text-center'>{title}</p>
        <p className='text-sm mt-2 text-center'>{subtitle}</p>
    </div>
  )
}

export default EmptyPage