// PublicRoute.js
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  let session =
    useSelector((state) => state?.auth?.session) ||
    JSON.parse(localStorage.getItem("session"));
  const user = JSON.parse(localStorage.getItem("user") || "{}")
  return (!user.isVerified) ? <Outlet /> : <Navigate to="/scripts" />;
};

export default PublicRoute;
