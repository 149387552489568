import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { getTitle, timeColors } from '../Editor/utils/sceneutils';
import SceneDetailsSlider from './SceneDetailsSlider';
import { GoDotFill } from "react-icons/go";
import EmptyPage from '../Editor/sidepanel/EmptyPage';
import { getAccessToken } from '../../../services/authService';
import LoaderSpin from '../../Common/LoaderSpin';
import { getPNodeArrtibute } from '../ScriptInfo/functions';

const ScenesTab = ({ setUpdateData, body, scriptData, searchVal }) => {
    const [activeScene, setActiveScene] = useState(null)
    const [activeIndex, setActiveIndex] = useState(null)

    const userData = JSON.parse(localStorage.getItem("user"));
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [permissionToEdit, setPermissionToEdit] = useState(false);

    useEffect(() => {
        if (users.length === 0) fetchCollaborator();
    }, []);

    const fetchCollaborator = async () => {
        setIsLoading(true);
        const token = getAccessToken();
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DEV_API}/documents/${id}/collaborators/list`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsLoading(false);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setUsers(data.data);
            const isAdmin = !data.data.find(user => user.email === userData.email);
            const allowedToEdit = isAdmin || data.data.find(user => user.email === userData.email)?.permission?.SCRIPT === "WRITE";
            setPermissionToEdit(allowedToEdit);
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    // const scenes = pNodes.filter(node => node.attrs.find(attr => attr.name === 'class')?.value === 'sceneHeadings').map((scene) => {
    //     return {
    //         color: scene.attrs.find(attr => attr.name === 'data-color')?.value,
    //         summary: scene.attrs.find(attr => attr.name === 'data-summary')?.value,
    //         title: scene.childNodes[0]?.value || '',
    //     }
    // })
    const scenes = []
    let sceneIndex = 0

    body.childNodes.forEach((node, index) => {
        if (node.nodeType === 1) {
            if (node.classList[0] === 'sceneHeadings') {
                //JSON.parse(node.getAttribute('data-note-list') || 'null')?.length ||
                scenes.push({
                    color: node.getAttribute('data-color'),
                    summary: node.getAttribute('data-summary'),
                    title: node.textContent,
                    notes: 0
                })
                sceneIndex++
            }
            if (scenes[sceneIndex - 1] && node.getAttribute("data-note-list")) {
                const noteList = JSON.parse(node.getAttribute("data-note-list") || "null") || []
                scenes[sceneIndex - 1].notes += noteList.length
            }

        }

    })

    const [sceneList, setSceneList] = useState(scenes)

    const filteredList = sceneList.slice().map((scene,index)=>{return {...scene, index:index+1}}).
    filter(({ title },index) => title.toUpperCase().includes(searchVal.toUpperCase()) || index+1 == searchVal);

    if (isLoading) {
        return (
            <div className="flex w-full justify-center items-center h-[80vh]">
                <LoaderSpin size={36} />
            </div>
        )
    }

    return (
        <>
            {!sceneList || sceneList.length === 0 ? <EmptyPage title={'You do not have any scenes'} subtitle={'Create some scenes to see here'} />
                :
                <>
                    <div className='mt-4'>
                        {!filteredList || filteredList.length === 0 &&
                            <div className='mt-10'>

                                <EmptyPage title={"No results found"} subtitle={"Try searching for another scene"} /> :

                            </div>}
                        {filteredList.map((scene, index) =>
                            <div key={scene.index} className='flex border-b p-2 gap-4 cursor-pointer' onClick={() => {
                                setActiveScene(scene)
                                setActiveIndex(scene.index)
                            }}>
                                <div style={{ background: scene.color || timeColors[getTitle(scene.title).time] || '#d9dee3' }} className='w-1 rounded-full' />
                                <div className='py-4'>
                                    <div className='flex items-center gap-1 mb-1 uppercase text-[15px] font-medium'>
                                        <b>#{scene.index}.</b>
                                        <label className='cursor-pointer max-w-[calc(100svw-150px)] truncate text-nowrap'>{scene.title}</label>
                                    </div>
                                    <div className='flex items-center underline gap-2 text-[14px] text-[#868686] font-medium'>
                                        <label>{scene.notes} Notes</label>
                                    </div>
                                </div>


                            </div>)}
                    </div>
                    {activeScene && <SceneDetailsSlider setUpdateData={setUpdateData} activeScene={activeScene} setActiveScene={setActiveScene} index={activeIndex} scenes={scenes} body={body} scriptData={scriptData} permissionToEdit={permissionToEdit} />}
                </>}
        </>
    )
}

export default ScenesTab