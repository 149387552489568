import { useEffect, useRef, useState } from "react";
import { getAccessToken } from "../../../../../services/authService";
import { toast } from "react-toastify";
import CustomButton from "../../../../CustomButton";
import { useParams } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import LoaderSpin from "../../../../Common/LoaderSpin";
import { Radio } from "antd";

const AddPeopleModal = ({ isOpen, setIsOpen, setUpdateCollaborators }) => {

  const [isLoading, setisLoading] = useState(null);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("")
  const [emailErrors, setEmailErrors] = useState("");
  const [nameErrors, setNameErrors] = useState("");
  const [permissionState, setPermission] = useState(2)
  const { id } = useParams();

  const permissionLookup = {
    1: {
      "OVERVIEW": "READ",
      "SCRIPT": "WRITE"
    },
    2: {
      "OVERVIEW": "READ"
    }
  }

  const close = () => {
    setIsOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailErrors(emailRegex.test(email) ? "" : "Invalid email format");
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    setNameErrors(nameRegex.test(name) ? "" : "Invalid name");
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setFullName(value);
    validateName(value);
  };

  const handlePermissionChange = (e) => {
    setPermission(e.target.value)
  }



  const handleSendInvite = () => {
    const token = getAccessToken();
    const documentId = id;
    const permissions = permissionLookup[permissionState]
    try {
      setisLoading(true);
      fetch(
        `${process.env.REACT_APP_DEV_API}/documents/${documentId}/collaborators/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: email,
            name: fullName,
            permissions
          }),
        }
      )
        .then(async (response) => {
          const data = await response.json();
          return { data, response };
        })
        .then(({ data, response }) => {
          if (response.status !== 200) {
            setEmailErrors(data.message);
          } else if (response.status === 200) {
            setEmailErrors("");
            setUpdateCollaborators(prev => !prev)
            toast.success("Invite sent successfully");
            close();
          }
        }).finally(() => {
          setisLoading(false);
        });
    } catch (error) {
      toast.error('Invite failed')
      console.error("Error:", error);
    }
  };


  //   curl --location '{{baseurl}}/documents/{{documentId}}/collaborators/add' \
  // --header 'Content-Type: application/json' \
  // --header 'Authorization: Bearer {{token}}' \
  // --data '{
  //   "userId": "66b47ac968cdd4c4c5690516",
  //   "permission": "edit"
  // }'

  return (
    <div className="fixed top-[7vh] left-0 h-[93vh] flex justify-center items-center bg-[#0000002c] z-[80] w-[100vw]" >
      <div className="bg-white w-full p-4 rounded-lg max-w-[500px]">
        <div className="flex w-full mb-4 justify-center px-7">
          <div className="font-bold self-center text-[#292929] ">Add People</div>
        </div>
        <hr />


        <div className="flex flex-col justify-center items-center mt-4 w-full px-8">
          <div className="w-full flex mb-2 justify-center items-center gap-2 border-2 h-[44px] rounded-[10px] p-2">
            <input
              type="fullname"
              required
              placeholder="Enter full name"
              className="w-full text-[12px] focus:border-none bg-transparent border-none outline-none h-[44px]"
              onChange={handleNameChange}
            />
          </div>
          {!!true && (
            <label className="text-[10px] text-red-600 text-left self-left w-full pl-2 my-2">
              {nameErrors}
            </label>
          )}
          <div className="w-full flex justify-center items-center gap-2 border-2 h-[44px] rounded-[10px] p-2">
            <input
              type="email"
              required
              placeholder="Enter email address"
              className="w-full text-[12px] focus:border-none bg-transparent border-none outline-none h-[44px]"
              onChange={handleEmailChange}
            />
          </div>
          {!!true && (
            <label className="text-[10px] text-red-600 text-left self-left w-full pl-2 my-2">
              {emailErrors}
            </label>
          )}
          <div className="w-full mb-4">
            <p className="text-[12px] text-[#868686] w-full my-2">
              Allow Collaborator to
            </p>

            <Radio.Group onChange={handlePermissionChange} value={permissionState}>
              <Radio className="text-[#868686]" value={1}>Read and Write</Radio>
              <Radio className="text-[#868686]" value={2}>Read Only</Radio>
            </Radio.Group>

          </div>

          <div className="flex justify-between w-full">
            <button className="text-[12px] h-[34px] w-[80px] rounded-lg border-2 " onClick={close}>Cancel</button>
            <button
              style={{ background: email === "" || emailErrors !== "" || fullName === "" ? '#CACACA' : '#0099FF' }}
              className="border-0 cursor-pointer h-[34px] w-[100px] rounded-lg text-[white] text-[12px] font-bold "
              onClick={handleSendInvite}
              disabled={email === "" || emailErrors !== "" || fullName === ""}
            >
              {isLoading ? <LoaderSpin color={'white'} /> : 'Send invite'}

            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPeopleModal;
