import { useEffect, useState } from "react"
import BackButton from "../BackButton"
import EmptyPage from "../EmptyPage"
import Dropdown from "../../../../Common/DropDown.js"
import { MdAdd, MdExpandMore } from "react-icons/md"
import ElementButton from "./ElementButton.js"
import { getAccessToken } from "../../../../../services/authService.js"
import { useParams } from "react-router-dom"
import LoaderSpin from "../../../../Common/LoaderSpin.js"
import { addCategory, deleteCategory, deleteElement } from "../../utils/elementsUtils.js"
import AddCategoryModal from "./AddCategoryModal.js"
import AddElementModal from "./AddElementModal.js"
import EditCategoryModal from "./EditCategoryModal.js"
import ElementDetails from "./ElementDetails.js"
import { goToScene } from "../../utils/sceneutils.js"

const ElementsPanel = ({ setPanel, editor, sceneElements, setUpdateElements, permissionToEdit }) => {


    const [sceneIndex, setSceneIndex] = useState(0)
    const [isLoading, setLoading] = useState(false)
    const [openedElement, setOpenedElement] = useState(null)
    const [openedCategory, setOpenedCategory] = useState(null)
    const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false)
    const [openAddElementModal, setOpenAddelementModal] = useState(false)
    const [closeDropDown, setCloseDropDown] = useState(false)
    const sceneList = sceneElements.sceneElements.map((_, index) => index) || []
    const elementList = sceneElements.sceneElements[sceneIndex]?.elementList || []
    const defaultCategories = sceneElements.defaultCategories
    const categoryList = [...(sceneElements.sceneElements[sceneIndex]?.categoryList || []),...defaultCategories.filter(category=>category.scene-1 !== sceneIndex)]
    
    const allElements = sceneElements.allElements;


    // add default category



    const handleDeleteCategory = (category) => {
        if (category.scene || category.scene === 0)
            deleteCategory(editor, category.name, category.scene-1)
        else
            deleteCategory(editor, category.name, sceneIndex)
        setUpdateElements(prev => !prev)
    }

    const handleDeleteElement = (elementName) => {
        deleteElement(editor, elementName, sceneIndex)
        setUpdateElements(prev => !prev)
    }


    const handleElementClick = (element) => {
        setOpenedElement(element)
    }

    const handleCategoryClick = (category) => {
        setOpenedCategory(category)
    }

    useEffect(() => {
        if (editor) {
            goToScene(sceneIndex, editor)
        }
    }, [sceneIndex, editor])

    return <>
        <div className='w-full h-full '>
            {openAddCategoryModal && <AddCategoryModal close={() => { setOpenAddCategoryModal(false) }} setUpdateElements={setUpdateElements} editor={editor} sceneIndex={sceneIndex} />}
            {openAddElementModal && <AddElementModal close={() => { setOpenAddelementModal(false) }} categoryList={categoryList} setUpdateElements={setUpdateElements} editor={editor} sceneIndex={sceneIndex} />}
            {openedCategory && <EditCategoryModal close={() => { setOpenedCategory(null) }} category={openedCategory} setUpdateElements={setUpdateElements} editor={editor} sceneIndex={sceneIndex} />}
            {openedElement !== null ?
                <ElementDetails categoryList={categoryList}
                    setUpdateElements={setUpdateElements}
                    setOpenedElement={setOpenedElement}
                    editor={editor}
                    openedElement={openedElement}
                    sceneIndex={sceneIndex} />
                :
                <>
                    <BackButton setPanel={setPanel} />
                    {isLoading ?
                        <div className="flex justify-center items-center py-8"><LoaderSpin /></div>
                        :
                        <div className='w-full h-[calc(100%-65px)] mt-4 shadow-sm overflow-hidden'>
                            {sceneList.length === 0 &&
                                <EmptyPage title={'You dont have any elements or scenes'}
                                    subtitle={'Start creating scenes and add elements to see here'} />}
                            {sceneList.length !== 0 &&
                                <div className=" bg-[#F3F4F6] h-full rounded flex flex-col gap-4 pt-2 pb-1">
                                    <div className="flex justify-between items-center px-4 ">
                                        <h3 className="font-bold text-[13px] ">Category and Elements</h3>
                                        <Dropdown close={closeDropDown} trigger={
                                            <button className="bg-white hover:bg-[#efefef] text-[12px] text-start rounded-lg flex items-center justify-between border text-[#868686] px-2 w-[100px] py-2">
                                                Scene {sceneIndex + 1}
                                                <MdExpandMore size={18} color="#CACACA" />
                                            </button>}>
                                            <div className=' flex flex-col w-[120px] max-h-[250px] overflow-y-auto'>
                                                {sceneList.map((index) =>
                                                    <button
                                                        key={index}
                                                        style={{ color: index === sceneIndex ? '#0099ff' : '', background: index === sceneIndex ? '#F0F9FF' : '' }}
                                                        className='border-b py-2 text-start text-[#868686] text-[13px] px-2 hover:bg-[#e2e2e2]'
                                                        onClick={() => {
                                                            setSceneIndex(index)
                                                            setCloseDropDown(prev=>!prev)
                                                        }}>
                                                        Scene {1 + index}
                                                    </button>
                                                )}
                                            </div>

                                        </Dropdown>
                                    </div>
                                    <hr className="-mt-2" />
                                    <div className="flex justify-between px-4 items-center">
                                        <h3 className="font-medium text-[12px] text-[#868686]">Category</h3>
                                        <button disabled={!permissionToEdit} onClick={() => { setOpenAddCategoryModal(true) }} className="text-[#CACACA] transition-colors hover:text-[#292929]">
                                            <MdAdd size={20} />
                                        </button>

                                    </div>
                                    { categoryList.length > 0 && <div className="-mt-4 py-4 px-4 overflow-y-auto gap-[1px] flex flex-col">
                                        {categoryList.map((category, index) =>
                                            <ElementButton
                                                key={index}
                                                onClick={() => { if (permissionToEdit) handleCategoryClick(category) }}
                                                isDelete={permissionToEdit}
                                                onDelete={() => { handleDeleteCategory(category) }}
                                                color={category.color}
                                                name={category.name}
                                                isDisabled={!permissionToEdit}
                                            />)}
                                    </div>}

                                    <hr />
                                    <div className="flex justify-between px-4 items-center">
                                        <h3 className="font-medium text-[12px] text-[#868686]">Elements</h3>
                                        <button disabled={!permissionToEdit} onClick={() => { setOpenAddelementModal(true) }} className="text-[#CACACA] transition-colors hover:text-[#292929]">
                                            <MdAdd size={20} />
                                        </button>

                                    </div>
                                    {elementList.length > 0 && <div className="-mt-4 py-4 px-4 overflow-y-auto gap-[1px] flex flex-col">
                                        {elementList.map((element, index) =>
                                            <ElementButton
                                                key={index}
                                                name={element.name}
                                                onClick={() => { if (permissionToEdit) handleElementClick(element) }}
                                                color={categoryList.find(cat => cat.name === element.category)?.color}
                                                isDelete={permissionToEdit}
                                                onDelete={() => {
                                                    handleDeleteElement(element.name)
                                                }}
                                                isDisabled={!permissionToEdit}
                                            />)}
                                    </div>}

                                    <hr />

                                </div>}
                        </div>
                    }
                </>
            }
        </div>
    </>
}

export default ElementsPanel