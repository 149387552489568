import React, { useDebugValue, useEffect, useState } from 'react'

import ScriptCard from './ScriptCard';
import emptyVector from '../../assets/backgrounds/shared_scripts_empty.webp'
import { toast } from 'react-toastify';
import ScrollPagination from './ScrollPagination';
import { MdOutlineSearch, MdOutlineWifiTetheringError } from 'react-icons/md';
import { getAccessToken } from '../../services/authService';
import ErrorPopUp from '../Common/ErrorPopUp';
import { setConnectionErrorOpen } from '../../store/navSlice';
import { useDispatch } from 'react-redux';
import FilterModal from './FilterModal';

const SharedScripts = ({ itemsPerPage, searchVal, setShowFilterModal, showFilterModal  }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [scriptlist, setScriptList] = useState([])
    const [totalScripts, setTotalScripts] = useState(0)
    const [lastId, setLastId] = useState(null)
    const [fetchScript, setFetch] = useState(false)
    const [filteredScriptsList, setFilteredScriptsList] = useState(scriptlist)
    const [updateFetch, setUpdateFetch] = useState(false)

    const fetchScriptList = async (id) => {
        setLoading(1)
        const token = getAccessToken()
        const url = `${process.env.REACT_APP_DEV_API}/shared-document?sort=recently_updated&limit=${itemsPerPage}${searchVal !== '' ? '&search=' + searchVal : ''}${id ? '&lastId=' + id : ''}`
        try {
            if (!token) {
                throw new Error('A valid session not found')
            }
            const res = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!res.ok) {
                throw new Error('Error getting response')
            }
            const body = await res.json()
            const list = body.data
            setTotalScripts(body.totalDocuments);
            if (id) {
                setScriptList(prev => [...prev, ...list]);
                setFilteredScriptsList(prev => [...prev, ...list]);
            } else {
                setScriptList(list);
                setFilteredScriptsList(list);
            }
            setLoading(0)
            setLastId(list[list.length - 1]?._id)
        } catch (error) {
            if (error.message === 'Failed to fetch' || error.name === 'TypeError') {
                dispatch(setConnectionErrorOpen(true))
              }
            setLoading(2)
            console.error(error)
        }
    }

    useEffect(() => {
        if (lastId)
            fetchScriptList(lastId)
    }, [fetchScript])

    useEffect(() => {
        setLastId(null)
        setScriptList([])
        fetchScriptList(null)
    }, [searchVal,updateFetch])

    return (
        <>
            <div className='flex justify-between'>
                <p className='text-[#585858] text-4 font-medium'>Shared Scripts</p>
                {!!scriptlist.length && <p className='text-[#868686] text-[15px] font-medium'>{filteredScriptsList.length} Scripts</p>}
            </div>
            <div className='lg:flex-row lg:flex-wrap w-full mt-4 flex flex-col gap-4'>
                {scriptlist.length !== 0 ? filteredScriptsList.map((script, i) => (
                    <ScriptCard setFetch={setUpdateFetch} key={i} script={script} />
                ))
                    : <>
                    {loading === 0 && searchVal === '' && <div className='mx-auto mt-50 flex flex-col p-4 gap-4 items-center'>
                        <img src={emptyVector} className='w-[177px] h-[143px] object-cover' />
                        <h2 className='text-[#404040] text-[20px] font-bold text-center'>
                            No Script shared with you until now
                        </h2>
                        <p className='font-medium text-[15px] text-[#868686] text-center'>
                            You will have a shared script when someone invites you.
                        </p>
                    </div>}
                        {loading === 0 && searchVal !== '' &&
                            <div className='mt-50 mx-auto flex flex-col p-4 gap-4 items-center'>
                                <img src={emptyVector} className='w-[177px] h-[143px] object-cover' />
                                <h2 className='text-[#404040] text-[20px] font-bold text-center'>No script matched your search</h2>
                                <h3 className='font-medium text-[15px] text-[#868686] text-center'>Check your filters or try searching for a different script</h3>
                            </div>}

                        {loading === 2 &&
                            <div className='mt-50 mx-auto flex flex-col p-4 gap-4 items-center'>
                                <MdOutlineWifiTetheringError size={150} color='#C9c9c9' />
                                <h2 className='text-[#404040] text-[20px] font-bold text-center'>There was a problem retirieving data</h2>
                                <h3 className='font-medium text-[15px] text-[#868686] text-center'>Check your internet connection and try again</h3>
                            </div>
                        }
                    </>}
                    {(filteredScriptsList.length == 0 && scriptlist.length != 0 && !loading) && 
                        <div className='mt-50 mx-auto flex flex-col p-4 gap-4 items-center'>
                        <img src={emptyVector} className='w-[177px] h-[143px] object-cover' />
                        <h2 className='text-[#404040] text-[20px] font-bold text-center'>
                            No Scripts here
                        </h2>
               
                    </div>
                        }
            </div>
            <ScrollPagination loading={loading} lastId={lastId} setFetch={setFetch} totalItems={totalScripts} length={scriptlist.length} />
      <FilterModal open={showFilterModal} setOpen={setShowFilterModal} docs={scriptlist} setDocs={setFilteredScriptsList} />

        </>
    )
}

export default SharedScripts