import { useEffect, useRef, useState } from "react"
import { getAccessToken } from "../../../services/authService";
import { toast } from 'react-toastify'
import LoaderSpin from "../../Common/LoaderSpin";
import { bodyToString } from "../Editor/utils/scriptUtils";
import { updateContent } from "../ScriptInfo/functions";

const AddExtrasCharacter = ({ sceneIndex, setUpdateData, isOpen, setIsOpen, body, scriptData }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selelectedCharacters, setSelectedCharacters] = useState([])
    const [openAddNew, setOpenAddNew] = useState(false)

    const handleAddClick = async () => {
        setIsLoading(true)
        const currentScene = body.querySelector('p.sceneHeadings[data-scene-index="' + (sceneIndex + 1) + '"]')
        const extrasList = JSON.parse(currentScene.getAttribute('data-extras-character') || 'null') || []
        selelectedCharacters.forEach(name => {
            if (!extrasList.includes(name)) {
                extrasList.push(name)
            }
        })
        currentScene.setAttribute('data-extras-character', JSON.stringify(extrasList))

        const content = bodyToString(body)

        const onComplete = () => {
            toast.success('Character Added Sucessfully')
            setUpdateData(prev => !prev)
            close()
        }
        const onError = () => {
            toast.error('Character Failed to Update')
        }

        await updateContent(scriptData, content, onComplete, onError)
        setIsLoading(false)
    }

    const menuRef = useRef(null)
    const close = () => {
        setIsVisible(false)
        setTimeout(() => {
            setIsOpen(false)
        }, 300);
    }
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            close()
        }
    };
    useEffect(() => {
        setIsVisible(isOpen)
    }, [isOpen])
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className={`${isVisible ? 'bg-[#00000026]' : 'bg-transparent'} transition-all duration-200 bottom-0 flex items-end z-20 absolute  w-[100vw] h-[100svh] `}>
            <div ref={menuRef}
                className={`${isVisible ? 'translate-y-0' : 'translate-y-full'} transition-all duration-300 w-full mx-auto  h-[calc(95svh)] rounded-t-2xl bg-white shadow`}>
                <div className="flex justify-between p-4 bg-[#F5F5F5] rounded-t-2xl border-b-2 border-[#F5F5F5]">
                    <button className="text-[#007AFF] font-medium text-[17px]" onClick={close}>Cancel</button>
                    <h2 className=" text-[17px] font-bold">Add Extras</h2>
                    <button onClick={handleAddClick} disabled={isLoading} className="text-[#007AFF] text-[17px] font-medium">
                        {isLoading ? <LoaderSpin size={17} /> : 'Add'}
                    </button>
                </div>
                {openAddNew ?
                    <div className="p-4">
                        <label className="mb-2 text-[14px] text-[#868686]">Character Name</label>
                        <input placeholder="Enter extra character" value={selelectedCharacters[0]} onChange={(e) => {
                            let newValue = e.target.value ? e.target.value.toUpperCase() : '';
                            setSelectedCharacters([newValue])
                        }} className="w-full mt-2 mb-4 border border-[#CACACA] p-3 text-[14px] rounded-lg" />
                    </div>
                    :
                    <div className=" overflow-y-auto h-[calc(100%-59px)]">
                        <div className="my-10 px-4 flex justify-center">
                            <button onClick={() => {
                                setOpenAddNew(true)
                                setSelectedCharacters([])
                            }} className=" bg-[#F5FBFF] py-4 text-center text-[#0099ff] text-[16px] font-bold w-full max-w-[310px] rounded-full">Add new character</button>
                        </div>
                    </div>}

            </div>
        </div>)
}

export default AddExtrasCharacter