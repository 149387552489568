import { useNavigate, useParams } from 'react-router-dom'
import TV from './TV'
import Film from './Film'
import Treatment from './Treatment'

const CreateScript = () => {
  const navigate = useNavigate()
  const {type} = useParams()
  const allTypes = ['treatment','film','tv']
  if(!allTypes.includes(type)){
    return <h1> {type} is not a script type </h1>
  }

  return (
        <div className='overflow-y-hidden h-full'>
          {type === 'tv' && <TV />}
          {type === 'film' && <Film />}
          {type === 'treatment' && <Treatment/>}
        </div>
  )
}

export default CreateScript