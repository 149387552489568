import { useEffect, useRef, useState } from "react";
import { getAccessToken } from "../../../services/authService";
import { toast } from "react-toastify";
import CustomButton from "../../CustomButton";
import { useParams } from "react-router-dom";
import { Radio } from "antd";

const AddPeoplePopUp = ({ isOpen, setIsOpen,setUpdateCollaborators }) => {
  const [isVisible, setIsVisible] = useState(false);
  const menuRef = useRef(null);

  const [isLoading, setisLoading] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [nameErrors, setNameErrors] = useState("");
  const [permissionState, setPermission] = useState(2)
  const { id } = useParams();

  const permissionLookup = {
    1: {
      "OVERVIEW": "READ",
      "SCRIPT": "WRITE"
    },
    2: {
      "OVERVIEW": "READ"
    }
  }

  const close = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailErrors(emailRegex.test(email) ? "" : "Invalid email format");
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    setNameErrors(nameRegex.test(name) ? "" : "Invalid name");
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    validateName(value);
  };

  const handlePermissionChange = (e) => {
    setPermission(e.target.value)
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleSendInvite = () => {
    const token = getAccessToken();
    const documentId = id;
    const permissions = permissionLookup[permissionState]
    try {
      setisLoading(true);
      fetch(
        `${process.env.REACT_APP_DEV_API}/documents/${documentId}/collaborators/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: email,
            name,
            permissions
          }),
        }
      )
        .then(async (response) => {
          const data = await response.json();
          return { data, response };
        })
        .then(({ data, response }) => {
          if (response.status !== 200) {
            setEmailErrors(data.message);
          }else if(response.status === 200){
            setEmailErrors("");
            setUpdateCollaborators(prev => !prev)
            toast.success("Invite sent successfully");
            close();
          }
        }).finally(()=>{
          setisLoading(false);
        });
    } catch (error) {
      toast.error('Invite failed')
      console.error("Error:", error);
    }
  };


//   curl --location '{{baseurl}}/documents/{{documentId}}/collaborators/add' \
// --header 'Content-Type: application/json' \
// --header 'Authorization: Bearer {{token}}' \
// --data '{
//   "userId": "66b47ac968cdd4c4c5690516",
//   "permission": "edit"
// }'

  return (
    <div
      className={`${
        isVisible ? "bg-[#00000026]" : "bg-transparent"
      } transition-all duration-200 bottom-0 flex items-end z-50 fixed left-0  w-[100vw] h-[100vh] `}
    >
      <div
        ref={menuRef}
        className={`${
          isVisible ? "translate-y-0" : "translate-y-full"
        } transition-all duration-300  w-full mx-auto rounded-t-2xl bg-white shadow flex flex-col items-center h-[60%]`}
      >
        <div className="bg-[#F6F6F6] w-[100vw]  rounded-md">
          <div className="flex w-full py-4 justify-center px-7">
            <button
              className="text-[#007AFF] text-[400] justify-start absolute left-7"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>
            <div className="text-[17px] self-center ">Add People</div>
          </div>
          <div className="px-7"></div>
          <div className="h-[1px] bg-gray-300 w-[100vw]"></div>
        </div>

        

        <div className="flex flex-col justify-center items-center mt-10 w-full px-8">
        <div className="w-full flex justify-center items-center gap-2 bg-[#7676801F] h-[44px] rounded-[10px] p-2">
            <input
              type="name"
              required
              placeholder="Enter full name"
              className="w-full focus:border-none bg-transparent border-none outline-none h-[44px]"
              onChange={handleNameChange}
            />
          </div>
          {!!true && (
            <label className="text-[10px] text-red-600 text-left self-left w-full pl-2 my-2">
              {nameErrors}
            </label>
          )}
          <div className="w-full flex justify-center items-center mt-4 gap-2 bg-[#7676801F] h-[44px] rounded-[10px] p-2">
            <input
              type="email"
              required
              placeholder="Enter email address"
              className="w-full focus:border-none bg-transparent border-none outline-none h-[44px]"
              onChange={handleEmailChange}
            />
          </div>
          {!!true && (
            <label className="text-[10px] text-red-600 text-left self-left w-full pl-2 my-2">
              {emailErrors}
            </label>
          )}
          <div className="w-full mb-4">
            <p className="text-[15px] text-[#868686] w-full my-2">
              Allow Collaborator to
            </p>

            <Radio.Group onChange={handlePermissionChange} value={permissionState}>
              <Radio className="text-[#868686] text-[15px]" value={1}>Read and Write</Radio>
              <Radio className="text-[#868686] text-[15px]" value={2}>Read Only</Radio>
            </Radio.Group>

          </div>
          <div className="flex">
          <CustomButton
            className="border-0 !bg-[#0099FF] h-[34px] w-[126px] rounded-[32px] text-[white] text-[900] "
            handleClick={handleSendInvite}
            loading={isLoading}
            disabled={email === "" || emailErrors !== "" || name === "" || nameErrors !== ""}
          >
            Send invite
          </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPeoplePopUp;
