import React, { useEffect, useState } from 'react'
import { MdFormatBold, MdFormatItalic, MdFormatUnderlined, MdFormatAlignLeft, MdFormatAlignCenter, MdFormatAlignRight, MdFormatAlignJustify, MdUndo, MdRedo, MdAdd, MdRemove, MdExpandMore, MdFindReplace } from "react-icons/md";
import Dropdown from '../../Common/DropDown';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { editFindAndReplace } from './utils/scriptUtils';

const FormatBar = ({ editor, zoomLevel ,setZoomLevel }) => {
    const isEditorFocus = useSelector(state => state.script.isEditorFocus)
    const [fontInput, setFontInput] = useState(12)
    const [showFontToolTip, setShowFontToolTip] = useState(true)
    const [showZoomToolTip, setShowZoomToolTip] = useState(true)
    const [isfontDropDownOpen, setIsFontDropDownOpen] = useState(false)
    const [isZoomDropDownOpen, setIsZoomDropDownOpen] = useState(false)
    const [showButtonToolTip, setShowButtonToolTip] = useState({
        decrease: true,
        input: true,
        increase: true,
        undo: true,
        redo: true,
        bold: true,
        italic: true,
        underline: true,
        left: true,
        center: true,
        right: true,
        justify: true,
        find: true,
    })

    const [activeFormats, setActiveFormats] = useState({
        bold: false,
        italic: false,
        underline: false,
        alignLeft: false,
        alignCenter: false,
        alignRight: false,
        justify: false,
        fontSize: 12,
        font: 'Courier,monospace',
    });

    useEffect(() => {
        setFontInput(activeFormats.fontSize)
    }, [activeFormats.fontSize])

    const fonts = {
        'Andele Mono': 'Andale Mono',
        'Courier New': 'Courier New',
        'Courier': 'Courier,monospace'
    }
    const fontKeys = Object.fromEntries(Object.entries(fonts).map(([k, v]) => [v, k]));

    const zoomList = [50,75,90,100,125,150,200]

    useEffect(() => {
        if (editor) {
            const updateActiveFormats = () => {
                setActiveFormats({
                    bold: editor.queryCommandState('Bold'),
                    italic: editor.queryCommandState('Italic'),
                    underline: editor.queryCommandState('Underline'),
                    alignLeft: editor.queryCommandState('JustifyLeft'),
                    alignCenter: editor.queryCommandState('JustifyCenter'),
                    alignRight: editor.queryCommandState('JustifyRight'),
                    justify: editor.queryCommandState('JustifyFull'),
                    font: editor.queryCommandValue('FontName'),
                    fontSize: Math.round(parseInt(editor?.selection?.getNode() ? window?.getComputedStyle(editor?.selection?.getNode())?.fontSize?.replace('px', '') : 0) * 0.75)
                });
            };

            editor.on('NodeChange', updateActiveFormats);
            updateActiveFormats()
        }
    }, [editor])

    const execute = (command) => {
        if (editor) {
            editor.execCommand(command)
            editor.focus()
        }
    }

    const hideToolTip = (button) => {
        if (showButtonToolTip[button] === false) return
        setShowButtonToolTip(prev => {
            const newstate = { ...prev }
            newstate[button] = false
            return newstate
        })
    }

    const unHideToolTip = (button) => {
        if (showButtonToolTip[button] === true) return
        setShowButtonToolTip(prev => {
            const newstate = { ...prev }
            newstate[button] = true
            return newstate
        })
    }



    const isWholeNumber = (value) => {
        const wholeNumberPattern = /^[1-9]\d*$/;
        return wholeNumberPattern.test(value);
    }
    useEffect(()=>{
        if(editor){
            const bodyStyle = editor.getBody().style
            bodyStyle.zoom = `${zoomLevel}%`;
            bodyStyle.MozTransform = `scale(${zoomLevel / 100})`;
            bodyStyle.MozTransformOrigin = '0 0';
        }
    },[zoomLevel,editor])

    const PipeIcon = () => <div className='w-[1px] h-[20px] bg-[#777777]' />

    return (
        <div>
            <div className='flex max-lg:hidden grow justify-between gap-2 pe-4 py-5 lg:py-0  items-center max-lg:border-t'>

                <div className='max-lg:hidden flex items-center gap-1 z-[60]'>
                    <Dropdown
                        close={isEditorFocus}
                        onOpen={() => {
                            setIsZoomDropDownOpen(true)
                            setShowZoomToolTip(false)
                        }}
                        onClose={() => { setIsZoomDropDownOpen(false) }}
                        trigger={
                            <Tooltip placement={'bottom'} title={showZoomToolTip ? 'Zoom' : ''}>
                                <button onMouseOver={() => {
                                    if (!isZoomDropDownOpen)
                                        setShowZoomToolTip(true)
                                }} className='flex bg-white hover:bg-[#efefef] justify-between w-[80px] text-[#292929] text-[14px] border-2 rounded-lg p-1 px-2 truncate'>
                                    {zoomLevel + '%'}
                                    <MdExpandMore size={24} color='#CACACA' /></button>
                            </Tooltip>
                        }>
                        <div className=' flex flex-col w-[80px]'>
                            {zoomList.map((zoomVal) =>
                                <button
                                    style={{color: zoomVal === zoomLevel ? '#0099ff' : '', background: zoomVal === zoomLevel ? '#F0F9FF' : '' }}
                                    className='border-b py-2 text-start text-[#444444] text-[13px] px-2 hover:bg-[#e2e2e2]'
                                    onClick={() => {
                                        setZoomLevel(zoomVal)
                                        editor.focus()
                                    }}>
                                    {zoomVal}%  
                                </button>
                            )}
                        </div>
                    </Dropdown>
                </div>
                <PipeIcon />
                <div className='max-lg:hidden flex items-center gap-1 z-[60]'>
                    <Dropdown
                        close={isEditorFocus}
                        onOpen={() => {
                            setIsFontDropDownOpen(true)
                            setShowFontToolTip(false)
                        }}
                        onClose={() => { setIsFontDropDownOpen(false) }}
                        trigger={
                            <Tooltip placement={'bottom'} title={showFontToolTip ? 'Font' : ''}>
                                <button onMouseOver={() => {
                                    if (!isfontDropDownOpen)
                                        setShowFontToolTip(true)
                                }} className='flex bg-white hover:bg-[#efefef] justify-between w-[130px] text-[#292929] text-[14px] border-2 rounded-lg p-1 px-2 truncate'>{activeFormats.font !== '' ? fontKeys[activeFormats.font] : 'Select font'} <MdExpandMore size={24} color='#CACACA' /></button>
                            </Tooltip>
                        }>
                        <div className=' flex flex-col w-[120px]'>
                            {Object.entries(fonts).map(([name, font]) =>
                                <button
                                    style={{ color: activeFormats.font === font ? '#0099ff' : '', background: activeFormats.font === font ? '#F0F9FF' : '' }}
                                    className='border-b py-2 text-start text-[#444444] text-[13px] px-2 hover:bg-[#e2e2e2]'
                                    onClick={() => {
                                        editor?.execCommand('FontName', false, font)
                                    }}>
                                    {name}
                                </button>
                            )}
                        </div>
                    </Dropdown>
                </div>
                <div className='max-lg:hidden flex items-center -ms-1 gap-1 z-[60]'>

                    <Tooltip placement={'bottom'} title={showButtonToolTip['decrease'] ? 'Decrease font size' : ''}>
                        <button
                            onMouseEnter={() => { unHideToolTip('decrease') }}
                            onClick={() => {
                                hideToolTip('decrease')
                                const newFont = fontInput - 1 < 1 ? 1 : fontInput - 1
                                setFontInput(newFont)
                                editor?.execCommand('FontSize', true, newFont + 'pt')
                            }}
                            className='hover:bg-[#ececec] p-1 rounded text-[#777777] active:text-[#0099ff]'>
                            <MdRemove size={20} />
                        </button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['input'] ? 'Font size' : ''}>
                        <input value={fontInput}

                            onClick={() => { hideToolTip('input') }}
                            onChange={(e) => {
                                const val = e.target.value
                                
                                if (val !=="" && (!isWholeNumber(val) || parseInt(val) > 99)) {
                                    return
                                }
                                setFontInput(e.target.value)
                            }}
                            onBlur={(e) => {
                                const font = e.target.value === "" ? 1: parseInt(e.target.value)
                                const newFont = font < 1 ? 1 : font;
                                setFontInput(newFont)
                                editor?.execCommand('FontSize', true, font + 'pt')
                                unHideToolTip('input')
                            }}
                            className='flex text-[14px] justify-between w-[40px] text-center border rounded-lg p-1 px-2' />
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['increase'] ? 'Increase font size' : ''}>
                        <button
                            onMouseEnter={() => { unHideToolTip('increase') }}
                            onClick={() => {
                                hideToolTip('increase')
                                const newFont = fontInput + 1 > 99 ? 99 : fontInput + 1
                                setFontInput(newFont)
                                editor?.execCommand('FontSize', true, newFont + 'pt')
                            }} className='hover:bg-[#ececec] p-1 rounded text-[#777777] active:text-[#0099ff]'>
                            <MdAdd size={20} />
                        </button>
                    </Tooltip>
                </div>

                <PipeIcon />

                <div className='max-lg:hidden flex items-center gap-1'>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['undo'] ? 'Undo' : ''}>

                        <button
                            onMouseEnter={() => { unHideToolTip('undo') }}
                            onClick={() => {
                                execute('Undo')
                                hideToolTip('undo')
                            }}
                            className='hover:bg-[#ececec] p-1 rounded text-[#777777] active:text-[#0099ff]'><MdUndo size={20} /></button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['redo'] ? 'Redo' : ''}>
                        <button
                            onMouseEnter={() => { unHideToolTip('redo') }}
                            onClick={() => {
                                execute('Redo')
                                hideToolTip('redo')
                            }}
                            className=' hover:bg-[#ececec] p-1 rounded text-[#777777] active:text-[#0099ff]'><MdRedo size={20} /></button>
                    </Tooltip>
                </div>

                <PipeIcon />

                <div className='flex items-center gap-1'>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['bold'] ? 'Bold' : ''}>
                        <button
                            onMouseEnter={() => { unHideToolTip('bold') }}
                            className='hover:bg-[#ececec] p-1 rounded'
                            onClick={() => {
                                execute('Bold')
                                hideToolTip('bold')
                            }}
                        >
                            <MdFormatBold size={20} color={activeFormats.bold ? '#0099FF' : '#777777'} />
                        </button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['italic'] ? 'Italic' : ''}>
                        <button
                            onMouseEnter={() => { unHideToolTip('italic') }}
                            className='hover:bg-[#ececec] p-1 rounded'
                            onClick={() => {
                                execute('Italic')
                                hideToolTip('italic')
                            }}>
                            <MdFormatItalic size={20} color={activeFormats.italic ? '#0099FF' : '#777777'} />
                        </button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['underline'] ? 'Underline' : ''}>
                        <button
                            onMouseEnter={() => { unHideToolTip('underline') }}
                            className='hover:bg-[#ececec] p-1 rounded'
                            onClick={() => {
                                execute('Underline')
                                hideToolTip('underline')
                            }}>
                            <MdFormatUnderlined size={20} color={activeFormats.underline ? '#0099FF' : '#777777'} />
                        </button>
                    </Tooltip>
                </div>

                <PipeIcon />

                <div className='flex items-center gap-1'>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['left'] ? 'Left align' : ''}>

                        <button
                            onMouseEnter={() => { unHideToolTip('left') }}
                            className='hover:bg-[#ececec] p-1 rounded'
                            onClick={() => {
                                execute('JustifyLeft')
                                hideToolTip('left')
                            }}>
                            <MdFormatAlignLeft size={20} color={activeFormats.alignLeft ? '#0099FF' : '#777777'} />
                        </button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['center'] ? 'Center align' : ''}>

                        <button
                            onMouseEnter={() => { unHideToolTip('center') }}
                            className='hover:bg-[#ececec] p-1 rounded'
                            onClick={() => {
                                execute('JustifyCenter')
                                hideToolTip('center')
                            }}>
                            <MdFormatAlignCenter size={20} color={activeFormats.alignCenter ? '#0099FF' : '#777777'} />
                        </button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['right'] ? 'Right align' : ''}>

                        <button
                            onMouseEnter={() => { unHideToolTip('right') }}
                            className='hover:bg-[#ececec] p-1 rounded'
                            onClick={() => {
                                execute('JustifyRight')
                                hideToolTip('right')
                            }}>
                            <MdFormatAlignRight size={20} color={activeFormats.alignRight ? '#0099FF' : '#777777'} />
                        </button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={showButtonToolTip['justify'] ? 'Justify' : ''}>

                        <button
                            onMouseEnter={() => { unHideToolTip('justify') }}
                            className='hover:bg-[#ececec] p-1 rounded'
                            onClick={() => {
                                execute('JustifyFull')
                                hideToolTip('justify')
                            }}>
                            <MdFormatAlignJustify size={20} color={activeFormats.justify ? '#0099FF' : '#777777'} />
                        </button>
                    </Tooltip>
                </div>

                <PipeIcon />

                <Tooltip placement={'bottom'} title={showButtonToolTip['find'] ? 'Find and Replace' : ''}>
                    <button
                        onMouseEnter={() => { unHideToolTip('find') }}
                        className='hover:bg-[#ececec] p-1 rounded text-[#777777] active:text-[#0099ff]'
                        onClick={() => {
                            execute('SearchReplace')
                            editFindAndReplace()
                            hideToolTip('find')
                        }}
                    >
                        <MdFindReplace size={20} />
                    </button>
                </Tooltip>

                <PipeIcon />
            </div>
        </div>
    )
}

export default FormatBar