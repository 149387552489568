import { parse } from "parse5";
import { getNameWithoutbrackets } from "../Editor/utils/characterUtils";
import { getAccessToken } from "../../../services/authService";
import { parseToBody } from "../Editor/utils/scriptUtils";

export const getPNodeArrtibute = (node, attribute) => {
    return node.attrs.find(attr => attr.name === attribute)?.value
}

export const fetchRevisions = async (id) => {
    try {

        const token = getAccessToken();
        const url = `${process.env.REACT_APP_DEV_API}/document/${id}/revision`;
        if (!token) {
            throw new Error("A valid session not found");
        }
        const res = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (!res.ok) {
            throw new Error("Error getting response", res);
        }
        const body = await res.json();

        return body.data.length

    } catch (error) {
        console.error(error);
    }
};

let allCharacters = {}

export const getSctipStatistics =  (content, id) => {

    const body = parseToBody(content)
    const scriptStatistics = { scenes: 0, characters: 0, elements: 0, notes: 0, revision: 0 }

    body.childNodes.forEach(node => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            if (node.getAttribute('data-note-list')) {
                const noteList = JSON.parse(node.getAttribute('data-note-list') || "null") || []
                scriptStatistics.notes += noteList.length
            }
            if (node.classList.contains('sceneHeadings')) {
                const elementList = JSON.parse(node.getAttribute('data-element-list') || 'null') || []

                scriptStatistics.scenes++
                scriptStatistics.elements += elementList.length

            }
            if (node.classList.contains('character')) {
                const characterName = node.textContent.toUpperCase().trim() || ''
                if (characterName !== '' && !allCharacters[getNameWithoutbrackets(characterName)]) {
                    allCharacters[getNameWithoutbrackets(characterName)] = true
                    scriptStatistics.characters++
                }
            }
        }

    })

    allCharacters = []
    return scriptStatistics
}

export const updateContent = async (scriptData,content, onComplete = () => {}, onError = () => {}) => {
    const token = getAccessToken()
    const url = `${process.env.REACT_APP_DEV_API}/document/${scriptData?._id}`
    try {
        if (!token) {
            throw new Error('A valid session not found')
        }
        const { author,
            basedOn,
            copyright,
            episodeNumber,
            episodeTitle,
            status,
            title,
            type, ...rest } = scriptData

        const data = {
            author,
            basedOn,
            content,
            copyright,
            episodeNumber,
            episodeTitle,
            status,
            title,
            type
        }
        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            method: 'PUT',
            body: JSON.stringify(data)

        })
        const body = await res.json()
        if (!res.ok) {
            throw new Error(body)
        }
        onComplete()
    } catch (error) {
        console.log(error)
        onError()
    }
}