import React, { useState } from "react";
import backgroundImage from "../../assets/backgrounds/my_profile_background.svg";
import { useDispatch } from "react-redux";
import ProfileSectionHolder from "./profileSectionHolder";
import ProfileHeader from "./profileHeader";
import { logOutAsync } from "../../store/asyncLogout";
import LoaderSpin from "../Common/LoaderSpin";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {

  return (
    <div className="relative w-full h-full overflow-hidden z-20">
      <div className="w-full h-full  flex flex-col">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "110%",
            backgroundRepeat: "no-repeat",
          }}
          className=" w-full h-full  bg-cover bg-bottom basis-[65%]"
        />
        <div className=" bg-[#F6F6F6] basis-[35%]"></div>
      </div>

      <div className=" absolute top-0 left-0 right-0 bottom-0 overflow-y-auto z-20">
        <div className="p-4 min-h-full ">
          <div className="flex justify-between  text-white">
            <p className="text-[20px] font-[500] leading-7 tracking-[1%]">
              My Profile
            </p>
          </div>
          <ProfileHeader />
          <ProfileSectionHolder />
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
