import React, { useState } from 'react'
import { MdMovie, MdAssistant, MdMicrowave, MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import image from '../../assets/backgrounds/script_card_image.webp'
import DeleteMenuPopUp from './ScriptInfo/DeleteMenu';

const ScriptCard = ({ script, setFetch }) => {
    const { title, type, status = 'COMPLETE', author, scenes, latestRevision, _id: id } = script
    const navigate = useNavigate()
    const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false)

    const handleClick = () => {
        if (window.innerWidth < 1024)
            navigate(`/scripts/${id}`)
        else
            navigate(`/editor/${id}`)
    }

    const handleDeleteClick = (e) => {
        e.stopPropagation()
        setIsDeleteMenuOpen(true)
    }

    const TypeIcon = () => {
        return <>
            {type === 'TV' && <MdMicrowave color='#F5A818' size={18} />}
            {type === 'TREATMENT' && <MdAssistant color='#D41E79' size={18} />}
            {type === 'FILM' && <MdMovie color='#868686' size={18} />}
        </>
    }
    const getTypeText = () => {
        return type === 'TV' ? 'TV'
            : type === 'TREATMENT' ? 'Treatment'
                : type === 'FILM' ? 'Film'
                    : ''
    }

    const getStatusText = () => {
        return status === 'COMPLETE' ? 'Complete'
            : status === 'INREVIEW' ? 'In Review'
                : status === 'INCOMPLETE' ? 'In Progress'
                    : ''
    }

    const getStatusBorderColor = () => {
        return status === 'COMPLETE' ? 'border-[#C7EED3]'
            : status === 'INREVIEW' ? 'border-[#FCE2B2]'
                : status === 'INCOMPLETE' ? 'border-[#E4E4E4]'
                    : '';
    }

    const getStatusBackgroundColor = () => {
        return status === 'COMPLETE' ? 'bg-[#F2FFF6]'
            : status === 'INREVIEW' ? 'bg-[#FDF8EE]'
                : status === 'INCOMPLETE' ? 'bg-[#F6F6F6]'
                    : '';
    }

    const getStatusTextColor = () => {
        return status === 'COMPLETE' ? 'text-[#2B653D]'
            : status === 'INREVIEW' ? 'text-[#7A540C]'
                : status === 'INCOMPLETE' ? 'text-[#868686]'
                    : '';
    }
    return (
        <>
            {isDeleteMenuOpen && <DeleteMenuPopUp onDelete={() => {
                setIsDeleteMenuOpen(false)
                setFetch(prev => !prev)
            }} isOpen={isDeleteMenuOpen} setIsOpen={setIsDeleteMenuOpen} id={id} />}
            <div
                onClick={handleClick}
                className=' group bg-white transition-all relative hover:bg-[#f6f6f6] lg:shadow lg:border cursor-pointer lg:w-[calc(33.33%-0.67rem)] h-fit rounded-lg px-3 py-4 '>
                <div className='flex gap-3'>
                    <button
                        onClick={handleDeleteClick}
                        className='absolute max-lg:hidden transition-all group-hover:opacity-100 opacity-0 border hover:text-white text-red-400 flex justify-center items-center top-[-10px] shadow left-[-10px] hover:bg-red-400 bg-white rounded-full size-10'>
                        <MdDelete size={20} />
                    </button>
                    <div className='bg-[#F6F6F6] min-w-[126px] pt-4 h-[137px]'>
                        <img src={image} className='mx-auto object-bottom max-h-full max-w-full' />
                    </div>
                    <div className='flex flex-col w-full max-w-[calc(100%-140px)]'>
                        <div className='flex justify-between items-center flex-wrap'>
                            <p className='text-[#757575] text-nowrap text-[14px] basis-[49%] font-medium'>
                                {scenes ? scenes : 'No'} Scenes
                            </p>
                            <div className='basis-[49%] flex justify-end'>
                                <label className={` border ${getStatusBorderColor()} ${getStatusBackgroundColor()} ${getStatusTextColor()}  text-nowrap w-fit font-medium text-[13px] rounded-full py-[3.5px] px-[11px]`}>
                                    {getStatusText()}
                                </label>
                            </div>

                        </div>

                        <h2 className='text-[#292929] mt-1 text-4 font-bold max-w-full truncate skiptranslate'>
                            {title}
                        </h2>
                        <div className='mt-2 flex gap-2 items-center'>
                            <TypeIcon />
                            <label className='text-[#868686] text-[15px] font-bold'>
                                {getTypeText()}
                            </label>
                        </div>

                    </div>
                </div>
                <div className='mt-3 flex justify-between items-center w-full'>
                    <div className='flex gap-2 w-full'>
                        <p className='text-[14px] text-[#AAAAAA] font-medium tracking-[0.5px]'>Created by</p>
                        <p className='text-[14px] text-[#292929] font-medium tracking-[0.5px] max-w-[calc(100%-100px)] truncate skiptranslate'>{author}</p>
                    </div>
                </div>
                <div className='flex w-full'>
                    <div className='flex gap-1 justify-between flex-wrap w-full'>
                        <div className='flex gap-1 min-w-[177px] basis-[48%]'>
                            <p className='text-[14px] text-[#AAAAAA] font-medium text-nowrap tracking-[0.5px]'>Updated by</p>
                            <p className='text-[14px] text-[#292929] font-medium text-nowrap tracking-[0.5px] max-w-[184px] truncate skiptranslate'>{latestRevision?.updatedBy?.name || ''} </p>
                        </div>

                        <div className='flex gap-1 min-w-[177px] basis-[48%] justify-end'>
                            <p className='text-[14px] text-[#AAAAAA] font-medium text-nowrap  tracking-[0.5px]'>Updated On</p>
                            <p className='text-[14px] text-[#292929] font-medium text-nowrap tracking-[0.5px] '>{latestRevision?.updatedAt?.slice(0, 10) || ''}</p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ScriptCard