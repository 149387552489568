import { createSlice } from "@reduxjs/toolkit";
import { saveUserToLocal } from "../services/authService";

const initialState = {
  session: null,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.session = action.payload.session;
      state.user = {...action.payload.user,photo:null};
    },
    
    logout: async (state) => {
      state.session = null;
      state.user = null;
      window.location.reload();
    },
    updateUser: (state, action) => {
      state.user = action.payload;
      saveUserToLocal(action.payload)
    },
  },
});

export const { login, logout, updateUser } = authSlice.actions;
export default authSlice.reducer;
