import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/authSlice";
import CountrySelector from "./FlagSelector";
import PhoneInput from "./PhoneInput";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getAccessToken } from "../../../services/authService";
import { MdClose } from "react-icons/md";
import ProfileHeader from "../profileHeader";
import { setConnectionErrorOpen } from "../../../store/navSlice";

const ProfileInfo = ({ close }) => {
  const userFromLocal = useSelector((state) => state.auth.user)
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [email, setEmail] = useState(userFromLocal?.email);
  const [userName, setUserName] = useState(userFromLocal?.name);
  const [phone, setPhone] = useState(userFromLocal?.phone);
  const [countryCode, setCountryCode] = useState(userFromLocal?.countryCode);
  const [isLoading, setIsLoading] = useState(true)

  const isUserNotUpdate = userName === userFromLocal?.name && phone === userFromLocal?.phone

  const handleUsernameChange = (e) => {
    const text = e.target.value
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(text)) {
      setUserName(text)
    }
  }

  const handleSave = async () => {
    setIsLoading(true)
    const userId = userFromLocal?._id;
    const accessToken = getAccessToken()

    try {
      if (!userId || !accessToken) {
        throw new Error("access token not found");
      }
      const body = { name: userName, phone, countryCode };
      const res = await fetch(
        `${process.env.REACT_APP_DEV_API}/user/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      const data = await res.json();
      const user = data.data;

      if (!res.ok) {
        throw new Error('response failed')
      }
      dispatch(
        updateUser({
          ...userFromLocal,
          phone: user.phone,
          name: user.name,
          countryCode: user.countryCode,
        })
      );
      setIsLoading(false)
      toast.success("Profile updated sucessfully");
      navigate('/myprofile')
    } catch (err) {
      if (err.message === 'Failed to fetch' || err.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      setIsLoading(false)
      toast.error("Failed to update profile");
      console.error("unable to update the data", err);
    }
  };

  useEffect(() => {
    setEmail(userFromLocal?.email)
    setUserName(userFromLocal?.name)
    setCountryCode(userFromLocal?.countryCode)
    setPhone(userFromLocal?.phone)
  }, [userFromLocal])

  useEffect(() => {
    setIsLoading(!!!userFromLocal)
  }, [userFromLocal])

  return (

    <div className="h-full overflow-hidden">
      <div className="flex gap-2 m-4 items-center justify-between">
        <Link to={"/myprofile"} className="lg:hidden">
          <IoIosArrowBack size={24} color="#292929" />
        </Link>
        <h1 className="grow text-[20px] text-[#292929] font-[700]">
          Profile Information
        </h1>
        <button className="text-[15px] text-[#868686] lg:hidden"
          disabled={phone?.length !== 10 || userName?.trim().length === 0 || isUserNotUpdate}
          onClick={handleSave}>
          Save
        </button>
        {close && <button onClick={close}>
          <MdClose size={24} />
        </button>}
      </div>
      <hr className="mt-8 w-full" />

      <div className="overflow-y-auto pt-4" style={{ height: 'calc(100% - 5rem)' }}>
        <div className="max-lg:hidden">
          <ProfileHeader/>
        </div>
        {!isLoading
          ? <div className="px-4">
            <div className="mb-8">
              <div className="flex flex-col text-[14px] gap-2">
                <label className="text-[#868686] font-medium">Full name</label>
                <input
                  value={userName}
                  onChange={handleUsernameChange}
                  placeholder="Enter full name"
                  className="p-3 rounded-lg border tracking-[0.02em] leading-[22px] font-medium border-[#CACACA] text-[#404040] "
                />
              </div>
              {userName?.trim().length === 0 &&
                <p className="text-red-500 text-sm mt-2">Full name cannot be empty</p>}
            </div>

            <div className="flex flex-col text-[14px] gap-2 mb-8">
              <label className="text-[#868686] font-medium">Email</label>
              <input
                value={email}
                placeholder="Enter user email"
                type="email"
                disabled
                className="p-3 rounded-lg border bg-white text-[#868686] border-[#CACACA] "
              />
            </div>

            <div className="flex flex-col text-[14px] gap-2 mb-8">
              <label className="text-[#868686] font-medium">Mobile Number</label>
              <div className="flex gap-3">
                { <CountrySelector value={countryCode} setValue={setCountryCode} />}
                { <PhoneInput
                  countryCode={countryCode}
                  phoneNumber={phone}
                  setPhoneNumber={setPhone}
                />}
              </div>
              {phone?.length !== 10 && <p className="text-red-500 text-sm mt-2">Enter a valid phone number</p>}

            </div>

            <button className={`text-[15px] rounded-full w-full p-4  text-[#868686] max-lg:hidden
            ${phone?.length !== 10 || userName?.trim().length === 0 || isUserNotUpdate ? 'bg-[#F6F6F6]' : 'bg-[#0099FF] text-white'} `}
              disabled={phone?.length !== 10 || userName?.trim().length === 0 || isUserNotUpdate}
              onClick={handleSave}>
              Save
            </button>
          </div>
          : <div className="w-full h-[80svh] flex items-center justify-center">
            <Spin indicator={
              <LoadingOutlined className="lg:size-10" spin />
            } />
          </div>}
      </div>
    </div>

  );
};

export default ProfileInfo;
