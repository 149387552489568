import { useEffect, useRef, useState } from "react";
import { MdUploadFile } from "react-icons/md";
import typeWriterImage from "../../../assets/backgrounds/type_writer.png";
import sceneYellow from "../../../assets/backgrounds/scene_yellow.png";
import sceneBlue from "../../../assets/backgrounds/scene_blue.png";
import { getAccessToken } from "../../../services/authService";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import LoaderSpin from "../../Common/LoaderSpin";
import CustomButton from "../../CustomButton";
import { Checkbox, Dropdown, Radio, Switch } from "antd";
import SaveMenuPopUp from "../ScriptInfo/SaveMenuPopUp";
import PermissionDropdown from "./PermissionDropdown";
import { useParams } from "react-router-dom";

const options = [
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
          fill="#AAAAAA"
        />
        <path
          d="M14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z"
          fill="#AAAAAA"
        />
      </svg>
    ),
    label: "Screenwriting",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_305_193214)">
          <path
            d="M12 12.75C13.63 12.75 15.07 13.14 16.24 13.65C17.32 14.13 18 15.21 18 16.38V18H6V16.39C6 15.21 6.68 14.13 7.76 13.66C8.93 13.14 10.37 12.75 12 12.75ZM4 13C5.1 13 6 12.1 6 11C6 9.9 5.1 9 4 9C2.9 9 2 9.9 2 11C2 12.1 2.9 13 4 13ZM5.13 14.1C4.76 14.04 4.39 14 4 14C3.01 14 2.07 14.21 1.22 14.58C0.48 14.9 0 15.62 0 16.43V18H4.5V16.39C4.5 15.56 4.73 14.78 5.13 14.1ZM20 13C21.1 13 22 12.1 22 11C22 9.9 21.1 9 20 9C18.9 9 18 9.9 18 11C18 12.1 18.9 13 20 13ZM24 16.43C24 15.62 23.52 14.9 22.78 14.58C21.93 14.21 20.99 14 20 14C19.61 14 19.24 14.04 18.87 14.1C19.27 14.78 19.5 15.56 19.5 16.39V18H24V16.43ZM12 6C13.66 6 15 7.34 15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6Z"
            fill="#AAAAAA"
          />
        </g>
        <defs>
          <clipPath id="clip0_305_193214">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    label: "Invite",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_368_194270)">
          <path
            d="M18 4L19.82 7.64C19.9 7.8 19.78 8 19.6 8H17.62C17.24 8 16.89 7.79 16.73 7.45L15 4H13L14.82 7.64C14.9 7.8 14.78 8 14.6 8H12.62C12.24 8 11.89 7.79 11.73 7.45L10 4H8L9.82 7.64C9.9 7.8 9.78 8 9.6 8H7.62C7.24 8 6.89 7.79 6.72 7.45L5 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V5C22 4.45 21.55 4 21 4H18Z"
            fill="#979797"
          />
        </g>
        <defs>
          <clipPath id="clip0_368_194270">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    label: "Scenes",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
  {
    icon: (

      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_368_194303)">
          <path
            d="M12.5997 18.06C12.2397 18.34 11.7297 18.34 11.3697 18.06L5.21969 13.28C4.85969 13 4.35969 13 3.99969 13.28C3.48969 13.68 3.48969 14.45 3.99969 14.85L10.7597 20.11C11.4797 20.67 12.4897 20.67 13.2197 20.11L19.9797 14.85C20.4897 14.45 20.4897 13.68 19.9797 13.28L19.9697 13.27C19.6097 12.99 19.1097 12.99 18.7497 13.27L12.5997 18.06ZM13.2297 15.04L19.9897 9.78002C20.4997 9.38002 20.4997 8.60002 19.9897 8.20002L13.2297 2.94002C12.5097 2.38002 11.4997 2.38002 10.7697 2.94002L4.00969 8.21002C3.49969 8.61002 3.49969 9.39002 4.00969 9.79002L10.7697 15.05C11.4897 15.61 12.5097 15.61 13.2297 15.04Z"
            fill="#979797"
          />
        </g>
        <defs>
          <clipPath id="clip0_368_194303">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

    ),

    label: "Characters",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
  {

    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_305_193226)">
          <path
            d="M16 4H8C7.45 4 7 4.45 7 5V18C7 18.55 7.45 19 8 19H16C16.55 19 17 18.55 17 18V5C17 4.45 16.55 4 16 4Z"
            fill="#AAAAAA"
          />
          <path
            d="M4 6C3.45 6 3 6.45 3 7V16C3 16.55 3.45 17 4 17C4.55 17 5 16.55 5 16V7C5 6.45 4.55 6 4 6Z"
            fill="#AAAAAA"
          />
          <path
            d="M20 6C19.45 6 19 6.45 19 7V16C19 16.55 19.45 17 20 17C20.55 17 21 16.55 21 16V7C21 6.45 20.55 6 20 6Z"
            fill="#AAAAAA"
          />
        </g>
        <defs>
          <clipPath id="clip0_305_193226">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    label: "Scene Cards",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
  {
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_368_194292)">
          <path
            d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
            fill="#323232"
          />
          <path
            d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
            fill="#323232"
          />
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM16.41 13.89C16.06 13.67 15.59 13.78 15.38 14.13C14.64 15.3 13.38 16 12 16C10.62 16 9.36 15.3 8.62 14.12C8.4 13.77 7.94 13.66 7.59 13.88C7.24 14.1 7.13 14.56 7.35 14.91C8.37 16.54 10.1 17.5 12 17.5C13.9 17.5 15.63 16.53 16.65 14.92C16.87 14.57 16.76 14.11 16.41 13.89Z"
            fill="#979797"
          />
        </g>
        <defs>
          <clipPath id="clip0_368_194292">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

    ),
    label: "Categories & Elements",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
  {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.55859 15.0001C3.51949 14.9598 3.46635 14.9379 3.41139 14.9393C3.35657 14.9409 3.3048 14.9659 3.26819 15.0084L0.953788 17.7059C0.794445 17.8895 0.754098 18.1537 0.850896 18.3797C0.947694 18.6058 1.1634 18.751 1.40099 18.7501H4.00019C4.15064 18.7493 4.29535 18.6899 4.40579 18.5834L5.60019 17.4409C5.6407 17.4031 5.66419 17.3493 5.66499 17.2926C5.66638 17.2356 5.64527 17.1805 5.60659 17.1401L3.55859 15.0001Z"
          fill="#979797"
        />
        <path
          d="M6.6158 16.6961C6.98595 17.0806 7.57734 17.1052 7.9758 16.7527C8.4646 16.3161 9.4158 15.6877 10.291 16.3802C10.7117 16.7054 11.3009 16.6436 11.651 16.2377L11.7966 16.0486C11.8604 15.9657 11.8542 15.8462 11.7822 15.7711L4.8438 8.54856C4.76948 8.47089 4.64997 8.46688 4.571 8.53939L4.3526 8.73772C4.00108 9.10733 3.96327 9.68967 4.2638 10.1052C4.9294 11.0169 4.3262 12.0069 3.9086 12.5169C3.56876 12.931 3.59139 13.5477 3.9606 13.9336L6.6158 16.6961Z"
          fill="#979797"
        />
        <path
          d="M12.2489 15.0834C12.2891 15.1256 12.3446 15.1478 12.4015 15.1444C12.4584 15.1411 12.5112 15.1125 12.5465 15.0659L19.2793 6.33344C19.7493 5.71336 19.7037 4.82608 19.1729 4.26178L15.5025 0.435942C14.9572 -0.11641 14.0977 -0.1453 13.5193 0.369276L5.47452 7.71344C5.43286 7.75143 5.40818 7.80571 5.40636 7.86336C5.40454 7.921 5.42574 7.97685 5.46492 8.01761L12.2489 15.0834Z"
          fill="#979797"
        />
        <path
          d="M19.9992 19.1668C19.9992 18.7066 19.641 18.3335 19.1992 18.3335H7.99922C7.55739 18.3335 7.19922 18.7066 7.19922 19.1668C7.19922 19.6271 7.55739 20.0002 7.99922 20.0002H19.1992C19.641 20.0002 19.9992 19.6271 19.9992 19.1668Z"
          fill="#979797"
        />
      </svg>
    ),
    label: "Revisions",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
  {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.451172 9.01408C0.451172 4.67575 4.73534 1.14575 10.0003 1.14575C15.2653 1.14575 19.5495 4.67659 19.5495 9.01408C19.5495 13.3516 15.2653 16.8816 10.0003 16.8816C8.92924 16.8835 7.86322 16.7345 6.83367 16.4391L1.72867 18.8149C1.67353 18.8404 1.61357 18.8537 1.55284 18.8541C1.40813 18.8542 1.27371 18.7793 1.19776 18.6561C1.12181 18.5329 1.11522 18.3791 1.18034 18.2499L3.08367 14.4441C1.44674 13.1121 0.482977 11.1242 0.451172 9.01408ZM4.54951 9.06242C4.54951 9.75277 5.10915 10.3124 5.79951 10.3124C6.48986 10.3124 7.04951 9.75277 7.04951 9.06242C7.04951 8.37206 6.48986 7.81242 5.79951 7.81242C5.10915 7.81242 4.54951 8.37206 4.54951 9.06242ZM10.0003 7.81242C10.6907 7.81242 11.2503 8.37206 11.2503 9.06242C11.2503 9.75277 10.6907 10.3124 10.0003 10.3124C9.30998 10.3124 8.75034 9.75277 8.75034 9.06242C8.75034 8.37206 9.30998 7.81242 10.0003 7.81242ZM12.917 9.06242C12.917 9.75277 13.4766 10.3124 14.167 10.3124C14.8574 10.3124 15.417 9.75277 15.417 9.06242C15.417 8.37206 14.8574 7.81242 14.167 7.81242C13.4766 7.81242 12.917 8.37206 12.917 9.06242Z"
          fill="#979797"
        />
      </svg>
    ),
    label: "Notes",
    options: [
      {
        label: "Can edit",
        value: "can_edit",
      },
      {
        label: "Only View",
        value: "only_view",
      },
    ],
    value: "can_edit",
  },
];

const ManagePermissions = ({  activeUser,  setActiveUser,setUpdateCollaborators }) => {

  const permissionLookup = {
    1: {
      "OVERVIEW": "READ",
      "SCRIPT": "WRITE"
    },
    2: {
      "OVERVIEW": "READ"
    }
  }
  const permissionLookupKey =  Object.fromEntries(
    Object.entries(permissionLookup).map(
      ([key, value]) => [JSON.stringify(value), parseInt(key)]
    )
  );


  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const menuRef = useRef(null);

  const [isLoading, setisLoading] = useState(null);
  const [users, setUsers] = useState();
  const [permissionState, setPermission] = useState(permissionLookupKey[JSON.stringify(activeUser.permission)])
  const [filteredUsers, setFilteredUsers] = useState();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { id } = useParams();

  const handleSave = async () => {
    const permissions = permissionLookup[permissionState]
    const token = getAccessToken();
    const documentId = id;
    try {
      setisLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/documents/${documentId}/collaborators/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: activeUser.userId,
            permissions
          }),
        }
      )

      const data = await response.json();

      if (!response.ok) {
        throw new Error('')
      }
      toast.success("Permimssions updated successfully");
      setUpdateCollaborators(prev => !prev)
      close();
      setisLoading(false);

    } catch (error) {
      setisLoading(false);
      toast.error('Permimssions failed to update')
    }

  }

  const handlePermissionChange = (e) => {
    setPermission(e.target.value)
  }

  const close = () => {
    setIsVisible(false);
    setTimeout(() => {
      setActiveUser(false);
    }, 300);
  };

  useEffect(() => {
    setIsVisible(activeUser);
  }, [activeUser]);


  const handleSearchUser = (name) => {
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  return (
    <div
      className={`${isVisible ? "bg-[#00000026]" : "bg-transparent"
        } transition-all duration-200 bottom-0 flex items-end z-50 fixed top-0 left-0  w-[100vw] h-[100vh] `}
    >
      <div
        ref={menuRef}
        className={`${isVisible ? "translate-y-0" : "translate-y-full"
          } transition-all duration-300  w-full mx-auto rounded-t-2xl bg-white shadow flex flex-col items-center h-[280px]`}
      >
        <div className="bg-[#F6F6F6] w-[100vw]  rounded-md">
          <div className="flex justify-center w-full py-4 px-7">
            <button
              className="text-[#007AFF] text-[400] absolute left-4"
              onClick={() => setActiveUser(false)}
            >
              Back
            </button>
            <div className="text-[17px] font-[700]">Permissions</div>
            <div></div>
          </div>

          <div className="h-[1px] bg-gray-300 w-[100vw]"></div>
        </div>
        <div className="mt-8">
          <p className=" text-start mb-4 text-[#292929] font-medium">Allow {activeUser.name} to</p>
          <Radio.Group onChange={handlePermissionChange} value={permissionState}>
            <Radio className="text-[#868686] text-[16px]" value={1}>Read and Write</Radio>
            <Radio className="text-[#868686] text-[16px]" value={2}>Read Only</Radio>
          </Radio.Group>
        </div>

        {/* <div className="bg-[white] w-full">
          <div className="flex justify-between px-4 mx-4 py-4 bg-[#F6F6F6] my-3 rounded-lg">
            <p>Allow edit all sections</p>
            <Switch />
          </div>
        </div> */}
        {/* <div className="bg-[white] w-full flex-grow overflow-auto ">
          <div className="flex justify-between rounded-lg bg-white px-4 py-2">
            <p className="font-[500]">Make as Admin</p>
            <Checkbox />
          </div>
          {options.map((option) => (
            <div className="flex justify-between w-full h-[56px]  items-center px-4 bg-[white] my-[1px] border-b-1">
              <div className="flex items-center gap-2 justify-between">
                {option.icon}
                <div className="flex flex-col">
                  <p className="text-[16px] font-[500]">{option.label}</p>
                </div>
              </div>
              
              <div
                className="text-[13px] text-[#868686] flex justify-center items-center gap-2"
                onClick={() => setIsDropDownOpen(true)}
              >
                <div className="Eudoxus font-[500] text-[#868686]">
                  {option.options.find((opt) => opt.value).label}
                </div>
                <div className="flex justify-center items-center">
                  {" "}
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.175 0.158447L5 3.97511L8.825 0.158447L10 1.33345L5 6.33345L0 1.33345L1.175 0.158447Z"
                      fill="#868686"
                    />
                  </svg>{" "}
                </div>
              </div>
            </div>
          ))}
        </div> */}
        <div className="">
          <button
            className="border-0 my-8 !bg-[#0099FF] py-2 w-[200px] rounded-[32px] text-[white] text-[900]"
            onClick={() => handleSave(false)}
          >
            {isLoading? <LoaderSpin color={'white'}/>:'Save Permissions'}
          </button>
        </div>
      </div>
      {isDropDownOpen && (
        <PermissionDropdown
          isSaveOpen={isDropDownOpen}
          setIsSaveOpen={setIsDropDownOpen}
        />
      )}
    </div>
  );
};

export default ManagePermissions;
