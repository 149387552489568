import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineRemoveRedEye, MdCheckCircle, MdClose } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa6";
import { LoadingOutlined } from "@ant-design/icons";
import SucessSplashScreen from "./sucessSplashScreen";
import { toast } from "react-toastify";
import { getAccessToken } from "../../../services/authService";
import { Spin } from "antd";
import { setConnectionErrorOpen } from "../../../store/navSlice";
import { useDispatch } from "react-redux";
const ChangePassword = ({ close }) => {
  const userFromLocal = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch()

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showSucess, setShowSucess] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  const isPasswordCapital = /[A-Z]/.test(newPass);
  const isPasswordLetgth = newPass.length >= 6;
  const isPasswordSymbol = symbolRegex.test(newPass);

  const isPasswordMatch = confirmPass === newPass;
  const isPasswordSame = newPass === currentPass && currentPass !== ''

  const allConditionMatch =
    currentPass != "" &&
    isPasswordMatch &&
    isPasswordCapital &&
    isPasswordLetgth &&
    isPasswordSymbol &&
    !isPasswordSame

  const navigate = useNavigate();
  const handlePasswordChange = async () => {
    setIsLoading(true)
    const userId = userFromLocal?._id;
    const accessToken = getAccessToken()
    try {
      if (!userId || !accessToken) {
        throw new Error("access token not found");
      }
      const body = { oldPassword: currentPass, newPassword: newPass };
      const res = await fetch(
        `${process.env.REACT_APP_DEV_API}/iam/change-password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      if (!res.ok) {
        const data = await res.json()
        toast.error(data.message)
        throw new Error(res);
      }
      setIsLoading(false)
      setShowSucess(true);
      setTimeout(() => {
        setShowSucess(false);
        navigate("/myprofile");
      }, 2000);
    } catch (err) {
      if (err.message === 'Failed to fetch' || err.name === 'TypeError') {
        dispatch(setConnectionErrorOpen(true))
      }
      setIsLoading(false)
      console.error("unable to update the data", err);
    }
  };

  return (
    <>
      {!showSucess ? <div className="h-full overflow-y-hidden">
        <div className="flex gap-2 m-4 items-center justify-between">
          <Link to={"/myprofile"} className="lg:hidden">
            <IoIosArrowBack size={24} color="#292929" />
          </Link>
          <h1 className="grow text-[20px] text-[#292929] font-[700]">
            Password Security
          </h1>
          {close && <button onClick={close}>
            <MdClose size={24} />
          </button>}

        </div>
        <hr className="mt-8 w-full" />
        <div className="overflow-y-auto " style={{ height: 'calc( 100% - 5rem)' }}>

          <div className="px-4 mt-4">
            <h2 className="text-[18px] text-[#757575] font-[700] mb-8">
              Change Password
            </h2>
            <div className="flex flex-col text-[14px] gap-2 mb-8">
              <label className="text-[#868686]">Current Password</label>
              <input
                type="password"
                onChange={(e) => {
                  setCurrentPass(e.target.value);
                }}
                value={currentPass}
                placeholder={"Enter Current Password"}
                className="p-3 rounded-xl border border-[#CACACA] "
                autoComplete="new-password"
              />
            </div>
            <form autoComplete="off" className="flex flex-col text-[14px] gap-2 mb-8">
              <label className="text-[#868686]">New Password</label>
              <div className="w-full relative">
                <input
                  value={newPass}
                  onChange={(e) => {
                    setNewPass(e.target.value);
                  }}
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder={"Enter Password"}
                  className="p-3 w-full rounded-xl border border-[#CACACA] "
                  autoComplete="off"
                />
                {isPasswordVisible ? (
                  <MdOutlineRemoveRedEye
                    color="#AAAAAA"
                    size={24}
                    className="absolute top-3 right-3"
                    onClick={() => {
                      setIsPasswordVisible((prev) => !prev);
                    }}
                  />
                ) : (
                  <FaRegEyeSlash
                    color="#AAAAAA"
                    size={24}
                    className="absolute top-3 right-3"
                    onClick={() => {
                      setIsPasswordVisible((prev) => !prev);
                    }}
                  />
                )}
              </div>
              {isPasswordSame && <p className="text-red-500">The new password should be different from your current password</p>}
            </form>
            <div className="flex flex-col text-[14px] gap-2 mb-8">
              <label className="text-[#868686]">Confirm Password</label>
              <input
                value={confirmPass}
                onChange={(e) => {
                  setConfirmPass(e.target.value);
                }}
                type="password"
                placeholder={"Enter Password"}
                className="p-3 rounded-xl border border-[#CACACA] "
                autoComplete="off"
              />
              {!isPasswordMatch && confirmPass !== "" && (
                <p className="text-red-500">Password does not match</p>
              )}
            </div>
            <div className="mt-2">
              <div className="flex gap-4 mb-4">
                <MdCheckCircle
                  size={24}
                  color={isPasswordCapital ? "#48A966" : "#CACACA"}
                />
                <p className="text-[#868686]">
                  Use a capital letter in the password
                </p>
              </div>
              <div className="flex gap-4 mb-4">
                <MdCheckCircle
                  size={24}
                  color={isPasswordLetgth ? "#48A966" : "#CACACA"}
                />
                <p className="text-[#868686]">
                  Password must be 6 or more characters
                </p>
              </div>
              <div className="flex gap-4 mb-4">
                <MdCheckCircle
                  size={24}
                  color={isPasswordSymbol ? "#48A966" : "#CACACA"}
                />
                <p className="text-[#868686]">
                  Password must have at least one symbol
                </p>
              </div>
            </div>
            <div className="mt-[68px] mb-8  ">
              <button
                style={{ color: allConditionMatch ? "white" : "#AAAAAA" }}
                className={`text-4 duration-200 font-[700] p-4 w-full text-center  rounded-full 
                      bg-[${allConditionMatch && !isLoading ? "#0099FF" : "#F6F6F6"}]`}
                disabled={!allConditionMatch || isLoading}
                onClick={handlePasswordChange}
              >
                {isLoading ?
                  <Spin indicator={
                    <LoadingOutlined className="lg:size-10" />
                  } />
                  : 'Change Password'}
              </button>
            </div>
          </div>
        </div>
      </div>
        : <SucessSplashScreen />}
    </>
  );
};

export default ChangePassword;
