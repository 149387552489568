import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCreateScriptOpen: false,
  isConnectionErrorOpen: false,
  isTranslateOpen: false,
  selectedLanguage: localStorage.getItem('preferred_language') || 'en',
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setCreateSciptOpen: (state, action) => {
      state.isCreateScriptOpen = action.payload;
    },
    setConnectionErrorOpen: (state, action) => {
      state.isConnectionErrorOpen = action.payload;
    },
    setIsTranslateOpen:(state, action) => {
      state.isTranslateOpen = action.payload;
    },
    setSelectedLanguage :(state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const { setCreateSciptOpen, setConnectionErrorOpen, setIsTranslateOpen,setSelectedLanguage } = navSlice.actions;
export default navSlice.reducer;
