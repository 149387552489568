import { useRef, useState } from "react"
import { getTitle, goToScene, setScene, timeColors } from "../../utils/sceneutils"
import { MdArrowBackIos, MdCancel } from "react-icons/md"
import { ColorPicker, Tooltip } from "antd"
import SuggestionDropDown from "../SuggestionDropDown"

const SceneDetails = ({ scene, scenes, index, editor, setUpdateScenes, setOpenedScene, permissionToEdit }) => {
    const [sceneState, setSceneState] = useState(scene)
    const [introSuggestionOpen, setIntroSuggestionOpen] = useState(false)
    const [timeSuggestionOpen, setTimeSuggestionOpen] = useState(false)

    const timeOftheDayList = ['DAY', 'NIGHT', 'MORNING', 'AFTERNOON', 'SUNRISE', 'SUNSET', 'DUSK', 'LATER', 'EVENING']
    const introList = ['INT', 'EXT', 'I/E']

    const colorInputRef = useRef(null)


    const [titleSpit, setTitleSplit] = useState(getTitle(sceneState?.title))



    const handleColorChange = (color) => {
        setSceneState(prev => { return { ...prev, color } })
    }

    const handleTitleChange = (e) => {
        const title = e.target.value
        setTitleSplit(prev => { return { ...prev, title } })
    }

    const setIntro = (intro) => {
        setTitleSplit(prev => { return { ...prev, intro } })
    }

    const handleIntroChange = (e) => {
        const intro = e.target.value
        setIntro(intro)
        setIntroSuggestionOpen(true)
    }

    const setTime = (time) => {
        setTitleSplit(prev => { return { ...prev, time } })
    }
    const handleTimeChange = (e) => {
        const time = e.target.value
        setTime(time)
        setTimeSuggestionOpen(true)
    }


    const handleSummaryChange = (e) => {
        const summary = e.target.value
        setSceneState(prev => { return { ...prev, summary } })
    }

    const handleSave = () => {
        goToScene(index - 1, editor)

        const selectedNode = editor.selection.getNode()
        if (!selectedNode || !titleSpit) {
            setOpenedScene(null)
            return
        }

        const { intro, title, time } = titleSpit

        const sceneObject = {
            title: `${intro ? intro + '. ' : ''}${title}${time ? ' - ' + time : ''}`,
            color:sceneState.color,
            summary:sceneState.summary,
        }
        setScene(editor, sceneObject, index-1)
        setUpdateScenes(prev => !prev)
        setOpenedScene(null)
        editor.fire("change")
    }

    return <div className="h-full">
        <div className="flex items-center border-b pb-2 overflow-hidden">
            <button onClick={() => { setOpenedScene(null) }}
                className="hover:text-[#636363] flex justify-center p-1 items-center">
                <MdArrowBackIos size={15} />
            </button>

            <h2 className="text-[13px]">Scene {index}</h2>
        </div>
        <div className="overflow-y-auto h-[calc(100%-20px)] p-2">
            
            {/* scene# and intro */}
            <div className="flex gap-4 mt-4">
                <div className="grow basis-[50%]">
                    <label className=" text-[12px] text-[#868686]">Scene #</label>
                    <div className="w-full border border-[#CACACA] p-3  py-2 text-[12px] text-[#868686]  rounded-lg" >{index}</div>
                </div>
                <div className="grow basis-[50%]">
                    <label className=" text-[12px] text-[#868686]">Intro</label>

                    <div className="relative z-20">
                        <input disabled={!permissionToEdit} onChange={handleIntroChange} onFocus={()=>{setIntroSuggestionOpen(true)}} value={titleSpit?.intro} className="w-full uppercase mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />
                        <SuggestionDropDown suggestions={introList} value={titleSpit?.intro} isOpen={introSuggestionOpen} setIsOpen={setIntroSuggestionOpen} setText={setIntro} />
                    </div>
                </div>

            </div>

            {/* Heading */}
            <label className=" text-[12px] text-[#868686]">Heading</label>
            <input disabled={!permissionToEdit} value={titleSpit?.title} onChange={handleTitleChange} className="w-full mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />
            
            {/* TIME OF THE DAY */}
            <label className="mb-2 text-[12px] text-[#868686]">Time of day</label>
            <div className="relative z-10">
                <input disabled={!permissionToEdit} onChange={handleTimeChange} onFocus={()=>{setTimeSuggestionOpen(true)}} value={titleSpit?.time} className="w-full uppercase mb-2 border border-[#CACACA] p-3 py-2 text-[12px] rounded-lg" />
                <SuggestionDropDown suggestions={timeOftheDayList} value={titleSpit?.time} isOpen={timeSuggestionOpen} setIsOpen={setTimeSuggestionOpen} setText={setTime} />
            </div>

            {/* color */}
            <div className="flex mb-1 gap-4 items-center">
                <div
                    className="flex items-center">
                    <div className="flex gap-2 items-center">
                        <ColorPicker className="z-0" disabled={!permissionToEdit} value={sceneState.color || timeColors[titleSpit.time] || '#d9dee3'} size="medium" onChange={(_, hex) => { handleColorChange(hex) }} />
                        <p className=" text-[12px] text-[#868686]">Color</p>

                    </div>

                </div>

                <Tooltip title={'Default color'} placement="bottom">
                    <button disabled={!permissionToEdit} onClick={() => { setSceneState(prev => { return { ...prev, color: '' } }) }}
                        className={`text-[12px] text-[#868686] ${permissionToEdit ? 'hover:text-[#CACACA]' : 'cursor-not-allowed'} rounded-lg text-start`}>
                        <MdCancel size={20} />
                    </button>
                </Tooltip>

            </div>

            <label className="mb-2 text-[12px] text-[#868686]">Scene summary</label>
            <textarea disabled={!permissionToEdit} onChange={handleSummaryChange} value={sceneState?.summary} className="w-full h-[140px] text-[#3e3e3e] border border-[#CACACA] p-3 text-[12px] rounded-lg" />

            {permissionToEdit && <button onClick={handleSave} className="bg-[#0099ff] p-3 rounded-full text-white text-[12px] py-2 mt-6 font-medium w-full">Save</button>}

        </div>
    </div>
}

export default SceneDetails