import React, { useRef, useEffect, useState } from 'react';
import EditorComponent from '../Editor/utils/EditorComponent';
import { useSelector, useDispatch } from 'react-redux';
import { setNotes, setEditor, setCurrentDocumentType, setContent } from '../../../store/scriptSlice';
import { getAccessToken } from '../../../services/authService';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'antd'
import EmptyPage from '../Editor/sidepanel/EmptyPage';
import { getAllNotes } from './functions';



const formatClasses = {
  "Film Script": [
    "sceneHeadings",
    "action",
    "character",
    "dialogue",
    "parentheses",
    "transition",
    "flashBack",
    "montage",
  ],
  "TV Script": [
    "coldOpening",
    "newAct",
    "sceneHeadings",
    "action",
    "character",
    "dialogue",
    "parentheses",
    "transition",
    "endAct",
    "flashBack",
  ],
  Treatment: ["tratmentPara"],
};

const Editor = () => {
  const editorRef = useRef(null);
  const documentTypeRef = useRef(null);

  const [loading, setLoading] = useState(1);
  const [scriptData, setScriptData] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [currentScene, setCurrentScene] = useState(null)
  const [editorState, setEditorState] = useState({});
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const dispatch = useDispatch();
  const { id } = useParams()

  const scenes = useSelector(state => state.script.scenes)
  const content = useSelector(state => state.script.content)

  const initializeEditor = () => {
    editorRef.current.on("keydown", (e) => { e.preventDefault() })
    const body = editorRef.current.getBody()
    body.setAttribute("data-show-notes", "1")
  }

  const getSctiptType = (type) => {
    return type === "FILM"
      ? "Film Script"
      : type === "TV"
        ? "TV Script"
        : type === "TREATMENT"
          ? "Treatment"
          : "";
  };

  const fetchScriptData = async () => {
    setLoading(1);
    const token = getAccessToken();
    const url = `${process.env.REACT_APP_DEV_API}/document/${id}`;
    try {
      if (!token) {
        throw new Error("A valid session not found");
      }
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        throw new Error("Error getting response");
      }
      const body = await res.json();
      const data = body.data;
      setDocumentType(getSctiptType(data.type))
      dispatch(setCurrentDocumentType(data.type))
      setScriptData(data)
      setIsPopUpOpen(data.content === '</p>')
      setLoading(0)
    } catch (error) {
      setLoading(2)
      toast.error('Failed to load script')
      console.error(error)
    }
  }


  useEffect(() => {
    fetchScriptData().then(() => {
      setTimeout(() => {
        if (editorRef.current) {
          const editor = editorRef.current;
          dispatch(setEditor(editor));
        }
      }, 3000);
    });
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      initializeEditor()
      getAllNotes(editorRef.current, dispatch);
      const editor = editorRef.current;
      dispatch(setEditor(editor))

    } {
      console.log("no editor")
    }
  }, [editorRef.current])



  const setCurrentFormat = (format) => {
    // Logic to set the current format
    setEditorState((prevState) => ({
      ...prevState,
      currentFormat: format,
    }));
  };

  return (
    <>

      {loading == 0 &&
        <>
          {scriptData.content === '<p></p>' ?
            <div className='w-[100vw]'>
              <EmptyPage title={'Your script is empty'} subtitle={'Type your script to add notes'} />
            </div>

            :
            <EditorComponent
              isDisabled={true}
              editorRef={editorRef}
              formatClasses={formatClasses}
              content={scriptData.content}
              documentType={documentType}
              setCurrentFormat={setCurrentFormat}
              documentTypeRef={documentTypeRef}
              scenes={scenes}
              scriptData={scriptData}
              setEditorState={setEditorState}
            />}
        </>}
    </>
  );
};

export default Editor;